import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: 100%;
`

export const ProductsContainer = styled.div`
  flex: 1;
  transition: all 0.2s;
  opacity: ${props => props.opacity};
`
