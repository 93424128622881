import React, { useState, useRef } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { ReactComponent as FilterIcon } from 'Assets/Icons/Filter.svg'
import { ReactComponent as FrozenIcon } from 'Assets/Icons/Frozen.svg'
import { ReactComponent as RefrigeratedIcon } from 'Assets/Icons/Refrigerated.svg'
import { TEMPERATURES } from 'Config/constants'
import { useClickOutside } from 'Components/utils/customHooks'

import { FilterButton, FilterContainer, MainText } from './styled'

const Temperature = ({ temperature, setTemperature }) => {
  const filterRef = useRef()
  const [displayFilter, setDisplayFilter] = useState('none')
  const closeFilters = () => setDisplayFilter('none')
  useClickOutside(filterRef, closeFilters)
  const { t } = useTranslation()

  const returnContent = () => {
    switch (temperature) {
      case TEMPERATURES.frozen:
        return (
          <>
            <MainText>{t('frozen')}</MainText>
            <FrozenIcon style={{ marginLeft: '12px' }} />
          </>
        )
      case TEMPERATURES.refrigerated:
        return (
          <>
            <MainText>{t('refrigerated')}</MainText>
            <RefrigeratedIcon style={{ marginLeft: '12px' }} />
          </>
        )
      case TEMPERATURES.room:
        return t('room')
      default:
        return (
          <>
            <MainText>{t('filterTemperature')}</MainText>
            <FilterIcon />
          </>
        )
    }
  }

  return (
    <div ref={filterRef}>
      <FilterButton onClick={() => setDisplayFilter(value => (value === 'none' ? 'flex' : 'none'))}>
        {returnContent()}
      </FilterButton>
      <FilterContainer display={displayFilter}>
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t('frozen')} <FrozenIcon style={{ marginLeft: '12px' }} />
            </div>
          }
          labelPlacement='start'
          checked={temperature === TEMPERATURES.frozen}
          onClick={() => setTemperature(TEMPERATURES.frozen)}
        />
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t('refrigerated')} <RefrigeratedIcon style={{ marginLeft: '12px' }} />
            </div>
          }
          labelPlacement='start'
          checked={temperature === TEMPERATURES.refrigerated}
          onClick={() => setTemperature(TEMPERATURES.refrigerated)}
        />
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label={t('room')}
          labelPlacement='start'
          checked={temperature === TEMPERATURES.room}
          onClick={() => setTemperature(TEMPERATURES.room)}
        />
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label={t('any')}
          labelPlacement='start'
          checked={temperature === ''}
          onClick={() => setTemperature('')}
        />
      </FilterContainer>
    </div>
  )
}

Temperature.defaultProps = {
  temperature: '',
  setTemperature: () => {},
}

Temperature.propTypes = {
  temperature: PropTypes.string,
  setTemperature: PropTypes.func,
}

export default Temperature
