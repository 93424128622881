import {
  CHECK_APPROVER_CONFIRMED_CART,
  CONFIRM_CART,
  DELETE_PROVIDER_PRODUCT,
  EDIT_CART,
  FETCH_APPROVED,
  FETCH_CART,
  FETCH_ORIGIN_CENTERS,
  FETCH_PENDING_APPROVAL,
  FETCH_REJECTED,
  IMPERSONATE,
  REJECT_CART,
  SET_APPROVED,
  SET_APPROVER_CONFIRMED_CART,
  SET_CART,
  SET_CART_DELIVERIES,
  SET_CHECKOUT_DATES_UPDATED,
  SET_ERROR_CART,
  SET_FILTER_CENTER,
  SET_FILTER_PROVIDER,
  SET_FILTER_REQUEST_NUMBER,
  SET_IS_FETCHING_APPROVED,
  SET_IS_FETCHING_CART,
  SET_IS_FETCHING_PENDING_APPROVAL,
  SET_IS_FETCHING_REJECTED,
  SET_IS_UPDATING_CART,
  SET_ORIGIN_CENTERS,
  SET_PENDING_APPROVAL,
  SET_REJECTED,
  SET_REQUEST_ORDER,
  SYNC_CART,
  SET_WEEK_SUMMARY,
  SET_IS_LOADING_WEEK_SUMMARY,
  FETCH_WEEK_SUMMARY,
  SET_SUMMARY_CENTER,
  SET_IS_FETCHING_SUMMARY_CENTER,
} from './types'

export const fetchPendingApproval = () => ({
  type: FETCH_PENDING_APPROVAL,
})

export const setPendingApproval = data => ({
  type: SET_PENDING_APPROVAL,
  payload: data,
})

export const setIsFetchingPendingApproval = data => ({
  type: SET_IS_FETCHING_PENDING_APPROVAL,
  payload: data,
})

export const fetchRejected = () => ({
  type: FETCH_REJECTED,
})

export const setRejected = data => ({
  type: SET_REJECTED,
  payload: data,
})

export const setIsFetchingRejected = data => ({
  type: SET_IS_FETCHING_REJECTED,
  payload: data,
})

export const fetchApproved = () => ({
  type: FETCH_APPROVED,
})

export const setApproved = data => ({
  type: SET_APPROVED,
  payload: data,
})

export const setIsFetchingApproved = data => ({
  type: SET_IS_FETCHING_APPROVED,
  payload: data,
})

export const fetchCart = requestOrderId => ({
  type: FETCH_CART,
  payload: { requestOrderId },
})

export const setCart = data => ({
  type: SET_CART,
  payload: data,
})

export const setIsFetchingCart = data => ({
  type: SET_IS_FETCHING_CART,
  payload: data,
})

export const setRequestOrder = data => ({
  type: SET_REQUEST_ORDER,
  payload: data,
})

export const confirmCart = (requestOrderId, params, successCallback) => ({
  type: CONFIRM_CART,
  payload: { requestOrderId, params },
  successCallback,
})

export const rejectCart = (requestOrderId, successCallback) => ({
  type: REJECT_CART,
  payload: { requestOrderId },
  successCallback,
})

export const setIsUpdatingCart = data => ({
  type: SET_IS_UPDATING_CART,
  payload: data,
})

export const editCart = (id, payload) => ({
  type: EDIT_CART,
  id,
  payload,
})

export const syncCart = () => ({
  type: SYNC_CART,
})

export const setErrorCart = data => ({
  type: SET_ERROR_CART,
  payload: data,
})

export const deleteProviderProducts = providerId => ({
  type: DELETE_PROVIDER_PRODUCT,
  payload: { providerId },
})

export const impersonate = successCallback => ({
  type: IMPERSONATE,
  payload: { successCallback },
})

export const setFilterCenter = center => ({
  type: SET_FILTER_CENTER,
  payload: center,
})

export const setFilterProvider = providers => ({
  type: SET_FILTER_PROVIDER,
  payload: providers,
})

export const setFilterRequestNumber = requestId => ({
  type: SET_FILTER_REQUEST_NUMBER,
  payload: requestId,
})

export const setCheckoutDatesUpdated = data => ({
  type: SET_CHECKOUT_DATES_UPDATED,
  payload: data,
})

export const setCartDeliveries = data => ({
  type: SET_CART_DELIVERIES,
  payload: data,
})

export const fetchOriginCenters = () => ({
  type: FETCH_ORIGIN_CENTERS,
})

export const setOriginCenters = data => ({
  type: SET_ORIGIN_CENTERS,
  payload: data,
})

export const checkApproverConfirmedCart = payload => ({
  type: CHECK_APPROVER_CONFIRMED_CART,
  payload,
})

export const setApproverConfirmedCart = payload => ({
  type: SET_APPROVER_CONFIRMED_CART,
  payload,
})

export const setLoadingWeekSummary = payload => ({
  type: SET_IS_LOADING_WEEK_SUMMARY,
  payload,
})

export const fetchWeekSummary = (center, startDate, endDate) => ({
  type: FETCH_WEEK_SUMMARY,
  payload: { center, startDate, endDate },
})

export const setWeekSummary = payload => ({
  type: SET_WEEK_SUMMARY,
  payload,
})

export const setSummaryCenter = payload => ({
  type: SET_SUMMARY_CENTER,
  payload,
})

export const setIsFetchingSummary = payload => ({
  type: SET_IS_FETCHING_SUMMARY_CENTER,
  payload,
})
