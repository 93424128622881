import styled, { css } from 'styled-components'
import Radio from '@material-ui/core/Radio'
import Tab from '@material-ui/core/Tab'
import colors from 'Assets/colors'

export const Container = styled.div`
  padding: 2.5rem 1.25rem 0;
  height: calc(100vh - 168px);
  overflow: auto;

  ${props =>
    props.$tutorial &&
    css`
      height: calc(100vh - 216px);
    `}
`

export const Grow = styled.div`
  flex-grow: 1;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`

export const FiltersContainer = styled.div``

export const RequestsContainer = styled.div``

export const SingleRequestContainer = styled.div`
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  margin: 12px 0;
  background: #fafafa;
`

export const RequestHeader = styled.div`
  background: ${colors.black};
  color: ${colors.white};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
`

export const RequestHeaderInfo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`

export const ShowProductsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  padding: 16px;
  cursor: pointer;
`

export const ShowMoreCopy = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
`

export const ShowLessCopy = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.secondary};
`

export const LowerMenu = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fafafa;
  border-top: 1px solid rgba(51, 51, 51, 0.24);
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
`

export const Center = styled.div`
  padding: 24px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CenterHelper = styled.div`
  border-right: 1px solid rgba(51, 51, 51, 0.24);
`

export const CenterInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const CenterLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
  color: ${props => props.theme.colors.gray40l};
`

export const CenterPlace = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.black};
`

export const CenterAction = styled.div`
  button {
  }
`

export const Selector = styled.button`
  background: ${colors.secondary};
  border-radius: 0.5rem;
  cursor: pointer;
  margin-left: 0.5rem;
  padding: 1rem 1.125rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const FinishButton = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.cart};
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const TotalItems = styled.div`
  background: #ffffff;
  border-radius: 4px;
  padding: 0px 8px;
  margin-left: 1rem;
`

export const ButtonLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-right: auto;
`
export const ButtonExport = styled.div`
  background-color: ${colors.secondary};
  font-family: Inter;
  font-style: normal;
  max-width: 100%;
  width: 288px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-transform: none;
  color: ${colors.white};
  transition: all 0.2s;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  height: 56px;
  opacity: ${({ disabled }) => (disabled ? '.4' : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  & svg {
    margin-right: 0.5rem;
    fill: ${colors.white};
    & path {
      fill: ${colors.white};
    }
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &.disabled {
    background-color: ${colors.gray40l};
    pointer-events: none;
  }
`

export const StyledRadio = styled(Radio)`
  color: ${colors.secondary};
`

export const Bullet = styled.div`
  background: ${colors.red};
  color: ${colors.white};
  border-radius: 8px;
  height: 8px;
  width: 8px;
  text-align: center;
  margin-right: 8px;
`

export const TabButton = styled(Tab)`
  text-transform: none !important;
  size: 24px !important!;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.colors[props.color]};
  padding-bottom: 1rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 10px 10px 10px;
`

export const ActionButtonDownload = styled.div`
  background-color: ${colors.white};
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s;
  height: 40px;
  display: inline-block;
  width: 400px;
  padding: 8px 12px;
  border:1px solid rgba(0, 0, 0, 1);
  border-color: 'black'

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const TabSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`

export const RightTabSection = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  column-gap: 2rem;
`

export const SmallActionButtonDownload = styled(ActionButtonDownload)`
  width: 200px;
  justify-content: center;
  padding: 6px 8px 6px 8px;
  display: row;
  align-items: center;
`

export const ButtonLiteralCenter = styled(ButtonLiteral)`
  text-align: center;
`
export const ButtonContainerSmall = styled(ButtonContainer)`
  display: flex;
  justify-content: center;
  margin: 0px 0px 0px 0px;
`
