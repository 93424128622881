import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MinAndMulti } from 'Components/molecules/productCard/styled'
import { percentage } from 'Config/utils'
import { financial } from 'Components/utils/cartCalculator'

import { ProductCardContainer } from '../styled'

import ProductButton from './productButton'
import ProductDifference from './productDifference'
import ProductPrice from './productPrice'

const ProviderProduct = ({ product, onClickRemove, onClickRemoveProduct }) => {
  const { t } = useTranslation()

  const { name, previous_price: previousPrice, price, min, multiple } = product

  const differencePrice = previousPrice ? price - previousPrice : null

  return (
    <ProductCardContainer>
      <div className='product-content'>
        <div className='product-left'>
          <span className='products-name'>{name}</span>
        </div>
        <div className='product-separator' />
        <div className='product-right'>
          <div className='product-total'>
            <span>
              <div style={{ flex: 1, justifyContent: 'space-around' }}>
                {!differencePrice || Math.abs(differencePrice) < percentage(previousPrice, 0.01) ? (
                  <ProductPrice product={product} />
                ) : (
                  <ProductDifference product={product} />
                )}
                <MinAndMulti>{`${t('min')}: ${financial(min)} / ${t('multi')}: ${financial(multiple)}`}</MinAndMulti>
              </div>
            </span>
          </div>
          <div className='product-amount'>
            <ProductButton
              product={product}
              onClickRemove={onClickRemove}
              onClickRemoveProduct={onClickRemoveProduct}
            />
          </div>
        </div>
      </div>
    </ProductCardContainer>
  )
}

ProviderProduct.defaultProps = {
  product: {},
  onClickRemove: () => null,
  onClickRemoveProduct: () => null,
}

ProviderProduct.propTypes = {
  product: PropTypes.object,
  onClickRemove: PropTypes.func,
  onClickRemoveProduct: PropTypes.func,
}

export default ProviderProduct
