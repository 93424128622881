import { useEffect, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setFilterRequestNumber } from 'Redux/carts/actions'
import { useQuery } from 'Components/utils/customHooks'

const TABS = {
  requestsToBeApproved: 1,
  approvedRequests: 2,
  rejectedRequests: 3,
  cancelledRequests: 4,
}

const useCartsTabs = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const query = useQuery()
  const queryId = query.get('id')

  const isUpdatingCart = useSelector(state => state.carts.isUpdatingCart)
  const filterRequestNumber = useSelector(state => state.carts.filterRequestNumber)

  const [activeTab, setActiveTab] = useState(TABS.requestsToBeApproved)

  useEffect(() => {
    if (!queryId) return
    setActiveTab(TABS.approvedRequests)
    dispatch(setFilterRequestNumber(queryId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryId])

  const removeQueryParams = () => {
    const queryParams = new URLSearchParams(location.search)
    if (!queryParams.has('id')) return
    queryParams.delete('id')
    history.replace({
      search: queryParams.toString(),
    })
  }

  const onClickTab = tab => {
    removeQueryParams()
    dispatch(setFilterRequestNumber(''))
    setActiveTab(tab)
  }

  return { TABS, onClickTab, activeTab, isUpdatingCart, filterRequestNumber, queryId }
}

export default useCartsTabs
