import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import colors from 'Assets/colors'

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    transition: 'all 0.4s ease-in',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#FFFFFF',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    textTransform: 'none',
  },
  bar: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    borderRadius: '4px',
  },
  bar2: {
    marginTop: '3px',
    marginBottom: '3px',
  },
  title: {
    flexGrow: 1,
    display: 'flex',
  },
  logout: {
    minWidth: 'auto',
    '& .MuiButton-startIcon': {
      margin: '0px',
    },
  },
}))

export const Text = styled.div`
  cursor: pointer;
`

export const Container = styled.div`
  max-height: ${props => props.maxHeight};
`

export const Topbar = styled.div`
  padding: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: ${colors.black};
  background: ${colors.grayInput};
  text-align: center;
`

export const Badge = styled.div`
  background: ${colors.red};
  color: ${colors.white};
  border-radius: 8px;
  height: 24px;
  width: 24px;
  text-align: center;
  margin-left: 8px;
`
