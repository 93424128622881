import { ANALYTICS_EVENT } from './constants'
import {
  convertCategoriesToArray,
  handleListCategoryType,
  mapListId,
  mapListName,
  sumProductsTotalValue,
  handleTypeAddToCard,
  valueFormatter,
  quantityFormater,
  sendAnalytics,
} from './utils'

const addToCart = ({ products, selectedCategories, repeatCart }) => {
  if (!selectedCategories || !products) return

  const categoriesToArray = convertCategoriesToArray(selectedCategories)
  const listName = mapListName(categoriesToArray)
  const listId = mapListId(categoriesToArray)
  const listCategoryType = handleListCategoryType(categoriesToArray)
  const typeAddToCart = handleTypeAddToCard(repeatCart)
  const total = sumProductsTotalValue(products)

  sendAnalytics(ANALYTICS_EVENT.ADD_TO_CART, {
    item_list_name: listName,
    item_list_id: listId,
    list_category_type: listCategoryType,
    type_add_to_cart: typeAddToCart,
    value: valueFormatter(total),
    currency: 'EUR',
    items: products.map(({ name, id, price, provider, category, temperature, amount }) => ({
      item_list_name: listName,
      item_list_id: listId,
      item_name: name,
      item_id: id,
      item_category: category?.name,
      item_category2: category?.children?.[0]?.name,
      item_category3: category?.children?.[0]?.children?.[0]?.name,
      item_category4: temperature,
      price: valueFormatter(price),
      currency: 'EUR',
      affiliation: `${provider?.name} - ${provider?.id}`,
      quantity: quantityFormater(amount),
    })),
  })
}

export default addToCart
