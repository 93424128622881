import { productState } from 'Config/constants'
import _, { groupBy, map, sumBy } from 'lodash'

export const getTotalByProvider = okProducts =>
  _(okProducts)
    .groupBy('provider.name')
    .map((item, key) => ({
      index: key,
      total: sumBy(item, 'total'),
    }))
    .value()

export const getProductsToShow = okProducts => groupBy(okProducts, 'provider.name')

export const getAvailableProducts = products =>
  map(products)?.reduce(
    ([ok, notOk], elem) => (elem.state === productState.noAvailable ? [ok, [...notOk, elem]] : [[...ok, elem], notOk]),
    [[], []]
  )
