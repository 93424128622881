import React, { useMemo } from 'react'

import PropTypes from 'prop-types'
import map from 'lodash/map'
import size from 'lodash/size'
import { useSelector } from 'react-redux'

import { getProductsToShow, getTotalByProvider } from '../utils'

import ProviderProductsCard from './providerProductsCard'

const ProvidersList = ({ onClickRemove, onClickRemoveProduct }) => {
  const products = useSelector(s => s.lists.products)

  const productsToShow = getProductsToShow(products)

  const totalByProvider = useMemo(() => getTotalByProvider(products), [products])

  return (
    <div name='containerElement' id='containerElement'>
      {map(productsToShow, (item, key) => {
        const checkEqualProvider = ({ index }) => index === key
        const totalProvider = totalByProvider.find(checkEqualProvider)?.total
        return (
          <ProviderProductsCard
            key={key}
            name={key}
            total={totalProvider}
            amount={size(item)}
            products={item}
            onClickRemove={onClickRemove}
            onClickRemoveProduct={onClickRemoveProduct}
          />
        )
      })}
    </div>
  )
}

ProvidersList.defaultProps = {
  onClickRemove: () => null,
  onClickRemoveProduct: () => null,
}

ProvidersList.propTypes = {
  onClickRemove: PropTypes.func,
  onClickRemoveProduct: PropTypes.func,
}

export default ProvidersList
