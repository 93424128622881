import colors from 'Assets/colors'
import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  font-weight: 500;
  transition: all 0.2s;
  cursor: pointer;
  ${({ iconRight }) => iconRight && 'flex-direction: row-reverse;'}

  &[disabled] {
    cursor: default;

    button {
      cursor: default;
      color: rgba(0, 0, 0, 0.3);
    }

    svg {
      opacity: 0.3;
    }

    &:hover {
      button {
        color: rgba(0, 0, 0, 0.3);
      }

      svg path {
        fill: initial;
      }
    }
  }

  svg path,
  button {
    transition: all 0.2s;
  }

  &:hover {
    svg path {
      fill: ${colors.secondary};
    }

    button {
      color: ${colors.secondary};
    }
  }
`

export const UnderlineButton = styled.button`
  text-decoration: underline;
  font-size: 0.9rem;
  font-family: Inter;
  line-height: 24px;
  cursor: pointer;
`
