import styled, { css } from 'styled-components'
import colors from 'Assets/colors'

export const Title = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: ${colors.black};
  margin-bottom: 15px;
`

export const CartRow = styled.div`
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  margin: 12px 0;
  background: ${colors.gray98b};
  margin-bottom: 15px;
`

export const CartBody = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  overflow: hidden;
`

export const Center = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-right: 15px;
  padding-right: 35px;
  position: relative;
  width: 250px;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 90px;
    width: 1px;
    background-color: rgba(51, 51, 51, 0.24);
    right: 0;
  }
`

export const Tag = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.black};
  background: ${colors.cart};
  border-radius: 8px;
  padding: 0 8px;
  margin-right: 15px;
  display: flex;
  align-items: center;
`

export const Code = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex: 3;
  align-items: center;
  padding-right: 20px;
  color: ${colors.gray40l};
`

export const Price = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  flex: 1;
  display: flex;
  text-align: right;
  align-items: center;
  padding-right: 20px;
`

export const Products = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  flex: 2;
  color: ${colors.black};
`

export const CartHeader = styled.div`
  background: ${colors.gray3};
  color: ${colors.black};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;

  ${props =>
    props.status === 'closeExpired' &&
    css`
      color: ${colors.white};
      background: ${colors.orange};
    `}

  ${props =>
    props.status === 'approved' &&
    css`
      color: ${colors.white};
      background: ${colors.black};
    `}
`

export const CartHeaderInfo = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`

export const CartHeaderDate = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  ${props =>
    props.margin &&
    css`
      margin-right: 47px;
    `}
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled.div`
  width: 100%;
  background-color: ${colors.secondary};
  padding: 1rem;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  pointer-events: ${props => (props.submitLoading ? 'none' : 'all')};
  padding: 15px 75px;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const ButtonLiteral = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`
