import styled, { css } from 'styled-components'
import colors from 'Assets/colors'

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 1.25rem;
`

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 100px;
  overflow-y: scroll;

  ${props => {
    const category = props.$hasCategoryTitle ? '62px' : '0px'
    const tutorial = props.$tutorial ? '48px' : '0px'
    const showDownloadButton = props.$showDownloadProductCatalog ? '40px' : '0px'
    const needsApproval = props.$needsApproval ? '138px' : '0px'
    return css`
      height: calc(100vh - 260px - ${category} - ${tutorial} - ${showDownloadButton} - ${needsApproval});
    `
  }}
`

export const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`

export const Grow = styled.div`
  flex-grow: 1;
`

export const TopbarContainer = styled.div`
  flex-grow: 1;
  padding: 1.25rem 1.25rem 2rem 1.25rem;
`

export const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  transition: all 0.2s;
  margin-left: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 40px;
  background: ${colors.gray3};
  margin-left: 40px;
  margin-right: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  align: center;
  text-align: center;
  border-radius: 8px;
  border: 1;
`
