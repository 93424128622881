import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { ReactComponent as RemoveIcon } from 'Assets/Icons/RemoveIcon.svg'

import { DivButton, TitleContainer } from '../styled'

const SingleModalTitle = ({ onClickEdit, onClickRemove }) => {
  const { t } = useTranslation()

  const selectedList = useSelector(s => s.lists.data)
  const isFetchingProducts = useSelector(s => s.lists.isFetchingProducts)

  const listName = t('shoppingList.single.title', { name: selectedList.title })

  return (
    <div className='title'>
      <TitleContainer>
        {listName}
        {isFetchingProducts && <CircularProgress style={{ marginLeft: 10 }} color='inherit' size={24} />}
      </TitleContainer>
      <div className='title-actions'>
        <EditButton onClick={onClickEdit} />
        <RemoveButton onClick={onClickRemove} />
      </div>
    </div>
  )
}

SingleModalTitle.defaultProps = {
  onClickEdit: () => null,
  onClickRemove: () => null,
}

SingleModalTitle.propTypes = {
  onClickEdit: PropTypes.func,
  onClickRemove: PropTypes.func,
}

const EditButton = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <DivButton className='change' onClick={onClick}>
      <span>{t('shoppingList.single.change')}</span>
    </DivButton>
  )
}

EditButton.defaultProps = {
  onClick: () => null,
}

EditButton.propTypes = {
  onClick: PropTypes.func,
}

const RemoveButton = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <DivButton className='delete' onClick={onClick}>
      <RemoveIcon />
      <span>{t('shoppingList.list.removeList')}</span>
    </DivButton>
  )
}

RemoveButton.defaultProps = {
  onClick: () => null,
}

RemoveButton.propTypes = {
  onClick: PropTypes.func,
}

export default SingleModalTitle
