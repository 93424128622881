import styled from 'styled-components'
import RadioGroup from '@material-ui/core/RadioGroup'

export const ProviderContainer = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(51, 51, 51, 0.24);
  width: 100%;
  margin-bottom: 24px;
  background: #fafafa;
`

export const ProviderNameRow = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fafafa;
  border-bottom: 1px solid rgba(51, 51, 51, 0.24);
  padding: 16px;
`

export const ProviderName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
`

export const ProviderDetail = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  display: flex;
`

export const TotalPrice = styled.span`
  margin-right: 24px;
`

export const TotalProductLines = styled.span``

export const DeliveryRow = styled.div`
  display: grid;
  grid-template-columns: 77% 23%;
  border-bottom: ${props => (props.openProducts ? '1px solid rgba(51, 51, 51, 0.24)' : 'none')};
`

export const RadioGroupContainer = styled.div`
  display: flex;
  margin-right: 16px;
  border-right: 1px solid rgba(51, 51, 51, 0.24);
`

export const DateSelectContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  svg {
    position: relative;
    top: -10px;
  }
`

export const DateSelect = styled.div`
  margin-left: 18px;
`

export const DateSelectTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  cursor: pointer;
`

export const DateSelectInfo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.gray40l};
`

export const ShowProductsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 40px;
`

export const ShowMoreCopy = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
`

export const ShowLessCopy = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.secondary};
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Column = styled(Row)`
  justify-content: flex-start;
  flex-direction: column;
`

export const Price = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
  color: ${props => props.theme.colors.gray40l};
`

export const PriceKG = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.gray40l};
`

export const ProductsContainer = styled.div`
  background-color: #fafafa;
  padding: 0 16px;
`

export const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 70% 15% 15%;
  padding: 16px 0;
`

export const TableHeaderItem = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  &:first-child {
    padding-left: 16px;
  }
`

export const SingleProductRow = styled.div`
  background: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.24);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 70% 15% 15%;
  margin-bottom: 16px;
`

export const SingleProductNameHelper = styled.div`
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const SingleProductName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  text-transform: uppercase;
  padding: 0 16px;
`

export const SingleProductDateWarning = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.red};
  padding: 0 16px;
  margin-top: 5px;
`

export const SingleProductPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px 16px 0;
`

export const SingleProductQtyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px 16px 0;
`

export const SingleProductTotalPrice = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${props => props.theme.colors.black};
`

export const SingleProductTotalPriceSplit = styled.div`
  display: flex;
`

export const SingleProductTotalQty = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${props => props.theme.colors.black};
`

export const MinAndMulti = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.gray40l};
`

export const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  align-items: center;
  flex-direction: row !important;
  padding: 18px;
  .MuiFormControlLabel-root {
    margin-right: 40px;
    .MuiIconButton-root {
      .MuiIconButton-label {
        .MuiSvgIcon-root {
          fill: ${props => props.theme.colors.black};
        }
      }
    }
    .MuiRadio-colorSecondary.Mui-checked {
      .MuiIconButton-label {
        .MuiSvgIcon-root {
          fill: ${props => props.theme.colors.secondary};
        }
      }
    }
    .MuiRadio-colorSecondary.Mui-checked + .MuiTypography-body1 {
      color: ${props => props.theme.colors.secondary};
    }
    .MuiTypography-body1 {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: unset;
      fill: ${props => props.theme.colors.black};
    }
  }
  .MuiFormHelperText-root {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.theme.colors.gray40l};
    padding-left: 32px;
    letter-spacing: 0;
    margin-top: 0;
  }
`
export const CalendarContainer = styled.div`
  position: absolute;
  right: 0;
  background: white;
  top: 98px;
  z-index: 9;
`
