import {
  SET_ANNOUNCEMENTS_ERROR,
  SET_ANNOUNCEMENTS_LIST,
  SET_ANNOUNCEMENTS_IS_LOADING,
  FETCH_ANNOUNCEMENTS,
} from './types'

export const setAnnouncementsError = payload => ({
  type: SET_ANNOUNCEMENTS_ERROR,
  payload,
})

export const setAnnouncementsIsLoading = payload => ({
  type: SET_ANNOUNCEMENTS_IS_LOADING,
  payload,
})

export const setAnnouncements = payload => ({
  type: SET_ANNOUNCEMENTS_LIST,
  payload,
})

export const fetchAnnouncements = params => ({
  type: FETCH_ANNOUNCEMENTS,
  params,
})
