import React from 'react'

import PropTypes from 'prop-types'

import * as S from './styled'

const UnderlineButton = ({ className, disabled, label, Icon, iconRight, title, onClick }) => (
  <S.MainContainer>
    <S.ButtonContainer disabled={disabled} iconRight={iconRight} title={title}>
      <Icon />
      <S.UnderlineButton className={className} disabled={disabled} type='button' onClick={onClick}>
        {label}
      </S.UnderlineButton>
    </S.ButtonContainer>
  </S.MainContainer>
)

UnderlineButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  Icon: PropTypes.object.isRequired,
  iconRight: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
}

UnderlineButton.defaultProps = {
  className: '',
  disabled: false,
  iconRight: false,
  title: '',
  onClick: () => null,
}

export default UnderlineButton
