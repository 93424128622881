import React, { useCallback, useEffect, useState } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'Config/constants'
import BasicTable from 'Components/molecules/basicTable'
import { fetchNoCompliances } from 'Redux/multicenter/actions'
import { selectIsLoadingNoCompliances, selectNoCompliances } from 'Redux/multicenter/selectors'

import ActionsCell from './actionsCell'
import { StateCell } from './styled'

const MulticenterNoComplianceTable = ({ filters, onViewDetailClick }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isLoading = useSelector(selectIsLoadingNoCompliances)
  const { data: noCompliances, count } = useSelector(selectNoCompliances)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(TABLE_ROWS_PER_PAGE_OPTIONS[0])

  useEffect(() => {
    dispatch(fetchNoCompliances({ filters, page, pageSize }))
  }, [dispatch, filters, page, pageSize])

  const handlePageEvent = useCallback(pageEvent => {
    setPage(pageEvent.page)
    setPageSize(pageEvent.pageSize)
  }, [])

  const columns = [
    {
      field: 'centerName',
      headerName: `${t('center')}`,
      width: 330,
      valueFormatter: ({ data }) => `${data.centerName} - ${data.centerId ?? ''}`,
    },
    {
      field: 'id',
      headerName: `${t('number')}`,
      width: 100,
      valueFormatter: ({ value }) => (value ? value.name : '-'),
    },
    {
      field: 'subject',
      headerName: `${t('type')}`,
      width: 320,
    },
    {
      field: 'providerName',
      headerName: `${t('provider')}`,
      width: 300,
    },
    {
      field: 'providerOrderId',
      headerName: `${t('order')}`,
      width: 110,
    },
    {
      field: 'created',
      headerName: `${t('date')}`,
      width: 106,
      valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/YYYY') : '-'),
    },
    {
      field: 'status',
      headerName: `${t('status')}`,
      width: 150,
      renderCell: ({ data }) => <StateCell state={data.status}>{t(`ticketingStatus.${data.status}`)}</StateCell>,
    },
    {
      field: 'actions',
      headerName: `${t('actions')}`,
      width: 140,
      renderCell: ({ data }) => <ActionsCell row={data} onViewDetailClick={onViewDetailClick} />,
    },
  ].map(column => ({ ...column, resizable: false, sortable: false }))

  return (
    <BasicTable
      autoHeight
      fixedHeight
      count={count}
      isLoading={!!isLoading}
      page={page}
      pageSize={pageSize}
      paginationLabel='noCompliancePerPage'
      rows={noCompliances}
      columns={columns}
      onPageChange={handlePageEvent}
      onPageSizeChange={handlePageEvent}
    />
  )
}

MulticenterNoComplianceTable.propTypes = {
  filters: PropTypes.object,
  onViewDetailClick: PropTypes.func.isRequired,
}

MulticenterNoComplianceTable.defaultProps = {
  filters: {},
}

export default MulticenterNoComplianceTable
