import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ProviderIcon } from 'Assets/Icons/Proveedor.svg'
import routes from 'Config/routes'
import moment from 'moment'
import 'moment/locale/es'
import { Calendar } from 'Components/atoms'
import { getProviderOrdinaryDates } from 'Services/api'
import * as Sentry from 'Services/sentry'
import { selectCenterId } from 'Redux/purchases/utils'
import { AVAILABILITY, DISABLE_WEEKS } from 'Config/constants'
import { setModalProviderOpen } from 'Redux/purchases/actions'

import {
  ContentContainer,
  TextsContainer,
  ProviderCodeContainer,
  ProviderCode,
  NextDeliveryDate,
  FormattedDate,
  StringDate,
  CalendarContainer,
  Name,
  useStyles,
} from './styled'

const ProviderDetail = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const selectedProvider = useSelector(state => state.purchase.selectedProvider)
  const { nextDeliveryDate } = selectedProvider
  const formattedDate = moment(nextDeliveryDate).format('dddd, LL')
  const centerId = useSelector(selectCenterId)
  const [dates, setDates] = useState({ availability: 'no', dates: [] })
  const selectedDate = nextDeliveryDate ? moment(nextDeliveryDate).toDate() : null

  const dispatch = useDispatch()

  useEffect(() => {
    getProviderOrdinaryDates(selectedProvider.id, centerId)
      .then(result => setDates(result))
      .catch(e => Sentry.captureException(e))
  }, [selectedProvider.id, centerId])

  const tileDisabled = ({ date, view }) => {
    if (view !== 'month') {
      return true
    }

    if (dates.availability === AVAILABILITY.NO) {
      const mDate = moment(date).startOf('day')
      return moment(selectedDate).startOf('day') >= mDate || (DISABLE_WEEKS && (mDate.day() === 0 || mDate.day() === 6))
    }

    const availabilityDates = dates.dates
    return !availabilityDates.some(element => {
      const deliveryDate = moment(element.delivery)

      return moment(date).format('DDMMYY') === deliveryDate.format('DDMMYY')
    })
  }

  const onSeeProducts = () => {
    dispatch(setModalProviderOpen(false))
    history.push(`${routes.addProvider(selectedProvider.id)}/category/all`)
  }

  const hasNextDate =
    (dates.availability === AVAILABILITY.YES && dates.dates.length > 0) || dates.availability === AVAILABILITY.NO

  return (
    <>
      <Name>{selectedProvider.name}</Name>
      <ContentContainer>
        <TextsContainer>
          {!hasNextDate && <NextDeliveryDate>{t('noCalendar')}</NextDeliveryDate>}
          {hasNextDate && (
            <NextDeliveryDate>
              {t('nextDeliveryDateProvDetail')}
              <FormattedDate>
                {t('nextDeliveryDateProvDetailLiteral')}
                <StringDate>{formattedDate}</StringDate>
              </FormattedDate>
            </NextDeliveryDate>
          )}
          <ProviderCodeContainer>
            Código de proveedor
            <ProviderCode>{selectedProvider.id}</ProviderCode>
          </ProviderCodeContainer>
        </TextsContainer>
        <CalendarContainer>
          <Calendar value={selectedDate} tileDisabled={tileDisabled} selectRange={false} />
        </CalendarContainer>
      </ContentContainer>
      <Button endIcon={<ProviderIcon />} className={classes.addCartButton} onClick={onSeeProducts}>
        {t('seeProducts')}
      </Button>
    </>
  )
}

export default ProviderDetail
