import styled, { css } from 'styled-components'
import colors from 'Assets/colors'
import { CircularProgress } from '@material-ui/core'

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.black};
  padding-bottom: 1rem;
`

export const Message = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  text-align: center;
  padding-bottom: 32px;
  max-width: 520px;
  margin: 0 auto;
  min-height: 86px;
`

export const InputLabel = styled.label`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
  color: ${colors.black};
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ActionButton = styled.div`
  width: 100%;
  background-color: ${colors.secondary};
  padding: 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  pointer-events: ${props => (props.submitLoading ? 'none' : 'all')};

  ${p =>
    (p.disabled || p.submitLoading) &&
    css`
      background-color: ${colors.grey3};
      pointer-events: none;
      cursor: auto;
    `}

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const ButtonLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`

export const CircularProgressStyled = styled(CircularProgress)`
  margin-right: 10px;
  &.MuiCircularProgress-colorPrimary {
    color: ${colors.white};
  }
`
