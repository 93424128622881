import React, { useEffect, useState, useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import map from 'lodash/map'
import { fetchCartRequest } from 'Redux/carts/actions'
import ArrowDropdown from 'Assets/Icons/ArrowDropdown'
import RequestOrderLoader from 'Components/molecules/requestOrderPlaceholder'
import ProviderProducts from 'Components/molecules/requestOrderCartProducts'

import { Root, Content, CartData, ShowProductsContainer, ShowMoreCopy } from './styled'

const DEFAULT_PAGE_SIZE = 10

const RequestOrderCartToApprove = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const cart = useSelector(state => state.carts.cart)
  const requestOrder = useSelector(state => state.carts.requestOrder)
  const isFetchingCart = useSelector(state => state.carts.isFetchingCart)
  const hasMoreRequest = useSelector(state => state.carts.hasMoreRequest)

  const [pageNumber, setPageNumber] = useState(0)

  const onMore = useCallback(() => {
    if (hasMoreRequest) {
      setPageNumber(prev => prev + 1)
    }
  }, [hasMoreRequest])

  useEffect(() => {
    if (requestOrder?.id) {
      dispatch(fetchCartRequest(requestOrder?.id, requestOrder?.state, pageNumber, DEFAULT_PAGE_SIZE))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestOrder?.id, pageNumber])

  return (
    <Root>
      {isFetchingCart || !cart ? (
        <RequestOrderLoader />
      ) : (
        <Content>
          <CartData>
            {map(cart?.data, (provider, key) => (
              <ProviderProducts key={key} provider={provider} state={requestOrder?.state} />
            ))}
            {hasMoreRequest && (
              <ShowProductsContainer onClick={onMore}>
                <ArrowDropdown style={{ margin: '0 0.5rem' }} />
                <ShowMoreCopy>{t('moreProducts')}</ShowMoreCopy>
              </ShowProductsContainer>
            )}
          </CartData>
        </Content>
      )}
    </Root>
  )
}

export default RequestOrderCartToApprove
