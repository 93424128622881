import React from 'react'

import PropTypes from 'prop-types'
import Trash from 'Assets/Icons/Trash'

import { EraseButtonContanier } from './styled'

const EraseButton = ({ onClick }) => (
  <EraseButtonContanier onClick={onClick}>
    <Trash className='trash' width={22} fill='#fff' />
  </EraseButtonContanier>
)

EraseButton.propTypes = {
  onClick: PropTypes.func,
}

EraseButton.defaultProps = {
  onClick: () => null,
}

export default EraseButton
