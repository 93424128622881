export const orderTypes = {
  ascending: 'asc',
  descending: 'desc',
}

export const sortTypes = {
  normal: 'precio',
  weight: 'precio_kg',
  name: 'name',
}
