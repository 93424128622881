import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'
import colors from 'Assets/colors'

export const Cell = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;

  .hidden {
    visibility: hidden;
  }

  svg path {
    fill: ${colors.black};
  }

  button {
    white-space: nowrap;
    font-size: 12px;
  }
`

export const Button = styled(IconButton)`
  &.download-btn {
    padding: 10px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  &:hover {
    svg path {
      fill: ${colors.secondary};
    }
  }
`
