import React from 'react'

import PropTypes from 'prop-types'
import { Modal } from 'Components/atoms'
import { ReactComponent as PortalLogo } from 'Assets/Logos/PortalLogo.svg'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Title, Description, ButtonContainer, ButtonLiteral, ActionButton } from './styled'

const ModalPendingCarts = ({ showModal, setShowModal }) => {
  const { t } = useTranslation()

  return (
    <Modal displayModal='flex' showClose={false} isOpen={showModal} setIsOpen={setShowModal}>
      <Box alignSelf='center'>
        <PortalLogo style={{ marginBottom: '30px' }} />
        <Title>{t('haveCartRequests')}</Title>
        <Description>{t('approveCartRequests')}</Description>
        <ButtonContainer>
          <ActionButton onClick={setShowModal}>
            <ButtonLiteral>{t('seeCartsToBeApprove')}</ButtonLiteral>
          </ActionButton>
        </ButtonContainer>
      </Box>
    </Modal>
  )
}

ModalPendingCarts.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
}

ModalPendingCarts.defaultProps = {
  showModal: false,
  setShowModal: () => {},
}

export default ModalPendingCarts
