import React from 'react'

import PropTypes from 'prop-types'
import { ReactComponent as CheckIcon } from 'Assets/Icons/CheckIcon.svg'

import { AcceptButtonContanier } from './styled'

const AcceptButton = ({ onClick }) => (
  <AcceptButtonContanier onClick={onClick} data-cy='AcceptButton'>
    <CheckIcon />
  </AcceptButtonContanier>
)

AcceptButton.propTypes = {
  onClick: PropTypes.func,
}

AcceptButton.defaultProps = {
  onClick: () => null,
}

export default AcceptButton
