import React from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'
import _ from 'lodash'
import routes from 'Config/routes'
import { CategoriesSidebar, Providers } from 'Components/molecules'
import { SelectedCenterRoute } from 'Components/system/routes/customRoutes'
import { selectConfig } from 'Redux/auth/utils'

import Products from '../products'
import HabitualProducts from '../habitualProducts'
import ShoppingLists from '../shoppingLists'

import { Container, ProductsContainer } from './styled'

const ProductsAndSection = ({ temperature, setTemperature }) => {
  const history = useHistory()
  const isLoadingProducts = useSelector(state => state.purchase.isLoadingProducts)
  const isLoadingProviders = useSelector(state => state.purchase.isLoadingProviders)
  const isLoading = isLoadingProducts || isLoadingProviders

  const isHabitualRoute = _.get(history, 'location.pathname', '').includes(routes.habitualCats)
  const isListsRoute = _.get(history, 'location.pathname', '').includes(routes.lists)
  const isProductsRoute = !isHabitualRoute && !isListsRoute
  const features = useSelector(selectConfig)

  return (
    <Container>
      <Route path={[routes.categories, routes.provider, routes.habitualCats]} component={CategoriesSidebar} />
      <ProductsContainer opacity={isLoading ? 0.5 : 1}>
        <SelectedCenterRoute
          path={[routes.category, routes.providerProducts, routes.habitualCat, routes.categories, routes.lists]}
        >
          {isHabitualRoute && features?.habituals && <HabitualProducts />}
          {isProductsRoute && <Products temperature={temperature} setTemperature={setTemperature} />}
          {isListsRoute && features?.shopping_lists && <ShoppingLists />}
        </SelectedCenterRoute>
        <Route path={routes.providers} component={Providers} exact />
      </ProductsContainer>
    </Container>
  )
}

ProductsAndSection.defaultProps = {
  temperature: '',
  setTemperature: () => {},
}

ProductsAndSection.propTypes = {
  temperature: PropTypes.string,
  setTemperature: PropTypes.func,
}

export default ProductsAndSection
