import styled from 'styled-components'

export const BigTitle = styled.div`
  margin: ${props => props.margin};
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: ${props => props.textAlign || null};
`
