import { useState } from 'react'

import { useDispatch } from 'react-redux'
import { removeListProduct } from 'Redux/lists/actions'

const useShoppingListsRemoveProduct = () => {
  const dispatch = useDispatch()

  const [removeProductModal, setRemoveProductModal] = useState(null)

  const onRemoveProduct = () => {
    if (removeProductModal) {
      dispatch(removeListProduct(removeProductModal))
      setRemoveProductModal(false)
    }
  }

  return {
    removeProductModal,
    onRemoveProduct,
    setRemoveProductModal,
  }
}

export default useShoppingListsRemoveProduct
