import {
  SET_APPROVED,
  SET_CANCELLED,
  SET_CART,
  SET_FILTER_PROVIDER,
  SET_FILTER_REQUEST_NUMBER,
  SET_IS_FETCHING_APPROVED,
  SET_IS_FETCHING_CANCELLED,
  SET_IS_FETCHING_CART,
  SET_IS_FETCHING_PENDING_APPROVAL,
  SET_IS_FETCHING_REJECTED,
  SET_IS_UPDATING_CART,
  SET_PENDING_APPROVAL,
  SET_REJECTED,
  SET_REQUEST_ORDER,
  SET_CURRENT_PAGE_CART,
  SET_HAS_MORE_REQUEST,
} from './types'

const initialState = {
  pendingApproval: [],
  pendingApprovalCount: 0,
  isFetchingPendingApproval: false,
  rejected: [],
  isFetchingRejected: false,
  approved: [],
  isFetchingApproved: false,
  cancelled: [],
  isFetchingCancelled: false,
  filterProviders: [],
  cart: null,
  isFetchingCart: false,
  requestOrder: null,
  isUpdatingCart: false,
  filterRequestNumber: '',
  currentPageCart: null,
  hasMoreRequest: false,
}

const Reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_PENDING_APPROVAL:
      return {
        ...state,
        pendingApproval: action.payload.data,
        pendingApprovalCount: action.payload.count,
      }
    case SET_IS_FETCHING_PENDING_APPROVAL:
      return {
        ...state,
        isFetchingPendingApproval: action.payload,
      }
    case SET_REJECTED:
      return {
        ...state,
        rejected: action.payload,
      }
    case SET_IS_FETCHING_REJECTED:
      return {
        ...state,
        isFetchingRejected: action.payload,
      }
    case SET_APPROVED:
      return {
        ...state,
        approved: action.payload,
      }
    case SET_IS_FETCHING_APPROVED:
      return {
        ...state,
        isFetchingApproved: action.payload,
      }
    case SET_CANCELLED:
      return {
        ...state,
        cancelled: action.payload,
      }
    case SET_IS_FETCHING_CANCELLED:
      return {
        ...state,
        isFetchingCancelled: action.payload,
      }
    case SET_FILTER_PROVIDER:
      return {
        ...state,
        filterProviders: action.payload,
      }
    case SET_CART:
      return {
        ...state,
        cart: action.payload,
      }
    case SET_IS_FETCHING_CART:
      return {
        ...state,
        isFetchingCart: action.payload,
      }
    case SET_REQUEST_ORDER:
      return {
        ...state,
        requestOrder: action.payload,
      }
    case SET_IS_UPDATING_CART:
      return {
        ...state,
        isUpdatingCart: action.payload,
      }
    case SET_FILTER_REQUEST_NUMBER:
      return {
        ...state,
        filterRequestNumber: action.payload,
      }
    case SET_CURRENT_PAGE_CART:
      return {
        ...state,
        currentPageCart: action.payload,
      }
    case SET_HAS_MORE_REQUEST:
      return {
        ...state,
        hasMoreRequest: action.payload,
      }

    default:
      return state
  }
}

export default Reducer
