import styled from 'styled-components'

export const AmountContainer = styled.div`
  display: flex;
  margin-right: auto;
  flex: 1;
  cursor: pointer;
  justify-content: center;
`

export const LabelValue = styled.span`
  width: 100%;
  text-align: left;
  display: flex;
  align-self: center;
`

export const Input = styled.input`
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 50px;
  text-align: left;
  width: 100%;
`
