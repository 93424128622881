import styled from 'styled-components'

export const ButtonContainer = styled.div`
  padding: 1rem 1.25rem;
  border-bottom: 1px solid rgba(51, 51, 51, 0.24);
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  background-color: ${props => props.theme.colors.gray99l};
  width: 100%;
  display: flex;
  cursor: pointer;

  :hover .categories-text {
    color: ${props => props.theme.colors.secondary};
  }
`

export const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => (props.selected ? props.theme.colors.secondary : props.theme.colors.black)};
  transition: all 0.2s;
  margin-left: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
