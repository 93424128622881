import React from 'react'

import PropTypes from 'prop-types'
import FormHelperText from '@material-ui/core/FormHelperText'
import Arrow from 'Assets/Icons/Arrow'
import { ReactComponent as Recepcionado } from 'Assets/Icons/EstadoRecepcionado.svg'
import { ReactComponent as Cancelado } from 'Assets/Icons/EstadoCancelado.svg'
import { ReactComponent as PorRecepcionar } from 'Assets/Icons/EstadoPorRecepcionar.svg'
import { ReactComponent as Tramitado } from 'Assets/Icons/EstadoTramitado.svg'
import { ORDER_STATUS } from 'Config/constants'
import map from 'lodash/map'

import './select.css'
import { StyledFormControl, StyledTextField, StyledMenuItem } from './styled'

const SelectInput = ({ label, choices, error, placeholder, boxShadow, opacity, border, ...rest }) => (
  <StyledFormControl error={!!error} fullWidth border={border}>
    <StyledTextField
      fullWidth
      {...rest}
      label={placeholder}
      boxShadow={boxShadow}
      opacity={opacity}
      select
      border={border}
    >
      {map(choices, choice => (
        <StyledMenuItem key={choice.id} value={choice.id}>
          {choice.id === ORDER_STATUS.PR && <PorRecepcionar />}
          {choice.id === ORDER_STATUS.RC && <Recepcionado />}
          {choice.id === ORDER_STATUS.CN && <Cancelado />}
          {choice.id === ORDER_STATUS.PE && <Tramitado />}
          {choice.name || choice.title}
          <Arrow className='icon-arrow' />
        </StyledMenuItem>
      ))}
    </StyledTextField>
    {error && <FormHelperText>{error}</FormHelperText>}
  </StyledFormControl>
)

SelectInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  choices: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  boxShadow: PropTypes.string,
  opacity: PropTypes.string,
  border: PropTypes.string,
}

SelectInput.defaultProps = {
  placeholder: '',
  value: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  label: '',
  choices: [],
  error: false,
  boxShadow: '',
  opacity: '',
  border: '',
}

export default SelectInput
