import { useSelector } from 'react-redux'

const useSummaryList = () => {
  const summaryWeek = useSelector(state => state.approver?.weekSummary?.weeklyReport?.data || [])
  const summarySpend = useSelector(state => state.approver?.weekSummary?.weeklySpend || 0)

  const grouped = summaryWeek.reduce((acc, item, index) => {
    const { totalPrice, provider } = item
    if (provider?.id && !Object.prototype.hasOwnProperty.call(acc, provider.id)) {
      acc[provider.id] = {
        total: 0,
        data: [],
        title: provider?.name,
        show: false,
        ind: index,
      }
    }
    const accuKey = acc[provider?.id]
    accuKey.total += totalPrice
    accuKey.data = accuKey.data.concat(item)

    return acc
  }, {})

  const summaryGroupedList = Object.values(grouped) || []

  return {
    summaryGroupedList,
    summarySpend,
  }
}

export default useSummaryList
