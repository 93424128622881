import React from 'react'

import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

export const StyledFormControl = styled(FormControl)`
  position: relative;
  border: ${props => props.border};
`

export const StyledTextField = styled(({ boxShadow, ...props }) => <TextField {...props} />)`
  background-color: ${props => props.background};
  height: 56px;
  border-radius: ${props => props.borderRadius};
  margin-top: 0 !important;
  box-shadow: ${props => props.boxShadow};

  svg:first-child {
    margin-right: 10px;
  }

  .MuiInputLabel-root {
    padding-left: 15px;
    top: -4px;
    color: ${props => props.placeholderColor};
  }

  .MuiInput-root {
    margin-top: 0;
    height: 56px;
    .MuiSelect-root {
      display: flex;
      align-items: center;
      padding-top: 7px;
    }

    &:before {
      border-bottom: none;
    }
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }

  .MuiInput-underline:after {
    border-bottom: none;
  }

  .MuiInputLabel-animated {
    opacity: ${props => props.opacity};
  }

  .MuiFormLabel-filled {
    opacity: 0;
  }

  &:hover:before {
    border-bottom: none !important;
  }
  &:after {
    border-bottom: none !important;
  }

  &:before {
    border-bottom: none !important;
  }

  .MuiSelect-icon {
    right: 14px !important;
  }

  .MuiSelect-select.MuiSelect-select {
    text-align: left !important;
    padding-left: 15px !important;
  }
  .MuiSelect-select:focus {
    background-color: transparent !important;
  }
  .icon-arrow {
    display: none;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  display: flex !important;
  padding: 0.5rem 1rem !important;
  cursor: pointer !important;
  align-items: center !important;
  svg {
    margin-right: 10px;
  }
  .icon-arrow {
    opacity: 0;
    transition: all 0.2s;
    margin: 3px 10px;
    display: inline-block;
  }

  :hover {
    background-color: #eaeaea !important;

    .icon-arrow {
      opacity: 1;
    }
  }
`
