import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Arrow from 'Assets/Icons/Arrow'
import Trash from 'Assets/Icons/Trash'
import { AVAILABILITY_STATES, MEASURE_UNIT } from 'Config/constants'
import colors from 'Assets/colors'
import { NumberFormat, ProductButtonApprover } from 'Components/atoms'
import { financial } from 'Components/utils/cartCalculator'
import { percentage } from 'Config/utils'
import { editCart } from 'Redux/approver/actions'

import {
  IconsContainer,
  Price,
  PreviousPrice,
  MinAndMulti,
  PriceKG,
  ButtonNotAvailable,
  SimpleName,
  SimpleCardContainer,
  EraseButton,
  Unavailable,
  ProductRow,
  RowPrice,
} from './styled'

const ProductHorizontalCardApprover = ({
  name,
  provider,
  price,
  min,
  multiple,
  measureUnit,
  pricePerKg,
  id,
  raw_material: rawMaterial,
  notAvailable,
  reference,
  setShowModalConfirmationProduct,
  setOnConfirmProduct,
  showJustButtonCard,
  previousPrice,
  availability,
  refButton,
  onNext,
  onPrevious,
  disabled,
}) => {
  const { amount = 0 } = useSelector(state => state.approver.cart[id]) || {}

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const setToZero = () => {
    dispatch(
      editCart(id, {
        amount: 0,
        name,
        total: 0,
        provider,
        price,
        pricePerKg,
        measureUnit,
        min,
        multiple,
        id,
        raw_material: rawMaterial,
        state: availability ? availability.status : AVAILABILITY_STATES.AVAILABLE,
        reference,
      })
    )
  }

  if (!amount) return null

  const differencePrice = previousPrice ? price - previousPrice : null

  const renderPrice = () => (
    <RowPrice>
      <>
        <Price>
          <NumberFormat value={price} suffix={`€/${measureUnit}`} />
        </Price>
        {measureUnit !== MEASURE_UNIT.KG ? (
          <PriceKG>
            <NumberFormat value={pricePerKg} suffix='€/KG' />
          </PriceKG>
        ) : null}
      </>
    </RowPrice>
  )

  const renderDifferencePrice = () => {
    const containerStyle = {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '0.5rem',
      marginLeft: '0.5rem',
      paddingBottom: '0.5rem',
      paddingTop: '0.5rem',
    }

    const priceStyle = {
      color: differencePrice > 0 ? colors.red : colors.secondary,
    }

    const arrowStyle = {
      marginRight: '0.5rem',
      marginLeft: '0.2rem',
      transform: differencePrice > 0 ? 'rotate(270deg)' : 'rotate(90deg)',
      position: 'relative',
    }

    return (
      <div style={containerStyle}>
        <>
          <Price style={priceStyle}>
            <NumberFormat value={price} suffix={`€/${measureUnit}`} />
          </Price>
          <Arrow style={arrowStyle} fill={differencePrice > 0 ? colors.red : colors.secondary} />
          <PreviousPrice>
            <NumberFormat value={previousPrice} suffix={`€/${measureUnit}`} />
          </PreviousPrice>
          {measureUnit !== MEASURE_UNIT.KG && (
            <PriceKG>
              <NumberFormat value={pricePerKg} suffix='€/KG' />
            </PriceKG>
          )}
        </>
      </div>
    )
  }

  const renderProductButton = () => {
    if (notAvailable) {
      return <NotAvailable onReset={setToZero} availability={availability} />
    }

    if (!disabled) {
      return (
        <ProductButtonApprover
          name={name}
          provider={provider}
          price={price}
          min={min}
          multiple={multiple}
          measureUnit={measureUnit}
          pricePerKg={pricePerKg}
          id={id}
          rawMaterial={rawMaterial}
          notAvailable={notAvailable}
          reference={reference}
          setShowModalConfirmationProduct={setShowModalConfirmationProduct}
          setOnConfirmProduct={setOnConfirmProduct}
          showJustButtonCard={showJustButtonCard}
          availability={availability}
          innerRef={refButton}
          onPrevious={onPrevious}
          onNext={onNext}
        />
      )
    }

    return null
  }

  return (
    <SimpleCardContainer aria-label={t('approverTutorial.stepSix.target')}>
      <ProductRow>
        <IconsContainer>
          <SimpleName title={name}>{name}</SimpleName>
        </IconsContainer>
        <div style={{ flex: 1, justifyContent: 'space-around' }}>
          {!differencePrice || Math.abs(differencePrice) < percentage(previousPrice, 0.01)
            ? renderPrice()
            : renderDifferencePrice()}
          <MinAndMulti>{`${t('min')}: ${financial(min)} / ${t('multi')}: ${financial(multiple)}`}</MinAndMulti>
        </div>
        <div style={{ flex: 1, borderLeft: '1px solid rgba(51,51,51,0.24)' }}>
          <RowPrice>
            <Price>
              <NumberFormat value={price * amount} />
            </Price>
          </RowPrice>
        </div>
        <div style={{ flex: 1, borderLeft: '1px solid rgba(51,51,51,0.24)' }}>
          <RowPrice>
            <Price>
              {amount} {measureUnit}
            </Price>
          </RowPrice>
        </div>
        <div style={{ width: 300, paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>{renderProductButton()}</div>
      </ProductRow>
    </SimpleCardContainer>
  )
}

ProductHorizontalCardApprover.defaultProps = {
  name: '',
  provider: {},
  price: 0,
  min: 0,
  multiple: 0,
  measureUnit: '',
  pricePerKg: 0,
  id: '',
  raw_material: 'food',
  notAvailable: false,
  reference: '',
  setOnConfirmProduct: () => null,
  setShowModalConfirmationProduct: () => null,
  showJustButtonCard: false,
  previousPrice: null,
  availability: null,
  refButton: null,
  onNext: () => null,
  onPrevious: () => null,
  disabled: false,
}

ProductHorizontalCardApprover.propTypes = {
  name: PropTypes.string,
  provider: PropTypes.object,
  price: PropTypes.number,
  min: PropTypes.number,
  multiple: PropTypes.number,
  measureUnit: PropTypes.string,
  pricePerKg: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  raw_material: PropTypes.string,
  notAvailable: PropTypes.bool,
  reference: PropTypes.string,
  setOnConfirmProduct: PropTypes.func,
  setShowModalConfirmationProduct: PropTypes.func,
  showJustButtonCard: PropTypes.bool,
  previousPrice: PropTypes.number,
  availability: PropTypes.object,
  refButton: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  disabled: PropTypes.bool,
}

const NotAvailable = ({ onReset, availability }) => {
  const { t } = useTranslation()

  return (
    <ButtonNotAvailable>
      <Unavailable>
        {availability && availability.status === AVAILABILITY_STATES.NOT_STOCK && t('noItems')}
        {availability && availability.status === AVAILABILITY_STATES.UNAVAILABLE && t('deleted')}
      </Unavailable>
      <EraseButton onClick={onReset}>
        <Trash className='trash' width={22} fill='#fff' /> {t('delete')}
      </EraseButton>
    </ButtonNotAvailable>
  )
}

NotAvailable.defaultProps = {
  onReset: () => null,
  availability: null,
}

NotAvailable.propTypes = {
  onReset: PropTypes.func,
  availability: PropTypes.object,
}

export default ProductHorizontalCardApprover
