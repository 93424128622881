export const getTitle = category => {
  switch (true) {
    case !!category:
      return category.name
    default:
      return ''
  }
}

export const getHabitualSelectedCategories = (key, categories) => {
  let category

  categories.every(({ id, name }) => {
    if (key === id) {
      category = { id, name }
      return false
    }

    return true
  })

  return category
}

export const getSearchHabitualParams = category => {
  switch (true) {
    case !!category:
      return { category: category.id }
    default:
      return {}
  }
}
