import { all, takeLatest, call, put, select, delay, takeLeading } from 'redux-saga/effects'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import { toast } from 'react-toastify'
import axios from 'axios'
import {
  confirmApproverCartSQS,
  getApproverPendingApproval,
  getApproverCloseToAutoApproved,
  getApproverRejected,
  getApproverApproved,
  getApproverCart,
  confirmApproverCart,
  rejectApproverCart,
  updateApproverCart,
  deleteApproverProviderProducts,
  getApproverOriginCenters,
  getWeekSummary,
} from 'Services/api'
import * as Sentry from 'Services/sentry'
import { assembleCart, assemblePayload } from 'Redux/purchases/utils'
import { getApiErrorMessage } from 'Config/utils'
import { AuthActions } from 'Redux/auth'
import { PurchaseActions } from 'Redux/purchases'
import analyticsService from 'Services/analytics'
import { ANALYTICS_PURCHASE_TYPE } from 'Services/analytics/constants'
import { selectConfig } from 'Redux/auth/utils'

import {
  checkApproverConfirmedCart,
  setApproved,
  setApproverConfirmedCart,
  setCart,
  setCartDeliveries,
  setErrorCart,
  setIsFetchingApproved,
  setIsFetchingCart,
  setIsFetchingPendingApproval,
  setIsFetchingRejected,
  setIsUpdatingCart,
  setOriginCenters,
  setPendingApproval,
  setRejected,
  setRequestOrder,
  syncCart,
  setWeekSummary,
  setIsFetchingSummary,
} from './actions'
import {
  CHECK_APPROVER_CONFIRMED_CART,
  CONFIRM_CART,
  DELETE_PROVIDER_PRODUCT,
  EDIT_CART,
  FETCH_APPROVED,
  FETCH_CART,
  FETCH_ORIGIN_CENTERS,
  FETCH_PENDING_APPROVAL,
  FETCH_REJECTED,
  IMPERSONATE,
  REJECT_CART,
  SYNC_CART,
  FETCH_WEEK_SUMMARY,
} from './types'

export function* fetchPendingApproval() {
  yield put(setIsFetchingPendingApproval(true))
  try {
    const filterCenter = yield select(state => state.approver.filterCenter)
    const filterProviders = yield select(state => state.approver.filterProviders)
    const filterRequestNumber = yield select(state => state.approver.filterRequestNumber)
    const { data: pendingApproval, count: pendingCount } = yield call(
      getApproverPendingApproval,
      filterCenter,
      filterProviders,
      filterRequestNumber
    )
    const { data: closeToAutoApproved, count: closeToAutoApprovedCount } = yield call(
      getApproverCloseToAutoApproved,
      filterCenter,
      filterProviders,
      filterRequestNumber
    )

    yield put(
      setPendingApproval({
        pendingApproval: uniqBy(pendingApproval, 'id'),
        closeToAutoApproved: uniqBy(closeToAutoApproved, 'id'),
        count: pendingCount + closeToAutoApprovedCount,
      })
    )
  } catch (e) {
    yield put(setPendingApproval({ pendingApproval: [], closeToAutoApproved: [] }))
    Sentry.captureException(e)
  } finally {
    yield put(setIsFetchingPendingApproval(false))
  }
}

export function* fetchRejected() {
  yield put(setIsFetchingRejected(true))
  try {
    const filterCenter = yield select(state => state.approver.filterCenter)
    const filterProviders = yield select(state => state.approver.filterProviders)
    const filterRequestNumber = yield select(state => state.approver.filterRequestNumber)
    const { data } = yield call(getApproverRejected, filterCenter, filterProviders, filterRequestNumber)
    yield put(setRejected(uniqBy(data, 'id')))
  } catch (e) {
    yield put(setRejected([]))
    Sentry.captureException(e)
  } finally {
    yield put(setIsFetchingRejected(false))
  }
}

export function* fetchApproved() {
  yield put(setIsFetchingApproved(true))
  try {
    const filterCenter = yield select(state => state.approver.filterCenter)
    const filterProviders = yield select(state => state.approver.filterProviders)
    const filterRequestNumber = yield select(state => state.approver.filterRequestNumber)
    const { data } = yield call(getApproverApproved, filterCenter, filterProviders, filterRequestNumber)
    yield put(setApproved(uniqBy(data, 'id')))
  } catch (e) {
    yield put(setApproved([]))
    Sentry.captureException(e)
  } finally {
    yield put(setIsFetchingApproved(false))
  }
}

export function* fetchCart(action) {
  yield put(setIsFetchingCart(true))
  try {
    const requestOrder = yield select(state => state.approver.requestOrder)
    if (!requestOrder) return
    const requestOrderId = get(action, 'payload.requestOrderId', requestOrder?.id)
    const response = yield call(getApproverCart, requestOrderId)
    yield put(checkApproverConfirmedCart(response))
    const { products = [], deliveries = [] } = response
    const newCart = assembleCart(products, deliveries)
    yield put(setCart(newCart))
    yield put(setCartDeliveries(deliveries))
  } catch (e) {
    yield put(setCart(null))
    yield put(setRequestOrder(null))
    toast.error(getApiErrorMessage(e))
    Sentry.captureException(e)
  } finally {
    yield put(setIsFetchingCart(false))
  }
}

function* approveCart(action, endpoint) {
  yield put(setIsUpdatingCart(true))
  try {
    const requestOrderId = get(action, 'payload.requestOrderId')
    const successCallback = get(action, 'successCallback')
    const params = get(action, 'payload.params')
    const result = yield call(endpoint, requestOrderId, params)

    yield put(setCart(null))
    yield put(setRequestOrder(null))

    if (successCallback) successCallback({ isSuccess: true })
    const mappedProductsForAnalytics = result?.products?.map(product => ({
      amount: product.amount,
      ...product.product,
    }))
    analyticsService.purchase({
      products: mappedProductsForAnalytics,
      transactionId: result?.trackingId,
      purchaseType: ANALYTICS_PURCHASE_TYPE.approved,
    })
  } catch (error) {
    Sentry.captureException(error)
    const successCallback = get(action, 'successCallback')
    if (successCallback) successCallback({ isSuccess: false, error })
  } finally {
    yield put(setIsUpdatingCart(false))
  }
}

export function* confirmCart(action) {
  const features = yield select(selectConfig)
  const ep = features.order_confirm_sqs ? confirmApproverCartSQS : confirmApproverCart
  yield approveCart(action, ep)
}

export function* rejectCart(action) {
  yield approveCart(action, rejectApproverCart)
}

function* editCart(action) {
  const cart = yield select(state => state.approver.cart)

  const id = get(action, 'id')
  const newItem = get(action, 'payload')

  if (!id || !newItem) {
    return
  }

  const newCart = { ...cart }
  newCart[id] = { ...newCart[id], ...newItem }
  yield put(setCart(newCart))

  yield delay(500)
  yield put(syncCart())
  yield fetchPendingApproval()
}

export function* syncRemoteCart() {
  const cart = yield select(state => state.approver.cart)
  const requestOrder = yield select(state => state.approver.requestOrder)
  const requestOrderId = get(requestOrder, 'id')

  if (!cart || !requestOrderId) {
    return
  }

  const products = assemblePayload(cart)

  try {
    yield call(updateApproverCart, requestOrderId, products)
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(setErrorCart(e))
      yield fetchCart({ payload: { requestOrderId } })
      toast.error(getApiErrorMessage(e))
      Sentry.captureException(e)
    }
  }
}

export function* deleteProductsProvider(action) {
  const requestOrder = yield select(state => state.approver.requestOrder)
  const requestOrderId = get(requestOrder, 'id')
  const providerId = get(action, 'payload.providerId')
  try {
    yield call(deleteApproverProviderProducts, requestOrderId, providerId)
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(setErrorCart(e))
      toast.error(getApiErrorMessage(e))
      Sentry.captureException(e)
    }
  } finally {
    yield fetchCart({ payload: { requestOrderId } })
  }
}

function* impersonate(action) {
  try {
    yield put(setIsFetchingCart(true))
    const requestOrder = yield select(state => state.approver.requestOrder)
    const cartData = yield call(getApproverCart, requestOrder?.id)
    const { products = [], deliveries = [], origin } = cartData
    const newCart = assembleCart(products, deliveries)
    yield put(setCart(newCart))
    yield put(setCartDeliveries(deliveries))
    yield put(AuthActions.setCenter(origin))
    yield put(setErrorCart(false))

    yield put(PurchaseActions.setProviders([products[0]?.product?.provider]))
    yield delay(250)
    const successCallback = get(action, 'payload.successCallback')
    if (successCallback) successCallback()
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(getApiErrorMessage(e))
    }
  } finally {
    yield put(setIsFetchingCart(false))
  }
}

export function* fetchOriginCenters() {
  try {
    const requestOrder = yield select(state => state.approver.requestOrder)
    const { data = [] } = yield call(getApproverOriginCenters, requestOrder?.origin?.id)
    yield put(setOriginCenters(data))
  } catch (e) {
    yield put(setErrorCart(true))
    Sentry.captureException(e)
  }
}

function* checkApproverConfirmedCartSaga(action) {
  try {
    const confirmedCart = action.payload
    const prevConfirmedCartDate = yield select(state => state.approver.approverConfirmedCart)

    if (new Date(confirmedCart?.updated) <= new Date(prevConfirmedCartDate?.updated)) {
      return
    }
    // TODO: delete string conversion in future
    const mmappedConfirmedCart = { ...confirmedCart, parentId: `${confirmedCart.parentId}` }
    yield put(setApproverConfirmedCart(mmappedConfirmedCart))
  } catch (error) {
    Sentry.captureException(error)
  }
}

export function* fetchWeekSummary(action) {
  try {
    const params = get(action, 'payload', {})
    yield put(setIsFetchingSummary(true))
    const data = yield call(getWeekSummary, params.center, { startDate: params.startDate, endDate: params.endDate })

    yield put(setWeekSummary(data || {}))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(getApiErrorMessage(e))
    }
  }
  yield put(setIsFetchingSummary(false))
}

function* watchFetchPendingApproval() {
  yield takeLatest(FETCH_PENDING_APPROVAL, fetchPendingApproval)
}

function* watchFetchRejected() {
  yield takeLatest(FETCH_REJECTED, fetchRejected)
}

function* watchFetchApproved() {
  yield takeLatest(FETCH_APPROVED, fetchApproved)
}

function* watchFetchCart() {
  yield takeLatest(FETCH_CART, fetchCart)
}

function* watchConfirmCart() {
  yield takeLatest(CONFIRM_CART, confirmCart)
}

function* watchRejectCart() {
  yield takeLatest(REJECT_CART, rejectCart)
}

function* watchEditCart() {
  yield takeLatest(EDIT_CART, editCart)
}

function* watchSyncCart() {
  yield takeLatest(SYNC_CART, syncRemoteCart)
}

function* watchDeleteProviderProducts() {
  yield takeLatest(DELETE_PROVIDER_PRODUCT, deleteProductsProvider)
}

function* watchImpersonate() {
  yield takeLatest(IMPERSONATE, impersonate)
}

function* watchOriginCenters() {
  yield takeLeading(FETCH_ORIGIN_CENTERS, fetchOriginCenters)
}

function* watchCheckApproverConfirmerCart() {
  yield takeLatest(CHECK_APPROVER_CONFIRMED_CART, checkApproverConfirmedCartSaga)
}

function* watchWeekSummary() {
  yield takeLeading(FETCH_WEEK_SUMMARY, fetchWeekSummary)
}

export default function* rootAuthSaga() {
  yield all([
    watchFetchPendingApproval(),
    watchFetchRejected(),
    watchFetchApproved(),
    watchFetchCart(),
    watchConfirmCart(),
    watchRejectCart(),
    watchEditCart(),
    watchSyncCart(),
    watchDeleteProviderProducts(),
    watchImpersonate(),
    watchOriginCenters(),
    watchCheckApproverConfirmerCart(),
    watchWeekSummary(),
  ])
}
