import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { StyledMaintenanceMode } from './styled'

const MaintenanceMode = ({ message }) => {
  const { t } = useTranslation()

  return (
    <StyledMaintenanceMode>
      <h4>{message || t('Modo de mantenimiento activo')}</h4>
    </StyledMaintenanceMode>
  )
}

MaintenanceMode.propTypes = {
  message: PropTypes.string,
}

MaintenanceMode.defaultProps = {
  message: '',
}

export default MaintenanceMode
