import { MSAL_AUTH, MSAL_CLIENT_ID, FRONT_URL } from './constants'

export const msalConfig = {
  auth: {
    clientId: MSAL_CLIENT_ID,
    authority: MSAL_AUTH,
    redirectUri: FRONT_URL,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}

export const loginRequest = {
  scopes: ['User.Read'],
}

export const logoutRequest = {
  postLogoutRedirectUri: FRONT_URL,
}
