import styled from 'styled-components'

export const FilterButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;

  align-items: center;
  justify-content: space-between;

  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  .icon {
    margin-right: 0.5rem;
  }
`

export const FilterContainer = styled.div`
  position: absolute;
  width: 320px;
  height: 250px;
  top: 3rem;
  display: ${props => props.display};
  flex-direction: column;
  padding: 1rem;

  .MuiTypography-body1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .MuiFormControlLabel-labelPlacementStart {
    justify-content: space-between;
    margin-right: 0;
  }

  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
`
