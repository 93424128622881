import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CarroVacio } from 'Assets/Icons/CarroVacio.svg'
import { ReactComponent as CarroError } from 'Assets/Icons/CarroError.svg'
import { fetchCart } from 'Redux/purchases/actions'
import { useDispatch } from 'react-redux'

import { Container, ErrorMessage, ErrorTitle, SVGRow, RetryButton } from './styled'

const CartTemplate = ({ variant }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const refetchCart = () => dispatch(fetchCart())

  return (
    <Container>
      <SVGRow>{variant === 'error' ? <CarroError /> : <CarroVacio />}</SVGRow>
      <ErrorTitle>{t('emptyCartTitle')}</ErrorTitle>
      <ErrorMessage>{t('emptyCartSubtitle')}</ErrorMessage>
      {variant === 'error' && <RetryButton onClick={refetchCart}>{t('errorCartRetry')}</RetryButton>}
    </Container>
  )
}

CartTemplate.propTypes = {
  variant: PropTypes.oneOf(['error', 'empty']).isRequired,
}

export default CartTemplate
