import React, { useRef, useState, useEffect, useCallback } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep'
import { useTranslation } from 'react-i18next'
import { DownloadProductCatalog } from 'Components/atoms'
import { downloadProductCatalog } from 'Services/api'
import { selectCenterId } from 'Redux/purchases/utils'
import { useDebounce } from 'Components/utils/customHooks'
import { TemperatureFilter, OrderFilter } from 'Components/molecules'
import { PurchaseTypes, PurchaseActions } from 'Redux/purchases'
import { AuthActions } from 'Redux/auth'
import ModalDownloadEmail from 'Components/molecules/modalDownloadEmail'
import Product from 'Components/molecules/product'
import { needsApprovalMode } from 'Redux/approver/selectors'
import { toast } from 'react-toastify'

import { sortTypes, orderTypes } from '../../molecules/orderFilter/constants'
import ProductList, { ResultMessage, MultipleProductCardLoader, SearchTextInput } from '../productsList'

import { getSelectedCategories, getSearchParams, getTitle } from './utils'
import { Title, CardsContainer, ButtonsContainer, Grow, TopbarContainer, Text } from './styled'

const LIMIT = 15

const Products = ({ temperature, setTemperature }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { id: paramsId, providerId = '' } = useParams()

  const [pageNumber, setPageNumber] = useState(0)
  const [hasMore, setHasMore] = useState('')
  const [products, setProducts] = useState([])
  const [search, setSearch] = useState('')
  const [showDownloadModal, setShowDownloadModal] = useState(false)

  const needsApproval = useSelector(needsApprovalMode)
  const productsReducer = useSelector(state => state.purchase.products)
  const isLoadingProducts = useSelector(state => state.purchase.isLoadingProducts)
  const isLoading = useSelector(state => state.purchase.isLoadingProducts)
  const categories = useSelector(state => state.purchase.categories)
  const providerCategories = useSelector(state => state.purchase.providerCategories)
  const selectedCategories = useSelector(state => state.purchase.selectedCategories)
  const showOnlyOneProduct = useSelector(state => state.purchase.showOnlyOneProduct)
  const isRefreshingCategory = useSelector(state => state.auth.isRefreshingCategory)
  const isReloading = useSelector(state => state.purchase.isReloading)
  const orderAndSort = useSelector(
    state => state.purchase.orderAndSort || { order: orderTypes.ascending, sort: sortTypes.name }
  )

  const setOrderAndSort = useCallback(
    value => {
      dispatch(PurchaseActions.setOrderAndSort(value))
    },
    [dispatch]
  )

  const centerId = useSelector(selectCenterId)

  const debouncedSearchTerm = useDebounce(search, 300)

  const observer = useRef()
  const lastProductElementRef = useCallback(
    node => {
      if (isLoadingProducts) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(prevPageNumber => prevPageNumber + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoadingProducts, hasMore]
  )

  const handleSuccessHabitual = (itemId, changeToHabitual) => {
    const newProducts = cloneDeep(products)
    const findIndex = newProducts?.findIndex(v => v.id === itemId)

    if (findIndex > -1) {
      newProducts[findIndex].isHabitual = changeToHabitual
      setProducts([...newProducts])
    }
  }

  const handleDownloadProductCatalog = async () => {
    try {
      await downloadProductCatalog(centerId)
    } catch (error) {
      toast.error(t('downloadCatalogError'))
    }
    setShowDownloadModal(true)
  }

  useEffect(() => {
    setProducts(prevProducts => [...prevProducts, ...productsReducer])
    setHasMore(productsReducer.length > 0 && productsReducer.length >= LIMIT)
    if (productsReducer.length > 0) {
      dispatch(AuthActions.setRefreshingCategory(false))
    }
  }, [dispatch, productsReducer])

  const { order, sort } = orderAndSort

  useEffect(() => {
    setProducts([])
    setPageNumber(0)
  }, [paramsId, temperature, debouncedSearchTerm, sort, order, providerId, providerCategories, categories])

  useEffect(() => {
    const categoriesToSelect = providerId ? providerCategories : categories

    if (!categoriesToSelect.length) return
    if (providerId && !categories.length) return

    const { category, subCategory, keymap3 } =
      paramsId === 'all'
        ? { category: { id: 'all', name: t('allCategories') } }
        : getSelectedCategories(paramsId, categoriesToSelect)

    const searchParams = paramsId === 'all' ? { category: '' } : getSearchParams(category, subCategory, keymap3)

    const offset = pageNumber * LIMIT
    dispatch(PurchaseActions.setSelectedCategories({ category, subCategory, keymap3 }))
    dispatch({ type: PurchaseTypes.FETCH_IS_RELOADING })

    dispatch({
      type: PurchaseTypes.FETCH_PRODUCTS,
      params: {
        providers: providerId,
        temperature,
        search: debouncedSearchTerm,
        offset,
        limit: LIMIT,
        order,
        sort,
        ...searchParams,
      },
    })
  }, [
    paramsId,
    categories,
    temperature,
    dispatch,
    debouncedSearchTerm,
    pageNumber,
    order,
    sort,
    providerId,
    providerCategories,
    t,
  ])

  const hasCategoryTitle = !!getTitle(selectedCategories)

  // DOWLOAD PRODUCT CATALOG
  const showDownloadProductCatalog = true

  return (
    <>
      {!showOnlyOneProduct ? (
        <TopbarContainer>
          {getTitle(selectedCategories) && <Title>{getTitle(selectedCategories)}</Title>}
          {showDownloadProductCatalog && <DownloadProductCatalog onClick={handleDownloadProductCatalog} />}
          <ButtonsContainer>
            <OrderFilter orderAndSort={orderAndSort} setOrderAndSort={setOrderAndSort} />
            <TemperatureFilter temperature={temperature} setTemperature={setTemperature} />
            <Grow />
            <SearchTextInput value={search} onChange={e => setSearch(e.target.value)} />
          </ButtonsContainer>
        </TopbarContainer>
      ) : null}
      {isReloading?.isReloading === true ? <Text>{t('reloadingProducts')}</Text> : null}
      <CardsContainer
        $needsApproval={needsApproval}
        $showDownloadProductCatalog={showDownloadProductCatalog}
        $tutorial={false}
        $hasCategoryTitle={hasCategoryTitle}
      >
        {showOnlyOneProduct ? (
          <div style={{ padding: 40 }}>
            <Product fixAllScreen />
          </div>
        ) : (
          <>
            {products.length > 0 && (
              <ProductList
                products={products}
                lastElementRef={lastProductElementRef}
                onSuccessHabitual={itemId => handleSuccessHabitual(itemId, false)}
                onSuccessUnHabitual={itemId => handleSuccessHabitual(itemId, true)}
              />
            )}
            {products.length === 0 && !isLoading && !isRefreshingCategory && <ResultMessage isLoading={isLoading} />}
            {isLoading && <MultipleProductCardLoader />}
          </>
        )}
      </CardsContainer>
      <ModalDownloadEmail
        setShowModal={() => setShowDownloadModal(false)}
        showModal={showDownloadModal}
        title={t('downloadProductCatalogTitle')}
        description={t('downloadProductCatalogDescription')}
      />
    </>
  )
}

Products.defaultProps = {
  temperature: '',
  setTemperature: () => {},
}

Products.propTypes = {
  temperature: PropTypes.string,
  setTemperature: PropTypes.func,
}

export default Products
