import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import SubCategory from 'Components/atoms/subCategoryButton'
import Icons from 'Components/utils/categoryIcons'

import { ButtonContainer, Text, SubCategoriesContainer } from './styled'

const CategoryButton = ({ name, id, subCategories, navigate }) => {
  const defaultHeight = subCategories.length * 40 + 16
  const Icon = Icons[id] || (() => <></>)
  const { category = {} } = useSelector(state => state.purchase.selectedCategories)
  const [selected, setSelected] = useState(category.id === id)
  const [dropdownDisplay, setDropdownDisplay] = useState(false)
  const [height, setHeight] = useState(defaultHeight)

  useEffect(() => {
    setTimeout(() => setDropdownDisplay(selected), 10)
  }, [selected])

  useEffect(() => {
    setTimeout(() => setHeight('auto'), 500)
  }, [dropdownDisplay])

  useEffect(() => {
    if (!selected) setHeight(defaultHeight)
  }, [selected, defaultHeight])

  useEffect(() => {
    setSelected(category.id === id)
  }, [category.id, id])

  const selectCategory = () => {
    setSelected(value => !value)
    navigate(id)
  }

  return (
    <>
      <ButtonContainer onClick={selectCategory} data-cy='categoryButton'>
        <Icon />
        <Text className='categories-text' selected={selected}>
          {name}
        </Text>
      </ButtonContainer>
      {selected && (
        <SubCategoriesContainer
          selected={dropdownDisplay}
          height={height}
          padding={subCategories.length > 0 ? '0.5rem' : 0}
        >
          {subCategories.map(({ id: subCatId, name: subCatName, children: types = [] }) => (
            <SubCategory key={subCatId} id={subCatId} name={subCatName} types={types} navigate={navigate} />
          ))}
        </SubCategoriesContainer>
      )}
    </>
  )
}

CategoryButton.defaultProps = {
  name: '',
  id: '',
  subCategories: [],
  navigate: () => {},
}

CategoryButton.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  subCategories: PropTypes.array,
  navigate: PropTypes.func,
}

export default CategoryButton
