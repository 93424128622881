import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 10vh;
  background: #00000011;
  display: ${props => (props.displayContainer ? props.displayContainer : 'flex')};
  justify-content: ${props => (props.justifyContainer ? props.justifyContainer : 'center')};
  z-index: 1400;
  overflow-y: ${props => (props.overflowY ? props.overflowY : 'unset')};
  &::-webkit-scrollbar {
    display: ${props => props.scrollbar};
  }
  & {
    -ms-overflow-style: ${props => props.scrollbar};
    scrollbar-width: ${props => props.scrollbar};
  }
  @media (max-width: 1200px) {
    display: none;
  }
  @media (max-width: 1280px) {
    height: 120%;
    padding: 4vh;
  }
`

export const ModalInsideContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1401;
`

export const ModalInside = styled.div`
  width: ${props => (props.width ? props.width : '42.5rem')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'unset')};
  background: white;
  height: ${props => (props.height ? props.height : '30rem')};
  min-height: ${props => (props.minHeight ? props.minHeight : 'unset')};
  padding: ${props => (props.padding ? props.padding : '2.5rem 5rem')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  margin: ${props => props.margin};
  position: relative;
  max-height: ${props => (props.maxHeight ? props.maxHeight : '80vh')};
  overflow-y: auto;
  z-index: 1402;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0')};
  display: ${props => props.displayModal || 'block'};

  &::-webkit-scrollbar {
    display: ${props => props.scrollbar};
  }
  & {
    -ms-overflow-style: ${props => props.scrollbar};
    scrollbar-width: ${props => props.scrollbar};
  }
  overflow: ${props => (props.overflowInside ? props.overflowInside : 'auto')};
`

export const CloseButtonContainer = styled.div`
  background: ${props => (props.closeBg ? props.closeBg : 'transparent')};
  position: absolute;
  top: 24px;
  right: 24px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 8px;
  :hover {
    opacity: 0.7;
  }
`

export const CloseButton = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;
  position: absolute;
  top: 3px;
  right: 8px;
  transform: rotate(45deg);
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`
