import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { PROVIDER_ORDERS_MODIFICATION_TYPE, REQUEST_ORDER_STATE } from 'Config/constants'

const STATUS_TEXT = {
  [REQUEST_ORDER_STATE.APPROVED]: 'userApproved',
  [REQUEST_ORDER_STATE.REJECTED]: 'userRejected',
  [REQUEST_ORDER_STATE.CANCELLED]: 'userCancelled',
}

const RequestOrderCell = ({ row }) => {
  const { t } = useTranslation()

  if (!row.requestOrder) {
    return null
  }

  const { state, modificationType, statusUpdatedBy } = row.requestOrder
  let cellContent

  if (
    state === REQUEST_ORDER_STATE.APPROVED &&
    modificationType === PROVIDER_ORDERS_MODIFICATION_TYPE.AUTOMATICALLY_APPROVED
  ) {
    cellContent = t('userApprovedAuto')
  } else if (statusUpdatedBy) {
    cellContent = !!STATUS_TEXT[state] && t(STATUS_TEXT[state], { user: statusUpdatedBy })
  }

  return cellContent || '-'
}

RequestOrderCell.propTypes = {
  row: PropTypes.object.isRequired,
}

export default RequestOrderCell
