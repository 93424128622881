import {
  SET_PROVIDER_RECIVING_ORDERS,
  SET_PROVIDER_RECIVING_ORDERS_LOADING,
  SET_PROVIDER_RECIVING_ORDERS_ERROR,
  SET_PROVIDER_RECIVING_ORDERS_FORM,
  SET_DELIVERY_FORM,
  SET_DELIVERY_ERRORS,
  FETCH_DELIVERY_ERRORS,
} from './types'

export const setProviderReceivingOrders = payload => ({
  type: SET_PROVIDER_RECIVING_ORDERS,
  payload,
})

export const setLoadingProviderReceivingOrders = payload => ({
  type: SET_PROVIDER_RECIVING_ORDERS_LOADING,
  payload,
})

export const setErrorProviderReceivingOrders = payload => ({
  type: SET_PROVIDER_RECIVING_ORDERS_ERROR,
  payload,
})

export const setProviderReceivingOrdersForm = payload => ({
  type: SET_PROVIDER_RECIVING_ORDERS_FORM,
  payload,
})

export const setDeliveryForm = payload => ({
  type: SET_DELIVERY_FORM,
  payload,
})

export const setDeliveryErrors = payload => ({
  type: SET_DELIVERY_ERRORS,
  payload,
})

export const fetchDeliveryErrors = () => ({
  type: FETCH_DELIVERY_ERRORS,
})
