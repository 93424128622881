import colors from 'Assets/colors'
import styled from 'styled-components'

export const AddCartButtonContanier = styled.button`
  background: ${colors.secondary};
  border-radius: 0.5rem;
  cursor: pointer;
  width: 45px;
  height: 45px;
  margin-left: 0.5rem;

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`
