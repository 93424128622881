import React from 'react'

import PropTypes from 'prop-types'

import NumberFormat from '../numberFormat'

import { AmountContainer, Input, LabelValue } from './styled'

const AmountInput = ({ onClick, focused, onBlur, value, onChange, onKeyDown, inputRef, suffix }) => (
  <AmountContainer onClick={onClick}>
    {focused ? (
      <Input
        focused={focused}
        onBlur={onBlur}
        ref={inputRef}
        type='number'
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    ) : (
      <LabelValue>
        <NumberFormat value={value} suffix={suffix} />
      </LabelValue>
    )}
  </AmountContainer>
)

AmountInput.propTypes = {
  onClick: PropTypes.func,
  focused: PropTypes.bool,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  inputRef: PropTypes.any,
  suffix: PropTypes.string,
}

AmountInput.defaultProps = {
  onClick: () => null,
  focused: false,
  onBlur: () => null,
  value: '',
  onChange: () => null,
  onKeyDown: () => null,
  inputRef: null,
  suffix: '',
}

export default AmountInput
