import React from 'react'

import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import routes from 'Config/routes'
import { ReactComponent as TicketConfirmado } from 'Assets/Icons/TicketConfirmado.svg'

import { Container, Content, Title, SubTitle, SvgContainer, ButtonsContainer, useStyles } from './styled'

const TicketingConfirmed = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const handleClickBack = () => history.push(routes.purchase)
  const handleClickHistoric = () => history.push(routes.historic)

  return (
    <Container>
      <Content>
        <Title>{t('ticketingRecievedTitle')}</Title>
        <SubTitle>{t('ticketingRecievedSubtitle')}</SubTitle>
        <SvgContainer>
          <TicketConfirmado style={{ width: '160px', height: '160px' }} />
        </SvgContainer>
        <ButtonsContainer>
          <Button onClick={handleClickBack} className={classes.back}>
            {t('backHome')}
          </Button>
          <Button onClick={handleClickHistoric} className={classes.history}>
            {t('backToHistory')}
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  )
}

export default TicketingConfirmed
