import styled from 'styled-components'
import colors from 'Assets/colors'

export const Input = styled.textarea`
  width: 100%;
  border-radius: ${props => props.borderRadius};
  border: ${props => props.border};
  padding: ${props => props.padding};
  transition: all 0.4s;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  height: ${props => props.height};

  :focus {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }

  &::placeholder {
    color: ${props => props.placeholderColor};
  }
`

export const Label = styled.label`
  position: relative;
  width: 100%;
  background: ${props => props.background};
  border-radius: ${props => props.borderRadius};

  .icon-input {
    content: '';
    position: absolute;
    right: ${props => props.rightIcon};
    top: ${props => props.topIcon};
    bottom: 0;
  }
`

export const Container = styled.div`
  position: relative;
  width: ${props => props.width};
  margin: ${props => props.margin};
`

export const ErrorContainer = styled.div`
  color: ${colors.red};
  padding: 5px 5px 0;
  font-size: 11px;
`
