import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import { persistStore, persistCombineReducers, persistReducer } from 'redux-persist'
import 'core-js'
import storage from 'redux-persist/es/storage'
import createSagaMiddleware from 'redux-saga'

import { setIsReady } from '../Redux/auth/actions'
import { reducers, rootSaga } from '../Redux'

const loggerMiddleware = createLogger({
  collapsed: true,
})

const sagaMiddleware = createSagaMiddleware()

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['ticket', 'approver', 'auth', 'carts', 'purchase', 'lists', 'tutorial', 'multicenter'],
}

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['errorToken', 'hiddenNavbar', 'errorLogin', 'hasOnlyOneCenter', 'centerCount', 'isLoadingMsal'],
  whitelist: ['token', 'refreshToken', 'selectedCenter', 'isRefreshingCategory', 'user', 'centersList'],
}

const purchasePersistConfig = {
  key: 'purchase',
  storage,
  blacklist: ['isUpdatingCart', 'isLoadingCart', 'repeatOrderLoading', 'isEditingCart', 'addProductsQueue'],
  whitelist: ['previusOrderProducts'],
}

const approverPersistConfig = {
  key: 'approver',
  storage,
  blacklist: [
    'cart',
    'pendingApproval',
    'closeToAutoApproved',
    'isFetchingPendingApproval',
    'rejected',
    'isFetchingRejected',
    'approved',
    'isFetchingApproved',
    'isFetchingCart',
    'requestOrder',
    'isUpdatingCart',
    'filterCenter',
    'filterProviders',
  ],
}

const listsPersistConfig = {
  key: 'lists',
  storage,
  blacklist: [
    'isFetchingLists',
    'lists',
    'isFetchingAdd',
    'products',
    'data',
    'isFetchingProducts',
    'errorAdd',
    'isFetchingUpdate',
    'isSendingCart',
  ],
}

const cartsPersistConfig = {
  key: 'carts',
  storage,
  whitelist: [''],
}

const multicenterPersistConfig = {
  key: 'multicenter',
  storage,
  whitelist: ['noCompliancesFilters', 'ordersFilters', 'selectedCenter'],
}

const rootReducer = {
  ...reducers,
  auth: persistReducer(authPersistConfig, reducers.auth),
  purchase: persistReducer(purchasePersistConfig, reducers.purchase),
  approver: persistReducer(approverPersistConfig, reducers.approver),
  lists: persistReducer(listsPersistConfig, reducers.lists),
  carts: persistReducer(cartsPersistConfig, reducers.carts),
  multicenter: persistReducer(multicenterPersistConfig, reducers.multicenter),
}

const persistRouterReducer = persistCombineReducers(rootPersistConfig, rootReducer)
const middlewares =
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(sagaMiddleware)
    : applyMiddleware(sagaMiddleware, loggerMiddleware)
const store = createStore(persistRouterReducer, middlewares)
const persistor = persistStore(store, {}, () => store.dispatch(setIsReady(true)))

sagaMiddleware.run(rootSaga)

export { persistor, store }
