import React from 'react'

import { useTranslation } from 'react-i18next'
import { ReactComponent as CarroError } from 'Assets/Icons/CarroError.svg'
import { ReactComponent as PlayStore } from 'Assets/Icons/PlayStore.svg'
import { BigTitle, RegularText } from 'Components/atoms'
import { ANDROID_APP_URL } from 'Config/constants'

import { MainContainer, SmallDevicesContainer } from './styled'

const SmallDeviceAlert = () => {
  const { t } = useTranslation()

  const handleOnClick = () => window.open(ANDROID_APP_URL, '_blank')

  return (
    <MainContainer>
      <SmallDevicesContainer>
        <CarroError style={{ width: '6rem', height: '6rem' }} />
        <BigTitle textAlign='center'>{t('smallDeviceHeader')}</BigTitle>
        <RegularText textAlign='center'>{t('smallDeviceSubHeader')}</RegularText>
        <PlayStore onClick={handleOnClick} style={{ width: '12rem', height: '6rem', cursor: 'pointer' }} />
      </SmallDevicesContainer>
    </MainContainer>
  )
}

export default SmallDeviceAlert
