import axios from 'axios'
import qs from 'qs'

export const getCategoriesHabitual = centerId =>
  axios.get('/api/categories?habituals=true', { headers: { 'X-Center-Id': centerId } })

export const getHabitualProducts = (params, centerId) =>
  axios.get(`/api/habituals?${qs.stringify(params, { skipNulls: true })}`, {
    headers: { 'X-Center-Id': centerId },
  })

export const removeHabitual = (productId, centerId) =>
  axios.delete(`/api/habituals/${productId}`, { headers: { 'X-Center-Id': centerId } })

export const addHabitual = (productId, centerId) =>
  axios.post(`/api/habituals/${productId}`, {}, { headers: { 'X-Center-Id': centerId } })

export const getFavoritesHabitualProducts = (params, centerId) =>
  axios.get(`/api/habituals/favorites?${qs.stringify(params, { skipNulls: true })}`, {
    headers: { 'X-Center-Id': centerId },
  })
