import { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { setFilterCenter } from 'Redux/approver/actions'

const useApproverCartsCenterFilter = () => {
  const dispatch = useDispatch()

  const filterCenter = useSelector(state => state.approver.filterCenter)

  const [showModal, setShowModal] = useState(false)

  const onClickShow = () => setShowModal(true)
  const onClickHide = () => setShowModal(false)
  const onSelectCenter = center => dispatch(setFilterCenter(center))
  const onResetCenter = () => dispatch(setFilterCenter(null))

  return { filterCenter, showModal, onClickShow, onClickHide, onSelectCenter, onResetCenter }
}

export default useApproverCartsCenterFilter
