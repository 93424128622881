import React, { useEffect, useCallback, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import map from 'lodash/map'
import times from 'lodash/times'
import some from 'lodash/some'
import ContentLoader from 'react-content-loader'
import { Notification, TicketStatusIcon } from 'Components/atoms'
import { TicketStatusFilter } from 'Components/molecules'
import colors from 'Assets/colors'
import { ReactComponent as Arrow } from 'Assets/Icons/ArrowRightTicketButton.svg'
import { TICKET_STATUS_COLOR, TICKET_STATUS } from 'Config/constants'
import routes from 'Config/routes'
import { TicketActions } from 'Redux/ticket'
import {
  selectTicketsList,
  selectIsLoadingTikets,
  selectFilterStatus,
  selectTicketsListCount,
  selectTicketsPendingListCount,
} from 'Redux/ticket/selectors'
import { CartDrawer, SearchBar } from 'Components/organisms'

import {
  Container,
  Title,
  SubTitle,
  TicketListContainer,
  SingleTicketContainer,
  SingleTicketHeader,
  SingleTicketInfo,
  SingleTicketContent,
  ProviderInfoContainer,
  ProviderName,
  TicketInfoAuxText,
  TicketInfoContainer,
  TicketStatusContainer,
  SingleTicketMainText,
  TicketStatusInfoContainer,
  TicketStatusCombo,
  ButtonContainer,
  ActionButton,
  SingleTicketRefContainer,
  Header,
} from './styled'

const LIMIT = 15

const TicketingList = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const observer = useRef()

  const tickets = useSelector(selectTicketsList)
  const isLoading = useSelector(selectIsLoadingTikets)
  const filterStatus = useSelector(selectFilterStatus)
  const count = useSelector(selectTicketsListCount)
  const pendingCount = useSelector(selectTicketsPendingListCount)

  const [hasMore, setHasMore] = useState('')
  const [pageNumber, setPageNumber] = useState(0)
  const offset = pageNumber * LIMIT
  const [queryParams, setQueryParams] = useState({ offset, limit: LIMIT })
  const [list, setList] = useState([])
  const [showDrawer, setShowDrawer] = useState(false)

  const lastProductElementRef = useCallback(
    node => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(pageNumber + 1)
          setQueryParams({ ...queryParams, offset: (pageNumber + 1) * LIMIT })
        }
      })
      if (node) observer.current.observe(node)
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading]
  )

  const showNoResponseNotification =
    filterStatus !== TICKET_STATUS.pending && (some(tickets, { status: TICKET_STATUS.pending }) || pendingCount > 0)

  useEffect(() => {
    dispatch(TicketActions.fetchPendingTickets())
  }, [dispatch])

  useEffect(() => {
    const newItemsList = tickets || []
    const newList = [...list, ...newItemsList]
    setList(newList)
    setHasMore(LIMIT === newItemsList.length && newList.length < count)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets])

  useEffect(() => {
    dispatch(TicketActions.fetchTickets(queryParams))
    return () => dispatch(TicketActions.setTickets([]))
  }, [dispatch, queryParams])

  const handleClickDetail = ticket => {
    dispatch(TicketActions.setTicket(ticket))
    history.push(routes.addTicketingDetail(ticket.id))
  }

  const handleChangeFilter = newStatus => {
    if (filterStatus !== newStatus) {
      dispatch(TicketActions.setFilterStatus(newStatus))
      setPageNumber(0)
      setList([])
      setQueryParams({ ...queryParams, offset: 0 })
    }
  }

  const handleClickShowPendingTickets = () => {
    handleChangeFilter(TICKET_STATUS.pending)
  }

  return (
    <>
      <SearchBar setShowDrawer={setShowDrawer} />
      <Container $tutorial={false}>
        <Header>
          <Title>{t('ticketingListTitle')}</Title>
          <TicketStatusFilter status={filterStatus} onChange={handleChangeFilter} />
        </Header>
        <SubTitle>{t('ticketingListSubtitle')}</SubTitle>
        {showNoResponseNotification && (
          <Notification
            text={t('ticketingNoResponseNotificationCopy')}
            color='error'
            buttonText={t('ticketingNoResponseNotificationButton')}
            onClick={handleClickShowPendingTickets}
          />
        )}
        <TicketListContainer>
          {map(list, (ticket, index) => (
            <SingleTicketRefContainer key={ticket.id} ref={list.length === index + 1 ? lastProductElementRef : null}>
              <SingleTicket ticket={ticket} onClickDetail={handleClickDetail} />
            </SingleTicketRefContainer>
          ))}
          {isLoading && map(times(3), key => <RequestsLoader key={key} />)}
        </TicketListContainer>
      </Container>
      <CartDrawer showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
    </>
  )
}

const SingleTicket = ({ onClickDetail, ticket }) => {
  const { t } = useTranslation()

  const { id, created, updated, providerOrderId, subcategory, status, providerName } = ticket || {}

  const statusText = t(`ticketingStatus.${status}`)
  const statusColor = TICKET_STATUS_COLOR[status]
  const statusBorder = status === TICKET_STATUS.pending ? `1px solid ${statusColor}` : ''

  const handleClickDetail = () => onClickDetail(ticket)

  return (
    <SingleTicketContainer>
      <SingleTicketHeader>
        <SingleTicketInfo>{t('singleTicketHeaderId', { noConformityId: id })}</SingleTicketInfo>
        <SingleTicketInfo>
          {t('singleTicketHeaderDate', { noConformityDate: moment(created).fromNow() })}
        </SingleTicketInfo>
      </SingleTicketHeader>
      <SingleTicketContent>
        <ProviderInfoContainer>
          <ProviderName>{providerName}</ProviderName>
          <TicketInfoAuxText>{`${t('order')} ${providerOrderId}`}</TicketInfoAuxText>
        </ProviderInfoContainer>
        <TicketInfoContainer>
          <SingleTicketMainText>{subcategory}</SingleTicketMainText>
        </TicketInfoContainer>
        <TicketStatusContainer style={{ border: statusBorder }}>
          <TicketStatusCombo>
            <TicketStatusIcon status={status} />
            <TicketStatusInfoContainer>
              <SingleTicketMainText style={{ color: statusColor }}>{statusText}</SingleTicketMainText>
              <TicketInfoAuxText>
                {t('singleTicketUpdatedDate', { ticketUpdatedDate: moment(updated).fromNow() })}
              </TicketInfoAuxText>
            </TicketStatusInfoContainer>
          </TicketStatusCombo>
          <ButtonContainer onClick={handleClickDetail}>
            <ActionButton>
              <Arrow style={{ fill: colors.white }} />
            </ActionButton>
          </ButtonContainer>
        </TicketStatusContainer>
      </SingleTicketContent>
    </SingleTicketContainer>
  )
}

SingleTicket.propTypes = {
  onClickDetail: PropTypes.func,
  ticket: PropTypes.object,
}

SingleTicket.defaultProps = {
  onClickDetail: () => {},
  ticket: '',
}

const RequestsLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width={1400}
      height={140}
      viewBox='0 0 1400 140'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='18' y='60' rx='0' ry='0' width='100%' height='10' />
      <rect x='18' y='18' rx='0' ry='0' width='150' height='10' />
      <rect x='18' y='90' rx='0' ry='0' width='100' height='10' />
      <rect x='400' y='90' rx='0' ry='0' width='100' height='10' />
    </ContentLoader>
  )
}

export default TicketingList
