import { ANALYTICS_EVENT } from './constants'
import {
  handleListCategoryType,
  valueFormatter,
  convertCategoriesToArray,
  mapListName,
  mapListId,
  sendAnalytics,
} from './utils'

const viewItemList = ({ products, selectedCategories }) => {
  if (!selectedCategories || !products) return

  const categoriesToArray = convertCategoriesToArray(selectedCategories)
  const listName = mapListName(categoriesToArray)
  const listId = mapListId(categoriesToArray)

  sendAnalytics(ANALYTICS_EVENT.VIEW_ITEM_LIST, {
    item_list_name: listName,
    item_list_id: listId,
    list_category_type: handleListCategoryType(categoriesToArray),
    items: products.map(({ name, id, price, provider, category, temperature }) => ({
      item_list_name: listName,
      item_list_id: listId,
      item_name: name,
      item_id: id,
      item_category: category?.name,
      item_category2: category?.children?.[0]?.name,
      item_category3: category?.children?.[0]?.children?.[0]?.name,
      item_category4: temperature,
      price: valueFormatter(price),
      currency: 'EUR',
      affiliation: `${provider?.name} - ${provider?.id}`,
    })),
  })
}

export default viewItemList
