import React from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { MEASURE_UNIT } from 'Config/constants'
import { NumberFormat } from 'Components/atoms'
import { Price, PriceKG } from 'Components/molecules/productCard/styled'

const ProductPrice = ({ product }) => {
  const center = useSelector(state => state.auth.selectedCenter)

  const { showPrice } = center
  const { price, measureUnit, pricePerKg } = product

  return (
    <div style={{ display: 'flex' }}>
      {showPrice && (
        <>
          <Price>
            <NumberFormat value={price} suffix={`€/${measureUnit}`} />
          </Price>
          {measureUnit !== MEASURE_UNIT.KG ? (
            <PriceKG>
              <NumberFormat value={pricePerKg} suffix='€/KG' />
            </PriceKG>
          ) : null}
        </>
      )}
    </div>
  )
}

ProductPrice.defaultProps = {
  product: {},
}

ProductPrice.propTypes = {
  product: PropTypes.object,
}

export default ProductPrice
