import styled from 'styled-components'
import colors from 'Assets/colors'

export const SingleFieldContainer = styled.div`
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '16px')};
`

export const AmountContainer = styled.div`
  display: flex;
  margin-right: auto;
`

export const Selector = styled.button`
  background: ${props => (props.disabled ? colors.grayInput : colors.cart)};
  border-radius: 4px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  margin-left: 0.5rem;
  padding: 5px;

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const Input = styled.input`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  width: 100%;
`

export const ButtonCart = styled.div`
  width: 100%;
  background-color: ${colors.white};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-transform: none;
  color: ${colors.black};
  transition: all 0.2s;
  display: flex;
  justify-content: ${props => (props.hasAmount ? 'flex-end' : 'center')};
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: ${props => (props.hasAmount ? null : 'pointer')};
  border: 2px solid ${colors.cart};
  border: ${props => (props.error ? `2px solid ${colors.red}` : null)};
  height: 40px;
  margin: ${props => props.margin};

  &:hover {
    opacity: ${props => (props.hasAmount ? 1 : 0.8)};
    transform: ${props => (props.hasAmount ? null : 'translateY(-2px)')};
    box-shadow: ${props => (props.hasAmount ? null : '0 3px 5px rgba(51, 51, 51, 0.2)')};
  }

  &:active {
    transform: ${props => (props.hasAmount ? null : 'translate(-1px)')};
    box-shadow: ${props => (props.hasAmount ? null : '0 1px 3px rgba(51, 51, 51, 0.4)')};
  }
`
