import { REQUEST_ORDER_STATE } from 'Config/constants'
import styled, { css } from 'styled-components'

export const ProviderHeaderContainer = styled.div`
  padding: 16px;
  display: flex;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.gray98b};
  color: ${props => props.theme.colors.black};
  border: 1px solid rgba(51, 51, 51, 0.24);

  ${props =>
    props.state === REQUEST_ORDER_STATE.PENDING &&
    css`
      background-color: ${props.theme.colors.orange};
      color: ${props.theme.colors.white};
    `}

    ${props =>
      props.state === REQUEST_ORDER_STATE.APPROVED &&
      css`
        background-color: ${props.theme.colors.black};
        color: ${props.theme.colors.white};
      `}

    ${props =>
      props.state === REQUEST_ORDER_STATE.CANCELLED &&
      css`
        background-color: ${props.theme.colors.gray3};
        color: ${props.theme.colors.black};
      `}

    ${props =>
      props.state === REQUEST_ORDER_STATE.REJECTED &&
      css`
        background-color: ${props.theme.colors.gray3};
        color: ${props.theme.colors.black};
      `}
`

export const ProviderHeaderContent = styled.div`
  display: flex;
`

export const ProviderHeaderValue = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding-right: 8px;
`

export const ProviderHeaderFlag = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 0 8px;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.cart};
  border-radius: 8px;
  margin-left: 16px;

  ${props =>
    props.bold &&
    css`
      font-weight: 600;
      font-size: 16px;
    `}
`

export const ProviderHeaderText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 0 8px;
  color: ${props => props.theme.colors.black};
  margin-left: 16px;

  ${props =>
    props.bold &&
    css`
      font-weight: 600;
      font-size: 16px;
    `}
`
