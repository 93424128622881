import React, { useCallback } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowRight } from 'Assets/Icons/ArrowRight.svg'
import UnderlineButton from 'Components/atoms/underlineButton'

import * as S from './styled'

const ActionsCell = ({ row, onViewDetailClick }) => {
  const { t } = useTranslation()

  const getClickCallback = useCallback(callback => () => callback(row), [row])

  return (
    <S.Cell>
      <UnderlineButton
        Icon={ArrowRight}
        iconRight
        label={t`viewDetail`}
        onClick={getClickCallback(onViewDetailClick)}
      />
    </S.Cell>
  )
}

ActionsCell.propTypes = {
  row: PropTypes.object.isRequired,
  onViewDetailClick: PropTypes.func,
}

ActionsCell.defaultProps = {
  onViewDetailClick: () => undefined,
}

export default ActionsCell
