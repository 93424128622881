import styled from 'styled-components'

export const SubCategory = styled.div`
  padding: 0.5rem 1.25rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover .subcategories-text {
    color: ${props => props.theme.colors.secondary};
  }
`

export const SubCatText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.2s;
  color: ${props => (props.selected ? props.theme.colors.secondary : props.theme.colors.black)};
`

export const TypeContainer = styled.div`
  max-height: ${props => (props.selected ? `${props.height}px` : 0)};
  overflow: hidden;
  transition: all 0.3s;
`

export const ShowMore = styled.div`
  padding: 0.5rem 1.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const ShowMoreText = styled.div`
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`
