import axios from 'axios'
import qs from 'qs'

export const getDownloadHistoric = (params, centerId) =>
  axios.get(`/api/v2/provider-orders/export?${qs.stringify(params, { skipNulls: true })}`, {
    headers: { 'X-Center-Id': centerId },
  })

export const getProviderPendingOrders = (params, centerId) =>
  axios.get(`/api/provider-orders/pending-to-receive?${qs.stringify(params, { skipNulls: true })}`, {
    headers: { 'X-Center-Id': centerId },
  })

export const getOrderProducts = (params, centerId, orderId, documentTypeCode, documentCompany) =>
  axios.get(
    `/api/provider-orders/${orderId}/${documentTypeCode}/${documentCompany}?${qs.stringify(params, {
      skipNulls: true,
    })}`,
    {
      headers: { 'X-Center-Id': centerId },
    }
  )

export const getProviderOrders = (params, centerId) => {
  if (params.ids?.length > 0) {
    return axios.get(`/api/provider-orders?${params.ids}`, {
      headers: { 'X-Center-Id': centerId },
    })
  }
  return axios.get(`/api/provider-orders?${qs.stringify(params, { skipNulls: true })}`, {
    headers: { 'X-Center-Id': centerId },
  })
}

export const getProviderOrdersExcel = (params, centerId, orderId, documentTypeCode, documentCompany) =>
  axios.get(
    `/api/v2/provider-orders/${orderId}/${documentTypeCode}/${documentCompany}/export?${qs.stringify(params, {
      skipNulls: true,
    })}`,
    {
      headers: { 'X-Center-Id': centerId },
    }
  )

export const getMulticenterProviderOrdersExcel = strParams =>
  axios.get(`/api/provider-orders/multicenter/export?${strParams}`, {
    responseType: 'arraybuffer',
  })

export const putRepeatOrder = (centerId, orderId, documentTypeCode, documentCompany) =>
  axios.post(
    `/api/provider-orders/${orderId}/${documentTypeCode}/${documentCompany}/repeat`,
    {},
    { headers: { 'X-Center-Id': centerId } }
  )

export const getExistsProviderPendingOrders = centerId =>
  axios.get('/api/provider-orders/exists-pending-to-receive', {
    headers: { 'X-Center-Id': centerId },
  })
