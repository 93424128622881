import { DataGrid } from '@material-ui/data-grid'
import styled from 'styled-components'
import colors from 'Assets/colors'

export const StyledDataGrid = styled(DataGrid)`
  &.grid-root {
    border: none;
    border-top: 1px solid black;
    border-radius: 0;

    .columns-container,
    .viewport,
    .footer {
      background-color: ${colors.grayInput};
    }

    .window {
      overflow-y: ${({ overflow }) => overflow};

      .material-row {
        border: 1px solid #cdcfde;
        margin-bottom: 5px;
        min-height: 52px;
        max-height: 52px;

        &.odd,
        &.even {
          background-color: white;
        }
      }
    }

    .material-col-cell,
    .material-cell {
      cursor: default;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      color: ${colors.black};

      &:focus {
        outline: none;
      }
    }

    .material-col-cell {
      font-size: 14px;
    }

    .columns-container .material-col-cell-wrapper .material-col-cell .column-separator {
      display: none;
    }

    .footer .row-count {
      visibility: hidden;
    }
  }
`
