import { all, call, put, takeLeading, takeEvery, select } from 'redux-saga/effects'
import axios from 'axios'
import i18next from 'i18next'
import * as Sentry from 'Services/sentry'
import { getTutorialsInfoV2, updateTutorial } from 'Services/api/tutorials'
import { TUTORIAL_STATUS, TUTORIAL_NAMES } from 'Config/constants'
import { toast } from 'react-toastify'

import { CHECK_TUTORIAL_BANNER_V2, FINISH_TUTORIAL_V2 } from './types'
import { showRequestTutorialBannerV2, showApproverTutorialBannerV2 } from './actions'

export function* finishTutorialV2(action) {
  const { tutorial } = action.payload
  yield put(showRequestTutorialBannerV2(false))
  try {
    // TODO: Check vale
    const response = yield call(updateTutorial, {
      name: tutorial,
      body: { name: tutorial, done: TUTORIAL_STATUS.done },
    })

    if (response === true) {
      // TODO: Check vale
      if (tutorial === TUTORIAL_NAMES.requestV2) {
        yield put(showRequestTutorialBannerV2(false))

        sessionStorage.removeItem('requestTutorialV2')
      } else if (tutorial === TUTORIAL_NAMES.approverRoleV2) {
        yield put(showApproverTutorialBannerV2(false))
        sessionStorage.removeItem('approverTutorialV2')
      }
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
    }
    if (tutorial === TUTORIAL_NAMES.requestV2) {
      yield put(showRequestTutorialBannerV2(true))
    } else if (tutorial === TUTORIAL_NAMES.approverRoleV2) {
      yield put(showApproverTutorialBannerV2(true))
    }
    sessionStorage.removeItem('requestTutorialV2')
    sessionStorage.removeItem('approverTutorialV2')
    toast.error(i18next.t('tutorialError'))
  }
}

function* checkTutorialV2() {
  try {
    const features = yield select(state => state.auth.config)
    if (!features.tutorial) {
      return
    }
    const tutorialInfo = yield call(getTutorialsInfoV2)

    const requestTutorialDone = tutorialInfo?.find(
      element => element.name === TUTORIAL_NAMES.requestV2 && element.done === TUTORIAL_STATUS.done
    )
    const approverTutorialDone = tutorialInfo?.find(
      element => element.name === TUTORIAL_NAMES.approverRoleV2 && element.done === TUTORIAL_STATUS.done
    )

    if (requestTutorialDone) {
      sessionStorage.removeItem('requestTutorialV2')
    } else {
      sessionStorage.setItem('requestTutorialV2', 'true')
    }

    if (approverTutorialDone) {
      sessionStorage.removeItem('approverTutorialV2')
    } else {
      sessionStorage.setItem('approverTutorialV2', 'true')
    }

    yield put(showRequestTutorialBannerV2(!requestTutorialDone))
    yield put(showApproverTutorialBannerV2(!approverTutorialDone))
  } catch (error) {
    if (!axios.isCancel(error)) {
      Sentry.captureException(error)
    }
    sessionStorage.removeItem('requestTutorialV2')
    sessionStorage.removeItem('approverTutorialV2')
  }
}

function* watchFinishTutorialV2Saga() {
  yield takeLeading(FINISH_TUTORIAL_V2, finishTutorialV2)
}

function* watchCheckTutorialV2Saga() {
  yield takeEvery(CHECK_TUTORIAL_BANNER_V2, checkTutorialV2)
}

export default function* rootTutorialSaga() {
  yield all([watchFinishTutorialV2Saga(), watchCheckTutorialV2Saga()])
}
