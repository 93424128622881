import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { ReactComponent as AddCartIcon } from 'Assets/Icons/AddCart.svg'
import { ReactComponent as NotAvailableIcon } from 'Assets/Icons/SinDisponibilidad.svg'
import { PurchaseActions } from 'Redux/purchases'
import { getProduct } from 'Services/api'
import Trash from 'Assets/Icons/Trash'
import { KEY_CODES, AVAILABILITY_STATES } from 'Config/constants'
import { needsApprovalMode } from 'Redux/approver/selectors'
import { ApproverActions } from 'Redux/approver'
import EraseProductButton from 'Components/atoms/eraseButton'
import AcceptProductButton from 'Components/atoms/acceptButton'
import SelectorButton from 'Components/atoms/selectorButton'
import { selectCenterId, floatModulus } from 'Redux/purchases/utils'
import AmountInput from 'Components/atoms/ammountInput'

import {
  ButtonCart,
  ButtonCartSustitution,
  ReplaceButton,
  ButtonCartRemove,
  RemoveButton,
  ButtonNotAvailable,
  Unavailable,
  EraseButton,
  MainContainer,
} from './styled'

const ProductButton = forwardRef((props, ref) => {
  const {
    name,
    provider,
    price,
    min,
    multiple,
    measureUnit,
    pricePerKg,
    id,
    isSimpleCard,
    rawMaterial,
    notAvailable,
    reference,
    setShowModalConfirmationProduct,
    setOnConfirmProduct,
    showJustButtonCard,
    availability,
    fromModal,
    innerRef,
    onNext,
    onPrevious,
  } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const inputRef = useRef()

  const isLoadingCart = useSelector(({ purchase }) => purchase.isLoadingCart)
  const centerId = useSelector(selectCenterId)
  const needsApproval = useSelector(needsApprovalMode)

  const reducerKey = needsApproval ? 'approver' : 'purchase'
  const { amount = 0 } = useSelector(state => state[reducerKey].cart[id]) || {}
  const inCart = useSelector(state => state[reducerKey].cart[id]) || null

  const [inputValue, setInputValue] = useState(amount || 0)
  const [focused, setFocused] = useState(false)
  const [error, setError] = useState(false)

  const alternativeReference = availability ? availability.alternative_reference : null
  const status = availability ? availability.status : AVAILABILITY_STATES.AVAILABLE

  useImperativeHandle(innerRef, () => ({
    focus() {
      setFocused(true)
    },
    hasInput() {
      return (
        amount !== 0 &&
        status !== AVAILABILITY_STATES.UNAVAILABLE &&
        status !== AVAILABILITY_STATES.NEXT &&
        status !== AVAILABILITY_STATES.NOT_STOCK
      )
    },
    getId() {
      return id
    },
  }))

  useEffect(() => {
    if (amount !== undefined) setInputValue(amount)
  }, [amount])

  useEffect(() => {
    if (focused && inputRef && inputRef.current) {
      inputRef.current.select()
    }
  }, [focused, innerRef])

  const setFocusInput = () => setFocused(true)

  const onChangeInput = e => {
    const text = e.target.value.replace(',', '.')
    if (!text) {
      setInputValue('')
      setError(true)
      return
    }

    const newValue = parseFloat(text) || 0
    setInputValue(newValue)
    const priceModulus = floatModulus(newValue, multiple)
    // eslint-disable-next-line eqeqeq
    if (text && (newValue < min || priceModulus !== 0) && newValue != 0) {
      setError(true)
      return
    }
    setError(false)
  }

  const updateProductAmount = newAmount => {
    const editCart = needsApproval ? ApproverActions.editCart : PurchaseActions.addProductToCart

    dispatch(
      editCart(id, {
        amount: newAmount,
        name,
        total: newAmount * price,
        provider,
        price,
        pricePerKg,
        measureUnit,
        min,
        multiple,
        id,
        raw_material: rawMaterial,
        state: status,
        reference,
      })
    )
  }

  const onConfirmProductErase = () => {
    updateProductAmount(0)
    setShowModalConfirmationProduct(false)
    setFocused(false)
  }

  const setToZero = () => {
    if (isSimpleCard && !notAvailable) {
      setShowModalConfirmationProduct(true)
      setOnConfirmProduct(onConfirmProductErase)
      return
    }
    setFocused(false)

    updateProductAmount(0)
  }

  const onBlurInput = () => {
    const newValue = parseFloat(inputValue) || 0
    const priceModulus = floatModulus(newValue, multiple)

    setFocused(false)
    setError(false)

    if (newValue === 0) {
      setInputValue(amount)
      setToZero()
      return
    }

    // eslint-disable-next-line eqeqeq
    if ((newValue >= min && priceModulus === 0) || !newValue) {
      const parsedInput = Number.isNaN(newValue) ? 0 : newValue
      updateProductAmount(parsedInput)
      return
    }
    setInputValue(amount)
  }

  const add = sum => updateProductAmount(+(amount + sum).toFixed(2))

  const susbtract = sub => updateProductAmount(+(amount - sub).toFixed(2))

  const onClickAdd = e => {
    e.stopPropagation()
    if (!amount) {
      updateProductAmount(min)
    }
  }

  const onClickSubstract = () => {
    if (amount - multiple <= 0) {
      return setToZero()
    }

    return susbtract(multiple)
  }

  const handleKeydown = e => {
    if (e.keyCode === KEY_CODES.UP_ARROW) {
      e.preventDefault()
      add(multiple)
    } else if (e.keyCode === KEY_CODES.DOWN_ARROW) {
      e.preventDefault()
      onClickSubstract()
    } else if (e.keyCode === KEY_CODES.TAB && e.shiftKey) {
      e.preventDefault()
      inputRef.current.blur()
      setFocused(false)
      onPrevious(id)
    } else if (e.keyCode === KEY_CODES.TAB || e.keyCode === KEY_CODES.ENTER) {
      e.preventDefault()
      inputRef.current.blur()
      setFocused(false)
      onNext(id)
    }
  }

  const renderContent = (buttonCopy, flag, icon) => (
    <>
      {icon === 'unavailable' ? (
        <NotAvailableIcon style={{ marginRight: '0.5rem' }} />
      ) : (
        <AddCartIcon style={{ marginRight: '0.5rem' }} />
      )}
      <span>{buttonCopy}</span>
      {flag ? <span className='flag'>{flag}</span> : null}
    </>
  )

  const renderSelectors = () => (
    <>
      <AmountInput
        onClick={setFocusInput}
        focused={focused}
        onBlur={onBlurInput}
        inputRef={inputRef}
        value={inputValue}
        onChange={onChangeInput}
        onKeyDown={handleKeydown}
        suffix={measureUnit}
      />
      {focused ? (
        <AcceptProductButton onClick={() => setFocused(false)} />
      ) : (
        <div style={{ display: 'flex' }}>
          <SelectorButton onClick={onClickSubstract} icon='down' />
          <SelectorButton onClick={() => add(multiple)} icon='up' />
        </div>
      )}
    </>
  )

  const onReplace = async e => {
    e.stopPropagation()
    if (alternativeReference) {
      try {
        const response = await getProduct(centerId, alternativeReference)
        dispatch(
          PurchaseActions.setProduct({
            ...response,
            measureUnit: response.measure_unit,
            previousPrice: response.previous_price,
            pricePerKg: response.price_per_kg,
            rawMaterial: response.raw_material,
          })
        )
        dispatch(PurchaseActions.setModalProductOpen(true))
      } catch (error2) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    }
  }

  const hasReplace = (alternativeReference && alternativeReference !== '') || false
  const willPurchase = inCart != null

  const onRemove = e => {
    e.stopPropagation()
    return setToZero()
  }

  const showUnavailableOptions = (unavailableCopy, reverse) => {
    if (isSimpleCard) {
      return (
        <ButtonCartRemove reverse={reverse} onClick={onRemove}>
          <span>{unavailableCopy}</span>
          <RemoveButton reverse={reverse}>{t('delete')}</RemoveButton>
        </ButtonCartRemove>
      )
    }

    if (hasReplace && !fromModal) {
      return (
        <ButtonCartSustitution reverse={reverse}>
          <span>{unavailableCopy}</span>
          <ReplaceButton onClick={onReplace} reverse={reverse}>
            {t('substitute')}
          </ReplaceButton>
        </ButtonCartSustitution>
      )
    }
    return (
      <ButtonCart reverse={reverse} focused={focused}>
        <span>{unavailableCopy}</span>
      </ButtonCart>
    )
  }

  const showSimpleCartButton = () => (
    <ButtonCart onClick={onClickAdd} hasAmount={!!amount} error={error} focused={focused}>
      {!amount ? renderContent() : renderSelectors()}
      {amount ? <EraseProductButton onClick={onRemove} /> : null}
    </ButtonCart>
  )

  const showSelector = e => {
    e.stopPropagation()
    onClickAdd(e)
  }

  const showLastUnitsOptions = (unavailableCopy, reverse) => {
    if (isSimpleCard) {
      return (
        <ButtonCartRemove reverse={reverse} onClick={onRemove}>
          <span>{unavailableCopy}</span>
          <RemoveButton reverse={reverse}>{t('delete')}</RemoveButton>
        </ButtonCartRemove>
      )
    }

    if (willPurchase && amount > 0) {
      return (
        <ButtonCart onClick={onClickAdd} hasAmount={!!amount} error={error} focused={focused}>
          {!amount ? renderContent(t('lastItems')) : renderSelectors()}
          {amount ? <EraseProductButton onClick={onRemove} /> : null}
        </ButtonCart>
      )
    }
    if (hasReplace && !fromModal) {
      return (
        <ButtonCartSustitution reverse={reverse} onClick={showSelector}>
          <span>{unavailableCopy}</span>
          <ReplaceButton onClick={onReplace} reverse={reverse}>
            {t('substitute')}
          </ReplaceButton>
        </ButtonCartSustitution>
      )
    }

    return (
      <ButtonCart onClick={onClickAdd} hasAmount={!!amount} error={error} focused={focused}>
        {!amount ? renderContent(t('lastItems')) : renderSelectors()}
        {amount ? <EraseProductButton onClick={onRemove} /> : null}
      </ButtonCart>
    )
  }

  const showButton = () => {
    if (availability) {
      if (status === AVAILABILITY_STATES.AVAILABLE) {
        if (isSimpleCard) {
          return showSimpleCartButton()
        }
        return (
          <ButtonCart onClick={onClickAdd} hasAmount={!!amount} error={error} focused={focused}>
            {!amount ? renderContent(t('addCart')) : renderSelectors()}
            {amount ? <EraseProductButton onClick={onRemove} /> : null}
          </ButtonCart>
        )
      }

      if (status === AVAILABILITY_STATES.UNAVAILABLE) {
        //not available any more
        if (willPurchase || hasReplace) {
          return showUnavailableOptions(t('deleted'))
        }
        return <ButtonCart className='disabled'>{renderContent(t('deleted'), '', 'unavailable')}</ButtonCart>
      }

      if (status === AVAILABILITY_STATES.NEXT) {
        if (willPurchase || hasReplace) {
          return showUnavailableOptions(t('nextAvailability'))
        }

        return <ButtonCart className='disabled'>{renderContent(t('nextAvailability'), '', 'unavailable')}</ButtonCart>
      }

      if (status === AVAILABILITY_STATES.NOT_STOCK) {
        if (!hasReplace && willPurchase && amount > 0) {
          return (
            <ButtonNotAvailable>
              <Unavailable>
                {availability && availability.status === AVAILABILITY_STATES.NOT_STOCK && t('noItems')}
                {availability && availability.status === AVAILABILITY_STATES.UNAVAILABLE && t('deleted')}
              </Unavailable>
              <EraseButton onClick={setToZero}>
                <Trash className='trash' width={22} fill='#fff' /> {t('delete')}
              </EraseButton>
            </ButtonNotAvailable>
          )
        }
        if (willPurchase || hasReplace) {
          return showUnavailableOptions(t('noItems'))
        }

        return <ButtonCart className='disabled'>{renderContent(t('noItems'), '', 'unavailable')}</ButtonCart>
      }

      if (status === AVAILABILITY_STATES.NEW) {
        if (isSimpleCard) {
          return showSimpleCartButton()
        }
        return (
          <ButtonCart onClick={onClickAdd} hasAmount={!!amount} error={error} focused={focused}>
            {!amount ? renderContent(t('addCart'), t('new')) : renderSelectors()}
            {amount ? <EraseProductButton onClick={onRemove} /> : null}
          </ButtonCart>
        )
      }

      if (status === AVAILABILITY_STATES.LAST_UNITS) {
        if (isSimpleCard) {
          return showSimpleCartButton()
        }
        if (willPurchase || hasReplace) {
          return showLastUnitsOptions(t('lastItems'), 'reverse')
        }

        return (
          <ButtonCart onClick={onClickAdd} hasAmount={!!amount} error={error} focused={focused}>
            {!amount ? renderContent(t('lastItems')) : renderSelectors()}
            {amount ? <EraseProductButton onClick={onRemove} /> : null}
          </ButtonCart>
        )
      }
    }
    if (isSimpleCard) {
      return showSimpleCartButton()
    }
    return (
      <ButtonCart onClick={onClickAdd} hasAmount={!!amount} error={error} focused={focused} ref={ref}>
        {!amount ? renderContent(t('addCart')) : renderSelectors()}
        {amount ? <EraseProductButton onClick={onRemove} /> : null}
      </ButtonCart>
    )
  }

  if (showJustButtonCard) {
    // TODO: No tiene return, se puede quitar?
    showButton()
  }

  return <MainContainer disabled={isLoadingCart}>{showButton()}</MainContainer>
})

ProductButton.defaultProps = {
  name: '',
  provider: {},
  price: 0,
  min: 0,
  multiple: 0,
  measureUnit: '',
  pricePerKg: 0,
  id: '',
  isSimpleCard: false,
  rawMaterial: 'food',
  notAvailable: false,
  reference: '',
  setOnConfirmProduct: () => null,
  setShowModalConfirmationProduct: () => null,
  showJustButtonCard: false,
  availability: null,
  fromModal: false,
  innerRef: null,
  onNext: () => null,
  onPrevious: () => null,
}

ProductButton.propTypes = {
  name: PropTypes.string,
  provider: PropTypes.object,
  price: PropTypes.number,
  min: PropTypes.number,
  multiple: PropTypes.number,
  measureUnit: PropTypes.string,
  pricePerKg: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSimpleCard: PropTypes.bool,
  rawMaterial: PropTypes.string,
  notAvailable: PropTypes.bool,
  reference: PropTypes.string,
  setOnConfirmProduct: PropTypes.func,
  setShowModalConfirmationProduct: PropTypes.func,
  showJustButtonCard: PropTypes.bool,
  availability: PropTypes.object,
  fromModal: PropTypes.bool,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
}

export default ProductButton
