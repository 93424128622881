import styled from 'styled-components'
import colors from 'Assets/colors'

export const FilterButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  cursor: pointer;

  align-items: center;
  justify-content: space-between;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
`

export const MainText = styled.div`
  margin-right: 1rem;
`

export const IconContainer = styled.div`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0px;
    right: -3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: solid 2px ${colors.white};
    background-color: ${colors.secondary};
    display: ${({ showDot }) => (showDot ? 'block' : 'none')};
  }
`
