import axios from 'axios'

export const ORDER_PRODUCT_URL = '/api/order/product'
export const ORDER_URL = '/api/order'

export const putDeliveryDate = (centerId, payload) =>
  axios.put('/api/order/delivery', payload, { headers: { 'X-Center-Id': centerId } })

export const confirmOrder = (centerId, payload) =>
  axios.post('/api/v2/order/confirm', payload, { headers: { 'X-Center-Id': centerId } })

export const confirmOrderSQS = (centerId, payload) =>
  axios.post('/api/v3/order/confirm', payload, { headers: { 'X-Center-Id': centerId } })

export const approveOrder = (centerId, payload) =>
  axios.post('/api/v3/order/approve', payload, { headers: { 'X-Center-Id': centerId } })

export const putOriginCenter = (centerId, payload) =>
  axios.put('/api/order/origin-center', payload, { headers: { 'X-Center-Id': centerId } })

export const deleteProviderProducts = (centerId, providerId) =>
  axios.delete(`/api/order/provider-products/${providerId}`, { headers: { 'X-Center-Id': centerId } })

export const updateCart = (centerId, products) =>
  axios.put(ORDER_PRODUCT_URL, { products }, { headers: { 'X-Center-Id': centerId } })

export const addProductToOrder = (centerId, productId, payload) =>
  axios.put(`${ORDER_PRODUCT_URL}/${productId}`, payload, { headers: { 'X-Center-Id': centerId } })

export const getCart = centerId => axios.get(ORDER_URL, { headers: { 'X-Center-Id': centerId } })

export const postPreConfirmCart = centerId =>
  axios.post('api/order/preconfirm', {}, { headers: { 'X-Center-Id': centerId } })

export const deleteCart = centerId => axios.delete(ORDER_URL, { headers: { 'X-Center-Id': centerId } })

export const addRequestOrderErrorId = (centerId, requestOrderId) =>
  axios.put(`/api/order/error-related-id/${requestOrderId}`, {}, { headers: { 'X-Center-Id': centerId } })

export const repeatRequestOrder = (centerId, requestOrderId) =>
  axios.post(`/api/order/${requestOrderId}/repeat`, {}, { headers: { 'X-Center-Id': centerId } })
