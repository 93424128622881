import React, { useEffect, useState } from 'react'

import Hotjar from '@hotjar/browser'
import { Switch, Route } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import routes from 'Config/routes'
import { ModalProduct } from 'Components/molecules'
import { NavBar } from 'Components/organisms'
import {
  Home,
  Purchases,
  Users,
  Checkout,
  CheckoutConfirmed,
  Historic,
  DeliveryQa,
  DeliveryConfirmed,
  Delivery,
  TicketingCreate,
  TicketingConfirmed,
  TicketingList,
  TicketingDetail,
  PreviusOrder,
  ApproverCarts,
  CheckoutApprovedConfirmed,
  Carts,
  ApproverSuccessUpdateOrder,
  ApproverCheckout,
  MulticenterOrders,
  MulticenterRepeatOrder,
  MulticenterTicketing,
  MulticenterTicketingDetail,
} from 'Components/pages'
import { configureToken } from 'Services/api'
import * as Sentry from 'Services/sentry'
import { AuthTypes } from 'Redux/auth'
import isIEBrowser from 'Components/utils/check-ie11'
import IEBrowserAlert from 'Components/molecules/ieBrowserAlert'
import Maintenance from 'Components/pages/maintenance'
import { GOOGLE_GTM_ID } from 'Config/constants'
import TagManager from 'react-gtm-module'
import { fetchFeatureFlagConfig } from 'Redux/auth/actions'
import analyticsService from 'Services/analytics'
import isEmpty from 'lodash/isEmpty'
import { selectConfig } from 'Redux/auth/utils'

import config from '../../../../package.json'

import { DashboardRoute, MulticenterRoute, SelectedCenterRoute, ApproverRoute } from './customRoutes'

const tagManagerArgs = {
  gtmId: GOOGLE_GTM_ID,
}

const Routes = () => {
  const dispatch = useDispatch()
  const [tokenReady, setTokenReady] = useState(false)
  const [analyticsReady, setAnalyticsReady] = useState(false)
  const isReady = useSelector(({ auth }) => auth.isReady)
  const token = useSelector(({ auth }) => auth.token)
  const refreshToken = useSelector(({ auth }) => auth.refreshToken)
  const username = useSelector(({ auth }) => auth.user.username)
  const user = useSelector(({ auth }) => auth.user)
  const center = useSelector(state => state.auth.selectedCenter)
  const features = useSelector(selectConfig)

  useEffect(() => {
    dispatch(fetchFeatureFlagConfig())
  }, [dispatch])

  useEffect(() => {
    if (user) {
      Sentry.setUser(user)
      Hotjar.identify(user.username, {
        name: user.name,
        email: user.email,
        center,
        version: config?.version,
      })
    }

    if (center) {
      Sentry.setContext('center', center)
    }

    Sentry.setContext('version', config.version)
  }, [user, center])

  useEffect(() => {
    if (features?.analytics && !analyticsReady) {
      TagManager.initialize(tagManagerArgs)
      setAnalyticsReady(true)
      localStorage.setItem('analytics', true)
    }
    if (!features?.analytics && !analyticsReady) {
      localStorage.setItem('analytics', false)
    }
  }, [features, analyticsReady])

  useEffect(() => {
    if (analyticsReady && user) {
      analyticsService.setUserProperties(user)
    }

    if (analyticsReady && !isEmpty(center)) {
      analyticsService.setCenter(center)
    }
  }, [analyticsReady, user, center])

  useEffect(() => {
    const configure = async () => {
      configureToken(token, refreshToken, username)
      dispatch({ type: AuthTypes.INITIAL_CENTER_REQUEST })
      setTokenReady(true)
    }
    if (token) configure()
  }, [token, refreshToken, username, dispatch])

  if (!isReady) return null

  if (isIEBrowser()) {
    return <IEBrowserAlert />
  }

  if (features?.show_maintenance) {
    return <Maintenance />
  }

  return (
    <>
      <ModalProduct />
      {!token ? null : <NavBar />}
      <Switch>
        <Route path={routes.root} component={Users} exact />
        <DashboardRoute path={routes.home}>
          <Home />
        </DashboardRoute>
        {tokenReady && (
          <>
            <SelectedCenterRoute path={routes.historic}>
              <Historic />
            </SelectedCenterRoute>
            <SelectedCenterRoute path={routes.purchase}>
              <Purchases />
            </SelectedCenterRoute>
            <SelectedCenterRoute path={routes.checkout}>
              <Checkout />
            </SelectedCenterRoute>
            <SelectedCenterRoute path={routes.checkoutConfirmed}>
              <CheckoutConfirmed />
            </SelectedCenterRoute>
            <SelectedCenterRoute path={routes.checkoutApprovedConfirmed}>
              <CheckoutApprovedConfirmed />
            </SelectedCenterRoute>
            <SelectedCenterRoute exact path={routes.delivery}>
              <Delivery />
            </SelectedCenterRoute>
            <SelectedCenterRoute path={routes.deliveryConfirmed}>
              <DeliveryConfirmed />
            </SelectedCenterRoute>
            <SelectedCenterRoute path={routes.deliveryQa}>
              <DeliveryQa />
            </SelectedCenterRoute>
            <SelectedCenterRoute exact path={routes.ticketingList}>
              <TicketingList />
            </SelectedCenterRoute>
            <SelectedCenterRoute exact path={routes.ticketingDetail}>
              <TicketingDetail />
            </SelectedCenterRoute>
            <SelectedCenterRoute exact path={routes.ticketingCreate}>
              <TicketingCreate />
            </SelectedCenterRoute>
            <SelectedCenterRoute exact path={routes.ticketingConfirmed}>
              <TicketingConfirmed />
            </SelectedCenterRoute>
            <SelectedCenterRoute path={routes.previusOrder}>
              <PreviusOrder />
            </SelectedCenterRoute>
            <SelectedCenterRoute path={routes.carts}>
              <Carts />
            </SelectedCenterRoute>

            <ApproverRoute exact path={routes.approverCarts}>
              <ApproverCarts />
            </ApproverRoute>
            <ApproverRoute exact path={routes.approverSuccessUpdatedOrder}>
              <ApproverSuccessUpdateOrder />
            </ApproverRoute>
            <ApproverRoute exact path={routes.approvedCheckout}>
              <ApproverCheckout />
            </ApproverRoute>
            {features?.multicenter && (
              <>
                <MulticenterRoute exact path={routes.multicenterOrders}>
                  <MulticenterOrders />
                </MulticenterRoute>
                <MulticenterRoute exact path={routes.multicenterRepeatOrder}>
                  <MulticenterRepeatOrder />
                </MulticenterRoute>
                <MulticenterRoute exact path={routes.multicenterTicketing}>
                  <MulticenterTicketing />
                </MulticenterRoute>
                <MulticenterRoute exact path={routes.multicenterTicketingDetail}>
                  <MulticenterTicketingDetail />
                </MulticenterRoute>
              </>
            )}
          </>
        )}
        {!token && <Route path='*' component={Users} exact />}
      </Switch>
    </>
  )
}
export default Routes
