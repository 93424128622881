import React from 'react'

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import map from 'lodash/map'
import { BigTitle } from 'Components/atoms'

import { ShoppingListContainer, NoResultsBox } from '../styled'

import Item from './item'

const List = ({ onClickRemove, onClickSee, onClickRemoveProduct }) => {
  const { t } = useTranslation()
  const lists = useSelector(s => s.lists.lists)

  if (!lists || lists.length === 0) {
    return (
      <NoResultsBox>
        <BigTitle textAlign='center'>{t('shoppingList.lists.noResults')}</BigTitle>
      </NoResultsBox>
    )
  }

  return (
    <ShoppingListContainer>
      {map(lists, item => (
        <Item
          key={item.id}
          item={item}
          onClickRemove={onClickRemove}
          onClickSee={onClickSee}
          onClickRemoveProduct={onClickRemoveProduct}
        />
      ))}
    </ShoppingListContainer>
  )
}

List.defaultProps = {
  onClickRemove: () => null,
  onClickSee: () => null,
  onClickRemoveProduct: () => null,
}

List.propTypes = {
  onClickRemove: PropTypes.func,
  onClickSee: PropTypes.func,
  onClickRemoveProduct: PropTypes.func,
}

export default List
