import {
  SET_CENTERS_LIST,
  SET_CENTER_ORDERS,
  SET_FILTER_CENTERS,
  SET_FILTER_ORIGIN_CENTERS,
  SET_FILTER_PROVIDERS,
  SET_IS_CREATE_QA,
  SET_IS_LOADING_CENTERS,
  SET_IS_LOADING_NO_COMPLIANCES,
  SET_IS_LOADING_ORDERS,
  SET_IS_REPEATING_ORDER,
  SET_NO_COMPLIANCES,
  SET_NO_COMPLIANCES_FILTERS,
  SET_ORDERS_FILTERS,
  SET_REQUEST_ORDERS,
  SET_SELECTED_CENTER,
  SET_STATS,
  SET_QA_FILTERS,
} from './types'

const initialState = {
  centersList: { data: [], count: 0 },
  centersOrders: {},
  isCreateQa: false,
  isLoadingCenters: false,
  isLoadingOrders: {},
  isLoadingNoCompliances: false,
  isRepeatingOrder: false,
  filterCenters: [],
  filterOriginCenters: [],
  filterProviders: [],
  ordersFilters: {},
  noCompliances: { count: 0, data: [] },
  noCompliancesFilters: {},
  requestOrdersByCenter: {},
  selectedCenter: null,
  stats: { centers: 0, ordersToReceive: 0, complaints: 0 },
  qaFilters: {},
}

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CENTERS_LIST:
      return { ...state, centersList: action.payload }
    case SET_CENTER_ORDERS:
      return {
        ...state,
        centersOrders: {
          ...state.centersOrders,
          [action.payload.centerId]: action.payload.orders,
        },
      }
    case SET_FILTER_CENTERS:
      return { ...state, filterCenters: action.payload }
    case SET_FILTER_ORIGIN_CENTERS:
      return { ...state, filterOriginCenters: action.payload }
    case SET_FILTER_PROVIDERS:
      return { ...state, filterProviders: action.payload }
    case SET_IS_CREATE_QA:
      return { ...state, isCreateQa: action.payload }
    case SET_IS_LOADING_CENTERS:
      return { ...state, isLoadingCenters: action.payload }
    case SET_IS_LOADING_NO_COMPLIANCES:
      return { ...state, isLoadingNoCompliances: action.payload }
    case SET_IS_LOADING_ORDERS:
      return {
        ...state,
        isLoadingOrders: {
          ...state.isLoadingOrders,
          [action.payload.centerId]: action.payload.isLoading,
        },
      }
    case SET_IS_REPEATING_ORDER:
      return { ...state, isRepeatingOrder: action.payload }
    case SET_NO_COMPLIANCES:
      return { ...state, noCompliances: action.payload }
    case SET_NO_COMPLIANCES_FILTERS:
      return { ...state, noCompliancesFilters: action.payload }
    case SET_ORDERS_FILTERS:
      return { ...state, ordersFilters: action.payload }
    case SET_QA_FILTERS:
      return { ...state, qaFilters: action.payload }

    case SET_REQUEST_ORDERS:
      return {
        ...state,
        requestOrdersByCenter: {
          ...state.requestOrdersByCenter,
          [action.payload.centerId]: action.payload.requestOrders,
        },
      }
    case SET_SELECTED_CENTER:
      return { ...state, selectedCenter: action.payload }
    case SET_STATS:
      return { ...state, stats: action.payload }
    default:
      return state
  }
}

export default Reducer
