import styled, { css } from 'styled-components'
import colors from 'Assets/colors'

export const CartRow = styled.div`
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  margin: 12px 0;
  background: ${colors.white};
  margin-bottom: 15px;
`

export const CartBody = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  overflow: hidden;
`

export const Request = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-right: 35px;
  position: relative;
`

export const Tag = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.black};
  background: ${colors.cart};
  border-radius: 8px;
  text-align: center;
  padding: 0 8px;
`

export const Price = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  display: block;
  text-align: right;
  margin-right: 51px;
`

export const Products = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
`

export const CartHeader = styled.div`
  background: ${colors.gray3};
  color: ${colors.black};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;

  ${props =>
    props.status === 'pending' &&
    css`
      color: ${colors.white};
      background: ${colors.orange};
    `}

  ${props =>
    props.status === 'approved' &&
    css`
      color: ${colors.white};
      background: ${colors.black};
    `}
`

export const CartHeaderInfo = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`

export const CartHeaderRight = styled.div`
  display: flex;
`

export const CartHeaderSeparator = styled.div`
  width: 47px;
`

export const CartHeaderDate = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  ${props =>
    props.margin &&
    css`
      margin-right: 47px;
    `}
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const CartBodyDivider = styled.div`
  display: flex;
  flex: 1;
`

export const ActionButton = styled.div`
  width: 100%;
  background-color: ${colors.secondary};
  padding: 1rem;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  pointer-events: ${props => (props.submitLoading ? 'none' : 'all')};
  padding: 15px 75px;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const ButtonLiteral = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`

export const CancelRequestButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 53px;
  cursor: pointer;

  span {
    color: ${colors.red};
    text-decoration: underline;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-right: 4px;
  }
`

export const SeeProducts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    color: ${colors.black};
    line-height: 24px;
    margin-left: 15px;
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`

export const User = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-left: 56px;
`
