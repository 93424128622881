import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as RemoveRed } from 'Assets/Icons/RemoveRed.svg'
import { ModalConfirmation } from 'Components/molecules'

const RemoveListModal = ({ showModal, setShowModal, onAccept }) => {
  const { t } = useTranslation()

  return (
    <ModalConfirmation
      showModal={showModal}
      setShowModal={() => setShowModal(false)}
      onCancel={() => setShowModal(false)}
      onAccept={onAccept}
      color='red'
      title={t('shoppingList.remove.title')}
      action={t('shoppingList.remove.confirm')}
      cancelAction={t('shoppingList.remove.cancel')}
      icon={<RemoveRed />}
    />
  )
}

RemoveListModal.defaultProps = {
  showModal: false,
  setShowModal: () => null,
  onAccept: () => null,
}

RemoveListModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onAccept: PropTypes.func,
}

export default RemoveListModal
