import size from 'lodash/size'

export const selectFilterCenter = state => state.approver.filterCenter

export const needsApprovalMode = state => !!state.approver.requestOrder

export const numberOfPendingRequests = state =>
  size(state.approver.pendingApproval) + size(state.approver.closeToAutoApproved)

export const summaryCenterId = state => state.approver.summaryCenter?.id
export const summaryCenter = state => state.approver.summaryCenter
