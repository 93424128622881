import { FEATURE_FLAG_DEFAULT_CONFIG } from 'Config/constants'

import {
  SET_TOKEN,
  SET_ERROR_TOKEN,
  SET_CENTERS_LIST,
  SET_CENTER_COUNT,
  SET_SELECTED_CENTER,
  SET_USER,
  LOGOUT,
  SET_HAS_ONLY_ONE_CENTER,
  HIDE_NAVBAR,
  SET_REFRESH_TOKEN,
  SET_ERROR_LOGIN,
  IS_LOADING_CENTER,
  SET_IS_READY,
  SET_REFRESHING_CATEGORY,
  IS_LOADING_LOGIN,
  SET_IS_LOADING_MSAL,
  SET_FEATURE_FLAG_CONFIG,
  IS_INITIAL_FECTH_CENTERS,
} from './types'

const initialState = {
  isReady: false,
  isLoadingLogin: false,
  token: '',
  errorToken: '',
  centersList: [],
  centerCount: 0,
  selectedCenter: {},
  user: {},
  hasOnlyOneCenter: false,
  hiddenNavbar: false,
  refreshToken: '',
  errorLogin: null,
  isLoadingCenter: false,
  isRefreshingCategory: false,
  isLoadingMsal: false,
  config: FEATURE_FLAG_DEFAULT_CONFIG,
  isFeatureFlagConfig: false,
  isInitialFechtCenters: false,
}

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return { ...state, token: action.payload }
    case SET_ERROR_TOKEN:
      return { ...state, errorToken: action.payload }
    case SET_ERROR_LOGIN:
      return { ...state, errorLogin: action.payload }
    case SET_CENTERS_LIST:
      return { ...state, centersList: action.payload }
    case SET_CENTER_COUNT:
      return { ...state, centerCount: action.payload }
    case SET_SELECTED_CENTER:
      return { ...state, selectedCenter: action.payload }
    case SET_USER:
      return { ...state, user: action.payload }
    case SET_HAS_ONLY_ONE_CENTER:
      return { ...state, hasOnlyOneCenter: action.payload }
    case SET_REFRESH_TOKEN:
      return { ...state, refreshToken: action.payload }
    case HIDE_NAVBAR:
      return { ...state, hiddenNavbar: action.payload }
    case IS_LOADING_CENTER:
      return { ...state, isLoadingCenter: action.payload }
    case IS_LOADING_LOGIN:
      return { ...state, isLoadingLogin: action.payload }
    case SET_IS_READY:
      return { ...state, isReady: action.payload }
    case SET_REFRESHING_CATEGORY:
      return { ...state, isRefreshingCategory: action.payload }
    case SET_IS_LOADING_MSAL:
      return { ...state, isLoadingMsal: action.payload }
    case SET_FEATURE_FLAG_CONFIG:
      return { ...state, config: { ...state.config, ...action.payload }, isFeatureFlagConfig: true }

    case IS_INITIAL_FECTH_CENTERS:
      return { ...state, isInitialFechtCenters: action.payload }

    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export default Reducer
