import React from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import { setListData, setListProducts } from 'Redux/lists/actions'
import { ReactComponent as ArrowLeftIcon } from 'Assets/Icons/ArrowLeft.svg'

import { useStyles } from '../styled'

const BackToListsButton = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleClickBack = () => {
    dispatch(setListData(null))
    dispatch(setListProducts(null))
  }

  return (
    <Button startIcon={<ArrowLeftIcon />} onClick={handleClickBack} className={classes.menuButton} color='inherit'>
      {t('shoppingList.single.back')}
    </Button>
  )
}

export default BackToListsButton
