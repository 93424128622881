import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { fetchRejected } from 'Redux/approver/actions'

const useApproverCartsRejected = () => {
  const dispatch = useDispatch()

  const data = useSelector(state => state.approver.rejected)
  const isFetching = useSelector(state => state.approver.isFetchingRejected)
  const filterCenter = useSelector(state => state.approver.filterCenter)
  const filterProviders = useSelector(state => state.approver.filterProviders)
  const filterRequestNumber = useSelector(state => state.approver.filterRequestNumber)

  const isFilterActive = !!filterCenter || !!filterProviders?.length || !!filterRequestNumber
  useEffect(() => {
    dispatch(fetchRejected())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCenter, filterProviders, filterRequestNumber])

  return { isFetching, data, isFilterActive }
}

export default useApproverCartsRejected
