import React from 'react'

import PropTypes from 'prop-types'
import { ReactComponent as CloseX2 } from 'Assets/Icons/CloseX2.svg'

import { Input, Label, ErrorContainer, Container, MinimalInput } from './styled'

const TextInput = ({
  placeholder,
  placeholderColor,
  topIcon,
  rightIcon,
  value,
  onChange,
  Icon,
  width,
  respWidth,
  height,
  refProp,
  borderRadius,
  border,
  onKeyDown,
  margin,
  onFocus,
  onBlur,
  padding,
  background,
  name,
  error,
  position,
  bottom,
  left,
  borderTop,
  borderBottom,
  shadow,
  type,
  disabled,
  minimal,
  minimalShadow,
  maxLength,
  closeIcon,
  color,
}) => (
  <Container margin={margin} width={width} respWidth={respWidth} position={position} bottom={bottom} left={left}>
    {!minimal ? (
      <Label background={background} borderRadius={borderRadius} topIcon={topIcon} rightIcon={rightIcon}>
        <Input
          onKeyDown={onKeyDown}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          placeholderColor={placeholderColor}
          ref={refProp}
          borderRadius={borderRadius}
          border={border}
          borderTop={borderTop}
          onFocus={onFocus}
          onBlur={onBlur}
          padding={padding}
          height={height}
          name={name}
          error={error}
          shadow={shadow}
          type={type}
          disabled={disabled}
          maxLength={maxLength}
          color={color}
        />
        <Icon className='icon-input' />
      </Label>
    ) : (
      <Label
        background={background}
        borderRadius={borderRadius}
        topIcon={topIcon}
        rightIcon={rightIcon}
        borderBottom={borderBottom}
        minimalShadow={minimalShadow}
      >
        <MinimalInput
          onKeyDown={onKeyDown}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          placeholderColor={placeholderColor}
          ref={refProp}
          onFocus={onFocus}
          onBlur={onBlur}
          padding={padding}
          height={height}
          name={name}
          error={error}
          type={type}
          disabled={disabled}
        />
        {value && closeIcon && <CloseX2 className='close-input-icon' onClick={() => onChange(null)} />}
        <Icon className='icon-input' />
      </Label>
    )}
    {error && <ErrorContainer>{error}</ErrorContainer>}
  </Container>
)

TextInput.propTypes = {
  placeholder: PropTypes.string,
  placeholderColor: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  width: PropTypes.string,
  respWidth: PropTypes.string,
  refProp: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  borderRadius: PropTypes.string,
  border: PropTypes.string,
  borderTop: PropTypes.string,
  borderBottom: PropTypes.string,
  onKeyDown: PropTypes.func,
  topIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  margin: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  padding: PropTypes.string,
  background: PropTypes.string,
  height: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  position: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  shadow: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  minimal: PropTypes.bool,
  minimalShadow: PropTypes.bool,
  maxLength: PropTypes.number,
  color: PropTypes.string,
  closeIcon: PropTypes.bool,
}

TextInput.defaultProps = {
  placeholder: '',
  placeholderColor: '',
  value: '',
  onChange: () => {},
  Icon: () => <></>,
  width: '100%',
  respWidth: '',
  refProp: () => {},
  borderRadius: '8px',
  border: '',
  borderTop: '',
  borderBottom: '',
  onKeyDown: () => {},
  rightIcon: '1rem',
  topIcon: '1.5rem',
  margin: '',
  onFocus: () => {},
  onBlur: () => {},
  padding: '1rem',
  background: '',
  height: '',
  name: '',
  error: '',
  position: '',
  bottom: '',
  left: '',
  shadow: '',
  type: '',
  disabled: false,
  minimal: false,
  minimalShadow: false,
  maxLength: null,
  color: '',
  closeIcon: false,
}

export default TextInput
