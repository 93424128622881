import { useCallback, useEffect, useState } from 'react'

import {
  MAINTENANCE_APP_KEY_CHECK,
  MAINTENANCE_TIME_TO_RELOAD_CHECK,
  MAINTENANCE_MOCKED_RESPONSE,
} from 'Config/constants'
import { getMaintenanceModeData } from 'Services/api/maintenance'

export const useMaintenanceStatus = () => {
  const [active, setActive] = useState(false)
  const [message, setMessage] = useState('')
  const maintenanceDefined = !!process.env.REACT_APP_BASE_URL

  const fetchMaintenanceStatus = useCallback(async () => {
    try {
      const response = maintenanceDefined ? await getMaintenanceModeData() : MAINTENANCE_MOCKED_RESPONSE

      if (!response) {
        throw new Error('Network response was not ok')
      }

      const environmentMaintenance = await response?.find(
        item => item.app === MAINTENANCE_APP_KEY_CHECK && item.active === true
      )

      setActive(!!environmentMaintenance)
      setMessage(environmentMaintenance?.message || '')
    } catch (error) {}
  }, [maintenanceDefined])

  useEffect(() => {
    fetchMaintenanceStatus()

    const intervalId = setInterval(fetchMaintenanceStatus, MAINTENANCE_TIME_TO_RELOAD_CHECK)

    return () => clearInterval(intervalId)
  }, [fetchMaintenanceStatus])

  return { active, message }
}
