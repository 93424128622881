import styled from 'styled-components'
import colors from 'Assets/colors'

export const ButtonsContainer = styled.div`
  a button {
    svg path {
      fill: ${colors.black};
    }

    &.active {
      font-weight: 700;
    }
  }
`

export const Separator = styled.div`
  display: inline;
  border-right: 1px solid ${colors.black};
  opacity: 0.24;
  width: 1px;
  height: 100%;
  margin: 0 40px 0 32px;
`
