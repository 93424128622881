import styled from 'styled-components'
import colors from 'Assets/colors'

export const Text = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.5rem;
  line-height: 24px;
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  text-align: ${props => props.textAlign || null};
  color: ${colors.red};
`
