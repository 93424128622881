import React from 'react'

import PropTypes from 'prop-types'

const SVG = ({ width, height, fill, className, style }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 10 5'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={style}
  >
    <path d='M0 0L5 5L10 0H0Z' fill={fill} />
  </svg>
)

SVG.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}

SVG.defaultProps = {
  width: 10,
  height: 5,
  fill: '#333333',
  className: '',
  style: {},
}

export default SVG
