import { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import size from 'lodash/size'
import { floatModulus } from 'Redux/purchases/utils'
import { AVAILABILITY_STATES, KEY_CODES } from 'Config/constants'
import { updateListProduct } from 'Redux/lists/actions'
import { ApproverActions } from 'Redux/approver'
import { needsApprovalMode } from 'Redux/approver/selectors'
import { PurchaseActions } from 'Redux/purchases'

const useShoppingListsProductButton = ({ product, onClickRemove, onClickRemoveProduct }) => {
  const inputRef = useRef()
  const dispatch = useDispatch()

  const products = useSelector(s => s.lists.products)
  const needsApproval = useSelector(needsApprovalMode)

  const {
    amount,
    multiple,
    min,
    availability,
    id,
    provider,
    price,
    name,
    pricePerKg,
    measureUnit,
    reference,
    // eslint-disable-next-line camelcase
    raw_material,
  } = product

  const status = availability ? availability.status : AVAILABILITY_STATES.AVAILABLE
  const notAvailable = status !== AVAILABILITY_STATES.AVAILABLE

  const [inputValue, setInputValue] = useState(0 || amount)
  const [focused, setFocused] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (amount !== undefined) setInputValue(amount)
  }, [amount])

  useEffect(() => {
    if (focused && inputRef && inputRef.current) {
      inputRef.current.select()
    }
  }, [focused])

  const setToZero = () => {
    if (size(products) <= 1) {
      onClickRemove()
    } else {
      onClickRemoveProduct(product)
    }
  }

  const updateProductAmount = newAmount => {
    if (newAmount === 0) {
      setToZero()
      return
    }

    dispatch(
      updateListProduct({
        ...product,
        amount: newAmount,
        total: newAmount * product.price,
      })
    )
  }

  const add = sum => updateProductAmount(+(amount + sum).toFixed(2))

  const susbtract = sub => updateProductAmount(+(amount - sub).toFixed(2))

  const setFocusInput = () => setFocused(true)

  const onChangeInput = e => {
    const text = e.target.value.replace(',', '.')
    if (!text) {
      setInputValue('')
      setError(true)
      return
    }

    const newValue = parseFloat(text) || 0
    setInputValue(newValue)
    const priceModulus = floatModulus(newValue, multiple)
    // eslint-disable-next-line eqeqeq
    if (text && (newValue < min || priceModulus !== 0) && newValue != 0) {
      setError(true)
      return
    }
    setError(false)
  }

  const onBlurInput = () => {
    const newValue = parseFloat(inputValue) || 0
    const priceModulus = floatModulus(newValue, multiple)

    setFocused(false)
    setError(false)

    if (newValue === 0) {
      setInputValue(amount)
      setToZero()
      return
    }

    // eslint-disable-next-line eqeqeq
    if ((newValue >= min && priceModulus === 0) || !newValue) {
      const parsedInput = Number.isNaN(newValue) ? 0 : newValue
      updateProductAmount(parsedInput)
      return
    }
    setInputValue(amount)
  }

  const onClickAdd = () => {
    add(multiple)
  }

  const onClickSubstract = () => {
    if (amount - multiple <= 0) {
      return setToZero()
    }

    return susbtract(multiple)
  }

  const onKeydown = e => {
    if (e.keyCode === KEY_CODES.UP_ARROW) {
      e.preventDefault()
      add(multiple)
    } else if (e.keyCode === KEY_CODES.DOWN_ARROW) {
      e.preventDefault()
      onClickSubstract()
    } else if (e.keyCode === KEY_CODES.TAB || e.keyCode === KEY_CODES.ENTER) {
      e.preventDefault()
      inputRef.current.blur()
      setFocused(false)
    }
  }

  const onClickAddToCart = () => {
    const editCart = needsApproval ? ApproverActions.editCart : PurchaseActions.addProductToCart

    dispatch(
      editCart(id, {
        amount: inputValue,
        name,
        total: inputValue * price,
        provider,
        price,
        pricePerKg,
        measureUnit,
        min,
        multiple,
        id,
        raw_material,
        state: status,
        reference,
      })
    )
  }

  return {
    inputValue,
    inputRef,
    error,
    focused,
    notAvailable,
    setFocusInput,
    onChangeInput,
    onBlurInput,
    onClickAdd,
    onClickSubstract,
    onKeydown,
    onClickAddToCart,
    setToZero,
    setFocused,
  }
}

export default useShoppingListsProductButton
