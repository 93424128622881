import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Download } from 'Assets/Icons/Descargar.svg'

import { ButtonContainer, UnderlineButton, MainContainer } from './styled'

const DownloadProductCatalog = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <MainContainer>
      <ButtonContainer>
        <Download />
        <UnderlineButton onClick={onClick} type='button'>
          {t('downloadProductCatalog')}
        </UnderlineButton>
      </ButtonContainer>
    </MainContainer>
  )
}

DownloadProductCatalog.propTypes = {
  onClick: PropTypes.func,
}

DownloadProductCatalog.defaultProps = {
  onClick: () => null,
}

export default DownloadProductCatalog
