import { useState } from 'react'

import { useDispatch } from 'react-redux'
import { removeList, setListData, setListProducts } from 'Redux/lists/actions'

const useShoppingListsRemove = () => {
  const dispatch = useDispatch()

  const [removeListModal, setRemoveListModal] = useState(null)

  const onRemoveList = () => {
    dispatch(removeList(removeListModal))
    dispatch(setListData(null))
    dispatch(setListProducts(null))
    setRemoveListModal(null)
  }

  return {
    removeListModal,
    onRemoveList,
    setRemoveListModal,
  }
}

export default useShoppingListsRemove
