import React from 'react'

import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Modal } from 'Components/atoms'
import routes from 'Config/routes'

import CenterSelector from '../centerSelector'

const ModalCenter = ({ showModal, setShowModal, onSelectCenter, showSubtitle, title, onClose }) => {
  const history = useHistory()

  const handleSelectCenter = data => {
    if (onSelectCenter) {
      onSelectCenter(data)
    } else if (!onClose) {
      history.push(routes.home)
    }
    if (!onClose) {
      setShowModal(false)
    }
  }

  return (
    <Modal isOpen={showModal} setIsOpen={setShowModal} onClose={onClose}>
      <CenterSelector
        customSelectCallback={!!onSelectCenter}
        onSelectCenter={handleSelectCenter}
        showSubtitle={showSubtitle}
        title={title}
      />
    </Modal>
  )
}

ModalCenter.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onSelectCenter: PropTypes.func,
  showSubtitle: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func || null,
}

ModalCenter.defaultProps = {
  showModal: false,
  setShowModal: () => null,
  onSelectCenter: null,
  showSubtitle: true,
  title: 'changeCenter',
  onClose: null,
}

export default ModalCenter
