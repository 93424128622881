export const FETCH_PENDING_APPROVAL = 'portal_compras/carts/FETCH_PENDING_APPROVAL'
export const SET_PENDING_APPROVAL = 'portal_compras/carts/SET_PENDING_APPROVAL'
export const SET_IS_FETCHING_PENDING_APPROVAL = 'portal_compras/carts/SET_IS_FETCHING_PENDING_APPROVAL'
export const FETCH_REJECTED = 'portal_compras/carts/FETCH_REJECTED'
export const SET_REJECTED = 'portal_compras/carts/SET_REJECTED'
export const SET_IS_FETCHING_REJECTED = 'portal_compras/carts/SET_IS_FETCHING_REJECTED'
export const FETCH_APPROVED = 'portal_compras/carts/FETCH_APPROVED'
export const SET_APPROVED = 'portal_compras/carts/SET_APPROVED'
export const SET_IS_FETCHING_APPROVED = 'portal_compras/carts/SET_IS_FETCHING_APPROVED'
export const FETCH_CANCELLED = 'portal_compras/carts/FETCH_CANCELLED'
export const SET_CANCELLED = 'portal_compras/carts/SET_CANCELLED'
export const SET_IS_FETCHING_CANCELLED = 'portal_compras/carts/SET_IS_FETCHING_CANCELLED'
export const SET_FILTER_PROVIDER = 'portal_compras/carts/SET_FILTER_PROVIDER'
export const FETCH_CART = 'portal_compras/carts/FETCH_CART'
export const SET_CART = 'portal_compras/carts/SET_CART'
export const SET_IS_FETCHING_CART = 'portal_compras/carts/SET_IS_FETCHING_CART'
export const SET_REQUEST_ORDER = 'portal_compras/carts/SET_REQUEST_ORDER'
export const CANCEL_REQUEST_ORDER = 'portal_compras/carts/CANCEL_REQUEST_ORDER'
export const SET_IS_UPDATING_CART = 'portal_compras/carts/SET_IS_UPDATING_CART'
export const SET_FILTER_REQUEST_NUMBER = 'portal_compras/carts/SET_FILTER_REQUEST_NUMBER'
export const FETCH_CART_REQUEST = 'portal_compras/carts/FETCH_CART_REQUEST'
export const SET_CURRENT_PAGE_CART = 'portal_compras/carts/SET_CURRENT_PAGE_CART'
export const SET_HAS_MORE_REQUEST = 'portal_compras/carts/SET_HAS_MORE_REQUEST'
