import { useDispatch } from 'react-redux'
import { setFilterRequestNumber } from 'Redux/approver/actions'

const useApproverCartsRequestNumberFilter = () => {
  const dispatch = useDispatch()

  const onChange = requestId => dispatch(setFilterRequestNumber(requestId))

  return { onChange }
}

export default useApproverCartsRequestNumberFilter
