import React from 'react'

import PropTypes from 'prop-types'
import Calendar from 'react-calendar'
import moment from 'moment'
import 'moment/locale/es'
import { ReactComponent as NextCalendar } from 'Assets/Icons/NextCalendar.svg'
import { ReactComponent as PreviousCalendar } from 'Assets/Icons/PreviousCalendar.svg'

import './Calendar.css'

const MyCalendar = ({ value, onChange, selectRange, className, tileDisabled = () => false, ...rest }) => (
  <Calendar
    formatShortWeekday={(locale, date) => moment(date).format('ddd')[0]}
    formatMonthYear={(locale, date) => moment(date).format('MMMM YYYY')}
    nextLabel={<NextCalendar />}
    prevLabel={<PreviousCalendar />}
    value={value}
    onChange={onChange}
    tileDisabled={tileDisabled}
    selectRange={selectRange}
    className={className}
    {...rest}
  />
)

MyCalendar.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  tileDisabled: PropTypes.func.isRequired,
  selectRange: PropTypes.bool,
  className: PropTypes.string,
}

MyCalendar.defaultProps = {
  selectRange: false,
  className: '',
}

export default MyCalendar
