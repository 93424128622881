import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'Components/atoms'
import { setModalProductOpen } from 'Redux/purchases/actions'

import Product from '../product'

const ModalProduct = () => {
  const isOpen = useSelector(state => state.purchase.modalProductOpen)
  const dispatch = useDispatch()

  const setIsOpen = value => dispatch(setModalProductOpen(value))

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      displayContainer='initial'
      justifyContainer='initial'
      margin='0 auto'
      width='57.5rem'
      height='auto'
      padding='2.5rem 5rem'
      textAlign='left'
    >
      <Product />
    </Modal>
  )
}

export default ModalProduct
