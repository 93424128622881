import React from 'react'

import PropTypes from 'prop-types'

import { Text } from './styled'

const MediumText = ({ children, margin, padding, fontSize }) => (
  <Text padding={padding} margin={margin} fontSize={fontSize}>
    {children}
  </Text>
)

MediumText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  margin: PropTypes.string,
  padding: PropTypes.string,
  fontSize: PropTypes.string,
}

MediumText.defaultProps = {
  children: '',
  margin: '',
  padding: '',
  fontSize: '',
}

export default MediumText
