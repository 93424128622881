import React from 'react'

import PropTypes from 'prop-types'
import { Modal } from 'Components/atoms'

import OriginCenterSelector from '../originCenterSelector'

const ModalOriginCenter = ({ showModal, onCenterSelected, centers }) => (
  <Modal isOpen={showModal} setIsOpen={onCenterSelected}>
    <OriginCenterSelector onSelectCenter={onCenterSelected} centers={centers} />
  </Modal>
)

ModalOriginCenter.propTypes = {
  showModal: PropTypes.bool,
  onCenterSelected: PropTypes.func,
  centers: PropTypes.array,
}

ModalOriginCenter.defaultProps = {
  showModal: false,
  onCenterSelected: () => {},
  centers: [],
}

export default ModalOriginCenter
