import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'Config/constants'

import NoRows from './noRows'
import Pagination from './pagination'
import { StyledDataGrid } from './styled'

const BasicTable = ({
  autoHeight,
  fixedHeight,
  rows,
  columns,
  page,
  pageSize,
  paginationLabel,
  count,
  isLoading,
  onPageChange,
  onPageSizeChange,
}) => {
  const dataGridRef = useRef()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [shouldHandleResize, setShouldHandleResize] = useState(false)
  const [overflowGrid, setOverflowGrid] = useState()

  useEffect(() => {
    if (!shouldHandleResize) {
      return () => undefined
    }

    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [shouldHandleResize])

  useEffect(() => {
    if (!fixedHeight) {
      return
    }

    setTimeout(() => {
      const gridWindow = dataGridRef.current?.querySelector('.window')
      const overflow = gridWindow.scrollHeight > gridWindow.offsetHeight
      setOverflowGrid(overflow ? 'auto' : 'hidden')
    }, 0)
  }, [fixedHeight, isLoading])

  const getWidthFromPercent = useCallback(
    percentage => {
      setShouldHandleResize(true)
      return percentage !== undefined
        ? (Math.min(100, Math.max(0, percentage)) * dataGridRef.current?.clientWidth) / 100
        : percentage
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataGridRef, windowWidth]
  )

  const gridColumns = useMemo(
    () =>
      columns.map(({ widthPercentage, ...column }) =>
        widthPercentage ? { ...column, width: getWidthFromPercent(widthPercentage) } : { ...column }),
    [columns, getWidthFromPercent]
  )

  const PaginationWLabel = useCallback(props => <Pagination {...props} label={paginationLabel} />, [paginationLabel])

  return (
    <StyledDataGrid
      autoHeight={autoHeight}
      rowHeight={autoHeight && 57}
      ref={dataGridRef}
      windowRef={dataGridRef?.current}
      disableSelectionOnClick
      showCellRightBorder
      paginationMode='server'
      rows={rows}
      columns={gridColumns}
      page={page}
      loading={isLoading}
      rowCount={count}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      rowsPerPageOptions={TABLE_ROWS_PER_PAGE_OPTIONS}
      pageSize={pageSize}
      overflow={overflowGrid}
      components={{
        noRowsOverlay: NoRows,
        pagination: PaginationWLabel,
      }}
    />
  )
}

BasicTable.propTypes = {
  autoHeight: PropTypes.bool,
  fixedHeight: PropTypes.bool,
  rows: PropTypes.array,
  columns: PropTypes.array,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  paginationLabel: PropTypes.string.isRequired,
  count: PropTypes.number,
  isLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
}

BasicTable.defaultProps = {
  autoHeight: undefined,
  fixedHeight: false,
  rows: [],
  columns: [],
  page: 0,
  pageSize: TABLE_ROWS_PER_PAGE_OPTIONS[0],
  count: 0,
  isLoading: false,
  onPageChange: () => undefined,
  onPageSizeChange: () => undefined,
}

export default BasicTable
