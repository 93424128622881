import {
  ADD_LIST,
  ADD_PRODUCT,
  FETCH_LISTS,
  FETCH_LIST_PRODUCTS,
  REMOVE_LIST,
  REMOVE_PRODUCT,
  SEND_CART,
  SET_ERROR_ADD,
  SET_IS_FETCHING_ADD,
  SET_IS_FETCHING_LISTS,
  SET_IS_FETCHING_LIST_PRODUCTS,
  SET_IS_FETCHING_UPDATE,
  SET_IS_SENDING_CART,
  SET_LISTS,
  SET_LIST_DATA,
  SET_LIST_PRODUCTS,
  UPDATE_PRODUCT,
  UPDATE_TITLE,
} from './types'

export const fetchLists = () => ({
  type: FETCH_LISTS,
})

export const setIsFetchingLists = payload => ({
  type: SET_IS_FETCHING_LISTS,
  payload,
})

export const setLists = payload => ({
  type: SET_LISTS,
  payload,
})

export const addList = (payload, successCallback) => ({
  type: ADD_LIST,
  payload,
  successCallback,
})

export const setIsFetchingAdd = payload => ({
  type: SET_IS_FETCHING_ADD,
  payload,
})

export const setErrorAdd = payload => ({
  type: SET_ERROR_ADD,
  payload,
})

export const removeList = payload => ({
  type: REMOVE_LIST,
  payload,
})

export const fetchListProducts = payload => ({
  type: FETCH_LIST_PRODUCTS,
  payload,
})

export const setIsFetchingListProducts = payload => ({
  type: SET_IS_FETCHING_LIST_PRODUCTS,
  payload,
})

export const setListProducts = payload => ({
  type: SET_LIST_PRODUCTS,
  payload,
})

export const setListData = payload => ({
  type: SET_LIST_DATA,
  payload,
})

export const removeListProduct = payload => ({
  type: REMOVE_PRODUCT,
  payload,
})

export const updateTitle = payload => ({
  type: UPDATE_TITLE,
  payload,
})

export const setIsFetchingUpdate = (payload, successCallback) => ({
  type: SET_IS_FETCHING_UPDATE,
  payload,
  successCallback,
})

export const sendcart = payload => ({
  type: SEND_CART,
  payload,
})

export const setIsSendingcart = payload => ({
  type: SET_IS_SENDING_CART,
  payload,
})

export const updateListProduct = payload => ({
  type: UPDATE_PRODUCT,
  payload,
})

export const addListProduct = payload => ({
  type: ADD_PRODUCT,
  payload,
})
