import React from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { MEASURE_UNIT } from 'Config/constants'
import { NumberFormat } from 'Components/atoms'
import Arrow from 'Assets/Icons/Arrow'
import colors from 'Assets/colors'
import { PreviousPrice, Price, PriceKG } from 'Components/molecules/productCard/styled'

const ProductDifference = ({ product }) => {
  const center = useSelector(state => state.auth.selectedCenter)

  const { showPrice } = center
  const { previous_price: previousPrice, price, measureUnit, pricePerKg } = product

  const differencePrice = previousPrice ? price - previousPrice : null

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '0.5rem',
        marginLeft: '0.5rem',
        paddingBottom: '0.5rem',
        paddingTop: '0.5rem',
      }}
    >
      {showPrice && (
        <>
          <Price style={{ color: differencePrice > 0 ? colors.red : colors.secondary }}>
            <NumberFormat value={price} suffix={`€/${measureUnit}`} />
          </Price>
          <Arrow
            style={{
              marginRight: '0.5rem',
              marginLeft: '0.2rem',
              transform: differencePrice > 0 ? 'rotate(270deg)' : 'rotate(90deg)',
              position: 'relative',
            }}
            fill={differencePrice > 0 ? colors.red : colors.secondary}
          />
          <PreviousPrice>
            <NumberFormat value={previousPrice} suffix={`€/${measureUnit}`} />
          </PreviousPrice>
          {measureUnit !== MEASURE_UNIT.KG ? (
            <PriceKG>
              <NumberFormat value={pricePerKg} suffix='€/KG' />
            </PriceKG>
          ) : null}
        </>
      )}
    </div>
  )
}

ProductDifference.defaultProps = {
  product: {},
}

ProductDifference.propTypes = {
  product: PropTypes.object,
}

export default ProductDifference
