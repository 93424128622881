import React from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import map from 'lodash/map'
import { REQUEST_ORDER_STATE } from 'Config/constants'
import { NumberFormat } from 'Components/atoms'
import ProviderHeader from 'Components/molecules/providerHeader'

import {
  ProviderContainer,
  ProviderBody,
  ProviderBodyLabel,
  ProviderBodyContent,
  ProviderProduct,
  ProviderProductColumn,
  ProviderProductColumnSeparator,
  ProductDescription,
  ProductValue,
  ProductValueUnits,
} from './styled'

const { APPROVED } = REQUEST_ORDER_STATE

const ProviderProducts = ({ provider, state }) => {
  const { t } = useTranslation()

  const showPrice = useSelector(s => s.auth.selectedCenter?.showPrice || false)
  const requestOrder = useSelector(s => s.carts.requestOrder)

  const { products } = provider

  return (
    <ProviderContainer>
      <ProviderHeader provider={provider} state={state} />
      <ProviderBody>
        <ProviderBodyContent>
          <ProviderProductColumn flex={4}>
            <ProviderBodyLabel>{t('product')}</ProviderBodyLabel>
          </ProviderProductColumn>
          {showPrice && (
            <ProviderProductColumn flex={2}>
              <ProviderBodyLabel>{t('total')}</ProviderBodyLabel>
            </ProviderProductColumn>
          )}
          <ProviderProductColumn flex={2}>
            <ProviderBodyLabel>{t('requestedAmount')}</ProviderBodyLabel>
          </ProviderProductColumn>
          {requestOrder?.state === APPROVED && (
            <ProviderProductColumn flex={3}>
              <ProviderBodyLabel>{t('approvedAmount')}</ProviderBodyLabel>
            </ProviderProductColumn>
          )}
        </ProviderBodyContent>
        {map(products, product => (
          <Product product={product} key={product.productId} />
        ))}
      </ProviderBody>
    </ProviderContainer>
  )
}

ProviderProducts.defaultProps = {
  provider: {},
  state: '',
}

ProviderProducts.propTypes = {
  provider: PropTypes.object,
  state: PropTypes.string,
}

const Product = ({ product }) => {
  const { t } = useTranslation()

  const requestOrder = useSelector(s => s.carts.requestOrder)

  const {
    productDescription,
    price,
    totalPrice: total,
    approvedTotalPrice,
    amount,
    unit,
    approvedAmount,
    status,
    approvedPrice,
  } = product

  const showPrice = useSelector(s => s.auth.selectedCenter?.showPrice || false)

  const isApproved = requestOrder?.state === APPROVED

  return (
    <ProviderProduct>
      <ProviderProductColumn flex={4}>
        <ProductDescription status={status}>
          <span>{productDescription}</span>
          {isApproved && <span className='status'>{t(`approvalState.${status}`)}</span>}
        </ProductDescription>
      </ProviderProductColumn>
      <ProviderProductColumnSeparator />
      {showPrice && (
        <ProviderProductColumn flex={2}>
          <ProductValue>
            <NumberFormat value={isApproved ? approvedTotalPrice : total} />
          </ProductValue>
          <ProductValueUnits>
            <NumberFormat value={isApproved ? approvedPrice : price} suffix={`€/${unit}`} />
          </ProductValueUnits>
        </ProviderProductColumn>
      )}
      <ProviderProductColumnSeparator />
      <ProviderProductColumn flex={2}>
        <ProductValue>
          <NumberFormat value={amount} decimalScale={0} suffix={` ${unit}`} />
        </ProductValue>
      </ProviderProductColumn>
      {isApproved && (
        <>
          <ProviderProductColumnSeparator />
          <ProviderProductColumn flex={3}>
            <ProductDescription status={status}>
              <span className='status'>
                <NumberFormat value={approvedAmount} decimalScale={0} suffix={` ${unit}`} />
              </span>
            </ProductDescription>
          </ProviderProductColumn>
        </>
      )}
    </ProviderProduct>
  )
}

Product.defaultProps = {
  product: {},
}

Product.propTypes = {
  product: PropTypes.object,
}

export default ProviderProducts
