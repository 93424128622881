import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import moment from 'moment'
import map from 'lodash/map'
import get from 'lodash/get'
import { fetchStyle } from 'Config/utils'
import { WORDPRESS_STYLES_SINGLE, WORDPRESS_ANNOUNCEMENTS } from 'Config/api'

import {
  useStyles,
  BodyContainer,
  CardImage,
  PostInfoContainer,
  PostTitle,
  PostMetasContainer,
  PostDate,
  PostCategories,
  PostCategory,
  MainContainer,
  CloseButtonContainer,
  CloseButton,
} from './styled'

const DrawerAnnouncement = ({ isOpen, onClose, announcement }) => {
  const classes = useStyles()

  const [body, setBody] = useState('')
  const [description, setDescription] = useState('')
  const [title, setTitle] = useState('')
  const [date, setDate] = useState('')
  const [image, setImage] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [categoryUrl, setCategoryUrl] = useState('')

  useEffect(() => {
    fetchStyle(WORDPRESS_STYLES_SINGLE)
  }, [])

  useEffect(() => {
    const newBody = announcement ? announcement.content : ''
    setBody(newBody)

    const newTitle = announcement ? announcement.title : ''
    setTitle(newTitle)

    const newDate = announcement ? announcement.published : ''
    setDate(newDate)

    const newImage = announcement ? announcement.image : ''
    setImage(newImage)

    const newDescription = announcement ? announcement.description : ''
    setDescription(newDescription)

    const categoriesNames = announcement ? map(announcement.categories, 'name') : ''
    setCategoryName(categoriesNames)

    const categoriesLinks = announcement ? map(announcement.categories, 'url') : ''
    setCategoryUrl(categoriesLinks)
  }, [announcement])

  const hadleClickCategory = (e, index) => {
    e.stopPropagation()
    window.open(get(categoryUrl, index, WORDPRESS_ANNOUNCEMENTS))
  }

  return (
    <Drawer anchor='right' open={isOpen} onClose={onClose} classes={classes}>
      <MainContainer className='post-modal'>
        {image && <CardImage src={image} />}
        <PostInfoContainer>
          <PostTitle>{title}</PostTitle>
          <PostMetasContainer>
            <PostDate>{moment(date).format('LL')}</PostDate>
            <PostCategories>
              {map(categoryName, (text, index) => (
                <PostCategory key={`${index}${text}`} onClick={e => hadleClickCategory(e, index)}>
                  {text}
                </PostCategory>
              ))}
            </PostCategories>
          </PostMetasContainer>
        </PostInfoContainer>
        {/* eslint-disable-next-line react/no-danger */}
        <BodyContainer padding='20px 40px 0 40px' dangerouslySetInnerHTML={{ __html: body || description }} />
        <CloseButtonContainer>
          <CloseButton onClick={onClose}>+</CloseButton>
        </CloseButtonContainer>
      </MainContainer>
    </Drawer>
  )
}

DrawerAnnouncement.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  announcement: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    content: PropTypes.string,
    title: PropTypes.string,
    published: PropTypes.string,
    image: PropTypes.string,
    categories: PropTypes.array,
    description: PropTypes.string,
  }),
}

DrawerAnnouncement.defaultProps = {
  isOpen: false,
  onClose: () => {},
  announcement: {},
}

export default DrawerAnnouncement
