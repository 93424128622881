import styled from 'styled-components'
import colors from 'Assets/colors'

export const Title = styled.h2`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 40px;
`

export const ProductsContainer = styled.div`
  background-color: ${colors.grayInput};
  border-radius: 8px;
`

export const ProductsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.24);

  > div {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`

export const TotalProducts = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 0px 8px;
  background: #ffffff;
  border-radius: 4px;
  margin-left: 16px;
`

export const ProductsList = styled.div`
  padding: 25px 16px;
`

export const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: ${({ withPrice }) => (withPrice ? '43% 17% 17% 23%' : '43% 34% 23%')};
  padding: 16px 0;
`

export const TableHeaderItem = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: ${colors.black};

  &:first-child {
    padding-left: 16px;
  }
`

export const SingleProductRowCL = styled.div`
  background: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.24);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 43% 17% 17% 23%;
  margin-bottom: 16px;
  margin-top: 16px;
  height: 64px;
`
