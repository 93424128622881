import i18n from 'Assets/i18n'

const validate = ({ values, hasFrozen, hasRefrigerated }) => {
  const {
    frozen,
    refrigerated,
    receptionTime,
    transport,
    package: packageItem,
    lifeUse,
    productAspect,
    productLabel,
    acceptedProduct,
    frozenComment,
    refrigeratedComment,
  } = values
  const errors = {}

  if (!receptionTime) errors.receptionTime = i18n.t('mandatoryField')
  if (!transport) errors.transport = i18n.t('mandatoryField')
  if (!packageItem) errors.package = i18n.t('mandatoryField')
  if (!lifeUse) errors.lifeUse = i18n.t('mandatoryField')
  if (!productAspect) errors.productAspect = i18n.t('mandatoryField')
  if (!productLabel) errors.productLabel = i18n.t('mandatoryField')
  if (!acceptedProduct) errors.acceptedProduct = i18n.t('mandatoryField')

  if (hasFrozen) {
    if (!frozen) errors.frozen = i18n.t('mandatoryField')
    if (!frozenComment) errors.frozenComment = i18n.t('mandatoryField')
  }
  if (hasRefrigerated) {
    if (!refrigerated) errors.refrigerated = i18n.t('mandatoryField')
    if (!refrigeratedComment) errors.refrigeratedComment = i18n.t('mandatoryField')
  }
  return errors
}

export default validate
