import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { ProductCard, ModalConfirmation, NoResults } from 'Components/molecules'
import range from 'lodash/range'
import ContentLoader from 'react-content-loader'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TextInput } from 'Components/atoms'
import { removeHabitual, addHabitual } from 'Services/api'
import SearchIcon from 'Assets/Icons/Search'
import { toast } from 'react-toastify'

import { ProductCardLoadingContainer } from './styled'

const ProductList = ({ products, lastElementRef, onSuccessHabitual, onSuccessUnHabitual }) => {
  const { t } = useTranslation()

  const [productId, setProductId] = useState(null)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const centerId = useSelector(state => state.auth.selectedCenter.id)

  const toggleConfirmModal = () => {
    setOpenConfirmModal(prevState => !prevState)
  }

  const handleHabitual = itemId => {
    setProductId(itemId)
    toggleConfirmModal()
  }

  const handleUnHabitual = async itemId => {
    try {
      onSuccessUnHabitual(itemId)
      await addHabitual(itemId, centerId)
    } catch (e) {
      onSuccessHabitual(itemId)
      toast.warning(t('errorAddHabitual'))
    }
  }

  const handleConfirmHabitual = async () => {
    try {
      onSuccessHabitual(productId)
      await removeHabitual(productId, centerId)
    } catch (e) {
      onSuccessUnHabitual(productId)
      toast.warning(t('errorDeleteHabitual'))
    } finally {
      toggleConfirmModal()
    }
  }

  return (
    <>
      {products.map(
        (
          {
            id,
            category,
            temperature: temp,
            name,
            provider,
            price,
            min,
            multiple,
            measure_unit: measureUnit,
            price_per_kg: pricePerKg,
            row_material: rowMaterial,
            state,
            reference,
            previous_price: previousPrice,
            availability,
            hasMoreInfo,
            isHabitual,
          },
          index
        ) => (
          <ProductCard
            id={id}
            provider={provider}
            name={name}
            temperature={temp}
            key={id}
            categoryName={category.name}
            categoryId={category.id}
            price={price}
            min={min}
            multiple={multiple}
            measureUnit={measureUnit}
            refProp={products.length === index + 1 ? lastElementRef : null}
            pricePerKg={pricePerKg}
            rowMaterial={rowMaterial}
            state={state}
            reference={reference}
            previousPrice={previousPrice}
            availability={availability}
            hasMoreInfo={hasMoreInfo}
            isHabitual={isHabitual}
            onHabitual={handleHabitual}
            onUnHabitual={handleUnHabitual}
          />
        )
      )}
      <ModalConfirmation
        onCancel={toggleConfirmModal}
        onAccept={handleConfirmHabitual}
        setShowModal={toggleConfirmModal}
        showModal={openConfirmModal}
        message={t('confirmDeleteHabitualDesc')}
        title={t('confirmDeleteHabitualTitle')}
        action={t('deleteHabitualProduct')}
        color='red'
        width='680px'
        height='min-content'
      />
    </>
  )
}

ProductList.defaultProps = {
  products: [],
  lastElementRef: () => null,
  onSuccessHabitual: () => null,
  onSuccessUnHabitual: () => null,
}

ProductList.propTypes = {
  products: PropTypes.array,
  lastElementRef: PropTypes.func,
  onSuccessHabitual: PropTypes.func,
  onSuccessUnHabitual: PropTypes.func,
}

export const SearchTextInput = ({ value, onChange }) => {
  const { t } = useTranslation()

  return (
    <TextInput
      placeholder={t('searchDots')}
      width='20rem'
      Icon={SearchIcon}
      value={value}
      onChange={onChange}
      padding='0.5rem 1rem'
      background='#F4F1F1'
      topIcon='0.75rem'
    />
  )
}

SearchTextInput.defaultProps = {
  value: '',
  onChange: () => null,
}

SearchTextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export const ResultMessage = ({ isLoading }) => {
  const { t } = useTranslation()
  if (isLoading) return ''
  return <NoResults title={t('noProductsTitle')} description={t('noProductsLiteral')} />
}

ResultMessage.defaultProps = {
  isLoading: false,
}

ResultMessage.propTypes = {
  isLoading: PropTypes.bool,
}

export const MultipleProductCardLoader = () => (
  <>
    {range(6).map(i => (
      <ProductCardLoadingContainer key={i}>
        <ProductCardLoader />
      </ProductCardLoadingContainer>
    ))}
  </>
)

const ProductCardLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width={320}
      height={290}
      viewBox='0 0 320 290'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='16' y='57' rx='3' ry='3' width='288' height='15' />
      <rect x='16' y='88' rx='0' ry='0' width='288' height='10' />
      <rect x='16' y='199' rx='0' ry='0' width='120' height='6' />
      <rect x='16' y='236' rx='0' ry='0' width='288' height='38' />
      <rect x='16' y='175' rx='0' ry='0' width='150' height='10' />
      <circle cx='26' cy='26' r='10' />
      <circle cx='296' cy='26' r='10' />
    </ContentLoader>
  )
}

export default ProductList
