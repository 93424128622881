import React, { useCallback, useMemo, useState } from 'react'

import omitBy from 'lodash/omitBy'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { ReactComponent as CloseX2 } from 'Assets/Icons/CloseX2.svg'
import colors from 'Assets/colors'
import { AutocompleteInput, CalendarDateRange } from 'Components/molecules'
import { LIMIT_REQUEST_CENTERS, MIN_CHARS_FOR_SEARCH } from 'Config/constants'
import { fetchFilterCenters, setFilterCenters } from 'Redux/multicenter/actions'
import CompassCenterIcon from 'Assets/Icons/Centers'

import {
  Title,
  FiltersContainer,
  FullWidthFilterItems,
  SplitWidthFilterItems,
  ItemContainer,
  InputLabel,
  ActionButton,
  ButtonLiteral,
  InputLabelSmall,
  CentersSection,
  CenterTag,
  CenterItem,
  ErrorLabelSmall,
} from './styled'

const ModalMulticenterFilterDownload = ({ title, onChange, onlyCenters }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [filters, setFilter] = useState({ targetCenters: [] })
  const [visible, setVisible] = useState({})
  const [errorDates, setErrorDates] = useState(null)

  const centersList = useSelector(({ multicenter }) => multicenter.filterCenters)

  const centers = useMemo(() => {
    const cAux = centersList.map(center => ({
      ...center,
      name: `${center.name} - ${center.id}`,
    }))
    const centerFiltered = cAux.filter(el => !filters.targetCenters.find(elem => elem.id === el.id))
    return centerFiltered
  }, [centersList, filters.targetCenters])

  const handleFocus = id => {
    setVisible({ ...visible, [id]: true })
  }
  const handleBlur = id => {
    setVisible({ ...visible, [id]: false })
  }

  const handleChange = newfilters => {
    setFilter(omitBy(newfilters, e => !e))
    setErrorDates(false)
  }

  const onRemoveTag = useCallback(
    tag => {
      const newTags = filters.targetCenters.filter(element => element.id !== tag.id)
      setFilter({ ...filters, targetCenters: newTags })
    },
    [filters]
  )

  const handleChangeCenter = useCallback(
    valueInput => {
      if (filters.targetCenters.length < 5) {
        const targetCenters = valueInput ? [...filters.targetCenters, valueInput] : [...filters.targetCenters]
        handleChange({ ...filters, targetCenters })
      }
    },
    [filters]
  )

  const handleSearch = useCallback(
    valueInput => {
      if (valueInput?.length >= MIN_CHARS_FOR_SEARCH || valueInput == null || valueInput?.length === 0) {
        dispatch(fetchFilterCenters({ search: valueInput, limit: LIMIT_REQUEST_CENTERS }))
      } else {
        dispatch(setFilterCenters([]))
      }
    },
    [dispatch]
  )

  const handleDownload = useCallback(() => {
    if (!onlyCenters && !filters.startDate && !filters.endDate) {
      setErrorDates(t('datesRequired'))
      return
    }

    const formatedTargetCenters = filters.targetCenters.reduce(
      (accumulator, currentValue) => accumulator.concat(`centerIds[]=${currentValue.id}&`),
      ''
    )
    const centerFormatedFilters = formatedTargetCenters.substring(0, formatedTargetCenters.length - 1)
    let fformatedFilters

    if (centerFormatedFilters && !filters.startDate) {
      fformatedFilters = `${centerFormatedFilters}`
    } else if (centerFormatedFilters && filters.startDate && filters.endDate) {
      fformatedFilters = `${centerFormatedFilters}&`
    } else if (filters.startDate && filters.endDate) {
      fformatedFilters = '?'
    }
    if (filters.startDate && filters.endDate) {
      const startDate = filters.startDate.toISOString().split('T')[0]
      const endDate = filters.endDate.toISOString().split('T')[0]

      fformatedFilters = fformatedFilters.concat(`startDate=${startDate}&endDate=${endDate}`)
    }

    onChange(fformatedFilters)
  }, [filters, onChange, onlyCenters, setErrorDates, t])

  return (
    <>
      <Title>{title}</Title>

      <FiltersContainer>
        <SplitWidthFilterItems>
          {!onlyCenters && (
            <ItemContainer>
              <InputLabel>{t('filterLabelDate')}</InputLabel>
              <InputLabelSmall>{t('dateRange')}</InputLabelSmall>
              <CalendarDateRange
                label={t('filterPlaceholderDate')}
                onRemove={() => {
                  const { startDate, endDate, ...restFilters } = filters
                  handleChange(restFilters)
                }}
                value={filters.startDate && filters.endDate ? [filters.startDate, filters.endDate] : null}
                onChange={valueInput => handleChange({ ...filters, ...valueInput })}
                selectRange
              />
              {errorDates && <ErrorLabelSmall>{t('datesRequired')}</ErrorLabelSmall>}
            </ItemContainer>
          )}
        </SplitWidthFilterItems>

        <ItemContainer className='full-width'>
          <InputLabel>{t('centers')}</InputLabel>
          <InputLabelSmall>{t('fiveCenterseRange')}</InputLabelSmall>

          <AutocompleteInput
            notIcon
            value={filters.targetCenters[filters.targetCenters.length - 1]}
            onChange={handleChangeCenter}
            onSearch={handleSearch}
            disabledItems={filters.targetCenters.length === 5}
            choices={centers}
            placeholder={t('filterPlaceholderCenter')}
            topIcon='1.2rem'
            Icon={props => (
              <CompassCenterIcon
                width='30px'
                height='20px'
                resize='contain'
                fill={visible.center ? colors.secondary : colors.black}
                {...props}
              />
            )}
            onFocus={() => handleFocus('center')}
            onBlur={() => {
              handleBlur('center')
            }}
            borderRadius={visible.center ? '8px 8px 0 0' : '8px'}
            background={visible.center ? colors.white : '#F4F1F1'}
          />
        </ItemContainer>

        <CentersSection>
          {filters.targetCenters.map(element => (
            <CenterItem>
              <CenterTag key={element.id}>{element.name}</CenterTag>
              <CloseX2
                onClick={() => onRemoveTag(element)}
                style={{ height: '17px', width: '17px', resize: 'contain', cursor: 'pointer' }}
              />
            </CenterItem>
          ))}
        </CentersSection>

        <FullWidthFilterItems onClick={() => setFilter({})} />
        <FullWidthFilterItems>
          <ActionButton
            disabled={filters.targetCenters.length === 0 || (!onlyCenters && !filters.startDate && !filters.endDate)}
            onClick={handleDownload}
          >
            <ButtonLiteral>{t('download')}</ButtonLiteral>
          </ActionButton>
        </FullWidthFilterItems>
      </FiltersContainer>
    </>
  )
}

ModalMulticenterFilterDownload.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  onlyCenters: PropTypes.bool,
}

ModalMulticenterFilterDownload.defaultProps = {
  message: '',
  title: '',
  onChange: () => {},
  onlyCenters: false,
}

export default ModalMulticenterFilterDownload
