import { AVAILABILITY_STATES } from 'Config/constants'

export const isAvailableProduct = element =>
  !!(
    element &&
    element.availability &&
    (element.availability.status === AVAILABILITY_STATES.AVAILABLE ||
      element.availability.status === AVAILABILITY_STATES.NEXT ||
      element.availability.status === AVAILABILITY_STATES.LAST_UNITS ||
      element.availability.status === AVAILABILITY_STATES.NEW)
  )
