import { useRef } from 'react'

import find from 'lodash/find'

export default () => {
  const itemEls = useRef({})
  //itemEls.current = {}

  const onNext = (id = null, i = null) => {
    if (id === null || !itemEls || !itemEls.current) return

    if (i !== null) {
      const keys = Object.keys(itemEls.current)
      let index = i
      if (index < 0) return

      const hasOtherInputs = find(itemEls.current, el => el && el.getId() !== id && el.hasInput())

      if (!hasOtherInputs) return

      let item = null
      do {
        if (index >= keys.length - 1) {
          index = 0
        } else {
          index += 1
        }

        const current = itemEls.current[index]
        if (current && (current.getId() !== id || (current.getId() === id && index !== i)) && current.hasInput()) {
          item = current
        }
      } while (item === null)

      if (item) item.focus()
    } else {
      const keys = Object.keys(itemEls.current)
      let index = keys.findIndex(v => v === id)
      if (index < 0) return

      const hasOtherInputs = find(itemEls.current, el => el && el.getId() !== id && el.hasInput())
      if (!hasOtherInputs) return

      let item = null
      do {
        if (index >= keys.length - 1) {
          index = 0
        } else {
          index += 1
        }

        const current = itemEls.current[keys[index]]
        if (current && current.getId() !== id && current.hasInput()) {
          item = current
        }
      } while (item === null)

      if (item) item.focus()
    }
  }

  const onPreviuos = (id = null, i = null) => {
    if (!id || !itemEls || !itemEls.current) return

    if (i !== null) {
      const keys = Object.keys(itemEls.current)
      let index = i
      if (index < 0) return

      const hasOtherInputs = find(itemEls.current, el => el && el.getId() !== id && el.hasInput())
      if (!hasOtherInputs) return

      let item = null
      do {
        if (index <= 0) {
          index = keys.length - 1
        } else {
          index -= 1
        }

        const current = itemEls.current[keys[index]]
        if (current && (current.getId() !== id || (current.getId() === id && index !== i)) && current.hasInput()) {
          item = current
        }
      } while (item === null)

      if (item) item.focus()
    } else {
      const keys = Object.keys(itemEls.current)
      let index = keys.findIndex(v => v === id)
      if (index < 0) return

      const hasOtherInputs = find(itemEls.current, el => el && el.getId() !== id && el.hasInput())
      if (!hasOtherInputs) return

      let item = null
      do {
        if (index <= 0) {
          index = keys.length - 1
        } else {
          index -= 1
        }

        const current = itemEls.current[keys[index]]
        if (current && current.getId() !== id && current.hasInput()) {
          item = current
        }
      } while (item === null)

      if (item) item.focus()
    }
  }

  const setRef = (element, id) => {
    itemEls.current[id] = element
  }

  return { onNext, onPreviuos, setRef, itemEls }
}
