import axios from 'axios'
import { REQUEST_ORDER_STATE } from 'Config/constants'
import qs from 'qs'

export const getOrders = (params, centerId) =>
  axios.get(`/api/request-orders?${qs.stringify(params, { skipNulls: true })}`, {
    headers: { 'X-Center-Id': centerId },
  })

const getCarts = ({ status, centerId, provider, sort, order, requestId }) =>
  axios.get(
    `api/request-orders?${qs.stringify(
      {
        ids: requestId ? JSON.stringify([requestId]) : null,
        statuses: [status],
        provider,
        limit: 100,
        sort,
        order,
      },
      { skipNulls: true }
    )}`,
    {
      headers: { 'X-Center-Id': centerId },
    }
  )

export const getCartsPending = (centerId, provider, requestId) =>
  getCarts({ centerId, provider, status: REQUEST_ORDER_STATE.PENDING, sort: 'deliveryDate', order: 'desc', requestId })

export const getCartsCancelled = (centerId, provider, requestId) =>
  getCarts({
    centerId,
    provider,
    status: REQUEST_ORDER_STATE.CANCELLED,
    sort: 'requestOrderId',
    order: 'desc',
    requestId,
  })

export const getCartsRejected = (centerId, provider, requestId) =>
  getCarts({
    centerId,
    provider,
    status: REQUEST_ORDER_STATE.REJECTED,
    sort: 'requestOrderId',
    order: 'desc',
    requestId,
  })

export const getCartsApproved = (centerId, provider, requestId) =>
  getCarts({
    centerId,
    provider,
    status: REQUEST_ORDER_STATE.APPROVED,
    sort: 'requestOrderId',
    order: 'desc',
    requestId,
  })

export const searchProviders = query => axios.get(`/api/search/request-orders/providers?query=${query}`)

export const getRequestOrderCart = (centerId, requestOrderId) =>
  axios.get(`/api/request-orders/${requestOrderId}/products`, {
    headers: { 'X-Center-Id': centerId },
  })

export const getRequestOrderCartWithPage = (centerId, requestOrderId, queryParams) =>
  axios.get(`/api/request-orders/${requestOrderId}/products?${qs.stringify(queryParams, { skipNulls: true })}`, {
    headers: { 'X-Center-Id': centerId },
  })

export const deleteRequestFailed = (centerId, requestOrderId) =>
  axios.delete(`/api/request-orders/${requestOrderId}/delete-error`, {
    headers: { 'X-Center-Id': centerId },
  })

export const getRequestOrderApprovalChanges = (centerId, requestOrderId) =>
  axios.get(`/api/request-orders/${requestOrderId}/approval-changes`, {
    headers: { 'X-Center-Id': centerId },
  })

export const cancelRequestOrderCart = (centerId, requestOrderId) =>
  axios.post(
    `/api/request-orders/${requestOrderId}/cancel`,
    {},
    {
      headers: { 'X-Center-Id': centerId },
    }
  )
