import React, { useCallback, useMemo, useState } from 'react'

import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ArrowDown from 'Assets/Icons/ArrowDropdown'
import { ReactComponent as CenterIcon } from 'Assets/Icons/CompassCenter.svg'
import { ReactComponent as DownloadIcon } from 'Assets/Icons/Descargar.svg'
import { ReactComponent as SolicitudesIcon } from 'Assets/Icons/Solicitudes.svg'
import UnderlineButton from 'Components/atoms/underlineButton'

import MulticenterOrdersTable from '../multicenterOrdersTable'

import * as S from './styled'

const CenterAccordion = ({
  center,
  onDownloadClick,
  onNewOrderClick,
  onViewProductsClick,
  onRepeatOrderClick,
  onDownloadOrderClick,
  onViewQAClick,
  onNoComplianceClick,
}) => {
  const [expanded, setExpanded] = useState()
  const { t } = useTranslation()

  const { name, id, requiresApproval } = center

  const filters = useSelector(({ multicenter }) => multicenter.ordersFilters)

  const areFiltersActive = useMemo(() => !!Object.keys(filters).length, [filters])

  const handleChange = useCallback(() => setExpanded(prev => !prev), [])

  const stopPropagation = useCallback((evt, callback) => {
    evt.stopPropagation()
    callback()
  }, [])

  return (
    <S.Accordion expanded={!!expanded} square>
      <S.AccordionSummary onClick={handleChange}>
        <div>
          <Typography>
            <CenterIcon />
            {t`center`} {name}
          </Typography>
          <Typography>
            {t`centerCode`} {id}
          </Typography>
        </div>
        <div>
          <UnderlineButton
            Icon={SolicitudesIcon}
            label={t`newOrder`}
            onClick={evt => stopPropagation(evt, onNewOrderClick)}
          />
          <UnderlineButton
            Icon={DownloadIcon}
            label={t`downloadOrders`}
            title={!areFiltersActive ? t`filterToDownload` : ''}
            disabled={!areFiltersActive}
            onClick={evt => stopPropagation(evt, onDownloadClick)}
          />
          <Typography className='accordion-toggle' onClick={evt => stopPropagation(evt, handleChange)}>
            {expanded ? t`hideOrders` : t`viewOrders`} <ArrowDown />
          </Typography>
        </div>
      </S.AccordionSummary>

      <S.AccordionDetails>
        {expanded !== undefined && (
          <MulticenterOrdersTable
            center={center}
            requiresApproval={requiresApproval}
            onViewProductsClick={onViewProductsClick}
            onRepeatOrderClick={onRepeatOrderClick}
            onDownloadOrderClick={onDownloadOrderClick}
            onViewQAClick={onViewQAClick}
            onNoComplianceClick={onNoComplianceClick}
          />
        )}
      </S.AccordionDetails>
    </S.Accordion>
  )
}

CenterAccordion.propTypes = {
  center: PropTypes.object.isRequired,
  onDownloadClick: PropTypes.func,
  onNewOrderClick: PropTypes.func,
  onViewProductsClick: PropTypes.func,
  onRepeatOrderClick: PropTypes.func,
  onDownloadOrderClick: PropTypes.func,
  onViewQAClick: PropTypes.func,
  onNoComplianceClick: PropTypes.func,
}

CenterAccordion.defaultProps = {
  onDownloadClick: () => undefined,
  onNewOrderClick: () => undefined,
  onViewProductsClick: () => undefined,
  onRepeatOrderClick: () => undefined,
  onDownloadOrderClick: () => undefined,
  onViewQAClick: () => undefined,
  onNoComplianceClick: () => undefined,
}

export default CenterAccordion
