import React from 'react'

import OrderFromOther from 'Components/organisms/orderFromOther'

import { Container } from './styled'

const PreviusOrder = () => (
  <>
    <Container>
      <OrderFromOther />
    </Container>
  </>
)

export default PreviusOrder
