import {
  CHECK_TUTORIAL_BANNER_V2,
  FINISH_TUTORIAL_V2,
  SHOW_REQUEST_TUTORIAL_BANNER_V2,
  SHOW_APPROVER_TUTORIAL_BANNER_V2,
} from './types'

export const finishTutorialV2 = tutorial => ({
  type: FINISH_TUTORIAL_V2,
  payload: tutorial,
})

export const checkTutorialBannerV2 = tutorial => ({
  type: CHECK_TUTORIAL_BANNER_V2,
  payload: tutorial,
})

export const showRequestTutorialBannerV2 = showTutorial => ({
  type: SHOW_REQUEST_TUTORIAL_BANNER_V2,
  payload: showTutorial,
})

export const showApproverTutorialBannerV2 = showTutorial => ({
  type: SHOW_APPROVER_TUTORIAL_BANNER_V2,
  payload: showTutorial,
})
