import React from 'react'

import PropTypes from 'prop-types'
import debounce from 'debounce-promise'
import { useTranslation } from 'react-i18next'
import { searchProviders } from 'Services/api'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import { ReactComponent as SearchIcon } from 'Assets/Icons/Lupa.svg'

import { selectStyles } from './styled'

const ProvidersSearchInput = ({ value, onChange, isMulti }) => {
  const { t } = useTranslation()

  const mapProvider = provider => ({
    ...provider,
    value: provider.key,
    label: provider.content,
  })

  const valueMapped = isMulti ? value.map(mapProvider) : value

  const loadOptions = debounce(async inputValue => {
    const data = await searchProviders(inputValue)
    return data.map(mapProvider)
  }, 250)

  const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  )

  const noOptionsMessage = ({ inputValue }) => (inputValue ? t('noResults') : t('noResultsPlaceholder'))

  return (
    <AsyncSelect
      isClearable
      isMulti={isMulti}
      cacheOptions={false}
      defaultOptions
      loadOptions={loadOptions}
      styles={selectStyles}
      noOptionsMessage={noOptionsMessage}
      loadingMessage={() => t('loading')}
      placeholder={t('searchProvider')}
      onChange={onChange}
      value={valueMapped}
      components={{ DropdownIndicator, IndicatorSeparator: null }}
    />
  )
}

ProvidersSearchInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isMulti: PropTypes.bool,
}

ProvidersSearchInput.defaultProps = {
  onChange: () => null,
  value: [],
  isMulti: true,
}

export default ProvidersSearchInput
