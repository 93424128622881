import styled from 'styled-components'
import colors from 'Assets/colors'
import Button from 'Components/atoms/button'

export const FilteredCenterButton = styled(Button)`
  background-color: ${colors.secondary};
  color: ${colors.white};
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`
