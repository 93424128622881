import {
  FETCH_CENTERS_LIST,
  FETCH_CENTER_ORDERS,
  FETCH_FILTER_CENTERS,
  FETCH_FILTER_ORIGIN_CENTERS,
  FETCH_FILTER_PROVIDERS,
  FETCH_NO_COMPLIANCES,
  FETCH_REQUEST_ORDERS,
  FETCH_STATS,
  SET_CENTERS_LIST,
  SET_CENTER_ORDERS,
  SET_FILTER_CENTERS,
  SET_FILTER_ORIGIN_CENTERS,
  SET_FILTER_PROVIDERS,
  SET_IS_CREATE_QA,
  SET_IS_LOADING_CENTERS,
  SET_IS_LOADING_NO_COMPLIANCES,
  SET_IS_LOADING_ORDERS,
  SET_IS_REPEATING_ORDER,
  SET_NO_COMPLIANCES,
  SET_NO_COMPLIANCES_FILTERS,
  SET_ORDERS_FILTERS,
  SET_REQUEST_ORDERS,
  SET_SELECTED_CENTER,
  SET_STATS,
  SET_QA_FILTERS,
} from './types'

export const fetchCentersList = params => ({
  type: FETCH_CENTERS_LIST,
  payload: params,
})

export const fetchCenterOrders = (centerId, params) => ({
  type: FETCH_CENTER_ORDERS,
  payload: { centerId, params },
})

export const fetchFilterCenters = params => ({
  type: FETCH_FILTER_CENTERS,
  payload: params,
})

export const fetchFilterOriginCenters = params => ({
  type: FETCH_FILTER_ORIGIN_CENTERS,
  payload: params,
})

export const fetchFilterProviders = params => ({
  type: FETCH_FILTER_PROVIDERS,
  payload: params,
})

export const fetchNoCompliances = params => ({
  type: FETCH_NO_COMPLIANCES,
  payload: params,
})

export const fetchRequestOrders = (centerId, orders) => ({
  type: FETCH_REQUEST_ORDERS,
  payload: { centerId, orders },
})

export const fetchStats = () => ({
  type: FETCH_STATS,
})

export const setCentersList = centers => ({
  type: SET_CENTERS_LIST,
  payload: centers,
})

export const setCenterOrders = (centerId, orders) => ({
  type: SET_CENTER_ORDERS,
  payload: { centerId, orders },
})

export const setFilterCenters = filterCenters => ({
  type: SET_FILTER_CENTERS,
  payload: filterCenters,
})

export const setFilterOriginCenters = filterOriginCenters => ({
  type: SET_FILTER_ORIGIN_CENTERS,
  payload: filterOriginCenters,
})

export const setFilterProviders = filterProviders => ({
  type: SET_FILTER_PROVIDERS,
  payload: filterProviders,
})

export const setIsCreateQa = isCreatingQa => ({
  type: SET_IS_CREATE_QA,
  payload: isCreatingQa,
})

export const setIsLoadingCenters = isLoadingCenters => ({
  type: SET_IS_LOADING_CENTERS,
  payload: isLoadingCenters,
})

export const setIsLoadingNoCompliances = isLoading => ({
  type: SET_IS_LOADING_NO_COMPLIANCES,
  payload: isLoading,
})

export const setIsLoadingOrders = (centerId, isLoading) => ({
  type: SET_IS_LOADING_ORDERS,
  payload: { centerId, isLoading },
})

export const setIsRepeatingOrder = isRepeatingOrder => ({
  type: SET_IS_REPEATING_ORDER,
  payload: isRepeatingOrder,
})

export const setNoCompliances = noCompliances => ({
  type: SET_NO_COMPLIANCES,
  payload: noCompliances,
})

export const setNoCompliancesFilters = filters => ({
  type: SET_NO_COMPLIANCES_FILTERS,
  payload: filters,
})

export const setOrdersFilters = value => ({
  type: SET_ORDERS_FILTERS,
  payload: value,
})

export const setQAFilters = value => ({
  type: SET_QA_FILTERS,
  payload: value,
})

export const setRequestOrders = (centerId, requestOrders) => ({
  type: SET_REQUEST_ORDERS,
  payload: { centerId, requestOrders },
})

export const setSelectedCenter = center => ({
  type: SET_SELECTED_CENTER,
  payload: center,
})

export const setStats = stats => ({
  type: SET_STATS,
  payload: stats,
})
