import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import map from 'lodash/map'
import { fetchCart } from 'Redux/carts/actions'
import RequestOrderLoader from 'Components/molecules/requestOrderPlaceholder'
import ProviderProducts from 'Components/molecules/requestOrderCartProducts'

import { Root, Content, CartData } from './styled'

const RequestOrderCart = () => {
  const dispatch = useDispatch()

  const cart = useSelector(state => state.carts.cart)
  const requestOrder = useSelector(state => state.carts.requestOrder)
  const isFetchingCart = useSelector(state => state.carts.isFetchingCart)

  useEffect(() => {
    if (requestOrder?.id) {
      dispatch(fetchCart(requestOrder?.id, requestOrder?.state))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestOrder?.id])

  return (
    <Root>
      {isFetchingCart || !cart ? (
        <RequestOrderLoader />
      ) : (
        <Content>
          <CartData>
            {map(cart?.data, (provider, key) => (
              <ProviderProducts key={key} provider={provider} state={requestOrder?.state} />
            ))}
          </CartData>
        </Content>
      )}
    </Root>
  )
}

export default RequestOrderCart
