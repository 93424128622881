import { all, call, put, takeEvery, takeLatest, delay, fork, takeLeading } from 'redux-saga/effects'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import i18next from 'i18next'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import {
  checkCookie,
  login,
  getCenters,
  getMe,
  configureToken,
  loginv2,
  resetApp,
  getFeatureFlagConfig,
} from 'Services/api'
import * as Sentry from 'Services/sentry'
import routes from 'Config/routes'

import {
  setToken,
  setErrorToken,
  setErrorCenterList,
  setCenterList,
  setCenterCount,
  setCenter,
  setUser,
  hasOnlyOneCenter,
  setRefreshToken,
  setErrorLogin,
  doLogout,
  setIsLoadingCenter,
  setIsLoadingLogin,
  setIsLoadingMsal,
  setFeatureFlagConfig,
  setIsInitialFecthCenters,
} from './actions'
import {
  LOGIN,
  TOKEN_REQUESTED,
  FETCH_AUTOCOMPLETE_CENTERS,
  INITIAL_CENTER_REQUEST,
  LOGOUT_USER,
  SET_USER,
  LOGIN_MSAL,
  FETCH_FEATURE_FLAG_CONFIG,
} from './types'

export function* fetchToken() {
  try {
    yield call(checkCookie)
    const token = Cookies.get('RESSSIONID')
    yield put(setToken(token))
  } catch (e) {
    yield put(setErrorToken(e))
    Sentry.captureException(e)
  }
}

export function* checkLogin({ token, refreshToken, username, msal }) {
  configureToken(token, refreshToken)

  // remove elemets from tutorial
  sessionStorage.removeItem('mode')
  sessionStorage.removeItem('tutorial')
  sessionStorage.removeItem('prevCenter')

  // check valid users
  const me = yield call(getMe)
  const { data: centers } = yield call(getCenters)
  if (isEmpty(get(me, 'roles'))) {
    throw new Error(i18next.t('userInvalidErrorMessage', { username }))
  }
  if (isEmpty(centers)) {
    throw new Error(i18next.t('userNoCentersErrorMessage', { username }))
  }

  // store user session
  yield put(setUser({ ...me, msal }))
  yield put(setToken(token))
  yield put(setRefreshToken(refreshToken))
}

export function* getTokenAlt(action) {
  try {
    yield put(setIsLoadingLogin(true))
    const params = get(action, 'params', {})
    const { username } = params
    const { token = '', refresh_token = '' } = yield call(login, params)
    yield checkLogin({ username, token, refreshToken: refresh_token, msal: false })
  } catch (e) {
    Sentry.captureException(e)
    let error = e || new Error(i18next.t('errorLogin'))
    if (e?.response?.status === 401) {
      error = new Error(i18next.t('errorCredentials'))
    }
    yield put(setErrorLogin(error))
  } finally {
    yield put(setIsLoadingLogin(false))
  }
}

export function* getTokenMsal(action) {
  try {
    yield put(setIsLoadingMsal(true))
    yield put(setIsLoadingLogin(true))
    const accessToken = get(action, 'params.accessToken', '')
    const username = get(action, 'params.username', '')
    const { token = '', refresh_token = '' } = yield call(loginv2, accessToken)
    yield checkLogin({ username, token, refreshToken: refresh_token, msal: true })
  } catch (e) {
    Sentry.captureException(e)
    const error = e || new Error(i18next.t('errorLogin'))
    yield put(setErrorLogin(error))
  } finally {
    yield put(setIsLoadingLogin(false))
    yield put(setIsLoadingMsal(false))
  }
}

export function* logoutUser() {
  try {
    yield put(doLogout())
    yield delay(500)
    sessionStorage.removeItem('mode')
    sessionStorage.removeItem('tutorial')
    sessionStorage.removeItem('prevCenter')
    resetApp()
    window.location.replace(routes.root)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export function* fetchCenters(action) {
  try {
    yield put(setIsLoadingCenter(true))
    const params = get(action, 'params', {})
    const { data = [], count = 0 } = yield call(getCenters, params)
    yield put(setCenterList(data))
    yield put(setCenterCount(count))
    yield put(setIsLoadingCenter(false))
    if (parseInt(count, 10) === 1 && !params.search) {
      yield put(setCenter(data[0]))
      yield put(hasOnlyOneCenter(true))
    }
  } catch (e) {
    Sentry.captureException(e)
    toast.error(e?.response?.data?.message || i18next.t('errorFetchingCenters'))
    yield put(setErrorCenterList(e))
    yield put(setIsLoadingCenter(false))
  }
}

export function* debounceFetchCenters(action) {
  yield delay(500)
  yield fork(fetchCenters, action)
}

export function* initialFecthCenters(action) {
  yield put(setIsInitialFecthCenters(true))
  yield fork(fetchCenters, action)
}

export function* handleSetMe(action) {
  yield delay(100)
  const me = get(action, 'payload', {})
  Sentry.setUser(me)
}

export function* fetchFeatureFlagConfigSaga() {
  try {
    const result = yield call(getFeatureFlagConfig)
    yield put(setFeatureFlagConfig(result))
  } catch (e) {
    Sentry.captureException(e)
  }
}

function* watchToken() {
  yield takeEvery(TOKEN_REQUESTED, fetchToken)
}

function* watchTokenAlt() {
  yield takeLeading(LOGIN, getTokenAlt)
}

function* watchGetTokenMsal() {
  yield takeLeading(LOGIN_MSAL, getTokenMsal)
}

function* watchLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser)
}

function* watchInitialCenterRequest() {
  yield takeLeading(INITIAL_CENTER_REQUEST, initialFecthCenters)
}

function* debounceAutocompleteCenters() {
  yield takeLatest(FETCH_AUTOCOMPLETE_CENTERS, debounceFetchCenters)
}

function* watchSetMe() {
  yield takeLeading(SET_USER, handleSetMe)
}

function* watchFeatureFlag() {
  yield takeEvery(FETCH_FEATURE_FLAG_CONFIG, fetchFeatureFlagConfigSaga)
}

export default function* rootAuthSaga() {
  yield all([
    watchToken(),
    watchTokenAlt(),
    watchGetTokenMsal(),
    debounceAutocompleteCenters(),
    watchInitialCenterRequest(),
    watchLogout(),
    watchSetMe(),
    watchFeatureFlag(),
  ])
}
