import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Modal } from 'Components/atoms'
import { ReactComponent as TicketLoading } from 'Assets/Icons/TicketLoading.svg'

import { Title, Message, SvgContainer } from './styled'

const ModalLoading = ({ showModal, setShowModal, message, title, width, height }) => {
  const { t } = useTranslation()

  const titleTxt = title || t('loadingTitle')
  const messageTxt = message || t('loadingBody')
  return (
    <Modal isOpen={showModal} setIsOpen={setShowModal} width={width} height={height} showClose={false}>
      <div>
        <Title>{titleTxt}</Title>
        <Message>{messageTxt}</Message>
        <SvgContainer>
          <TicketLoading style={{ width: '160px', height: '160px' }} />
        </SvgContainer>
      </div>
    </Modal>
  )
}

ModalLoading.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  message: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}

ModalLoading.defaultProps = {
  showModal: false,
  setShowModal: () => {},
  message: '',
  title: '',
  width: '',
  height: '',
}

export default ModalLoading
