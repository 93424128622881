import React, { useEffect, useRef, useState, useMemo, createRef } from 'react'

import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { BigTitle, TextInput, RegularText } from 'Components/atoms'
import ArrowDropdown from 'Assets/Icons/ArrowDropdown'
import Arrow from 'Assets/Icons/Arrow'
import { AuthTypes, AuthActions } from 'Redux/auth'
import classNames from 'classnames'
import _ from 'lodash'
import { KEY_CODES, LIMIT_REQUEST_CENTERS } from 'Config/constants'

import { CentersContainer, StyledText, Center } from './styled'

const OriginCenterSelector = ({ title, onSelectCenter, centers }) => {
  const [inputValue, setInputValue] = useState('')
  const [hoveredCenter, setHoveredCenter] = useState('')
  const { t } = useTranslation()

  const InputRef = useRef()
  const refs = useMemo(() => Array.from({ length: centers.length }).map(() => createRef()), [centers])
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => InputRef.current?.focus(), 100)
  }, [])

  useEffect(() => {
    dispatch({
      type: AuthTypes.FETCH_AUTOCOMPLETE_CENTERS,
      params: { search: inputValue, limit: LIMIT_REQUEST_CENTERS },
    })
  }, [inputValue, dispatch])

  useEffect(() => {
    if (hoveredCenter !== '' && refs[hoveredCenter] && refs[hoveredCenter].current) {
      refs[hoveredCenter].current.scrollIntoView()
    }
  }, [hoveredCenter, refs])

  useEffect(() => {
    setHoveredCenter('')
  }, [centers])

  const onChangeCenter = e => setInputValue(e.target.value)

  const onKeyDownInput = event => {
    const char = event.which || event.keyCode
    if (_.isEmpty(centers)) return
    switch (char) {
      case KEY_CODES.UP_ARROW:
        if (hoveredCenter === '' || hoveredCenter === 0) {
          setHoveredCenter(centers.length - 1)
          break
        }
        setHoveredCenter(value => value - 1)
        break
      case KEY_CODES.DOWN_ARROW:
        if (hoveredCenter === '' || hoveredCenter === centers.length - 1) {
          setHoveredCenter(0)
          break
        }
        setHoveredCenter(value => value + 1)
        break
      case KEY_CODES.ENTER:
        if (hoveredCenter === '') break
        dispatch(AuthActions.setCenter(centers[hoveredCenter]))
        break
      default:
        break
    }
  }

  return (
    <>
      <BigTitle margin='0 0 1rem'>{t(title)}</BigTitle>
      <RegularText margin='0 0 1.5rem'>{t('accessOriginCenterMessage')}</RegularText>
      <TextInput
        value={inputValue}
        onChange={onChangeCenter}
        refProp={InputRef}
        placeholder={t('selectAOriginCenter')}
        Icon={props => <ArrowDropdown {...props} />}
        borderRadius='8px 8px 0 0'
        onKeyDown={onKeyDownInput}
      />
      <div style={{ height: 0, border: '1px solid rgba(51, 51, 51, 0.24)', position: 'relative' }}>
        <CentersContainer>
          {centers.map(({ name, id }, index) => (
            <Center
              className={classNames({ selected: index === hoveredCenter })}
              key={id}
              onClick={() => {
                onSelectCenter({ name, id })
              }}
              ref={refs[index]}
            >
              <StyledText padding='8px 0'>{name}</StyledText>
              <Arrow className='icon-arrow' />
            </Center>
          ))}
        </CentersContainer>
      </div>
    </>
  )
}

OriginCenterSelector.defaultProps = {
  title: 'changeOriginCenter',
  onSelectCenter: () => {},
  centers: [],
}

OriginCenterSelector.propTypes = {
  title: PropTypes.string,
  onSelectCenter: PropTypes.func,
  centers: PropTypes.array,
}

export default OriginCenterSelector
