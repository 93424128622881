import { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { addListProduct, setErrorAdd } from 'Redux/lists/actions'
import useShoppingLists from 'hooks/shoppingLists/useShoppingLists'

const useShoppingListsAddProduct = ({ setShowModal, productId }) => {
  const { isFetching } = useShoppingLists()
  const lists = useSelector(s => s.lists.lists)
  const error = useSelector(s => s.lists.errorAdd)
  const dispatch = useDispatch()

  const [inputValue, setInputValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleSuccess = () => {
    setIsLoading(false)
    setInputValue('')
    setShowModal()
  }

  const onClose = () => dispatch(setErrorAdd(''))

  const handleChange = e => {
    dispatch(setErrorAdd(''))
    setIsLoading(true)
    setInputValue(e.target.value)
    dispatch(
      addListProduct({
        productId,
        listId: e.target.value,
        successCallback: () => handleSuccess(),
        errorCallback: () => setIsLoading(false),
      })
    )
  }

  return {
    isFetching,
    inputValue,
    lists,
    error,
    isLoading,
    handleChange,
    onClose,
  }
}

export default useShoppingListsAddProduct
