import React from 'react'

import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import map from 'lodash/map'
import { NumberFormat } from 'Components/atoms'

import { ProviderProductsCardContainer } from '../styled'

import ProviderProduct from './providerProduct'

const ProviderProductsCard = ({ name, total, amount, products, onClickRemove, onClickRemoveProduct }) => {
  const { t } = useTranslation()

  const showPrice = useSelector(state => state.auth.selectedCenter?.showPrice || false)

  return (
    <ProviderProductsCardContainer>
      <div className='provider-header'>
        <span className='provider-name'>{name}</span>
        <div>
          {showPrice && (
            <span className='provider-total'>
              <NumberFormat value={total} />
            </span>
          )}

          <span className='provider-amount'>{`${amount} ${t(amount === 1 ? 'product' : 'products')}`}</span>
        </div>
      </div>
      <div className='provider-content'>
        <div className='provider-product-header'>
          <span>{t('product')}</span>
          {showPrice && <span>{t('total')}</span>}
        </div>
        {map(products, product => (
          <ProviderProduct
            key={product.id}
            product={product}
            onClickRemove={onClickRemove}
            onClickRemoveProduct={onClickRemoveProduct}
          />
        ))}
      </div>
    </ProviderProductsCardContainer>
  )
}

ProviderProductsCard.defaultProps = {
  name: '',
  total: 0,
  amount: 0,
  products: [],
  onClickRemove: () => null,
  onClickRemoveProduct: () => null,
}

ProviderProductsCard.propTypes = {
  name: PropTypes.string,
  total: PropTypes.number,
  amount: PropTypes.number,
  products: PropTypes.array,
  onClickRemove: PropTypes.func,
  onClickRemoveProduct: PropTypes.func,
}

export default ProviderProductsCard
