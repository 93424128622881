import { useDispatch, useSelector } from 'react-redux'
import { setFilterProvider } from 'Redux/approver/actions'

const useApproverCartsProviderFilter = () => {
  const dispatch = useDispatch()

  const value = useSelector(state => state.approver.filterProviders)

  const onChange = providers => dispatch(setFilterProvider(providers))

  return { value, onChange }
}

export default useApproverCartsProviderFilter
