import React from 'react'

import PropTypes from 'prop-types'

import { BigTitle } from './styled'

const Title = ({ children, margin, textAlign }) => (
  <BigTitle margin={margin} textAlign={textAlign}>
    {children}
  </BigTitle>
)

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  margin: PropTypes.string,
  textAlign: PropTypes.string,
}

Title.defaultProps = {
  children: '',
  margin: '',
  textAlign: '',
}

export default Title
