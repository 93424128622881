import React from 'react'

import PropTypes from 'prop-types'
import ModalEditShoppingList from 'Components/molecules/modalEditShoppingList'
import useShoppingListsSingleModal from 'hooks/shoppingLists/useShoppingListsSingleModal'

import { SingleContainer } from '../styled'

import AddCartButton from './addCartButton'
import SingleModalLoader from './SingleModalLoader'
import ProvidersList from './providersLists'
import SingleModalTitle from './singleModalTitle'
import BackToListsButton from './backToListButton'

const SingleModal = ({ onClickRemove, onClickRemoveProduct }) => {
  const {
    selectedList,
    isFetchingProducts,
    showEditModal,
    onClickEdit,
    setShowEditModal,
  } = useShoppingListsSingleModal()

  const handleClickRemove = () => onClickRemove(selectedList)

  if (!selectedList) return null

  return (
    <>
      <SingleContainer>
        <div className='header'>
          <BackToListsButton />
        </div>
        <div className='content'>
          <SingleModalTitle onClickEdit={onClickEdit} onClickRemove={handleClickRemove} />
          {isFetchingProducts ? (
            <SingleModalLoader />
          ) : (
            <ProvidersList onClickRemove={handleClickRemove} onClickRemoveProduct={onClickRemoveProduct} />
          )}
        </div>
        <div className='footer'>
          <AddCartButton item={selectedList} />
        </div>
      </SingleContainer>
      <ModalEditShoppingList showModal={showEditModal} setShowModal={setShowEditModal} />
    </>
  )
}

SingleModal.defaultProps = {
  onClickRemove: () => null,
  onClickRemoveProduct: () => null,
}

SingleModal.propTypes = {
  onClickRemove: PropTypes.func,
  onClickRemoveProduct: PropTypes.func,
}

export default SingleModal
