import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { fetchListProducts } from 'Redux/lists/actions'

const useShoppingListsSingleModal = () => {
  const dispatch = useDispatch()

  const selectedList = useSelector(s => s.lists.data)
  const isFetchingProducts = useSelector(s => s.lists.isFetchingProducts)

  const [showEditModal, setShowEditModal] = useState(false)

  useEffect(() => {
    if (selectedList) {
      dispatch(fetchListProducts(selectedList))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList])

  const onClickEdit = () => setShowEditModal(true)

  return {
    selectedList,
    isFetchingProducts,
    showEditModal,
    onClickEdit,
    setShowEditModal,
  }
}

export default useShoppingListsSingleModal
