import {
  SEARCH_PURCHASES,
  ERROR_SEARCH,
  ERROR_PRODUCTS,
  SET_PRODUCTS,
  SET_PRODUCTS_COUNT,
  SET_SELECTED_CATEGORIES,
  IS_LOADING_PRODUCTS,
  SET_CATEGORIES,
  ERROR_CATEGORIES,
  SET_PROVIDERS,
  SET_ERROR_PROVIDERS,
  IS_LOADING_PROVIDERS,
  IS_LOADING_CATEGORIES,
  SET_PROVIDER_CATEGORIES,
  EDIT_CART,
  SET_LOADING_PRODUCT,
  SET_PRODUCT,
  SET_CART,
  CART_INITIATED,
  SET_BUDGET,
  SET_ERROR_CART,
  MODAL_PRODUCT_OPEN,
  MODAL_ERROR_CART,
  IS_LOADING_CART,
  IS_LOADING_BUDGET,
  ORIGIN_CENTERS,
  MODAL_PROVIDER_OPEN,
  SET_SELECTED_PROVIDER,
  SET_ORIGIN_CENTER,
  SET_CART_DELIVERIES,
  FETCH_CART,
  PRE_CONFIRM_CART,
  CART_CONFIRMED,
  SET_DATES_UPDATED,
  IS_LOADING_ORDERS,
  SET_ERROR_ORDERS,
  IS_LOADING_PROVIDER_ORDERS,
  SET_ERROR_PROVIDER_ORDERS,
  SET_HISTORIC_PROVIDERS,
  SET_HISTORIC_CENTERS,
  IS_LOADING_REPEAT_ORDER,
  MODAL_ERROR_REPEAT_ORDER,
  SET_PENDING_PROVIDER_ORDERS,
  SET_CATEGORIES_HABITUAL,
  IS_LOADING_CATEGORIES_HABITUAL,
  ERROR_CATEGORIES_HABITUAL,
  SET_SELECTED_CATEGORIES_HABITUAL,
  IS_LOADING_HABITUAL_PRODUCTS,
  SET_HABITUAL_PRODUCTS_COUNT,
  SET_HABITUAL_PRODUCTS,
  ERROR_HABITUAL_PRODUCTS,
  SET_SHOW_ONLY_ONE_PRODUCT,
  SET_PREVIUS_ORDER_PRODUCTS,
  IS_LOADING_PREVIUS_ORDER,
  ERROR_PREVIUS_ORDER,
  SET_PREVIUS_ORDER,
  SET_QA_FORM,
  SET_HABITUAL_PRODUCTS_LAST_REFERENCE,
  IS_UPDATING_CART,
  SET_IS_EDITING_CART,
  SET_IS_LOADING_PRECONFIRM,
  ADD_PRODUCT_TO_CART,
  FETCH_REPEAT_ORDER,
  SET_ADDING_PRODUCT_QUEUE,
  SET_CONFIRMED_CART,
  CHECK_CONFIRMED_CART,
  SET_LOADING_IS_RELOADING,
  SET_IS_RELOADING,
  SET_ORDERS,
  SET_PROVIDER_ORDERS,
  SET_FAILED_RECEPTIONS,
  SET_ORDER_AND_SORT,
} from './types'

export const setMainSearch = search => ({
  type: SEARCH_PURCHASES,
  payload: search,
})

export const setErrorSearch = err => ({
  type: ERROR_SEARCH,
  payload: err,
})

export const setErrorProducts = err => ({
  type: ERROR_PRODUCTS,
  payload: err,
})

export const setProducts = products => ({
  type: SET_PRODUCTS,
  payload: products,
})

export const setProductsCount = count => ({
  type: SET_PRODUCTS_COUNT,
  payload: count,
})

export const setSelectedCategories = payload => ({
  type: SET_SELECTED_CATEGORIES,
  payload,
})

export const setLoadingProducts = payload => ({
  type: IS_LOADING_PRODUCTS,
  payload,
})

export const setCategories = payload => ({
  type: SET_CATEGORIES,
  payload,
})

export const setErrorCategories = payload => ({
  type: ERROR_CATEGORIES,
  payload,
})

export const setProviders = payload => ({
  type: SET_PROVIDERS,
  payload,
})

export const setErrorProviders = payload => ({
  type: SET_ERROR_PROVIDERS,
  payload,
})

export const setLoadingProviders = payload => ({
  type: IS_LOADING_PROVIDERS,
  payload,
})

export const setLoadingCategories = payload => ({
  type: IS_LOADING_CATEGORIES,
  payload,
})

export const setProviderCategories = payload => ({
  type: SET_PROVIDER_CATEGORIES,
  payload,
})

export const editCart = (id, payload) => ({
  type: EDIT_CART,
  id,
  payload,
})

export const addProductToCart = (id, payload) => ({
  type: ADD_PRODUCT_TO_CART,
  id,
  payload,
})

export const setLoadingProduct = payload => ({
  type: SET_LOADING_PRODUCT,
  payload,
})

export const setProduct = payload => ({
  type: SET_PRODUCT,
  payload,
})

export const setCart = payload => ({
  type: SET_CART,
  payload,
})

export const setDeliveries = payload => ({
  type: SET_CART_DELIVERIES,
  payload,
})

export const setOriginCenter = payload => ({
  type: SET_ORIGIN_CENTER,
  payload,
})

export const initiateCart = payload => ({
  type: CART_INITIATED,
  payload,
})

export const setBudget = payload => ({
  type: SET_BUDGET,
  payload,
})

export const setErrorCart = payload => ({
  type: SET_ERROR_CART,
  payload,
})

export const setModalProductOpen = payload => ({
  type: MODAL_PRODUCT_OPEN,
  payload,
})

export const setModalErrorCart = payload => ({
  type: MODAL_ERROR_CART,
  payload,
})

export const setIsLoadingCart = payload => ({
  type: IS_LOADING_CART,
  payload,
})

export const setIsLoadingBudget = payload => ({
  type: IS_LOADING_BUDGET,
  payload,
})

export const setOriginCenters = payload => ({
  type: ORIGIN_CENTERS,
  payload,
})

export const setModalProviderOpen = payload => ({
  type: MODAL_PROVIDER_OPEN,
  payload,
})

export const setSelectedProvider = payload => ({
  type: SET_SELECTED_PROVIDER,
  payload,
})

export const fetchCart = () => ({
  type: FETCH_CART,
})

export const preConfirmCart = () => ({
  type: PRE_CONFIRM_CART,
})

export const setDatesUpdated = value => ({
  type: SET_DATES_UPDATED,
  payload: value,
})

export const setCartConfirmed = payload => ({
  type: CART_CONFIRMED,
  payload,
})

export const setOrders = search => ({
  type: SET_ORDERS,
  payload: search,
})

export const setIsLoadingOrders = search => ({
  type: IS_LOADING_ORDERS,
  payload: search,
})

export const setErrorOrders = search => ({
  type: SET_ERROR_ORDERS,
  payload: search,
})

export const setProviderOrders = search => ({
  type: SET_PROVIDER_ORDERS,
  payload: search,
})

export const setIsLoadingProviderOrders = search => ({
  type: IS_LOADING_PROVIDER_ORDERS,
  payload: search,
})

export const setErrorProviderOrders = value => ({
  type: SET_ERROR_PROVIDER_ORDERS,
  payload: value,
})

export const setHistoricProviders = search => ({
  type: SET_HISTORIC_PROVIDERS,
  payload: search,
})

export const setHistoricCenters = search => ({
  type: SET_HISTORIC_CENTERS,
  payload: search,
})

export const setLoadingRepeatOrder = value => ({
  type: IS_LOADING_REPEAT_ORDER,
  payload: value,
})

export const setModalRepeatErrors = value => ({
  type: MODAL_ERROR_REPEAT_ORDER,
  payload: value,
})

export const setNumProviderPendingOrders = value => ({
  type: SET_PENDING_PROVIDER_ORDERS,
  payload: value,
})

export const setLoadingCategoriesHabitual = value => ({
  type: IS_LOADING_CATEGORIES_HABITUAL,
  payload: value,
})

export const setErrorCategoriesHabitual = payload => ({
  type: ERROR_CATEGORIES_HABITUAL,
  payload,
})

export const setCategoriesHabitual = payload => ({
  type: SET_CATEGORIES_HABITUAL,
  payload,
})

export const setSelectedCategoriesHabitual = payload => ({
  type: SET_SELECTED_CATEGORIES_HABITUAL,
  payload,
})

export const setErrorHabitualProducts = err => ({
  type: ERROR_HABITUAL_PRODUCTS,
  payload: err,
})

export const setHabitualProducts = products => ({
  type: SET_HABITUAL_PRODUCTS,
  payload: products,
})

export const setHabitualProductsLastReference = lastReference => ({
  type: SET_HABITUAL_PRODUCTS_LAST_REFERENCE,
  payload: lastReference,
})

export const setHabitualProductsCount = count => ({
  type: SET_HABITUAL_PRODUCTS_COUNT,
  payload: count,
})

export const setLoadingHabitualProducts = payload => ({
  type: IS_LOADING_HABITUAL_PRODUCTS,
  payload,
})

export const setShowOnlyOneProducts = payload => ({
  type: SET_SHOW_ONLY_ONE_PRODUCT,
  payload,
})

export const setPreviusOrderProducts = payload => ({
  type: SET_PREVIUS_ORDER_PRODUCTS,
  payload,
})

export const setIsLoadingPreviusOrder = payload => ({
  type: IS_LOADING_PREVIUS_ORDER,
  payload,
})

export const setErrorPreviusOrder = payload => ({
  type: ERROR_PREVIUS_ORDER,
  payload,
})

export const setPreviusOrder = payload => ({
  type: SET_PREVIUS_ORDER,
  payload,
})

export const setQaForm = value => ({
  type: SET_QA_FORM,
  payload: value,
})

export const setIsUpdatingCart = value => ({
  type: IS_UPDATING_CART,
  payload: value,
})

export const setIsEditingCart = value => ({
  type: SET_IS_EDITING_CART,
  payload: value,
})

export const setILoadingPreconfirm = value => ({
  type: SET_IS_LOADING_PRECONFIRM,
  payload: value,
})

export const fetchRepeatOrder = value => ({
  type: FETCH_REPEAT_ORDER,
  params: value,
})

export const setAddingProductQueue = (id, value) => ({
  type: SET_ADDING_PRODUCT_QUEUE,
  id,
  payload: value,
})

export const checkConfirmedCart = payload => ({
  type: CHECK_CONFIRMED_CART,
  payload,
})

export const setConfirmedCart = payload => ({
  type: SET_CONFIRMED_CART,
  payload,
})

export const setIsLoadingReloading = payload => ({
  type: SET_LOADING_IS_RELOADING,
  payload,
})

export const setIsReloading = payload => ({
  type: SET_IS_RELOADING,
  payload,
})

export const setFailedReceptions = search => ({
  type: SET_FAILED_RECEPTIONS,
  payload: search,
})

export const setOrderAndSort = value => ({
  type: SET_ORDER_AND_SORT,
  payload: value,
})
