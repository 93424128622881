import axios from 'axios'
import qs from 'qs'

export const getProviderReceivingOrders = (params, centerId, orderId, documentTypeCode, documentCompany) =>
  axios.get(
    `/api/provider-reception-orders/${orderId}/${documentTypeCode}/${documentCompany}?${qs.stringify(params, {
      skipNulls: true,
    })}`,
    { headers: { 'X-Center-Id': centerId } }
  )

export const putProviderReceivingOrders = (payload, centerId, orderId, documentTypeCode, documentCompany) =>
  axios.put(`/api/provider-reception-orders/${orderId}/${documentTypeCode}/${documentCompany}`, payload, {
    headers: { 'X-Center-Id': centerId },
  })

export const confirmDelivery = (centerId, orderId, documentTypeCode, documentCompany, payload) =>
  axios.post(`/api/provider-reception-orders/${orderId}/${documentTypeCode}/${documentCompany}/confirm`, payload, {
    headers: { 'X-Center-Id': centerId },
  })

export const confirmDeliverySQS = (centerId, orderId, documentTypeCode, documentCompany, payload) =>
  axios.post(`/api/v2/provider-reception-orders/${orderId}/${documentTypeCode}/${documentCompany}/confirm`, payload, {
    headers: { 'X-Center-Id': centerId },
  })

export const getProviderReceptionOrdersLimitDate = centerId =>
  axios.get('/api/provider-reception-orders/limit-date', { headers: { 'X-Center-Id': centerId } })

export const putDeliveryQa = (orderId, documentTypeCode, documentCompany, centerId, payload) =>
  axios.post(
    `/api/provider-reception-orders/${orderId}/${documentTypeCode}/${documentCompany}/reception-feedback`,
    payload,
    {
      headers: { 'X-Center-Id': centerId },
    }
  )

export const getDeliveryQa = (orderId, documentTypeCode, documentCompany, centerId) =>
  axios.get(`/api/provider-reception-orders/${orderId}/${documentTypeCode}/${documentCompany}/reception-feedback`, {
    headers: { 'X-Center-Id': centerId },
  })

export const getProviderOrdersQaExcel = (params, centerId) =>
  axios.get(
    `/api/v2/provider-reception-orders/reception-feedback/export?${qs.stringify(params, { skipNulls: true })}`,
    {
      headers: { 'X-Center-Id': centerId },
    }
  )

export const getMulticenterProviderOrdersQaExcel = params =>
  axios.get(
    `/api/provider-reception-orders/multicenter/reception-feedback/export?${qs.stringify(params, {
      skipNulls: true,
    })}`,
    { responseType: 'arraybuffer' }
  )

export const getDeliveryQaExcel = (orderId, documentTypeCode, documentCompany, centerId) =>
  axios.get(
    `/api/v2/provider-reception-orders/${orderId}/${documentTypeCode}/${documentCompany}/reception-feedback/export`,
    {
      headers: { 'X-Center-Id': centerId },
    }
  )

export const getDeliveryErrors = (centerId, params) =>
  axios.get(`/api/v2/provider-reception-orders/confirm-errors?${qs.stringify(params, { skipNulls: true })}`, {
    headers: { 'X-Center-Id': centerId },
  })
