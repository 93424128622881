import styled, { css } from 'styled-components'
import colors from 'Assets/colors'

export const Container = styled.div`
  padding: 2.5rem 1.25rem 1rem;
  height: calc(100vh - 168px);

  ${props =>
    props.$tutorial &&
    css`
      height: calc(100vh - 216px);
    `}
  overflow: auto;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 1rem;
`

export const SubTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 24px;
`

export const TicketListContainer = styled.div``

export const SingleTicketContainer = styled.div`
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  margin-bottom: 24px;
`

export const SingleTicketRefContainer = styled.div`
  &:last-child {
    margin-bottom: 0;
  }
`

export const SingleTicketHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${colors.black};
  border-radius: 8px 8px 0px 0px;
  padding: 8px 16px;
`

export const SingleTicketInfo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.white};
`

export const SingleTicketContent = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 34%;
`

export const ProviderInfoContainer = styled.div`
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  padding: 16px;
`

export const ProviderName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 8px;
`

export const TicketInfoAuxText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40l};
`

export const TicketInfoContainer = styled.div`
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  padding: 16px;
`

export const TicketStatusContainer = styled.div`
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  border-radius: 0px 0px 8px 0px;
`

export const SingleTicketMainText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 8px;
`

export const TicketStatusCombo = styled.div`
  display: flex;
  padding: 4px 0;
`

export const TicketStatusInfoContainer = styled.div`
  margin-left: 16px;
`

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

export const ActionButton = styled.div`
  width: 100%;
  background-color: ${props => (props.disabled ? colors.gray40l : colors.secondary)};
  padding: 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;
  height: 56px;
  width: 64px;
  display: flex;
  justify-content: center;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`
