import React from 'react'

import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ReactComponent as DownloadIcon } from 'Assets/Icons/Descargar.svg'
import { Modal } from 'Components/atoms'

import { Title, Description, ButtonContainer, ButtonLiteral, ActionButton } from './styled'

const ModalMulticenterDownload = ({
  canDownloadCatalog,
  canDownloadOrder,
  canDownloadQA,
  isSingleOrder,
  showModal,
  setShowModal,
  onDownloadCatalog,
  onDownloadOrder,
  onDownloadQa,
  isLoadingOrders,
  isLoadingQA,
  isLoadingCatalog,
}) => {
  const { t } = useTranslation()

  return (
    <Modal displayModal='flex' margin='5rem' isOpen={showModal} setIsOpen={setShowModal} height='30rem'>
      <Box alignSelf='center' width='100%'>
        <Title>{t('downloadMulticenterTitle')}</Title>
        {!isSingleOrder && <Description>{t('downloadMulticenterDescription')}</Description>}

        {canDownloadOrder && (
          <ButtonContainer>
            <ActionButton onClick={onDownloadOrder}>
              {isLoadingOrders ? <CircularProgress size='1rem' color='secondary' /> : <DownloadIcon />}
              <ButtonLiteral>{isSingleOrder ? t('downloadOrder') : t('downloadOrdersList')}</ButtonLiteral>
            </ActionButton>
          </ButtonContainer>
        )}
        {canDownloadQA && (
          <ButtonContainer>
            <ActionButton onClick={onDownloadQa}>
              {isLoadingQA ? <CircularProgress size='1rem' color='secondary' /> : <DownloadIcon />}
              <ButtonLiteral>{t('downloadQA')}</ButtonLiteral>
            </ActionButton>
          </ButtonContainer>
        )}
        {canDownloadCatalog && (
          <ButtonContainer>
            <ActionButton onClick={onDownloadCatalog}>
              {isLoadingCatalog ? <CircularProgress size='1rem' color='secondary' /> : <DownloadIcon />}
              <ButtonLiteral>{t('downloadCatalog')}</ButtonLiteral>
            </ActionButton>
          </ButtonContainer>
        )}
      </Box>
    </Modal>
  )
}

ModalMulticenterDownload.propTypes = {
  canDownloadCatalog: PropTypes.bool,
  canDownloadOrder: PropTypes.bool,
  canDownloadQA: PropTypes.bool,
  isSingleOrder: PropTypes.bool,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onDownloadOrder: PropTypes.func,
  onDownloadQa: PropTypes.func,
  onDownloadCatalog: PropTypes.func,
  isLoadingOrders: PropTypes.bool,
  isLoadingQA: PropTypes.bool,
  isLoadingCatalog: PropTypes.bool,
}

ModalMulticenterDownload.defaultProps = {
  canDownloadCatalog: false,
  canDownloadOrder: true,
  canDownloadQA: false,
  isSingleOrder: false,
  showModal: false,
  setShowModal: () => {},
  onDownloadOrder: () => {},
  onDownloadQa: () => {},
  onDownloadCatalog: () => {},
  isLoadingOrders: false,
  isLoadingQA: false,
  isLoadingCatalog: false,
}

export default ModalMulticenterDownload
