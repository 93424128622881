import React from 'react'

import PropTypes from 'prop-types'

const Arrow = ({ fill, className }) => (
  <svg className={className} width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M16 14V12H2V0H0V12C0 13.1 0.9 14 2 14H16Z' fill={fill} />
  </svg>
)

Arrow.defaultProps = {
  fill: '#333333',
  className: '',
}

Arrow.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
}

export default Arrow
