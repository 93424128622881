import styled from 'styled-components'

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.colors[props.color]};
  padding-bottom: 1rem;
`

export const Message = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  text-align: center;
  padding-bottom: 1.5rem;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Cancel = styled.div`
  width: 15rem;
  padding: 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  border: 2px solid #333333;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;

  :hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.black};
  }
`

export const Action = styled(Cancel)`
  background-color: ${props => props.theme.colors[props.color]};
  color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors[props.color]};
  transition: all 0.1s;

  :hover {
    opacity: 0.8;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors[props.color]};
  }
`

export const IconContainer = styled.div`
  margin-bottom: 80px;
  margin-top: 40px;
`
