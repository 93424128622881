import React from 'react'

import PropTypes from 'prop-types'
import get from 'lodash/get'
import map from 'lodash/map'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { NumberFormat } from 'Components/atoms'
import ContentLoader from 'react-content-loader'
import { CART_STATUS, PROVIDER_ORDERS_MODIFICATION_TYPE } from 'Config/constants'
import { ReactComponent as RemoveIcon } from 'Assets/Icons/Remove.svg'
import ArrowDropdown from 'Assets/Icons/ArrowDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { cancelRequestOrder, setCart, setRequestOrder } from 'Redux/carts/actions'
import RequestOrderCartToApprove from 'Components/organisms/requestApprovedOrderCart'

import RequestOrderCart from '../requestOrderCart'

import {
  CartRow,
  CartHeader,
  CartHeaderInfo,
  Tag,
  CartBody,
  Request,
  Price,
  CartHeaderDate,
  CartBodyDivider,
  CancelRequestButton,
  User,
  CartHeaderRight,
  SeeProducts,
  CartHeaderSeparator,
} from './styled'

const { PENDING, CANCELLED, APPROVED, REJECTED } = CART_STATUS

const CartsList = ({ data, status }) => {
  const { t } = useTranslation()
  return (
    <div aria-label={t('requestTutorial.stepSix.target')}>
      {map(data, item => (
        <CartItem key={item.id} item={item} status={status} />
      ))}
    </div>
  )
}

CartsList.defaultProps = {
  data: [],
  status: '',
}

CartsList.propTypes = {
  data: PropTypes.array,
  status: PropTypes.oneOf([PENDING, CANCELLED, APPROVED, REJECTED]),
}

const CartItem = ({ item, status }) => {
  const showPrice = useSelector(state => state.auth.selectedCenter?.showPrice || false)
  switch (status) {
    case PENDING:
      return <CartItemPending item={item} status={status} showPrice={showPrice} />
    case APPROVED:
      return <CartItemApproved item={item} status={status} showPrice={showPrice} />
    case REJECTED:
      return <CartItemRejected item={item} status={status} showPrice={showPrice} />
    case CANCELLED:
      return <CartItemCancelled item={item} status={status} showPrice={showPrice} />
    default:
      return null
  }
}

CartItem.defaultProps = {
  item: {},
  status: '',
}

CartItem.propTypes = {
  item: PropTypes.object,
  status: PropTypes.oneOf([PENDING, CANCELLED, APPROVED, REJECTED]),
}

const CartItemPending = ({ item, status, showPrice }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const requestOrder = useSelector(state => state.carts.requestOrder)
  const showProducts = item.id === requestOrder?.id

  const handleClickCancel = () => dispatch(cancelRequestOrder(item?.id))

  return (
    <CartRow aria-label={t('requestTutorial.stepNine.target')}>
      <CartHeader status={status}>
        <CartHeaderInfo>{t('cartPending')}</CartHeaderInfo>
        <RequestDate item={item} />
      </CartHeader>
      <CartBody>
        <RequestId item={item} />
        <DeliveryDate item={item} />
        <CartBodyDivider />
        {showPrice && <OrderAmount item={item} />}
        {showPrice && <OrderTotal item={item} />}
        <CancelRequestButton onClick={handleClickCancel}>
          <span aria-label={t('requestTutorial.stepSeven.target')}>{t('cancelRequest')}</span>
          <RemoveIcon />
        </CancelRequestButton>
        <SeeProductsButton item={item} />
      </CartBody>
      {showProducts && <RequestOrderCartToApprove />}
    </CartRow>
  )
}

CartItemPending.defaultProps = {
  item: {},
  status: '',
  showPrice: false,
}

CartItemPending.propTypes = {
  item: PropTypes.object,
  status: PropTypes.oneOf([PENDING, CANCELLED, APPROVED, REJECTED]),
  showPrice: PropTypes.bool,
}

const CartItemApproved = ({ item, status, showPrice }) => {
  const { t } = useTranslation()

  const requestOrder = useSelector(state => state.carts.requestOrder)
  const showProducts = item.id === requestOrder?.id

  return (
    <CartRow aria-label={t('requestTutorial.stepTwelve.target')}>
      <CartHeader status={status}>
        <CartHeaderInfo>{t('requestOrderStatus.Aprobada')}</CartHeaderInfo>
        <CartHeaderRight>
          <RequestDate item={item} />
          <CartHeaderSeparator />
          <ApprovedDate item={item} />
        </CartHeaderRight>
      </CartHeader>
      <CartBody>
        <RequestId item={item} />
        <DeliveryDate item={item} />
        <UserApproved item={item} />
        <CartBodyDivider />
        {showPrice && <OrderAmount item={item} />}
        {showPrice && <OrderTotal item={item} />}
        <SeeProductsButton item={item} />
      </CartBody>
      {showProducts && <RequestOrderCart />}
    </CartRow>
  )
}

CartItemApproved.defaultProps = {
  item: {},
  status: '',
  showPrice: false,
}

CartItemApproved.propTypes = {
  item: PropTypes.object,
  status: PropTypes.oneOf([PENDING, CANCELLED, APPROVED, REJECTED]),
  showPrice: PropTypes.bool,
}

const CartItemRejected = ({ item, status, showPrice }) => {
  const { t } = useTranslation()

  const requestOrder = useSelector(state => state.carts.requestOrder)
  const showProducts = item.id === requestOrder?.id

  return (
    <CartRow>
      <CartHeader status={status}>
        <CartHeaderInfo>{t('requestOrderStatus.Rechazada')}</CartHeaderInfo>
        <CartHeaderRight>
          <RequestDate item={item} />
          <CartHeaderSeparator />
          <RejectedDate item={item} />
        </CartHeaderRight>
      </CartHeader>
      <CartBody>
        <RequestId item={item} />
        <DeliveryDate item={item} />
        <UserRejected item={item} />
        <CartBodyDivider />
        {showPrice && <OrderAmount item={item} />}
        {showPrice && <OrderTotal item={item} />}
        <SeeProductsButton item={item} />
      </CartBody>
      {showProducts && <RequestOrderCart />}
    </CartRow>
  )
}

CartItemRejected.defaultProps = {
  item: {},
  status: '',
  showPrice: false,
}

CartItemRejected.propTypes = {
  item: PropTypes.object,
  status: PropTypes.oneOf([PENDING, CANCELLED, APPROVED, REJECTED]),
  showPrice: PropTypes.bool,
}

const CartItemCancelled = ({ item, status, showPrice }) => {
  const { t } = useTranslation()

  const requestOrder = useSelector(state => state.carts.requestOrder)
  const showProducts = item.id === requestOrder?.id

  return (
    <CartRow>
      <CartHeader status={status}>
        <CartHeaderInfo>{t('requestOrderStatus.Cancelada')}</CartHeaderInfo>
        <CartHeaderRight>
          <RequestDate item={item} />
          <CartHeaderSeparator />
          <CancelledDate item={item} />
        </CartHeaderRight>
      </CartHeader>
      <CartBody>
        <RequestId item={item} />
        <DeliveryDate item={item} />
        <UserCancelled item={item} />
        <CartBodyDivider />
        {showPrice && <OrderAmount item={item} />}
        {showPrice && <OrderTotal item={item} />}
        <SeeProductsButton item={item} />
      </CartBody>
      {showProducts && <RequestOrderCart />}
    </CartRow>
  )
}

CartItemCancelled.defaultProps = {
  item: {},
  status: '',
  showPrice: false,
}

CartItemCancelled.propTypes = {
  item: PropTypes.object,
  status: PropTypes.oneOf([PENDING, CANCELLED, APPROVED, REJECTED]),
  showPrice: PropTypes.bool,
}

const SeeProductsButton = ({ item }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const requestOrder = useSelector(state => state.carts.requestOrder)
  const showProducts = item.id === requestOrder?.id

  const handleClickSeeProducts = () => {
    dispatch(setCart({}))
    dispatch(setRequestOrder(item))
  }

  const handleClickContractProducts = () => {
    dispatch(setCart({}))
    dispatch(setRequestOrder(null))
  }

  return (
    <SeeProducts
      aria-label={t('requestTutorial.stepEight.target')}
      onClick={showProducts ? handleClickContractProducts : handleClickSeeProducts}
    >
      <ArrowDropdown style={{ transform: showProducts ? 'rotate(180deg)' : 'none' }} />
      <span>{t(showProducts ? 'contractProducts' : 'seeProducts')}</span>
    </SeeProducts>
  )
}

SeeProductsButton.defaultProps = {
  item: {},
}

SeeProductsButton.propTypes = {
  item: PropTypes.object,
}

const RequestDate = ({ item }) => {
  const { t } = useTranslation()

  const requestDate = moment(get(item, 'created')).format('DD/MM/YYYY')

  return <CartHeaderDate>{t('requestDate', { requestDate })}</CartHeaderDate>
}

RequestDate.defaultProps = {
  item: {},
}

RequestDate.propTypes = {
  item: PropTypes.object,
}

const ApprovedDate = ({ item }) => {
  const { t } = useTranslation()

  const approvedDate = moment(get(item, 'statusUpdatedAt')).format('DD/MM/YYYY')
  if (!approvedDate) return null

  return <CartHeaderDate>{t('approvedDate', { approvedDate })}</CartHeaderDate>
}

ApprovedDate.defaultProps = {
  item: {},
}

ApprovedDate.propTypes = {
  item: PropTypes.object,
}

const RejectedDate = ({ item }) => {
  const { t } = useTranslation()

  const rejectedDate = moment(get(item, 'statusUpdatedAt')).format('DD/MM/YYYY')
  if (!rejectedDate) return null

  return <CartHeaderDate>{t('rejectedDate', { rejectedDate })}</CartHeaderDate>
}

RejectedDate.defaultProps = {
  item: {},
}

RejectedDate.propTypes = {
  item: PropTypes.object,
}

const CancelledDate = ({ item }) => {
  const { t } = useTranslation()

  const cancelledDate = moment(get(item, 'statusUpdatedAt')).format('DD/MM/YYYY')
  if (!cancelledDate) return null

  return <CartHeaderDate>{t('cancelledDate', { cancelledDate })}</CartHeaderDate>
}

CancelledDate.defaultProps = {
  item: {},
}

CancelledDate.propTypes = {
  item: PropTypes.object,
}
const UserApproved = ({ item }) => {
  const { t } = useTranslation()

  const modificationType = get(item, 'modificationType')
  if (modificationType === PROVIDER_ORDERS_MODIFICATION_TYPE.AUTOMATICALLY_APPROVED) {
    return <User>{t('userApprovedAuto')}</User>
  }

  const userApproved = get(item, 'statusUpdatedBy')
  if (!userApproved) return null
  return <User>{t('userApproved', { user: userApproved })}</User>
}

UserApproved.defaultProps = {
  item: {},
}

UserApproved.propTypes = {
  item: PropTypes.object,
}

const UserRejected = ({ item }) => {
  const { t } = useTranslation()

  const userRejected = get(item, 'statusUpdatedBy')
  if (!userRejected) return null

  return <User>{t('userRejected', { user: userRejected })}</User>
}

UserRejected.defaultProps = {
  item: {},
}

UserRejected.propTypes = {
  item: PropTypes.object,
}

const UserCancelled = ({ item }) => {
  const { t } = useTranslation()

  const userCancelled = get(item, 'statusUpdatedBy')
  if (!userCancelled) return null

  return <User>{t('userCancelled', { user: userCancelled })}</User>
}

UserCancelled.defaultProps = {
  item: {},
}

UserCancelled.propTypes = {
  item: PropTypes.object,
}
const RequestId = ({ item }) => {
  const { t } = useTranslation()

  const requestId = get(item, 'id', '')

  return <Request>{t('request', { request: requestId })}</Request>
}

RequestId.defaultProps = {
  item: {},
}

RequestId.propTypes = {
  item: PropTypes.object,
}

const DeliveryDate = ({ item }) => {
  const { t } = useTranslation()

  const deliveryDate = get(item, 'deliveryDate')
  const approvedDeliveryDate = get(item, 'approvedDeliveryDate')
  const date = approvedDeliveryDate || deliveryDate
  const deliveryDateFormatted = moment(date).format('DD/MM/YYYY')

  return <Tag>{t('deliveryDateApprover', { deliveryDate: deliveryDateFormatted })}</Tag>
}

DeliveryDate.defaultProps = {
  item: {},
}

DeliveryDate.propTypes = {
  item: PropTypes.object,
}

const OrderAmount = ({ item }) => {
  const { t } = useTranslation()

  const total = get(item, 'total', 0)
  const approvedTotal = get(item, 'approvedTotal', 0)

  return (
    <Price>
      {t('amount')} <NumberFormat value={approvedTotal || total} suffix='€' />
    </Price>
  )
}

OrderAmount.defaultProps = {
  item: {},
}

OrderAmount.propTypes = {
  item: PropTypes.object,
}

const OrderTotal = ({ item }) => {
  const { t } = useTranslation()

  const totalLines = get(item, 'totalLines', 0)
  const approvedTotalLines = get(item, 'approvedTotalLines', 0)
  const lines = Math.abs(approvedTotalLines || totalLines)
  const productKey = lines === 1 ? 'product' : 'products'
  const textProduct = `${lines} ${t(productKey).toLowerCase()}`

  return <Price>{textProduct}</Price>
}

OrderTotal.defaultProps = {
  item: {},
}

OrderTotal.propTypes = {
  item: PropTypes.object,
}

export const CartsListLoader = () => (
  <>
    <RequestsLoader />
    <RequestsLoader />
  </>
)

const RequestsLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width='100%'
      height={210}
      viewBox='0 0 100% 210'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='0' y='9' rx='10' ry='10' width='300' height='16' />
      <rect x='0' y='50' rx='10' ry='10' width='100%' height='30' />
      <rect x='19' y='60' rx='0' ry='1' width='101' height='10' />
      <rect x='10' y='120' rx='0' ry='0' width='200' height='14' />
      <rect x='246' y='120' rx='6' ry='6' width='254' height='20' />
      <rect x='517' y='123' rx='0' ry='0' width='150' height='14' />
    </ContentLoader>
  )
}

export default CartsList
