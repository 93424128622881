import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseXIcon } from 'Assets/Icons/Cross.svg'
import { ReactComponent as FilterIcon } from 'Assets/Icons/Filter.svg'
import Button from 'Components/atoms/button'
import ModalCenter from 'Components/molecules/modalCenter'

import { FilteredCenterButton } from './styled'

const FilterCenter = ({ center, onSelectCenter }) => {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState(false)

  return (
    <>
      {center ? (
        <FilteredCenterButton className='squared' onClick={() => onSelectCenter()}>
          {center.name} <CloseXIcon className='icon' />
        </FilteredCenterButton>
      ) : (
        <Button className='squared' onClick={() => setShowModal(true)}>
          <FilterIcon />
          <span> {t('filterByCenter')}</span>
        </Button>
      )}
      <ModalCenter
        showModal={showModal}
        setShowModal={setShowModal}
        onSelectCenter={onSelectCenter}
        showSubtitle={false}
        title='filterByCenter'
      />
    </>
  )
}

FilterCenter.propTypes = {
  center: PropTypes.object,
  onSelectCenter: PropTypes.func.isRequired,
}

FilterCenter.defaultProps = {
  center: null,
}

export default FilterCenter
