import React, { useEffect, useMemo, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import { toast } from 'react-toastify'
import isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'
import { AuthTypes } from 'Redux/auth'
import { ReactComponent as PortalLogo } from 'Assets/Logos/PortalLogo.svg'
import { ReactComponent as CompassGroup } from 'Assets/Logos/CompassGroup.svg'
import { Login } from 'Components/molecules'
import { loginRequest } from 'Config/mfa-config'
import routes from 'Config/routes'
import { clearAuthStorage } from 'Components/utils/auth-storage'
import { isUserRoleApprover } from 'Components/utils/roles'
import { setIsLoadingMsal } from 'Redux/auth/actions'
import { selectConfig } from 'Redux/auth/utils'

import config from '../../../../package.json'

import { RootContainer, LoginContainer, AppVersion } from './styled'

const Users = () => {
  const { instance, accounts } = useMsal()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const isLoadingCenter = useSelector(state => state.auth.isLoadingCenter)
  const isLoadingLogin = useSelector(state => state.auth.isLoadingLogin)
  const centersList = useSelector(({ auth }) => auth.centersList)
  const token = useSelector(({ auth }) => auth.token)
  const user = useSelector(({ auth }) => auth.user)
  const hasOnlyOneCenter = useSelector(({ auth }) => auth.hasOnlyOneCenter)
  const isLoadingMsal = useSelector(state => state.auth.isLoadingMsal)
  const features = useSelector(selectConfig)

  const [submitLoading, setSubmitLoading] = useState(false)

  const homeRoute = useMemo(
    () =>
      hasOnlyOneCenter || isUserRoleApprover(user) || !features?.multicenter
        ? routes.home
        : routes.multicenterOrders,
    [hasOnlyOneCenter, user, features]
  )

  useEffect(() => {
    if (token && !isEmpty(centersList)) {
      history.push(homeRoute)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centersList, history, token])

  useEffect(() => {
    async function getSilentToken() {
      try {
        dispatch(setIsLoadingMsal(true))
        const { accessToken } = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts?.[0],
        })

        dispatch({
          type: AuthTypes.LOGIN_MSAL,
          params: { accessToken, username: accounts?.[0]?.username },
        })
      } catch {
        toast.error(t('generalError'))
        dispatch(setIsLoadingMsal(false))
      }
    }

    if (accounts?.[0]?.username && !token) {
      getSilentToken()
    } else if (token && !isEmpty(centersList)) {
      history.push(homeRoute)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, token, centersList])

  const handleClickMsalLogin = async () => {
    if (process.env.NODE_ENV === 'development') {
      toast.info('Acceso con Microsoft desactivado en desarrollo. MSAL sólo funciona en https.')
      return
    }

    try {
      setSubmitLoading(true)
      clearAuthStorage()
      await instance.loginRedirect(loginRequest)
    } catch (e) {
      toast.error(e || new Error('Login error'))
    } finally {
      setSubmitLoading(false)
    }
  }

  return (
    <RootContainer>
      <PortalLogo style={{ marginBottom: '2.5rem' }} />
      <LoginContainer>
        <Login
          isLoading={submitLoading || isLoadingCenter || isLoadingLogin}
          isLoadingMsal={isLoadingMsal}
          onClickMsalLogin={handleClickMsalLogin}
          title={t('enterTitle')}
        />
        <AppVersion>V{config.version}</AppVersion>
      </LoginContainer>
      <CompassGroup style={{ marginTop: '2.5rem' }} />
    </RootContainer>
  )
}

export default Users
