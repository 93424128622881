import React from 'react'

import PropTypes from 'prop-types'
import EraseButton from 'Components/atoms/eraseButton'
import AcceptButton from 'Components/atoms/acceptButton'
import SelectorButton from 'Components/atoms/selectorButton'
import AddCartButton from 'Components/atoms/addCartButton'
import AmountInput from 'Components/atoms/ammountInput'
import ButtonNotAvailable from 'Components/atoms/buttonNotAvailable'
import useShoppingListsProductButton from 'hooks/shoppingLists/useShoppingListsProductButton'

import { ButtonCart, ProductButtonContainer } from '../styled'

const ProductButton = ({ disabled, product, onClickRemove, onClickRemoveProduct }) => {
  const {
    inputValue,
    inputRef,
    error,
    focused,
    notAvailable,
    setFocusInput,
    onChangeInput,
    onBlurInput,
    onClickAdd,
    onClickSubstract,
    onKeydown,
    onClickAddToCart,
    setToZero,
    setFocused,
  } = useShoppingListsProductButton({ product, onClickRemove, onClickRemoveProduct })

  const { amount, measureUnit, availability } = product

  if (notAvailable) {
    return <ButtonNotAvailable onClick={setToZero} availability={availability} />
  }

  return (
    <ButtonCart hasAmount={!!amount} error={error} focused={focused}>
      <ProductButtonContainer disabled={disabled}>
        <AmountInput
          onClick={setFocusInput}
          focused={focused}
          onBlur={onBlurInput}
          inputRef={inputRef}
          value={inputValue}
          onChange={onChangeInput}
          onKeyDown={onKeydown}
          suffix={measureUnit}
        />
        {focused ? (
          <>
            <AcceptButton onClick={() => setFocused(false)} />
            <EraseButton onClick={setToZero} />
          </>
        ) : (
          <>
            <SelectorButton onClick={onClickSubstract} icon='down' />
            <SelectorButton onClick={onClickAdd} icon='up' />
            <EraseButton onClick={setToZero} />
            <AddCartButton onClick={onClickAddToCart} />
          </>
        )}
      </ProductButtonContainer>
    </ButtonCart>
  )
}

ProductButton.defaultProps = {
  disabled: false,
  product: {},
  onClickRemove: () => null,
  onClickRemoveProduct: () => null,
}

ProductButton.propTypes = {
  disabled: PropTypes.bool,
  product: PropTypes.object,
  onClickRemove: PropTypes.func,
  onClickRemoveProduct: PropTypes.func,
}

export default ProductButton
