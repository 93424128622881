import React, { useEffect, useState, useRef, useMemo } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  MEASURE_UNIT,
  DELIVERY_TYPE,
  AVAILABILITY,
  DELIVERY_DATE_MIN_DAYS,
  HAS_CALENDAR,
  DISABLE_WEEKS,
} from 'Config/constants'
import Radio from '@material-ui/core/Radio'
import { ReactComponent as CalendarIcon } from 'Assets/Icons/Calendario.svg'
import colors from 'Assets/colors'
import ArrowDropdown from 'Assets/Icons/ArrowDropdown'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { isExpiredDeliveryDate } from 'Redux/purchases/utils'
import { fetchCart, setCart, fetchOriginCenters, setCheckoutDatesUpdated } from 'Redux/approver/actions'
import { getProviderExceptionalDates, getProviderOrdinaryDates, putApproverDeliveryDate } from 'Services/api'
import { Calendar, CostMessage, Notification, NumberFormat } from 'Components/atoms'
import { ModalLoading } from 'Components/molecules'
import { useClickOutside } from 'Components/utils/customHooks'
import moment from 'moment'
import { isAvailableProduct } from 'Components/utils/products'
import { financial } from 'Components/utils/cartCalculator'
import * as Sentry from 'Services/sentry'

import { assembleCart } from '../../../Redux/purchases/utils'

import {
  ProviderContainer,
  ProviderNameRow,
  ProviderName,
  ProviderDetail,
  TotalPrice,
  TotalProductLines,
  DeliveryRow,
  RadioGroupContainer,
  StyledRadioGroup,
  DateSelectContainer,
  DateSelect,
  DateSelectTitle,
  DateSelectInfo,
  ShowProductsContainer,
  ShowMoreCopy,
  ShowLessCopy,
  ProductsContainer,
  TableHeaderRow,
  TableHeaderItem,
  SingleProductRow,
  SingleProductNameHelper,
  SingleProductName,
  SingleProductPriceContainer,
  SingleProductQtyContainer,
  SingleProductTotalPrice,
  SingleProductTotalPriceSplit,
  SingleProductTotalQty,
  Price,
  PriceKG,
  MinAndMulti,
  CalendarContainer,
  SingleProductDateWarning,
} from './styled'
import { getTotalPrice, getCalendarColor, isNextMonth, getDateSelectTitle, areProductsNoAvailable } from './utils'

const ApproverProviderCheckout = ({ products, providerName, setErrors }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const requestOrder = useSelector(state => state.approver.requestOrder)

  const [ordinaryDates, setOrdinaryDates] = useState(null)
  const [exceptionalDates, setExceptionalDates] = useState(null)
  const [availability, setAvailability] = useState('')
  const [openProducts, setOpenProducts] = useState(true)
  const [openCalendar, setOpenCalendar] = useState(false)
  const [deliveryType, setDeliveryType] = useState('')
  const [deliveryDate, setDeliveryDate] = useState(null)
  const [showLoading, setShowLoading] = useState(false)

  const availableProducts = useMemo(() => products.filter(element => isAvailableProduct(element)), [products])

  const ContainerRef = useRef()
  useClickOutside(ContainerRef, () => setOpenCalendar(false))

  const showPrice = true
  const providerId = _.get(products, '0.provider.id', '')
  const centerId = _.get(requestOrder, 'origin.id', '')
  const requestOrderId = _.get(requestOrder, 'id', '')

  const hasExceptionalDates = exceptionalDates && exceptionalDates.length > 0

  const handleChangeDeliveryType = async type => {
    const availableDays = type === DELIVERY_TYPE.NORMAL ? ordinaryDates : exceptionalDates
    if (
      availableDays &&
      ((type === DELIVERY_TYPE.NORMAL && availableDays.length > 0) ||
        (type === DELIVERY_TYPE.SPECIAL && availableDays.length === 1))
    ) {
      setDeliveryDate(moment(availableDays[0].delivery).toDate())
      setDeliveryType(type)
      dispatch(setCheckoutDatesUpdated(true))
      setErrors(false)
      await putApproverDeliveryDate(requestOrderId, {
        providerId,
        type,
        date: moment(availableDays[0].delivery).utc(true).toISOString(),
      })
      dispatch(fetchCart())
    } else {
      setDeliveryDate(null)
      setErrors(true)
      setDeliveryType(type)
      dispatch(setCheckoutDatesUpdated(false))
    }
  }

  useEffect(() => {
    const getDates = async () => {
      try {
        const { dates: orDates = [], availability: currentAvailability } = await getProviderOrdinaryDates(
          providerId,
          centerId
        )
        const { dates: exDates } = await getProviderExceptionalDates(providerId, centerId)

        setOrdinaryDates(orDates)
        setAvailability(currentAvailability)
        setExceptionalDates(exDates)
      } catch (error) {
        Sentry.captureException(error)
      }
    }
    if (providerId) getDates()
  }, [providerId, centerId])

  useEffect(() => {
    dispatch(fetchOriginCenters())
  }, [dispatch])

  useEffect(() => {
    const handleChangeDeliveryDate2 = async () => {
      const d = moment().add(2, 'days')
      try {
        await putApproverDeliveryDate(requestOrderId, {
          providerId,
          type: DELIVERY_TYPE.NORMAL,
          date: d.utc(true).toISOString(),
        })
        setDeliveryDate(moment(d).toDate())
        setErrors(false)
        dispatch(setCheckoutDatesUpdated(true))
        dispatch(fetchCart())
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }

    const date = _.get(availableProducts, '0.delivery.date', '')
    const type = _.get(availableProducts, '0.delivery.type', '')
    if (availableProducts?.length > 0) {
    if (!deliveryType && !deliveryDate && date && type) {
      setDeliveryDate(moment(date).toDate())
      setErrors(false)
      setDeliveryType(type)
    } else if (availability === HAS_CALENDAR.NO && ordinaryDates?.length === 0 && deliveryDate == null) {
      setDeliveryType(DELIVERY_TYPE.NORMAL)
      handleChangeDeliveryDate2()
    } else if (!deliveryType && ordinaryDates) {
      handleChangeDeliveryType(DELIVERY_TYPE.NORMAL)
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDate, availableProducts, availability, ordinaryDates, dispatch, centerId, providerId, deliveryType])

  const hasAtLeastOneProduct = () => availableProducts.find(({ amount }) => amount > 0)

  if (!hasAtLeastOneProduct()) return null

  const totalProductLines = availableProducts.length

  const isExpiredDate = isExpiredDeliveryDate({ deliveryDate, deliveryType })
  const calendarColor = getCalendarColor({ isExpiredDate, deliveryDate })
  const dateSelectTitle = getDateSelectTitle({ t, isExpiredDate, deliveryDate })

  const handleClickSeeProducts = () => setOpenProducts(newValue => !newValue)

  const handleClickOpenCalendar = () => setOpenCalendar(newValue => !newValue)

  const handleChangeDeliveryDate = async value => {
    setOpenCalendar(false)
    setShowLoading(true)
    try {
      const response = await putApproverDeliveryDate(requestOrderId, {
        providerId,
        type: deliveryType,
        date: moment(value).utc(true).toISOString(),
      })
      // eslint-disable-next-line no-shadow
      const { products = [], deliveries = [] } = response
      const newCart = assembleCart(products, deliveries)
      dispatch(setCart(newCart))
      setDeliveryDate(value)
      setErrors(false)
      setShowLoading(false)
      dispatch(setCheckoutDatesUpdated(true))
      dispatch(fetchCart())
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  const tileDisabled = ({ date, view }) => {
    if (view !== 'month') {
      return true
    }

    if (deliveryType === DELIVERY_TYPE.NORMAL) {
      const mDate = moment(date).startOf('day')
      if (availability === AVAILABILITY.NO) {
        return (
          moment().startOf('day').add(DELIVERY_DATE_MIN_DAYS, 'd') >= mDate ||
          (DISABLE_WEEKS && (mDate.day() === 0 || mDate.day() === 6))
        )
      }

      return !ordinaryDates?.some(
        ({ delivery: fDelivery }) => moment(date).format('DDMMYY') === moment(fDelivery).format('DDMMYY')
      )
    }

    if (deliveryType === DELIVERY_TYPE.SPECIAL) {
      return !exceptionalDates.some(
        ({ delivery: fDelivery }) => moment(date).format('DDMMYY') === moment(fDelivery).format('DDMMYY')
      )
    }

    return false
  }

  const exceptionalSelectedDay = _.find(exceptionalDates, date => moment(date.delivery).isSame(deliveryDate, 'day'))

  const productsNotAvailable = areProductsNoAvailable(availableProducts)

  return (
    <>
      <ProviderContainer>
        <ProviderNameRow>
          <ProviderName>{providerName}</ProviderName>
          <ProviderDetail>
            {showPrice && (
              <TotalPrice>
                <NumberFormat value={getTotalPrice(availableProducts)} />
              </TotalPrice>
            )}
            <TotalProductLines>
              {totalProductLines} {t('singleProduct')}
              {totalProductLines > 1 ? 's' : ''}
            </TotalProductLines>
            <ShowProductsContainer onClick={handleClickSeeProducts}>
              <ArrowDropdown
                fill={openProducts ? colors.secondary : colors.black}
                style={{ margin: '0 0.5rem', transform: openProducts ? 'rotate(180deg)' : 'none' }}
              />
              {openProducts ? (
                <ShowLessCopy>{t('contractProducts')}</ShowLessCopy>
              ) : (
                <ShowMoreCopy>{t('seeProducts')}</ShowMoreCopy>
              )}
            </ShowProductsContainer>
          </ProviderDetail>
        </ProviderNameRow>
        <DeliveryRow openProducts={openProducts || productsNotAvailable}>
          <RadioGroupContainer>
            <StyledRadioGroup
              aria-label='delivery'
              name='delivery1'
              value={deliveryType}
              onChange={event => handleChangeDeliveryType(event.target.value)}
            >
              <FormControl>
                <FormControlLabel value={DELIVERY_TYPE.NORMAL} control={<Radio />} label={t('ordinaryDelivery')} />
                <FormHelperText>{t('free')}</FormHelperText>
              </FormControl>
              {hasExceptionalDates && (
                <FormControl>
                  <FormControlLabel value={DELIVERY_TYPE.SPECIAL} control={<Radio />} label={t('specialDelivery')} />
                  <FormHelperText>
                    {showPrice &&
                    deliveryDate &&
                    deliveryType === DELIVERY_TYPE.SPECIAL &&
                    exceptionalSelectedDay &&
                    exceptionalSelectedDay.price ? (
                      <>
                        {t('specialrelatedCosts')} <NumberFormat value={exceptionalSelectedDay.price} />
                      </>
                    ) : (
                      t('relatedCosts')
                    )}
                  </FormHelperText>
                </FormControl>
              )}
            </StyledRadioGroup>
          </RadioGroupContainer>
          <DateSelectContainer ref={ContainerRef}>
            <CalendarIcon fill={calendarColor} />
            <DateSelect>
              <DateSelectTitle onClick={handleClickOpenCalendar} style={{ color: calendarColor }}>
                {dateSelectTitle}
              </DateSelectTitle>
              <DateSelectInfo>{deliveryDate ? t('deliveryDateEdit') : t('choose')}</DateSelectInfo>
            </DateSelect>
            {openCalendar && (
              <CalendarContainer>
                <Calendar
                  value={deliveryDate}
                  onChange={handleChangeDeliveryDate}
                  tileDisabled={tileDisabled}
                  selectRange={false}
                />
              </CalendarContainer>
            )}
          </DateSelectContainer>
        </DeliveryRow>
        {deliveryDate && isNextMonth(deliveryDate) && <CostMessage message={t('costMessage')} />}

        <ProductsContainer>
          {productsNotAvailable && <Notification icon='warning' text={t('warningMessage')} color='error' />}
          {openProducts && (
            <>
              <TableHeaderRow>
                <TableHeaderItem>{t('products')}</TableHeaderItem>
                {showPrice && <TableHeaderItem>{t('sum')}</TableHeaderItem>}
                <TableHeaderItem>{t('quantity')}</TableHeaderItem>
              </TableHeaderRow>
              {availableProducts.map(
                ({ name, id, total, price, measureUnit, pricePerKg, amount, min, multiple, state }) => (
                  <SingleProductRow key={id}>
                    <SingleProductNameHelper>
                      <SingleProductName>{name}</SingleProductName>

                      {state === 'no_available' && (
                        <SingleProductDateWarning>{t('productNoAvailable')}</SingleProductDateWarning>
                      )}
                    </SingleProductNameHelper>
                    {showPrice && (
                      <SingleProductPriceContainer>
                        <SingleProductTotalPrice>
                          <NumberFormat value={total} />
                        </SingleProductTotalPrice>
                        <SingleProductTotalPriceSplit>
                          <Price>
                            <NumberFormat value={price} suffix={`€/${measureUnit}`} />
                          </Price>
                          {measureUnit !== MEASURE_UNIT.KG ? (
                            <PriceKG>
                              <NumberFormat value={pricePerKg} suffix='€/KG' />
                            </PriceKG>
                          ) : null}
                        </SingleProductTotalPriceSplit>
                      </SingleProductPriceContainer>
                    )}
                    <SingleProductQtyContainer>
                      <SingleProductTotalQty>
                        <NumberFormat value={amount} suffix={measureUnit} />
                      </SingleProductTotalQty>

                      <div style={{ display: 'flex' }}>
                        <MinAndMulti>
                          {t('min')}: {financial(min)} / {t('multi')}: {financial(multiple)}
                        </MinAndMulti>
                      </div>
                    </SingleProductQtyContainer>
                  </SingleProductRow>
                )
              )}
            </>
          )}
        </ProductsContainer>
      </ProviderContainer>
      <ModalLoading showModal={showLoading} message={t('checkoutChangeDateLoading')} title={t('loading')} />
    </>
  )
}

ApproverProviderCheckout.defaultProps = {
  products: [],
  providerName: '',
}

ApproverProviderCheckout.propTypes = {
  products: PropTypes.array,
  providerName: PropTypes.string,
  setErrors: PropTypes.func.isRequired,
}

export default ApproverProviderCheckout
