export default {
  grey3: '#828282',
  secondary: '#17CF63',
  cart: '#FFD600',
  black: '#333333',
  gray40l: '#666666',
  gray: '#4D4D4D',
  gray2: '#808080',
  gray3: '#eaeaea',
  grey4: '#C9C9C9',
  white: '#ffffff',
  gray99l: '#FCFCFC',
  red: '#F43E3E',
  gray98b: '#FAFAFA',
  grayInput: '#F4F1F1',
  orange: '#f36e23',
  blue: '#17B9CF',
  yellow: '#FFD600',
}
