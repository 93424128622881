import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Title, Message, Row, Cancel, Action, IconContainer } from './styled'

const Confirmation = ({
  title,
  message,
  color,
  titleColor,
  action,
  onCancel,
  onAccept,
  cancel,
  icon,
  cancelAction,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Title color={titleColor || color}>{title}</Title>
      {message && <Message>{message}</Message>}
      {icon && <IconContainer>{icon}</IconContainer>}
      <Row>
        <Cancel color={color} onClick={onCancel}>
          {cancelAction || cancel || t('cancel')}
        </Cancel>
        <Action color={color} onClick={onAccept}>
          {action}
        </Action>
      </Row>
    </>
  )
}

Confirmation.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string,
  titleColor: PropTypes.string,
  action: PropTypes.string,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  cancelAction: PropTypes.string,
  cancel: PropTypes.string,
}

Confirmation.defaultProps = {
  icon: null,
  title: '',
  message: '',
  color: 'black',
  titleColor: '',
  action: '',
  onCancel: () => {},
  onAccept: () => {},
  cancelAction: '',
  cancel: '',
}

export default Confirmation
