import viewItemList from './viewItemList'
import selectItem from './selectItem'
import addToCart from './addToCart'
import removeFromCart from './removeFromCart'
import setUserProperties from './setUserProperties'
import setCenter from './setCenter'
import purchase from './purchase'
import beingCheckout from './beingCheckout'
import addToWishlist from './addToWishlist'

export default {
  viewItemList,
  selectItem,
  addToCart,
  removeFromCart,
  setUserProperties,
  setCenter,
  purchase,
  beingCheckout,
  addToWishlist,
}
