import styled from 'styled-components'
import colors from 'Assets/colors'
import Checkbox from '@material-ui/core/Checkbox'

export const FilterButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;

  align-items: center;
  justify-content: space-between;

  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  .icon {
    margin-right: 0.5rem;
  }
`

export const FilterContainer = styled.div`
  position: absolute;
  top: 3rem;
  display: ${props => props.display};
  border-radius: 24px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  background: #ffffff;
  overflow: hidden;
  padding: 16px 0;

  .MuiTypography-body1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .MuiFormControlLabel-root {
    justify-content: space-between;
    margin-right: 0;
    margin-left: 16px;
    margin-right: -11px;
    flex-direction: row-reverse;
  }
`

export const FilterContainerInner = styled.div`
  overflow: auto;
  flex-direction: column;
  padding: 0 1rem;
  z-index: 10;
  width: 340px;
  max-height: 180px;

  ::-webkit-scrollbar {
    width: 0.4em;
    background: white;
  }

  ::-webkit-scrollbar-button {
    background: white;
  }

  ::-webkit-scrollbar-track-piece {
    background: white;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    border-radius: 4px;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;

  &:hover {
    background-color: ${colors.white} !important;
  }

  &.Mui-checked {
    svg {
      path {
        color: ${colors.secondary};
      }

      rect {
        fill: ${colors.secondary};
        stroke: none;
        stroke-width: 0;
      }
    }
  }

  svg {
    rect {
      fill: none;
      stroke: #666666;
      stroke-width: 0.5;
    }
  }
`

export const CheckGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`
