import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import colors from 'Assets/colors'

export const useStyles = makeStyles(() => ({
  back: {
    height: '56px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    textAlign: 'center',
    textTransform: 'none',
    color: colors.black,
    transition: 'all 0.2s',
    border: `2px solid ${colors.black}`,
    backgroundColor: colors.white,
    '&:hover': {
      opacity: 0.8,
      color: colors.black,
      transform: 'translateY(-2px)',
      backgroundColor: colors.se,
      boxShadow: '0 3px 5px rgba(51, 51, 51, 0.2)',
    },
    '&:active': {
      transform: 'translate(-1px)',
      boxShadow: '0 1px 3px rgba(51, 51, 51, 0.4)',
    },
  },
}))

export const Container = styled.div`
  padding: 2.5rem 1.25rem 9rem;
  height: calc(100vh - 64px);
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Content = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 40px 80px;
  width: 680px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: ${colors.black};
  margin-bottom: 16px;
  text-align: center;
  max-width: 400px;
`

export const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 4.5rem;
  flex: 1;
  align-self: stretch;
`
