import {
  SET_APPROVED,
  SET_APPROVER_CONFIRMED_CART,
  SET_CART,
  SET_CART_DELIVERIES,
  SET_CHECKOUT_DATES_UPDATED,
  SET_ERROR_CART,
  SET_FILTER_CENTER,
  SET_FILTER_PROVIDER,
  SET_FILTER_REQUEST_NUMBER,
  SET_IS_FETCHING_APPROVED,
  SET_IS_FETCHING_CART,
  SET_IS_FETCHING_PENDING_APPROVAL,
  SET_IS_FETCHING_REJECTED,
  SET_IS_UPDATING_CART,
  SET_ORIGIN_CENTERS,
  SET_PENDING_APPROVAL,
  SET_REJECTED,
  SET_REQUEST_ORDER,
  SET_WEEK_SUMMARY,
  SET_SUMMARY_CENTER,
  SET_IS_FETCHING_SUMMARY_CENTER,
} from './types'

const initialState = {
  pendingApprovalCount: 0,
  pendingApproval: [],
  closeToAutoApproved: [],
  isFetchingPendingApproval: false,
  rejected: [],
  isFetchingRejected: false,
  approved: [],
  isFetchingApproved: false,
  cart: null,
  isFetchingCart: false,
  requestOrder: null,
  isUpdatingCart: false,
  errorCart: null,
  filterCenter: null,
  filterProviders: [],
  filterRequestNumber: '',
  checkoutDatesUpdated: false,
  deliveries: [],
  originCenters: [],
  approverConfirmedCart: {},
  weekSummary: {},
  summaryCenter: {},
  isFetchingSummary: false,
}

const Reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_PENDING_APPROVAL:
      return {
        ...state,
        pendingApproval: action.payload.pendingApproval,
        closeToAutoApproved: action.payload.closeToAutoApproved,
        pendingApprovalCount: action.payload.count,
      }
    case SET_IS_FETCHING_PENDING_APPROVAL:
      return {
        ...state,
        isFetchingPendingApproval: action.payload,
      }
    case SET_REJECTED:
      return {
        ...state,
        rejected: action.payload,
      }
    case SET_IS_FETCHING_REJECTED:
      return {
        ...state,
        isFetchingRejected: action.payload,
      }
    case SET_APPROVED:
      return {
        ...state,
        approved: action.payload,
      }
    case SET_IS_FETCHING_APPROVED:
      return {
        ...state,
        isFetchingApproved: action.payload,
      }
    case SET_CART:
      return {
        ...state,
        cart: action.payload,
      }
    case SET_IS_FETCHING_CART:
      return {
        ...state,
        isFetchingCart: action.payload,
      }
    case SET_REQUEST_ORDER:
      return {
        ...state,
        requestOrder: action.payload,
      }
    case SET_IS_UPDATING_CART:
      return {
        ...state,
        isUpdatingCart: action.payload,
      }
    case SET_ERROR_CART:
      return {
        ...state,
        errorCart: action.payload,
      }
    case SET_FILTER_CENTER:
      return {
        ...state,
        filterCenter: action.payload,
      }
    case SET_FILTER_PROVIDER:
      return {
        ...state,
        filterProviders: action.payload,
      }
    case SET_FILTER_REQUEST_NUMBER:
      return {
        ...state,
        filterRequestNumber: action.payload,
      }
    case SET_CHECKOUT_DATES_UPDATED:
      return {
        ...state,
        checkoutDatesUpdated: action.payload,
      }
    case SET_CART_DELIVERIES:
      return {
        ...state,
        deliveries: action.payload,
      }
    case SET_ORIGIN_CENTERS:
      return {
        ...state,
        originCenters: action.payload,
      }
    case SET_APPROVER_CONFIRMED_CART:
      return { ...state, approverConfirmedCart: action.payload }
    case SET_WEEK_SUMMARY:
      return {
        ...state,
        weekSummary: action.payload,
      }
    case SET_SUMMARY_CENTER:
      return {
        ...state,
        summaryCenter: action.payload,
      }
    case SET_IS_FETCHING_SUMMARY_CENTER:
      return {
        ...state,
        isFetchingSummary: action.payload,
      }

    default:
      return state
  }
}

export default Reducer
