import axios from 'axios'

export const getShoppingList = centerId => axios.get('/api/shopping-list', { headers: { 'X-Center-Id': centerId } })

export const postShoppingList = (data, centerId) =>
  axios.post('/api/shopping-list', data, { headers: { 'X-Center-Id': centerId } })

export const getShoppingListProducts = (id, centerId) =>
  axios.get(`/api/shopping-list/${id}`, { headers: { 'X-Center-Id': centerId } })

export const deleteShoppingList = (id, centerId) =>
  axios.delete(`/api/shopping-list/${id}`, { headers: { 'X-Center-Id': centerId } })

export const deleteShoppingListProduct = (id, reference, centerId) =>
  axios.delete(`/api/shopping-list/${id}/products`, {
    headers: { 'X-Center-Id': centerId },
    data: [{ reference }],
  })

export const putShoppingList = (id, data, centerId) =>
  axios.put(`/api/shopping-list/${id}`, data, { headers: { 'X-Center-Id': centerId } })

export const postSendCart = (id, centerId) =>
  axios.post(`/api/shopping-list/${id}/send-cart`, {}, { headers: { 'X-Center-Id': centerId } })

export const putShoppingListProduct = (id, data, centerId) =>
  axios.put(`/api/shopping-list/${id}/products`, data, { headers: { 'X-Center-Id': centerId } })

export const postShoppingListProduct = (id, productId, centerId) =>
  axios.post(`/api/shopping-list/${id}/products/${productId}`, {}, { headers: { 'X-Center-Id': centerId } })
