import styled from 'styled-components'
import colors from 'Assets/colors'

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.black};
  padding-bottom: 1rem;
`

export const Message = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  text-align: center;
  padding-bottom: 1.5rem;
  max-width: 520px;
  margin: 0 auto;
`
export const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
`
