import React from 'react'

import PropTypes from 'prop-types'

import { Text } from './styled'

const ErrorText = ({ children, margin, padding, textAlign }) => (
  <Text padding={padding} margin={margin} textAlign={textAlign}>
    {children}
  </Text>
)

ErrorText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  margin: PropTypes.string,
  padding: PropTypes.string,
  textAlign: PropTypes.string,
}

ErrorText.defaultProps = {
  children: '',
  margin: '',
  padding: '',
  textAlign: '',
}

export default ErrorText
