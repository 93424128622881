import styled from 'styled-components'

export const NoResultsContainer = styled.div`
  padding: 40px;
  margin: 0 auto;
`

export const NoResultsBox = styled.div`
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  max-width: 660px;
  margin: 0 auto;
`
