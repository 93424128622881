import { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import groupBy from 'lodash/groupBy'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { putApproverOriginCenter } from 'Services/api'
import * as Sentry from 'Services/sentry'
import routes from 'Config/routes'
import { confirmCart, fetchCart, setCheckoutDatesUpdated, setRequestOrder } from 'Redux/approver/actions'
import { CART_CHANGES_EXCEPTION, REQUEST_APPROVERD_EXCEPTION, REQUEST_ORDER_STATE } from 'Config/constants'
import { getApiErrorMessage } from 'Config/utils'

const useApproverCartsCheckout = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const requestOrder = useSelector(state => state.approver.requestOrder)
  const deliveries = useSelector(state => state.approver.deliveries)
  const cart = useSelector(state => state.approver.cart)
  const approverConfirmedCart = useSelector(state => state.approver.approverConfirmedCart)
  const errorCart = useSelector(state => state.approver.errorCart)
  const originCenters = useSelector(state => state.approver.originCenters)

  const isLoadingCart = useSelector(state => state.approver.isFetchingCart)

  const [showModal, setShowModal] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [errors, setErrors] = useState({})

  const orderedByProvider = groupBy(cart, 'provider.name')
  const selectedOrigin = get(approverConfirmedCart, 'origin')
  const selectedCenter = get(requestOrder, 'target')
  const requestOrderId = get(requestOrder, 'id')

  const isLoading = isLoadingCart || (deliveries?.length === 0 && !errorCart)

  useEffect(() => {
    if (!requestOrder) {
      history.replace(routes.approverCarts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(setCheckoutDatesUpdated(false))
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchCart())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendSelectedOriginCenter = async center => {
    try {
      await putApproverOriginCenter(requestOrderId, center.id)
      dispatch(fetchCart())
    } catch (error) {
      Sentry.captureException(error)
      toast.error(getApiErrorMessage(error))
    }
  }

  const handleConfirmedCart = ({ isSuccess, error }) => {
    if (isSuccess) {
      history.push(routes.approverSuccessUpdatedOrder, { status: REQUEST_ORDER_STATE.APPROVED })
    } else if (
      !isSuccess &&
      error?.response?.data?.error === CART_CHANGES_EXCEPTION &&
      error?.response?.data?.statusCode === 400
    ) {
      toast.info(t('updatedCart'))
      dispatch(fetchCart())
      dispatch(setCheckoutDatesUpdated(false))
    } else if (
      !isSuccess &&
      error?.response?.data?.error === REQUEST_APPROVERD_EXCEPTION &&
      error?.response?.data?.statusCode === 422
    ) {
      history.push(routes.approverCarts)
      dispatch(setRequestOrder(null))
      toast.warning(t('approvedRequestException'))
    } else {
      toast.error(t('petitionError'))
    }

    setIsSubmiting(false)
  }

  const onConfirm = async () => {
    setIsSubmiting(true)
    const params = { updated: approverConfirmedCart.updated }
    try {
      dispatch(confirmCart(requestOrderId, params, handleConfirmedCart))
    } catch (e) {
      setIsSubmiting(false)
      toast.error(t('petitionError'))
    }
  }

  const onCenterSelected = center => {
    setShowModal(false)
    if (center) {
      sendSelectedOriginCenter(center)
    }
  }

  const onClickChangeCenter = () => setShowModal(true)

  return {
    cart,
    isLoading,
    showModal,
    onConfirm,
    onCenterSelected,
    onClickChangeCenter,
    originCenters,
    isSubmiting,
    errors,
    setErrors,
    orderedByProvider,
    selectedOrigin,
    selectedCenter,
    deliveries,
  }
}

export default useApproverCartsCheckout
