export const FETCH_CENTERS_LIST = 'portal_compras/multicenter/FETCH_CENTERS_LIST'
export const FETCH_CENTER_ORDERS = 'portal_compras/multicenter/FETCH_CENTER_ORDERS'
export const FETCH_FILTER_CENTERS = 'portal_compras/multicenter/FETCH_FILTER_CENTERS'
export const FETCH_FILTER_ORIGIN_CENTERS = 'portal_compras/multicenter/FETCH_FILTER_ORIGIN_CENTERS'
export const FETCH_FILTER_PROVIDERS = 'portal_compras/multicenter/FETCH_FILTER_PROVIDERS'
export const FETCH_NO_COMPLIANCES = 'portal_compras/multicenter/FETCH_NO_COMPLIANCES'
export const FETCH_REQUEST_ORDERS = 'portal_compras/multicenter/FETCH_REQUEST_ORDERS'
export const FETCH_STATS = 'portal_compras/multicenter/FETCH_STATS'
export const SET_IS_CREATE_QA = 'portal_compras/multicenter/SET_IS_CREATE_QA'
export const SET_IS_LOADING_CENTERS = 'portal_compras/multicenter/SET_IS_LOADING_CENTERS'
export const SET_IS_LOADING_NO_COMPLIANCES = 'portal_compras/multicenter/SET_IS_LOADING_NO_COMPLIANCES'
export const SET_IS_LOADING_ORDERS = 'portal_compras/multicenter/SET_IS_LOADING_ORDERS'
export const SET_IS_REPEATING_ORDER = 'portal_compras/multicenter/SET_IS_REPEATING_ORDER'
export const SET_CENTER_ORDERS = 'portal_compras/multicenter/SET_CENTER_ORDERS'
export const SET_CENTERS_LIST = 'portal_compras/multicenter/SET_CENTERS_LIST'
export const SET_FILTER_CENTERS = 'portal_compras/multicenter/SET_FILTER_CENTERS'
export const SET_FILTER_ORIGIN_CENTERS = 'portal_compras/multicenter/SET_FILTER_ORIGIN_CENTERS'
export const SET_FILTER_PROVIDERS = 'portal_compras/multicenter/SET_FILTER_PROVIDERS'
export const SET_ORDERS_FILTERS = 'portal_compras/multicenter/SET_ORDERS_FILTERS'
export const SET_NO_COMPLIANCES = 'portal_compras/multicenter/SET_NO_COMPLIANCES'
export const SET_NO_COMPLIANCES_FILTERS = 'portal_compras/multicenter/SET_NO_COMPLIANCES_FILTERS'
export const SET_REQUEST_ORDERS = 'portal_compras/multicenter/SET_REQUEST_ORDERS'
export const SET_SELECTED_CENTER = 'portal_compras/multicenter/SET_SELECTED_CENTER'
export const SET_STATS = 'portal_compras/multicenter/SET_STATS'
export const SET_QA_FILTERS = 'portal_compras/multicenter/SET_QA_FILTERS'
