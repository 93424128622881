import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin: ${props => props.margin};
  justify-content: ${props => props.justifyContent};
`

export const RedSquare = styled(Row)`
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.red};
  border: 1px solid ${props => props.theme.colors.red};
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  padding: 0.7rem;
`

export const Container = styled.div`
  padding: 1rem;
  border-bottom: 1px solid rgba(51, 51, 51, 0.24);
`
