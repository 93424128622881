import axios from 'axios'
import qs from 'qs'

export const getNoConformitiesCategories = centerId =>
  axios.get('/api/v2/non-conformities/categories', { headers: { 'X-Center-Id': centerId } })

export const postNoConformitiesAttachment = (centerId, file) => {
  const formData = new FormData()
  formData.append('file', file, file.name)
  return axios.post('/api/non-conformities/attachments', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Center-Id': centerId,
    },
  })
}

export const confirmNoConformities = (centerId, payload) =>
  axios.post('/api/v2/non-conformities/tickets', payload, { headers: { 'X-Center-Id': centerId } })

export const getNoConformitiesTickets = (centerId, params) =>
  axios.get(`/api/non-conformities/tickets?${qs.stringify(params, { skipNulls: true })}`, {
    headers: { 'X-Center-Id': centerId },
  })

export const getNoConformitiesTicketInfo = (centerId, id) =>
  axios.get(`/api/non-conformities/tickets/${id}`, { headers: { 'X-Center-Id': centerId } })

export const putNoConformitiesTicketComment = (centerId, id, payload) =>
  axios.put(`/api/non-conformities/tickets/${id}`, payload, { headers: { 'X-Center-Id': centerId } })

export const getNoConformitiesPendingTickets = centerId =>
  axios.get('api/non-conformities/tickets/pendings', {
    headers: { 'X-Center-Id': centerId },
  })

export const getMulticenterNoConformitiesTickets = params =>
  axios.get(`/api/non-conformities/tickets/multicenter?${qs.stringify(params, { skipNulls: true })}`)

export const getMulticenterNoConformitiesPendingTickets = params =>
  axios.get(`/api/non-conformities/tickets/multicenter/pendings?${qs.stringify(params, { skipNulls: true })}`)
