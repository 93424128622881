import { all, select, takeLatest, put, call, fork, takeEvery } from 'redux-saga/effects'
import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'
import axios from 'axios'
import i18next from 'i18next'
import { toast } from 'react-toastify'
import { selectPurchaseProducts, selectSelectedCategoriesHabitual } from 'Redux/purchases/selectors'
import {
  assembleCart,
  handleHabitualCategories,
  selectCart,
  selectCenterId,
  selectedCategories,
} from 'Redux/purchases/utils'
import {
  deleteShoppingList,
  deleteShoppingListProduct,
  getShoppingList,
  getShoppingListProducts,
  postSendCart,
  postShoppingList,
  putShoppingList,
  putShoppingListProduct,
  postShoppingListProduct,
} from 'Services/api'
import * as Sentry from 'Services/sentry'
import { fetchCart } from 'Redux/purchases/sagas'
import analyticsService from 'Services/analytics'

import {
  setErrorAdd,
  setIsFetchingAdd,
  setIsFetchingListProducts,
  setIsFetchingLists,
  setIsFetchingUpdate,
  setIsSendingcart,
  setListData,
  setListProducts,
  setLists,
} from './actions'
import {
  ADD_LIST,
  FETCH_LISTS,
  FETCH_LIST_PRODUCTS,
  REMOVE_LIST,
  REMOVE_PRODUCT,
  SEND_CART,
  UPDATE_PRODUCT,
  UPDATE_TITLE,
  ADD_PRODUCT,
} from './types'
import { selectShoppingList, selectShoppingListData, selectShoppingListProducts } from './selectors'

export function* fetchLists() {
  yield put(setIsFetchingLists(true))
  try {
    const centerId = yield select(selectCenterId)
    const response = yield call(getShoppingList, centerId)
    yield put(setLists(response))
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(setLists(null))
      toast.error(e?.response?.data?.message || i18next.t('shoppingList.errorMessages.fetchLists'))
      Sentry.captureException(e)
    }
  } finally {
    yield put(setIsFetchingLists(false))
  }
}

export function* addList(action) {
  yield put(setIsFetchingAdd(true))

  const title = get(action, 'payload')
  const successCallback = get(action, 'successCallback')

  const centerId = yield select(selectCenterId)
  const cart = yield select(selectCart)

  const products = map(cart, product => ({
    productId: product.id,
    amount: product.amount,
  }))

  const data = { title, products }

  try {
    yield call(postShoppingList, data, centerId)
    toast.success(i18next.t('shoppingList.create.success'))
    if (successCallback) successCallback()

    yield fork(fetchLists)
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(setErrorAdd(e?.response?.data?.message || i18next.t('shoppingList.create.errorDuplicated')))
      Sentry.captureException(e)
    }
  } finally {
    yield put(setIsFetchingAdd(false))
  }
}

export function* removeList(action) {
  const listId = get(action, 'payload.id')
  const centerId = yield select(selectCenterId)
  const lists = yield select(selectShoppingList)

  try {
    const newLists = filter(lists, ({ id }) => id !== listId)
    yield put(setLists(newLists))

    yield call(deleteShoppingList, listId, centerId)
    toast.success(i18next.t('shoppingList.remove.success'))

    yield fork(fetchLists)
  } catch (e) {
    if (!axios.isCancel(e)) {
      toast.error(e?.response?.data?.message || i18next.t('shoppingList.errorMessages.removeList'))
      Sentry.captureException(e)
    }
  }
}

export function* fetchListProducts(action) {
  yield put(setIsFetchingListProducts(true))
  const id = get(action, 'payload.id')

  try {
    const centerId = yield select(selectCenterId)
    const response = yield call(getShoppingListProducts, id, centerId)
    const products = assembleCart(response?.products)
    yield put(setListProducts(products))
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(setListData(null))
      yield put(setListProducts(null))
      toast.error(e?.response?.data?.message || i18next.t('shoppingList.errorMessages.fetchListsProducts'))
      Sentry.captureException(e)
    }
  } finally {
    yield put(setIsFetchingListProducts(false))
  }
}

export function* removeProduct(action) {
  const product = get(action, 'payload')
  const centerId = yield select(selectCenterId)
  const data = yield select(selectShoppingListData)
  const products = yield select(selectShoppingListProducts)

  try {
    const newProducts = { ...products }
    delete newProducts[product?.id]
    yield put(setListProducts(newProducts))
    yield call(deleteShoppingListProduct, data?.id, product?.reference, centerId)

    yield fork(fetchLists)
  } catch (e) {
    if (!axios.isCancel(e)) {
      toast.error(e?.response?.data?.message || i18next.t('shoppingList.errorMessages.removeProduct'))
      Sentry.captureException(e)
    }
  }
}

export function* updateTitleList(action) {
  yield put(setIsFetchingUpdate(true))

  const title = get(action, 'payload')
  const successCallback = get(action, 'successCallback')

  const centerId = yield select(selectCenterId)
  const data = yield select(selectShoppingListData)

  try {
    yield put(setListData({ ...data, title }))
    yield call(putShoppingList, data?.id, { title }, centerId)
    toast.success(i18next.t('shoppingList.edit.success'))
    if (successCallback) successCallback()

    yield fork(fetchLists)
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(setListData({ ...data }))
      yield put(setErrorAdd(e?.response?.data?.message || i18next.t('shoppingList.edit.errorDuplicated')))
      Sentry.captureException(e)
    }
  } finally {
    yield put(setIsFetchingUpdate(false))
  }
}

export function* sendCart(action) {
  yield put(setIsSendingcart(true))

  const id = get(action, 'payload')
  const centerId = yield select(selectCenterId)

  try {
    yield call(postSendCart, id, centerId)
    toast.success(i18next.t('shoppingList.add.success'))
    yield fork(fetchCart)
  } catch (e) {
    if (!axios.isCancel(e)) {
      toast.error(e?.response?.data?.message || i18next.t('shoppingList.errorMessages.sendToCart'))
      Sentry.captureException(e)
    }
  } finally {
    yield put(setIsSendingcart(false))
  }
}

export function* updateProduct(action) {
  const product = get(action, 'payload')
  const centerId = yield select(selectCenterId)
  const data = yield select(selectShoppingListData)
  const products = yield select(selectShoppingListProducts)
  const body = { reference: product?.reference, amount: product?.amount }

  try {
    const newProducts = { ...products }
    newProducts[product?.id] = product
    yield put(setListProducts(newProducts))
    yield call(putShoppingListProduct, data?.id, body, centerId)
  } catch (e) {
    if (!axios.isCancel(e)) {
      toast.error(e?.response?.data?.message || i18next.t('shoppingList.errorMessages.updateProduct'))
      Sentry.captureException(e)
    }
  }
}

export function* addProduct(action) {
  const productId = get(action, 'payload.productId')
  const id = get(action, 'payload.listId')
  const successCallback = get(action, 'payload.successCallback')
  const errorCallback = get(action, 'payload.errorCallback')
  const centerId = yield select(selectCenterId)
  const categories = yield select(selectedCategories)
  const selectedHabitualCategories = yield select(selectSelectedCategoriesHabitual)
  const habitualCategories = handleHabitualCategories(selectedHabitualCategories)

  const lists = yield select(selectShoppingList)
  const listName = lists?.find(list => list.id === id)?.title

  const products = yield select(selectPurchaseProducts)
  const product = products?.find(p => p.id === productId)

  analyticsService.addToWishlist({
    products: product ? [{ ...product, amount: product.min }] : [],
    selectedCategories: habitualCategories || categories,
    wishListName: listName,
  })

  try {
    yield call(postShoppingListProduct, id, productId, centerId)
    toast.success(i18next.t('shoppingList.addToList.success'))
    if (successCallback) successCallback()
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(setErrorAdd(i18next.t('petitionError')))
      toast.error(e?.response?.data?.message || i18next.t('shoppingList.errorMessages.addProduct'))
      if (errorCallback) errorCallback()
      Sentry.captureException(e)
    }
  }
}

function* watchFetchLists() {
  yield takeLatest(FETCH_LISTS, fetchLists)
}

function* watchAddList() {
  yield takeLatest(ADD_LIST, addList)
}

function* watchRemoveList() {
  yield takeLatest(REMOVE_LIST, removeList)
}

function* watchFetchProducts() {
  yield takeLatest(FETCH_LIST_PRODUCTS, fetchListProducts)
}

function* watchRemoveProduct() {
  yield takeLatest(REMOVE_PRODUCT, removeProduct)
}

function* watchUpdateTitleList() {
  yield takeLatest(UPDATE_TITLE, updateTitleList)
}

function* watchSendCart() {
  yield takeLatest(SEND_CART, sendCart)
}

function* watchUpdateProduct() {
  yield takeEvery(UPDATE_PRODUCT, updateProduct)
}

function* watchAddProduct() {
  yield takeEvery(ADD_PRODUCT, addProduct)
}

export default function* rootDeliverySaga() {
  yield all([
    watchFetchLists(),
    watchAddList(),
    watchRemoveList(),
    watchFetchProducts(),
    watchRemoveProduct(),
    watchUpdateTitleList(),
    watchSendCart(),
    watchUpdateProduct(),
    watchAddProduct(),
  ])
}
