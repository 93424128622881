import { all, call, put, select, takeLeading, takeLatest, delay } from 'redux-saga/effects'
import axios from 'axios'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import { getDeliveryErrors, getProviderReceivingOrders, putProviderReceivingOrders } from 'Services/api'
import * as Sentry from 'Services/sentry'
import { getApiErrorMessage } from 'Config/utils'

import {
  setProviderReceivingOrders,
  setLoadingProviderReceivingOrders,
  setErrorProviderReceivingOrders,
  setDeliveryForm,
  setDeliveryErrors,
} from './actions'
import {
  PROVIDER_RECIVING_ORDERS,
  PROVIDER_RECIVING_ORDERS_RESET,
  DELIVERY_FORM,
  PROVIDER_RECIVING_ORDERS_FORM,
  FETCH_DELIVERY_ERRORS,
} from './types'
import { selectCenterId } from './utils'

export function* providerReceivingOrders(action) {
  try {
    yield put(setProviderReceivingOrders({ providerOrder: {}, receptionlines: null }))
    yield put(setDeliveryForm({}))
    const params = get(action, 'params', {})
    const { orderId, documentTypeCode, documentCompany, ...restParams } = params
    const centerId = yield select(selectCenterId)
    yield put(setLoadingProviderReceivingOrders(true))
    const response = yield call(
      getProviderReceivingOrders,
      restParams,
      centerId,
      orderId,
      documentTypeCode,
      documentCompany
    )
    const { providerOrder = {}, receptionlines = [] } = response || { providerOrder: {}, receptionlines: [] }
    yield put(setProviderReceivingOrders({ providerOrder, receptionlines }))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(getApiErrorMessage(e))
      yield put(setErrorProviderReceivingOrders(e))
    }
  } finally {
    yield put(setLoadingProviderReceivingOrders(false))
  }
}

export function* deliveryForm(action) {
  const params = get(action, 'params', {})
  yield put(setDeliveryForm(params))
}

export function* providerReceivingOrdersForm(action) {
  const params = get(action, 'params', {})
  const { orderId, documentTypeCode, documentCompany, form } = params
  const centerId = yield select(selectCenterId)
  yield put(setProviderReceivingOrders(form))
  yield delay(1000)
  try {
    yield call(putProviderReceivingOrders, form, centerId, orderId, documentTypeCode, documentCompany)
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(getApiErrorMessage(e))
      yield put(setErrorProviderReceivingOrders(e))
    }
  }
}

export function* fetchDeliveryErrors() {
  const centerId = yield select(selectCenterId)
  try {
    if (centerId) {
      const { data } = yield call(getDeliveryErrors, centerId)
      yield put(setDeliveryErrors(data))
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(getApiErrorMessage(e))
    }
  }
}

export function* providerReceivingOrdersReset() {
  yield put(setProviderReceivingOrders({ providerOrder: {}, receptionlines: null }))
}

function* watchProviderReceivingOrders() {
  yield takeLeading(PROVIDER_RECIVING_ORDERS, providerReceivingOrders)
}

function* watchProviderReceivingOrdersReset() {
  yield takeLeading(PROVIDER_RECIVING_ORDERS_RESET, providerReceivingOrdersReset)
}

function* wathSetDeliveryForm() {
  yield takeLatest(DELIVERY_FORM, deliveryForm)
}

function* watchSetProviderReceivingOrdersForm() {
  yield takeLatest(PROVIDER_RECIVING_ORDERS_FORM, providerReceivingOrdersForm)
}

function* watchFetchDeliveryErrors() {
  yield takeLatest(FETCH_DELIVERY_ERRORS, fetchDeliveryErrors)
}

export default function* rootDeliverySaga() {
  yield all([
    watchProviderReceivingOrders(),
    watchProviderReceivingOrdersReset(),
    wathSetDeliveryForm(),
    watchSetProviderReceivingOrdersForm(),
    watchFetchDeliveryErrors(),
  ])
}
