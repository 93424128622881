import { ANALYTICS_EVENT } from './constants'
import {
  convertCategoriesToArray,
  handleListCategoryType,
  mapListId,
  mapListName,
  sendAnalytics,
  sumProductsTotalValue,
  quantityFormater,
  valueFormatter,
} from './utils'

const addToWishlist = ({ products, selectedCategories, wishListName }) => {
  if (!products || !selectedCategories) {
    return
  }

  const categoriesToArray = convertCategoriesToArray(selectedCategories)
  const listName = mapListName(categoriesToArray)
  const listId = mapListId(categoriesToArray)
  const listCategoryType = handleListCategoryType(categoriesToArray)
  const total = sumProductsTotalValue(products)

  const analyticsData = {
    item_list_name: listName,
    item_list_id: listId,
    list_category_type: listCategoryType,
    value: valueFormatter(total),
    currency: 'EUR',
    step: !wishListName ? 1 : 2,
    items: products.map(({ name, id, price, provider, category, temperature, amount }) => ({
      item_list_name: listName,
      item_list_id: listId,
      item_name: name,
      item_id: id,
      item_category: category?.name,
      item_category2: category?.children?.[0]?.name,
      item_category3: category?.children?.[0]?.children?.[0]?.name,
      item_category4: temperature,
      price: valueFormatter(price),
      currency: 'EUR',
      affiliation: `${provider?.name} - ${provider?.id}`,
      quantity: quantityFormater(amount),
    })),
  }

  if (wishListName) {
    analyticsData.custom_list_name = wishListName
  }

  sendAnalytics(ANALYTICS_EVENT.ADD_TO_WISHLIST, analyticsData)
}

export default addToWishlist
