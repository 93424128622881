export const LOGIN = 'portal_compras/auth/LOGIN'
export const LOGIN_MSAL = 'portal_compras/auth/LOGIN_MSAL'
export const SET_TOKEN = 'portal_compras/auth/SET_TOKEN'
export const SET_REFRESH_TOKEN = 'portal_compras/auth/SET_REFRESH_TOKEN'
export const SET_ERROR_TOKEN = 'portal_compras/auth/SET_ERROR_TOKEN'
export const TOKEN_REQUESTED = 'portal_compras/auth/TOKEN_REQUESTED'
export const SET_CENTERS_LIST = 'portal_compras/auth/SET_CENTERS_LIST'
export const SET_ERROR_CENTERS_LIST = 'portal_compras/auth/SET_ERROR_CENTERS_LIST'
export const SET_CENTER_COUNT = 'portal_compras/auth/SET_CENTER_COUNT'
export const SET_SELECTED_CENTER = 'portal_compras/auth/SET_SELECTED_CENTER'
export const FETCH_AUTOCOMPLETE_CENTERS = 'portal_compras/auth/FETCH_AUTOCOMPLETE_CENTERS'
export const SET_USER = 'portal_compras/auth/SET_USER'
export const LOGOUT = 'portal_compras/auth/LOGOUT'
export const SET_HAS_ONLY_ONE_CENTER = 'portal_compras/auth/SET_HAS_ONLY_ONE_CENTER'
export const INITIAL_CENTER_REQUEST = 'portal_compras/auth/INITIAL_CENTER_REQUEST'
export const HIDE_NAVBAR = 'portal_compras/auth/HIDE_NAVBAR'
export const SET_ERROR_LOGIN = 'portal_compras/auth/SET_ERROR_LOGIN'
export const LOGOUT_USER = 'portal_compras/auth/LOGOUT_USER'
export const IS_LOADING_CENTER = 'portal_compras/auth/IS_LOADING_CENTER'
export const IS_LOADING_LOGIN = 'portal_compras/auth/IS_LOADING_LOGIN'
export const SET_IS_LOADING_MSAL = 'portal_compras/auth/SET_IS_LOADING_MSAL'
export const SET_IS_READY = 'portal_compras/auth/SET_IS_READY'
export const SET_REFRESHING_CATEGORY = 'portal_compras/auth/SET_REFRESHING_CATEGORY'
export const FETCH_FEATURE_FLAG_CONFIG = 'portal_compras/auth/FETCH_FEATURE_FLAG_CONFIG'
export const SET_FEATURE_FLAG_CONFIG = 'portal_compras/auth/SET_FEATURE_FLAG_CONFIG'
export const IS_INITIAL_FECTH_CENTERS = 'portal_compras/auth/IS_INITIAL_FECTH_CENTERS'
