import {
  CANCEL_REQUEST_ORDER,
  FETCH_APPROVED,
  FETCH_CANCELLED,
  FETCH_CART,
  FETCH_PENDING_APPROVAL,
  FETCH_REJECTED,
  SET_APPROVED,
  SET_CANCELLED,
  SET_CART,
  SET_FILTER_PROVIDER,
  SET_IS_FETCHING_APPROVED,
  SET_IS_FETCHING_CANCELLED,
  SET_IS_FETCHING_CART,
  SET_IS_FETCHING_PENDING_APPROVAL,
  SET_IS_FETCHING_REJECTED,
  SET_IS_UPDATING_CART,
  SET_PENDING_APPROVAL,
  SET_REJECTED,
  SET_REQUEST_ORDER,
  SET_FILTER_REQUEST_NUMBER,
  FETCH_CART_REQUEST,
  SET_CURRENT_PAGE_CART,
  SET_HAS_MORE_REQUEST,
} from './types'

export const fetchPendingApproval = () => ({
  type: FETCH_PENDING_APPROVAL,
})

export const setPendingApproval = data => ({
  type: SET_PENDING_APPROVAL,
  payload: data,
})

export const setIsFetchingPendingApproval = data => ({
  type: SET_IS_FETCHING_PENDING_APPROVAL,
  payload: data,
})

export const fetchRejected = () => ({
  type: FETCH_REJECTED,
})

export const setRejected = data => ({
  type: SET_REJECTED,
  payload: data,
})

export const setIsFetchingRejected = data => ({
  type: SET_IS_FETCHING_REJECTED,
  payload: data,
})

export const fetchApproved = () => ({
  type: FETCH_APPROVED,
})

export const setApproved = data => ({
  type: SET_APPROVED,
  payload: data,
})

export const setIsFetchingApproved = data => ({
  type: SET_IS_FETCHING_APPROVED,
  payload: data,
})

export const fetchCancelled = () => ({
  type: FETCH_CANCELLED,
})

export const setCancelled = data => ({
  type: SET_CANCELLED,
  payload: data,
})

export const setIsFetchingCancelled = data => ({
  type: SET_IS_FETCHING_CANCELLED,
  payload: data,
})

export const setFilterProvider = providers => ({
  type: SET_FILTER_PROVIDER,
  payload: providers,
})

export const fetchCart = (requestOrderId, state) => ({
  type: FETCH_CART,
  payload: { requestOrderId, state },
})

export const fetchCartRequest = (requestOrderId, state, page, pageSize) => ({
  type: FETCH_CART_REQUEST,
  payload: { requestOrderId, state, page, pageSize },
})

export const setCart = data => ({
  type: SET_CART,
  payload: data,
})

export const setCurrentPageCart = data => ({
  type: SET_CURRENT_PAGE_CART,
  payload: data,
})

export const setIsFetchingCart = data => ({
  type: SET_IS_FETCHING_CART,
  payload: data,
})

export const setRequestOrder = data => ({
  type: SET_REQUEST_ORDER,
  payload: data,
})

export const cancelRequestOrder = data => ({
  type: CANCEL_REQUEST_ORDER,
  payload: data,
})

export const setIsUpdatingCart = data => ({
  type: SET_IS_UPDATING_CART,
  payload: data,
})

export const setFilterRequestNumber = data => ({
  type: SET_FILTER_REQUEST_NUMBER,
  payload: data,
})

export const setHasMore = data => ({
  type: SET_HAS_MORE_REQUEST,
  payload: data,
})
