import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { RegularText, BigTitle } from 'Components/atoms'
import Image from 'Assets/Images/center.png'

import { ImageNotCenter, SearchContainer, ButtonContainer, ActionButton, ButtonLiteral } from './styled'

const NotCenterView = ({ onSelect }) => {
  const { t } = useTranslation()

  return (
    <SearchContainer>
      <BigTitle textAlign='center'>{t('summary.selectCenter')}</BigTitle>
      <RegularText margin='16px' textAlign='center'>
        {t('summary.search')}
      </RegularText>
      <ImageNotCenter src={Image} />
      <ButtonContainer>
        <ActionButton onClick={onSelect}>
          <ButtonLiteral>{t('select')}</ButtonLiteral>
        </ActionButton>
      </ButtonContainer>
    </SearchContainer>
  )
}

NotCenterView.propTypes = {
  onSelect: PropTypes.func,
}

NotCenterView.defaultProps = {
  onSelect: () => {},
}

export default NotCenterView
