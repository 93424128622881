import { APPROVAL_STATE } from 'Config/constants'
import forEach from 'lodash/forEach'

export const mapRequestOrderCart = (data, lines, requestOrder, prevData = {}) => {
  const cart = {
    totalAmount: prevData?.totalAmount || 0,
    totalPrice: prevData?.totalPrice || 0,
    totalProducts: prevData?.totalProducts || 0,
    data: prevData?.data ? { ...prevData.data } : {},
  }
  forEach(data, ({ providerId, providerName, deliveryDate, ...product }) => {
    const totalPrice = product.totalPrice || product.price * product.amount
    if (!cart.data[providerId] || !cart.data[providerId].products) {
      cart.data[providerId] = {
        providerId,
        providerName,
        deliveryDate,
        products: { [product.productId]: product },
        totalPrice,
        totalAmount: product.amount,
        totalProducts: 1,
      }
    } else {
      cart.data[providerId].products[product.productId] = product
      cart.data[providerId].totalPrice += totalPrice
      cart.data[providerId].totalAmount += product.amount
      cart.data[providerId].totalProducts += 1
    }
    cart.totalAmount += cart.data[providerId].totalAmount
    cart.totalPrice += cart.data[providerId].totalPrice
    cart.totalProducts += 1
  })

  if (lines) {
    cart.approvedTotalAmount = 0
    cart.approvedTotalPrice = 0
    cart.approvedTotalProducts = 0

    forEach(lines, line => {
      if (line.status !== APPROVAL_STATE.DELETED) {
        cart.approvedTotalProducts += 1
        cart.approvedTotalPrice += line.totalPrice
        cart.approvedTotalAmount += line.amount
      }

      if (!cart.data[line.providerId]) {
        cart.data[line.providerId] = {
          ...line,
          totalPrice: line.originalPrice,
          totalAmount: line.originalAmount,
          totalProducts: 1,
          products: { [line.productId]: line },
        }
      } else if (!cart.data[line.providerId].products[line.productId]) {
        cart.data[line.providerId].products[line.productId] = line
        cart.data[line.providerId].totalPrice += line.originalPrice
        cart.data[line.providerId].totalAmount += line.originalAmount
        cart.data[line.providerId].totalProducts += 1
      }

      if (!cart.data[line.providerId].approvedPrice) {
        cart.data[line.providerId].approvedPrice = line.price
        cart.data[line.providerId].approvedTotalPrice = line.totalPrice
        cart.data[line.providerId].approvedAmount = line.amount

        if (line.status !== APPROVAL_STATE.DELETED) {
          cart.data[line.providerId].approvedTotalProducts = 1
        } else {
          cart.data[line.providerId].approvedTotalProducts = 0
        }
      } else {
        cart.data[line.providerId].approvedPrice += line.price
        cart.data[line.providerId].approvedTotalPrice += line.totalPrice
        cart.data[line.providerId].approvedAmount += line.amount

        if (line.status !== APPROVAL_STATE.DELETED) {
          cart.data[line.providerId].approvedTotalProducts += 1
        }
      }

      cart.data[line.providerId].approvedDeliveryDate = line.deliveryDate || requestOrder?.deliveryDate

      cart.data[line.providerId].products[line.productId].status = line.status
      cart.data[line.providerId].products[line.productId].approvedAmount = line.amount
      cart.data[line.providerId].products[line.productId].approvedPrice = line.price
      cart.data[line.providerId].products[line.productId].approvedTotalPrice = line.totalPrice
      cart.data[line.providerId].products[line.productId].approvedDeliveryDate = line.deliveryDate

      if (line.status === APPROVAL_STATE.ADDED) {
        cart.data[line.providerId].products[line.productId].amount = 0
      }
    })
  }

  return cart
}
