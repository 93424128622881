import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { ModalConfirmation } from 'Components/molecules'
import { ReactComponent as AddCartIcon } from 'Assets/Icons/AddCart.svg'
import { sendcart } from 'Redux/lists/actions'

import { AddCartButtonContainer } from '../styled'

const AddCartButton = ({ item }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const cart = useSelector(state => state.purchase.cart)

  const [showModalConfirmation, setShowModalConfirmation] = useState(false)

  const handleAccept = () => {
    setShowModalConfirmation(false)
    dispatch(sendcart(item?.id))
  }

  const handleCancel = () => setShowModalConfirmation(false)

  const handleClick = () => {
    if (isEmpty(cart)) {
      handleAccept()
    } else {
      setShowModalConfirmation(true)
    }
  }

  return (
    <>
      <AddCartButtonContainer onClick={handleClick}>
        <AddCartIcon style={{ marginRight: '0.5rem' }} />
        {t('addCart')}
      </AddCartButtonContainer>
      <ModalConfirmation
        onCancel={handleCancel}
        onAccept={handleAccept}
        showModal={showModalConfirmation}
        setShowModal={setShowModalConfirmation}
        message={t('shoppingList.add.confirm')}
        title={t('addCart')}
        action={t('addCart')}
        color='secondary'
        width='680px'
        height='256px'
      />
    </>
  )
}

AddCartButton.defaultProps = {
  item: {},
}

AddCartButton.propTypes = {
  item: PropTypes.object,
}

export default AddCartButton
