import styled from 'styled-components'

export const StyledMaintenanceMode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  white-space: pre-line;
  text-align: center;
  font-family: sans-serif;
  font-size: 2rem;
`
