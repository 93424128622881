import axios from 'axios'
import qs from 'qs'

export const getBudget = centerId => axios.get('/api/centers/budget', { headers: { 'X-Center-Id': centerId } })

export const getOriginCenters = centerId => axios.get('/api/centers/origins', { headers: { 'X-Center-Id': centerId } })

export const getCenters = params => axios.get(`/api/centers/me?${qs.stringify(params, { skipNulls: true })}`)

export const getMulticenterCenters = params =>
  axios.get(`/api/centers/multicenter?${qs.stringify(params, { skipNulls: true })}`)
