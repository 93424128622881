import colors from 'Assets/colors'
import styled from 'styled-components'

export const UnderlineButton = styled.button`
  text-decoration: underline;
  font-size: 0.9rem;
  font-family: Inter;
  line-height: 24px;
  cursor: pointer;
`

export const MainContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: 40px;
  gap: 0.5rem;
  font-weight: 500;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    svg {
      path {
        fill: ${colors.secondary};
        transition: all 0.2s;
      }
    }

    button {
      color: ${colors.secondary};
      transition: all 0.2s;
    }
  }
`
