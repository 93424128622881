import React from 'react'

import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowDown } from 'Assets/Icons/Arrow-Down-complete.svg'

import { Container, RedSquare } from './styled'

const ProductsNotAvailable = ({ onClick }) => (
  <Container>
    <RedSquare>
      <span>
        <Trans i18nKey='someProductsNotAvailableCart' components={{ bold: <bold /> }} />
        <ArrowDown onClick={onClick} cursor='pointer' />
      </span>
    </RedSquare>
  </Container>
)

ProductsNotAvailable.propTypes = {
  onClick: PropTypes.func,
}

ProductsNotAvailable.defaultProps = {
  onClick: () => null,
}

export default ProductsNotAvailable
