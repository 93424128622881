import { all, delay, call, put, select, takeEvery, fork, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import {
  getNoConformitiesCategories,
  getNoConformitiesTickets,
  getNoConformitiesTicketInfo,
  putNoConformitiesTicketComment,
  getNoConformitiesPendingTickets,
} from 'Services/api'
import * as Sentry from 'Services/sentry'
import i18next from 'i18next'

import { FETCH_CATEGORIES, FETCH_TICKETS, FETCH_TICKET, ADD_TICKET_COMMENT, FETCH_PENDING_TICKETS } from './types'
import { selectCenterId, selectFilterStatus } from './selectors'
import { mapTicketsList } from './utils'
import {
  setTicketCategories,
  setTicketError,
  setTickets,
  setTicketsIsLoading,
  setTicket,
  setTicketCommentIsLoading,
  setPendingTickets,
} from './actions'

export function* fetchCategories() {
  yield delay(100)
  try {
    const centerId = yield select(selectCenterId)
    const response = yield call(getNoConformitiesCategories, centerId)
    yield put(setTicketCategories(response))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      yield put(setTicketError(e))
    }
  }
}

export function* fetchTickets(action) {
  yield delay(100)
  yield put(setTicketsIsLoading(true))

  try {
    const centerId = yield select(selectCenterId)
    const status = yield select(selectFilterStatus)
    const params = get(action, 'params', {})
    const response = yield call(getNoConformitiesTickets, centerId, { ...params, status })
    const { data = [], count = 0 } = response
    const list = mapTicketsList(data)
    yield put(setTickets({ list, count }))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      yield put(setTicketError(e))
    }
  } finally {
    yield put(setTicketsIsLoading(false))
  }
}

export function* fetchPendingTickets() {
  yield delay(100)
  yield put(setTicketsIsLoading(true))

  try {
    const centerId = yield select(selectCenterId)

    const response = yield call(getNoConformitiesPendingTickets, centerId)
    const { data = [], count = 0 } = response
    const list = mapTicketsList(data)
    yield put(setPendingTickets({ list, count }))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      yield put(setTicketError(e))
    }
  } finally {
    yield put(setTicketsIsLoading(false))
  }
}

export function* fetchTicket(action) {
  const params = get(action, 'params', {})
  const { id } = params

  yield delay(100)
  yield put(setTicketsIsLoading(true))

  try {
    const centerId = yield select(selectCenterId)
    const response = yield call(getNoConformitiesTicketInfo, centerId, id)
    yield put(setTicket(response))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(e?.response?.data?.message || i18next.t('ticketingErrors.fetchTicket'))
      yield put(setTicketError(e))
    }
  } finally {
    yield put(setTicketsIsLoading(false))
  }
}

export function* addTicketComment(action) {
  const params = get(action, 'params', {})
  const { id, ...payload } = params

  yield delay(100)
  yield put(setTicketCommentIsLoading(true))

  try {
    const centerId = yield select(selectCenterId)
    yield call(putNoConformitiesTicketComment, centerId, id, payload)
    yield fork(fetchTicket, action)
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(e?.response?.data?.message || i18next.t('ticketingErrors.addComment'))
      yield put(setTicketError(e))
    }
  } finally {
    yield put(setTicketCommentIsLoading(false))
  }
}

function* watchFetchCategories() {
  yield takeEvery(FETCH_CATEGORIES, fetchCategories)
}

function* watchFetchTickets() {
  yield takeEvery(FETCH_TICKETS, fetchTickets)
}

function* watchFetchPendingTickets() {
  yield takeLatest(FETCH_PENDING_TICKETS, fetchPendingTickets)
}

function* watchFetchTicket() {
  yield takeEvery(FETCH_TICKET, fetchTicket)
}

function* watchAddTicketComment() {
  yield takeEvery(ADD_TICKET_COMMENT, addTicketComment)
}

export default function* rootTicketSaga() {
  yield all([
    watchFetchCategories(),
    watchFetchTickets(),
    watchFetchPendingTickets(),
    watchFetchTicket(),
    watchAddTicketComment(),
  ])
}
