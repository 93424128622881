import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { addList, setErrorAdd } from 'Redux/lists/actions'

const useShoppingListsCreateModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(s => s.lists.isFetchingAdd)
  const error = useSelector(s => s.lists.errorAdd)

  const [name, setName] = useState('')

  useEffect(() => {
    setName('')
  }, [showModal])

  const onChangeName = e => {
    setName(e.target.value)
    dispatch(setErrorAdd(''))
  }

  const onSubmit = () => {
    dispatch(addList(name, () => setShowModal(false)))
  }

  return {
    name,
    error,
    isLoading,
    onSubmit,
    onChangeName,
  }
}

export default useShoppingListsCreateModal
