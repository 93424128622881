import React from 'react'

import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import find from 'lodash/find'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { useHistory } from 'react-router-dom'
import routes from 'Config/routes'
import { ReactComponent as CompassCenterIcon } from 'Assets/Icons/CompassCenter.svg'
import { ReactComponent as ArrowLeftIcon } from 'Assets/Icons/ArrowLeft.svg'
import { ReactComponent as RequestIcon } from 'Assets/Icons/Solicitudes.svg'
import { ReactComponent as SwitchOnIcon } from 'Assets/Icons/SwitchOn.svg'
import { setCart, setRequestOrder } from 'Redux/approver/actions'
import { numberOfPendingRequests } from 'Redux/approver/selectors'
import { AuthTypes } from 'Redux/auth'
import { ROLES } from 'Config/constants'
import { useMsal } from '@azure/msal-react'
import { logoutRequest } from 'Config/mfa-config'

import { useStyles, Container, Topbar, Badge } from './styled'

export default function ImpersonatedNavBar({ showBanner, showAppBar, showBack }) {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      {showBanner && <ImpersonateApprovedBannerInfo />}
      {showAppBar && (
        <AppBar position='static' color='default' className={classes.bar}>
          <Toolbar className={classes.toolbar}>
            <CenterMenuButton />
            <PendingRequestsButton />
            <MenuItemSeparator />
            <LogoutMenuButton />
            <UserInfo />
          </Toolbar>
        </AppBar>
      )}
      {showBack && (
        <AppBar position='static' color='default' className={classes.bar2}>
          <Toolbar className={classes.toolbar}>
            <BackButton />
          </Toolbar>
        </AppBar>
      )}
    </Container>
  )
}

ImpersonatedNavBar.defaultProps = {
  showBanner: false,
  showAppBar: false,
  showBack: false,
}

ImpersonatedNavBar.propTypes = {
  showBanner: PropTypes.bool,
  showAppBar: PropTypes.bool,
  showBack: PropTypes.bool,
}

const MenuItemSeparator = () => {
  const classes = useStyles()
  return <div className={classes.title} />
}

const CenterMenuButton = () => {
  const classes = useStyles()

  const center = useSelector(({ auth }) => auth.selectedCenter)

  return (
    <Button startIcon={<CompassCenterIcon />} className={classes.menuButton} color='inherit'>
      {`${center.name} - ${center.id}`}
    </Button>
  )
}

const ImpersonateApprovedBannerInfo = () => {
  const { t } = useTranslation()

  const requestOrder = useSelector(state => state.approver.requestOrder)
  const orderId = get(requestOrder, 'id', '')

  return (
    <Topbar>
      <p>
        <strong>{t('approvedInfo', { orderId })}</strong>
      </p>
    </Topbar>
  )
}

const BackButton = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const handleClickBack = () => history.push(routes.approverCarts)

  return (
    <Button
      aria-label={t('approverTutorial.stepTen.target')}
      startIcon={<ArrowLeftIcon />}
      className={classes.menuButton}
      color='inherit'
      onClick={handleClickBack}
    >
      {t('backToRequest')}
    </Button>
  )
}

const PendingRequestsButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const badge = useSelector(numberOfPendingRequests)

  const goToTicketing = () => {
    dispatch(setRequestOrder(null))
    dispatch(setCart(null))
    history.push(routes.approverCarts)
  }

  return (
    <Button
      onClick={goToTicketing}
      startIcon={<RequestIcon />}
      className={classes.menuButton}
      color='inherit'
      data-cy='pending-request-nav'
    >
      {t('approveRequest')}
      {parseInt(badge, 10) > 0 && <Badge>{badge}</Badge>}
    </Button>
  )
}

const LogoutMenuButton = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { instance } = useMsal()

  const user = useSelector(state => state.auth.user)

  const logout = async () => {
    dispatch({
      type: AuthTypes.LOGOUT_USER,
    })
    if (user.msal) {
      await instance.logout(logoutRequest)
    }
  }

  return (
    <IconButton
      color='inherit'
      onClick={logout}
      style={{ textTransform: 'none ' }}
      className={classes.logout}
      data-cy='logout'
    >
      <SwitchOnIcon />
    </IconButton>
  )
}

const UserInfo = () => {
  const classes = useStyles()

  const user = useSelector(state => state.auth.user)

  const role = find(user.roles, elem => elem !== 'ROLE_USER')

  if (!user) return null

  return (
    <>
      <span className={classes.username}>{user.username}</span>
      {ROLES[role] && <span className={classes.role}>{ROLES[role]}</span>}
    </>
  )
}
