import styled from 'styled-components'
import colors from 'Assets/colors'
import FilterTicket from 'Components/molecules/filterTicket'

export const Container = styled.div`
  height: calc(100vh - 64px);
  padding: 34px 20px 0;
  background-color: ${colors.gray98b};
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .filters-holder {
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 14px;
  }

  .table-holder {
    height: calc(100% - 64px - 12px);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px #00000040;
    margin-bottom: 12px;

    > div {
      height: 100% !important;
    }

    .grid-root {
      border-top: none;
      height: 100% !important;
    }
  }
`

export const StyledStatusFilter = styled(FilterTicket)`
  position: relative;

  & > :first-child {
    border-radius: 8px;
    flex-direction: row-reverse;
    column-gap: 1rem;

    div,
    svg {
      margin: 0;
    }
  }

  & > :last-child {
    top: 60px;
    left: -10px;
    z-index: 999;
  }
`
