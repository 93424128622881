import React, { useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FilterIcon } from 'Assets/Icons/Filter.svg'
import { useClickOutside } from 'Components/utils/customHooks'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { FilterButton, FilterContainer, StyledCheckbox, CheckGroup, FilterContainerInner } from './styled'

const FilterProviderProducts = ({ providers, onChangeFilters }) => {
  const [displayFilter, setDisplayFilter] = useState('none')
  const filterRef = useRef()
  const closeFilters = () => setDisplayFilter('none')
  useClickOutside(filterRef, closeFilters)
  const { t } = useTranslation()

  return (
    <div ref={filterRef} style={{ position: 'relative' }}>
      <FilterButton onClick={e => setDisplayFilter(e.target.value)}>
        <FilterIcon className='icon' />
        {t('filterProviderProducts')}
      </FilterButton>
      <FilterContainer display={displayFilter}>
        <FilterContainerInner>
          {providers?.map(provider => (
            <CheckGroup key={provider.id}>
              <FormControlLabel
                key={provider.id}
                value={provider.id}
                control={<StyledCheckbox onChange={e => onChangeFilters(e.target.value, e.target.checked)} />}
                label={provider.name}
                labelPlacement='start'
              />
            </CheckGroup>
          ))}
        </FilterContainerInner>
      </FilterContainer>
    </div>
  )
}

FilterProviderProducts.defaultProps = {
  providers: [],
  onChangeFilters: () => {},
}

FilterProviderProducts.propTypes = {
  providers: PropTypes.array,
  onChangeFilters: PropTypes.func,
}

export default FilterProviderProducts
