import { ANALYTICS_EVENT } from './constants'
import { quantityFormater, sendAnalytics, sumProductsTotalValue, valueFormatter } from './utils'

const purchase = ({ products, transactionId, purchaseType }) => {
  if (!products) return

  const total = sumProductsTotalValue(products)
  sendAnalytics(ANALYTICS_EVENT.PURCHASE, {
    transiaction_id: transactionId,
    value: valueFormatter(total),
    currency: 'EUR',
    purchase_type: purchaseType,
    items: products.map(({ name, id, price, provider, category, temperature, amount }) => ({
      item_name: name,
      item_id: id,
      item_category: category?.name,
      item_category2: category?.children?.[0]?.name,
      item_category3: category?.children?.[0]?.children?.[0]?.name,
      item_category4: temperature,
      price: valueFormatter(price),
      currency: 'EUR',
      affiliation: `${provider?.name} - ${provider?.id}`,
      quantity: quantityFormater(amount),
    })),
  })
}

export default purchase
