import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const Container = styled.div`
  width: 100%;
  padding: ${props => (props.padding ? props.padding : '2.5rem 4rem;')};
  display: flex;
  justify-content: center;
  height: calc(100vh - 64px);
  background-color: ${props => props.theme.colors.gray98b};
`

export const Input = styled(TextField)`
  width: 50%;
`

export const useStyles = makeStyles({
  buttonRoot: {
    fontSize: '1.2rem',
    padding: '1rem 3rem',
  },
})

export const CenterSelectContainer = styled.div`
  width: 42.5rem;
  background: white;
  height: 30rem;
  padding: 2.5rem 5rem;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`

export const RootContainer = styled(Container)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
