import styled from 'styled-components'
import colors from 'Assets/colors'

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 24px;
  padding: 30px 24px 0;
  background: ${colors.gray98b};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 24px;
  width: 100%;
  flex: 1 1 0px;
  overflow: hidden;

  .centers-list-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .centers-list {
    width: 100%;
    height: 100%;
    flex: 1 1 0px;
    overflow: auto;
    padding-bottom: 2rem;
    position: relative;

    &.is-loading {
      padding-bottom: 120px;
    }

    .MuiCircularProgress-root {
      position: absolute;
      left: calc(50% - 20px);
      margin: 20px 0;

      &.initial {
        top: calc(50% - 20px - 1rem);
      }
    }

    .MuiAccordion-root {
      margin-bottom: 16px;
    }
  }
`

export const IconContainer = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    right: -3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: solid 2px ${colors.white};
    background-color: ${colors.secondary};
    display: ${({ showDot }) => (showDot ? 'block' : 'none')};
  }
`

export const FiltersMessage = styled.p`
  width: 100%;
  text-align: left;
  font-style: italic;
  margin-top: -10px;
`
