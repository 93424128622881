import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Trash from 'Assets/Icons/Trash'
import { AVAILABILITY_STATES } from 'Config/constants'

import { ButtonNotAvailableContainer, EraseButton, Unavailable } from './styled'

const ButtonNotAvailable = ({ availability, onClick }) => {
  const { t } = useTranslation()

  return (
    <ButtonNotAvailableContainer>
      <Unavailable>
        {availability && availability.status === AVAILABILITY_STATES.NOT_STOCK && t('noItems')}
        {availability && availability.status === AVAILABILITY_STATES.UNAVAILABLE && t('deleted')}
        {availability && availability.status === AVAILABILITY_STATES.NEXT && t('nextAvailability')}
      </Unavailable>
      <EraseButton onClick={onClick}>
        <Trash className='trash' width={22} fill='#fff' /> {t('delete')}
      </EraseButton>
    </ButtonNotAvailableContainer>
  )
}

ButtonNotAvailable.propTypes = {
  onClick: PropTypes.func,
  availability: PropTypes.object,
}

ButtonNotAvailable.defaultProps = {
  onClick: () => null,
  availability: null,
}

export default ButtonNotAvailable
