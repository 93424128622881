import { all, call, delay, put, select, takeLatest, takeLeading } from 'redux-saga/effects'
import axios from 'axios'
import i18next from 'i18next'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import { REQUEST_ORDER_STATE } from 'Config/constants'
import { getApiErrorMessage } from 'Config/utils'
import { mapTicketsList } from 'Redux/ticket/utils'
import {
  getCenters,
  getMulticenterCenters,
  getMulticenterNoConformitiesPendingTickets,
  getMulticenterNoConformitiesTickets,
  getMulticenterOriginCenters,
  getMulticenterProviders,
  getOrders,
  getProviderOrders,
  getExistsMulticenterPendingOrders,
} from 'Services/api'
import * as Sentry from 'Services/sentry'

import {
  setCentersList,
  setCenterOrders,
  setFilterCenters,
  setFilterOriginCenters,
  setFilterProviders,
  setIsLoadingCenters,
  setIsLoadingNoCompliances,
  setIsLoadingOrders,
  setNoCompliances,
  setRequestOrders,
  setStats,
} from './actions'
import { selectCenters } from './selectors'
import {
  FETCH_CENTERS_LIST,
  FETCH_CENTER_ORDERS,
  FETCH_FILTER_CENTERS,
  FETCH_FILTER_ORIGIN_CENTERS,
  FETCH_FILTER_PROVIDERS,
  FETCH_NO_COMPLIANCES,
  FETCH_REQUEST_ORDERS,
  FETCH_STATS,
} from './types'

export function* fetchCenters(action) {
  const { filters, page = 0, pageSize = 0 } = get(action, 'payload', {})
  const queryParams = { ...filters, limit: pageSize, offset: page * pageSize }

  try {
    yield put(setIsLoadingCenters(true))
    const { data: currentCentersList } = yield select(selectCenters)

    const { data = [] } = yield call(getMulticenterCenters, queryParams)
    const centersList = page === 0 ? data : [...currentCentersList, ...data]

    const results = yield all(
      centersList.map(centerItem => {
        const r2 = call(getCenters, { search: centerItem.id, limit: 1 })
        return r2
      })
    )

    const resultData = results.filter(it => it.count > 0).map(ee => ee.data[0])

    const newCenterList = centersList.map(el => {
      const rr = resultData.find(el3 => el3.id === el.id) || {}
      if (rr) return { ...el, ...rr }
      return { ...el }
    })

    yield put(setCentersList({ data: newCenterList, currentPageCount: data?.length ?? 0 }))
  } catch (e) {
    Sentry.captureException(e)
    toast.error(e?.response?.data?.message || i18next.t('errorFetchingCenters'))
    yield put(setCentersList({ data: [], currentPageCount: 0 }))
  } finally {
    yield put(setIsLoadingCenters(false))
  }
}

export function* fetchCenterOrders(action) {
  const centerId = get(action, 'payload.centerId', '')
  const { page, pageSize, filters } = get(action, 'payload.params', {})
  try {
    yield put(setIsLoadingOrders(centerId, true))

    const queryParams = { ...filters, limit: pageSize, offset: page * pageSize }
    const response = yield call(getProviderOrders, queryParams, centerId)
    yield put(setCenterOrders(centerId, { count: response.count, orders: response.data }))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(getApiErrorMessage(e))
    }
    yield setCenterOrders(centerId, { count: 0, orders: [] })
  } finally {
    yield put(setIsLoadingOrders(centerId, false))
  }
}

export function* fetchFilterCenters(action) {
  yield delay(500)
  try {
    const params = get(action, 'payload', {})
    const { data = [] } = yield call(getCenters, params)
    yield put(setFilterCenters(data))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(i18next.t(getApiErrorMessage(e)))
    }
  }
}

export function* fetchFilterOriginCenters(action) {
  yield delay(200)
  try {
    const params = get(action, 'payload', {})
    const { data = [] } = yield call(getMulticenterOriginCenters, params)
    const searchResults = data.filter((center, idx) => data.findIndex(({ id }) => center.id === id) === idx)
    yield put(setFilterOriginCenters(searchResults))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(i18next.t(getApiErrorMessage(e)))
    }
  }
}

export function* fetchFilterProviders(action) {
  yield delay(200)
  try {
    const params = get(action, 'payload', {})
    const { data = [] } = yield call(getMulticenterProviders, params)
    yield put(setFilterProviders(data))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(i18next.t(getApiErrorMessage(e)))
    }
  }
}

export function* fetchNoCompliances(action) {
  const { page, pageSize, filters } = get(action, 'payload', {})

  try {
    yield put(setIsLoadingNoCompliances(true))

    const queryParams = { ...filters, limit: pageSize, offset: page * pageSize }
    const response = yield call(getMulticenterNoConformitiesTickets, queryParams)
    yield put(setNoCompliances({ data: mapTicketsList(response.data), count: response.count }))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(getApiErrorMessage(e))
    }
    yield setNoCompliances({ count: 0, noCompliances: [] })
  } finally {
    yield put(setIsLoadingNoCompliances(false))
  }
}

export function* fetchRequestOrders(action) {
  const centerId = get(action, 'payload.centerId', '')
  const orders = get(action, 'payload.orders', [])
  const requestsIds = orders.map(({ parentRequest }) => parentRequest)

  try {
    const { data = [] } = yield call(
      getOrders,
      { ids: JSON.stringify(requestsIds), statuses: Object.values(REQUEST_ORDER_STATE) },
      centerId
    )
    yield put(setRequestOrders(centerId, data))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(getApiErrorMessage(e))
    }
    yield setRequestOrders(centerId, [])
  }
}

export function* fetchStats() {
  try {
    const { count: centers = 0 } = yield call(getMulticenterCenters, { limit: 0, offset: 0 })
    const { exists: existsOrdersToReceive = false } = yield call(getExistsMulticenterPendingOrders)
    const { count: complaints = 0 } = yield call(getMulticenterNoConformitiesPendingTickets)

    yield put(setStats({ centers, existsOrdersToReceive, complaints }))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(getApiErrorMessage(e))
    }
  }
}

export function* debounceFilterCenters() {
  yield takeLatest(FETCH_FILTER_CENTERS, fetchFilterCenters)
}

function* debounceFilterOriginCenters() {
  yield takeLatest(FETCH_FILTER_ORIGIN_CENTERS, fetchFilterOriginCenters)
}

function* debounceFilterProviders() {
  yield takeLatest(FETCH_FILTER_PROVIDERS, fetchFilterProviders)
}

function* watchFetchCenterOrders() {
  yield takeLeading(FETCH_CENTER_ORDERS, fetchCenterOrders)
}

function* watchFetchCentersList() {
  yield takeLeading(FETCH_CENTERS_LIST, fetchCenters)
}

function* watchFetchNoCompliances() {
  yield takeLatest(FETCH_NO_COMPLIANCES, fetchNoCompliances)
}

function* watchFetchRequestOrders() {
  yield takeLeading(FETCH_REQUEST_ORDERS, fetchRequestOrders)
}

function* watchFetchStats() {
  yield takeLeading(FETCH_STATS, fetchStats)
}

export default function* rootDeliverySaga() {
  yield all([
    debounceFilterCenters(),
    debounceFilterOriginCenters(),
    debounceFilterProviders(),
    watchFetchCentersList(),
    watchFetchCenterOrders(),
    watchFetchNoCompliances(),
    watchFetchRequestOrders(),
    watchFetchStats(),
  ])
}
