import React from 'react'

import PropTypes from 'prop-types'
import { Modal } from 'Components/atoms'
import { ReactComponent as Download } from 'Assets/Icons/Descargar.svg'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Title, Description, ButtonContainer, ButtonLiteral, ActionButton } from './styled'

const ModalDownloadEmail = ({ showModal, setShowModal, title, description }) => {
  const { t } = useTranslation()

  return (
    <Modal
      displayModal='flex'
      margin='5rem'
      showClose={false}
      isOpen={showModal}
      setIsOpen={setShowModal}
      maxHeight='40rem'
    >
      <Box alignSelf='center'>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Download width={100} height={100} />
        <ButtonContainer>
          <ActionButton onClick={setShowModal}>
            <ButtonLiteral>{t('accept')}</ButtonLiteral>
          </ActionButton>
        </ButtonContainer>
      </Box>
    </Modal>
  )
}

ModalDownloadEmail.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
}

ModalDownloadEmail.defaultProps = {
  showModal: false,
  setShowModal: () => {},
  title: '',
  description: '',
}

export default ModalDownloadEmail
