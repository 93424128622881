import styled from 'styled-components'
import colors from 'Assets/colors'

export const SingleProductRow = styled.div`
  background: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.24);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-columns: ${({ withPrice }) => (withPrice ? '43% 17% 17% 23%' : '43% 34% 23%')};
  margin-bottom: 16px;
  height: 64px;
`
export const SingleProductName = styled.div`
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 62px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  text-transform: uppercase;
  padding: 0 16px;
`

export const SingleProductPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  margin-right: 16px;
  padding: 16px 16px 16px 0;
  height: 62px;
`

export const SingleProductTotalPrice = styled.div`
  display: flex;
  justify-content: start;
  column-gap: 8px;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
  text-align: left;
  color: ${colors.black};
`

export const Price = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: ${colors.gray40l};
`

export const SingleProductQtyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px 16px 0;
  height: 62px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  text-align: left;
  color: ${colors.black};
`

export const SingleProductApprovedQty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  margin: 4px 0;
  margin-right: 8px;
  height: 54px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  text-align: left;
  color: ${colors.black};
  border: 2px solid ${colors.cart};
  border-radius: 8px;
`
