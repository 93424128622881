import React from 'react'

import PropTypes from 'prop-types'
import { Modal } from 'Components/atoms'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { ReactComponent as CheckQA } from 'Assets/Icons/QaOk.svg'
import { ReactComponent as UncheckQA } from 'Assets/Icons/QAFailed.svg'

import {
  Container,
  TextItem,
  Row,
  ItemsRow,
  Title,
  DetailSection,
  DetailTitle,
  DetailTitleComments,
  DetailSectionBorder,
  DetailSectionText,
  DetailText,
  DetailTextComments,
} from './styled'

const ModalQAForm = ({ showModal, setShowModal, data, orderNumber }) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={showModal}
      setIsOpen={setShowModal}
      displayContainer='initial'
      justifyContainer='initial'
      margin='0 auto'
      width='50rem'
      height='auto'
      padding='2.5rem 5rem'
      textAlign='left'
    >
      <Container>
        <Title>{`${t('qaTitleOrder')} ${orderNumber || ''} - ${t('qaTitleQAcontrol')}`}</Title>
        <ItemsRow>
          <QAItem text={t('qaTitleReceptionTime')} value={0} data={data} />
          <QAItem text={t('qaTitleTransport')} value={1} data={data} />
        </ItemsRow>
        <ItemsRow>
          <QAItem text={t('qaTitlePackage')} value={2} data={data} />
          <QAItem text={t('qaTitleLifeUse')} value={3} data={data} />
        </ItemsRow>
        <ItemsRow>
          <QAItem text={t('qaTitleProductAspect')} value={4} data={data} />
          <QAItem text={t('qaTitleProductLabel')} value={5} data={data} />
        </ItemsRow>
        <ItemsRow>
          <QAItem text={t('qaTitleAcceptedProduct')} value={6} data={data} />
        </ItemsRow>
        <DetailSectionBorder>
          {data.qaData && !isEmpty(data.qaData.coolTemperature) ? (
            <>
              <DetailSection>
                <DetailTitle>{t('qaTemp')}</DetailTitle>
                <DetailTitleComments>{t('qaTempComments')}</DetailTitleComments>
              </DetailSection>
              <DetailSectionText>
                <DetailText>{`${data.qaData.coolTemperature.temperature} ºC`}</DetailText>
                <DetailTextComments>{data.qaData.coolTemperature.comment}</DetailTextComments>
              </DetailSectionText>
            </>
          ) : null}
          {data.qaData && !isEmpty(data.qaData.frozenTemperature) ? (
            <>
              <DetailSection>
                <DetailTitle>{t('qaTempFreeze')}</DetailTitle>
                <DetailTitleComments>{t('qaTempCommentsFreeze')}</DetailTitleComments>
              </DetailSection>
              <DetailSectionText>
                <DetailText>{`${data.qaData.frozenTemperature.temperature} ºC`}</DetailText>
                <DetailTextComments>{data.qaData.frozenTemperature.comment}</DetailTextComments>
              </DetailSectionText>
            </>
          ) : null}
        </DetailSectionBorder>
      </Container>
    </Modal>
  )
}

ModalQAForm.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  data: PropTypes.object,
  orderNumber: PropTypes.number,
}

ModalQAForm.defaultProps = {
  showModal: false,
  setShowModal: () => {},
  data: {},
  orderNumber: 0,
}

const QAItem = ({ value, text, data }) => (
  <Row>
    {data.qaData && data.qaData.questions && data.qaData.questions[value].answer === true ? <CheckQA /> : <UncheckQA />}
    <TextItem>{text}</TextItem>
  </Row>
)

QAItem.propTypes = {
  value: PropTypes.number,
  text: PropTypes.string,
  data: PropTypes.object,
}

QAItem.defaultProps = {
  value: false,
  text: '',
  data: {},
}

export default ModalQAForm
