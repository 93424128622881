import { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { selectConfig } from 'Redux/auth/utils'

const TABS = {
  requestsToBeApproved: 1,
  approvedRequests: 2,
  rejectedRequests: 3,
  weekSummary: 4,
}

const useApproverCartsTabs = () => {
  const closeToAutoApproved = useSelector(state => state.approver.closeToAutoApproved)
  const requestOrder = useSelector(state => state.approver.requestOrder)
  const isFetchingCart = useSelector(state => state.approver.isFetchingCart)
  const features = useSelector(selectConfig)

  const [activeTab, setActiveTab] = useState(TABS.requestsToBeApproved)
  const [showPendingModal, setShowPendingModal] = useState(false)

  const isDemoMode = !!sessionStorage.getItem('mode')
  const isTutorialEnabled = features?.tutorial

  useEffect(() => {
    if (isDemoMode && isTutorialEnabled) {
      setShowPendingModal(false)
      return
    }
    if (!isEmpty(closeToAutoApproved) && isEmpty(requestOrder)) {
      setShowPendingModal(true)
    } else {
      setShowPendingModal(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeToAutoApproved, isDemoMode])

  const onClickTab = tab => setActiveTab(tab)

  return { TABS, onClickTab, showPendingModal, activeTab, isFetchingCart, requestOrder, setShowPendingModal }
}

export default useApproverCartsTabs
