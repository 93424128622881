import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import colors from 'Assets/colors'
import ArrowDropdown from 'Assets/Icons/ArrowDropdown'
import { getRequestOrderCart } from 'Services/api'
import { ReactComponent as ErrorRequest } from 'Assets/Icons/EstadoPorRecepcionar.svg'
import { ReactComponent as CartRepeat } from 'Assets/Icons/CartRepeatBlack.svg'
import { ReactComponent as RemoveIcon } from 'Assets/Icons/RemoveIconBlack.svg'
import NumberFormat from 'Components/atoms/numberFormat'
import ProductInfoLoader from 'Components/atoms/productInfoLoader'
import ProductNameLoader from 'Components/atoms/productNameLoader'
import ProductActionLoader from 'Components/atoms/productActionLoader'
import ActionButton from 'Components/atoms/actionButton'

import {
  TableHeaderRow,
  TableHeaderItem,
  SingleProductRow,
  SingleProductNameHelper,
  SingleProductName,
  SingleProductPriceContainer,
  SingleProductQtyContainer,
  SingleProductTotalPrice,
  SingleProductTotalPriceSplit,
  SingleProductTotalQty,
  SingleProductButtonContainer,
  SingleProductRowCL,
  Price,
  PriceKG,
  ProductsContainer,
  RequestProviderInfoContainer,
  RequestProviderInfo,
  ProviderDetail,
  TotalProductLines,
  TotalPrice,
  RequestOrderContainer,
  RequestRepeatOrderContainer,
  ShowProductsContainer,
  ShowMoreCopy,
  ShowLessCopy,
  RequestStatusOrderContainer,
  RequestStatusAdviceText,
  RequestStatusAdviceSubText,
  RequestStatusOrderContainerError,
  ErrorInfo,
} from './styled'

const HistoryRequestProduct = ({ order, centerId, onAction, disabledBtns }) => {
  const { t } = useTranslation()
  const center = useSelector(state => state.auth.selectedCenter)
  const [openProducts, setOpenProducts] = useState(false)
  const [products, setProducts] = useState([])
  const [productsLoading, setProductsLoading] = useState(false)

  const handleShowProducts = () => {
    if (products.length <= 0) {
      setProductsLoading(true)
      getRequestOrderCart(centerId, order.id)
        .then(value => {
          setProducts(value.data)
          setProductsLoading(false)
          setOpenProducts(!openProducts)
        })
        .catch(() => {
          setProductsLoading(false)
        })
    } else {
      setOpenProducts(!openProducts)
    }
  }

  return (
    <>
      <RequestProviderInfoContainer>
        <RequestProviderInfo>
          {`${''}`}
          <ErrorInfo>{t('failedRequests.requestStatusError3')}</ErrorInfo>
        </RequestProviderInfo>
        <ProviderDetail>
          {order.total && center.showPrice ? (
            <TotalPrice>
              <NumberFormat value={order.total} />
            </TotalPrice>
          ) : null}
          <TotalProductLines> </TotalProductLines>
          <ShowProductsContainer onClick={handleShowProducts}>
            <ArrowDropdown
              fill={openProducts ? colors.secondary : colors.black}
              style={{ margin: '0 0.5rem', transform: openProducts ? 'rotate(180deg)' : 'none' }}
            />
            {products && openProducts ? (
              <ShowLessCopy>{t('contractProducts')}</ShowLessCopy>
            ) : (
              <ShowMoreCopy>{t('seeProducts')}</ShowMoreCopy>
            )}
          </ShowProductsContainer>
        </ProviderDetail>
      </RequestProviderInfoContainer>
      <RequestOrderContainer openProducts={openProducts[order.id]}>
        <RequestRepeatOrderContainer>
          <ActionButton
            onClick={() => onAction(order.id, 'addToCart')}
            buttonText={t('failedRequests.processAgain')}
            helpText={t('failedRequests.processAgainHelpText')}
            Icon={CartRepeat}
            disabled={order.state !== 'Error' && disabledBtns}
            orderState={order.state === 'Error'}
          />
          <ActionButton
            onClick={() => onAction(order.id, 'remove')}
            buttonText={t('failedRequests.removeRequest')}
            helpText={t('failedRequests.removeRequestHelpText')}
            Icon={RemoveIcon}
          />
        </RequestRepeatOrderContainer>
        <RequestStatusOrderContainer>
          <RequestStatusOrderContainerError>
            <ErrorRequest style={{ fill: colors.red }} />
            <RequestStatusAdviceText>
              {order.state === 'Error'
                ? t('failedRequests.requestStatusError1')
                : t('failedRequests.requestStatusErrorCorrected')}
            </RequestStatusAdviceText>
          </RequestStatusOrderContainerError>
          <RequestStatusAdviceSubText>
            {order.state === 'Error' ? t('failedRequests.requestStatusError2') : ''}
          </RequestStatusAdviceSubText>
        </RequestStatusOrderContainer>
      </RequestOrderContainer>
      <ProductsContainer>
        {products && openProducts && (
          <TableHeaderRow>
            <TableHeaderItem>{t('products')}</TableHeaderItem>
            {center.showPrice && <TableHeaderItem>{t('sum')}</TableHeaderItem>}
            <TableHeaderItem>{t('quantity')}</TableHeaderItem>
          </TableHeaderRow>
        )}
        {productsLoading && (
          <SingleProductRowCL>
            <SingleProductNameHelper>
              <ProductNameLoader />
            </SingleProductNameHelper>
            <SingleProductPriceContainer>
              <ProductInfoLoader />
            </SingleProductPriceContainer>
            <SingleProductQtyContainer>
              <ProductInfoLoader />
            </SingleProductQtyContainer>
            <SingleProductButtonContainer>
              <ProductActionLoader />
            </SingleProductButtonContainer>
          </SingleProductRowCL>
        )}
        {!productsLoading &&
          openProducts &&
          products.map(product => {
            const { productDescription, price, productId, amount, unit } = product
            return (
              <SingleProductRow key={productId} withPrice={19}>
                <SingleProductNameHelper>
                  <SingleProductName>{productDescription}</SingleProductName>
                </SingleProductNameHelper>
                {center.showPrice && (
                  <SingleProductPriceContainer>
                    <SingleProductTotalPrice>
                      <NumberFormat value={price * amount} />
                    </SingleProductTotalPrice>
                    <SingleProductTotalPriceSplit>
                      <Price>
                        <NumberFormat decimalScale={3} value={price} suffix={`€/${unit}`} />
                      </Price>
                      <PriceKG />
                    </SingleProductTotalPriceSplit>
                  </SingleProductPriceContainer>
                )}
                <SingleProductQtyContainer>
                  <SingleProductTotalQty>
                    {amount} {unit}
                  </SingleProductTotalQty>
                </SingleProductQtyContainer>
              </SingleProductRow>
            )
          })}
      </ProductsContainer>
    </>
  )
}

HistoryRequestProduct.propTypes = {
  order: PropTypes.object,
  centerId: PropTypes.string,
  onAction: PropTypes.func,
  disabledBtns: PropTypes.bool,
}

HistoryRequestProduct.defaultProps = {
  order: null,
  centerId: null,
  onAction: () => null,
  disabledBtns: false,
}

export default HistoryRequestProduct
