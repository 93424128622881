import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SearchIcon from 'Assets/Icons/Search'
import colors from 'Assets/colors'
import { TextInput } from 'Components/atoms'

import { Row, Container } from './styled'

const CartSearchBar = ({ expanded, onSeek }) => {
  const selectedCenter = useSelector(state => state.auth.selectedCenter)

  const [query, setQuery] = useState('')
  const [focused, setFocused] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    setQuery('')
  }, [selectedCenter])

  const onFocusInput = () => {
    setFocused(true)
  }

  const handleOnChange = value => {
    setQuery(value)
  }

  useEffect(() => {
    onSeek(query)
  }, [onSeek, query])

  return (
    <Container>
      {expanded ? (
        <Row>
          <div>
            <TextInput
              placeholder={t('searchPlaceholderCart')}
              topIcon='1.2rem'
              width='36rem'
              respWidth='30rem'
              Icon={props => <SearchIcon fill={focused ? colors.secondary : colors.black} {...props} />}
              margin='0 1rem'
              value={query}
              onChange={e => handleOnChange(e.target.value)}
              onFocus={onFocusInput}
              borderRadius={focused ? '8px 8px 0 0' : '8px'}
              background='#F4F1F1'
            />
          </div>
        </Row>
      ) : (
        <Row>
          <div>
            <TextInput
              placeholder={t('searchPlaceholderCart')}
              topIcon='1.2rem'
              width='20rem'
              respWidth='30rem'
              borderRadius='0px'
              Icon={props => <SearchIcon fill={focused ? colors.secondary : colors.black} {...props} />}
              margin='0 1rem'
              value={query}
              onChange={e => handleOnChange(e.target.value)}
              onFocus={onFocusInput}
              background='#FFFFFF'
              minimal
              borderBottom='1px solid rgba(51, 51, 51, 0.24)'
            />
          </div>
        </Row>
      )}
    </Container>
  )
}

CartSearchBar.defaultProps = {
  expanded: false,
  onSeek: () => {},
}

CartSearchBar.propTypes = {
  expanded: PropTypes.bool || undefined,
  onSeek: PropTypes.func,
}

export default CartSearchBar
