import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'Components/atoms/numberFormat'
import ProductInfoLoader from 'Components/atoms/productInfoLoader'
import { financial } from 'Components/utils/cartCalculator'

import * as S from './styled'

const SingleRowProduct = ({ product, requiresApproval, showPrice }) => {
  const { name, price, measureUnit, amount, priceUnit, approvalChanges, product: productData } = product
  const { t } = useTranslation()

  return (
    <S.SingleProductRow withPrice={showPrice}>
      <S.SingleProductName>{name}</S.SingleProductName>

      {showPrice && (
        <S.SingleProductPriceContainer>
          <S.SingleProductTotalPrice>
            <NumberFormat value={price} />
            <S.Price>
              <NumberFormat value={priceUnit} suffix={`€/${measureUnit}`} />
            </S.Price>
          </S.SingleProductTotalPrice>
          {productData && (
            <S.Price>
              {`${t('min')}: ${financial(productData.min)} / ${t('multi')}: ${financial(productData.multiple)}`}
            </S.Price>
          )}
        </S.SingleProductPriceContainer>
      )}

      <S.SingleProductQtyContainer>
        {requiresApproval &&
          (approvalChanges ? (
            <NumberFormat value={approvalChanges.originalAmount} suffix={measureUnit} />
          ) : (
            <ProductInfoLoader />
          ))}
        {!requiresApproval && `${amount} ${measureUnit}`}
      </S.SingleProductQtyContainer>

      {requiresApproval && (
        <S.SingleProductApprovedQty>
          {approvalChanges ? (
            <NumberFormat value={approvalChanges.amount} suffix={measureUnit} />
          ) : (
            <ProductInfoLoader />
          )}
        </S.SingleProductApprovedQty>
      )}
    </S.SingleProductRow>
  )
}

SingleRowProduct.propTypes = {
  product: PropTypes.object.isRequired,
  requiresApproval: PropTypes.bool,
  showPrice: PropTypes.bool,
}

SingleRowProduct.defaultProps = {
  requiresApproval: false,
  showPrice: false,
}

export default SingleRowProduct
