import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SmallDevicesContainer = styled.div`
  display: flex;
  gap: 1rem;
  min-width: 300px;
  width: 95%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 2rem 1rem;
  margin: 1rem;


  @media (min-width: 500px) {
    width: 495px;
    padding: 2rem;
    gap: 3rem;
  }
`
