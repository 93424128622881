export const selectIsLoadingCenter = centerId => state => state.multicenter.isLoadingOrders[centerId]

export const selectCenterOrders = centerId => state => state.multicenter.centersOrders[centerId]

export const selectRequestOrders = centerId => state => state.multicenter.requestOrdersByCenter[centerId]

export const selectCenter = state => state.multicenter.selectedCenter

export const selectCenters = state => state.multicenter.centersList

export const selectIsLoadingNoCompliances = state => state.multicenter.isLoadingNoCompliances

export const selectNoCompliances = state => state.multicenter.noCompliances

export const selectStats = state => state.multicenter.stats
