import styled from 'styled-components'
import { Element } from 'react-scroll'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  root: {
    zIndex: '900 !important',
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
  paper: { maxWidth: '22.5rem', overflow: 'hidden' },
  expand: { width: '100%', overflow: 'hidden' },
}))

export const MainRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 22.5rem;
  border-bottom: 1px solid rgba(51, 51, 51, 0.24);
  padding: 1.25rem;

  .hoverable {
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }
  }
`

export const HorizontalMainRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: '100%';
  border-bottom: 2px solid rgba(51, 51, 51, 0.24);
  padding: 1.25rem;

  .hoverable {
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }
  }
`

export const SearchRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: '100%';
  padding-left: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  height: 5rem;

  .hoverable {
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin: ${props => props.margin};
  justify-content: ${props => props.justifyContent};
`

export const Cart = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
`

export const HorizontalClose = styled.div`
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
`

export const Empty = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.red};
`

export const EmptyMargin = styled(Empty)`
  margin-right: 1rem;
`

export const ProductContainer = styled(Element)`
  overflow-y: scroll;
  height: calc(100% - 256px);

  ::-webkit-scrollbar {
    display: none;
  }
`

export const HorizontalProductContainer = styled(Element)`
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 100px;
`

export const Provider = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
`

export const HorizontalProvider = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-left: 1rem;
  margin-right: 1rem;
  color: ${props => props.theme.colors.black};
`

export const Budget = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid black;
  width: 100%;
  min-height: 12rem;
  background: #ffffff;
  padding: 1rem 1.25rem 1rem 1rem;
`

export const Remaining = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.black};
`

export const BudgetRow = styled(Row)`
  justify-content: space-between;
  margin-bottom: ${props => props.marginBottom};
`

export const BudgetRowHorizontal = styled(Row)``

export const HorizontalTitleSection = styled(Row)`
  justify-content: flex-start;
  margin-right: 330px;
`
export const HorizontalTitleProduct = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  width: 31rem;
  margin-left: 1.1rem;
`

export const HorizontalTitleValue = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  flex: 1;
`

export const HorizontalTitleTotal = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  flex: 1;
`

export const HorizontalText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-right: 1rem;
  margin-left: 1rem;
`

export const ProviderRow = styled(Row)`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  justify-content: space-between;
  border-bottom: 1px solid rgba(51, 51, 51, 0.24);
`

export const BudgetKind = styled(Remaining)`
  font-weight: normal;
`

export const BudgetAmountPositive = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.secondary};
`

export const BudgetAmountNegative = styled(BudgetAmountPositive)`
  color: ${props => props.theme.colors.red};
`

export const SeeButton = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.cart};
  padding: 1rem;
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const CreateListButton = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.black};
  cursor: pointer;
  align-items: center;
  text-decoration: underline;
`

export const TotalItems = styled.div`
  background: #ffffff;
  border-radius: 4px;
  padding: 0px 8px;
  margin-left: 1rem;
`

export const ButtonLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ButtonCartLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`

export const Container = styled.div`
  padding: 1rem;
  border-bottom: 1px solid rgba(51, 51, 51, 0.24);
`

export const FinishButton = styled.div`
  width: 25rem;
  background-color: ${props => (props.disabled ? props.theme.colors.gray3 : props.theme.colors.cart)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  user-select: ${props => (props.disabled ? 'none' : 'auto')};
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  border-radius: 8px;
  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const HorizontalProviderContainer = styled.div`
  margin: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  background: ${props => props.theme.colors.gray98b};
`

export const HorizontalRedSquare = styled.div`
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.red};
  border: 1px solid ${props => props.theme.colors.red};
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 1rem;
  padding-right: 1rem;
`

export const ButtonNoProducts = styled.div`
  border-radius: 8px;
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.red};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-top: 1rem;
  transition: all 0.2s;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

export const HorizontalButtonNoProducts = styled.div`
  border-radius: 8px;
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.red};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition: all 0.2s;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

export const NotAvailablesContainer = styled(Element)`
  padding: 1rem;
  border-top: 1px solid rgba(51, 51, 51, 0.24);
`

export const NotAvailablesRowContainer = styled(Element)`
  padding: 1rem;
  border-top: 1px solid rgba(51, 51, 51, 0.24);
`

export const NotAvailableTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.red};
`

export const Message = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  padding-bottom: 1rem;
`

export const ProductCardLoaderContainer = styled.div`
  margin: 0 0 1rem;
  padding: 1rem;
`
export const ExpandedBudget = styled(Row)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid rgba(51, 51, 51, 0.24);

  background: ${props => props.theme.colors.gray98b};
`
export const FinishButtonSection = styled(Row)`
  width: '100%';
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;

  ${CreateListButton} {
    margin-right: 30px;
  }
`

export const RemainingsSectionHorizontal = styled(Row)``
export const RemainingHorizontal = styled.div`
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  height: 4rem;
  padding-top: 0.7rem;
  color: ${props => props.theme.colors.black};
`

export const Remainings = styled.div`
  padding-left: 1rem;
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  justify-content: flex-start;
  color: ${props => props.theme.colors.black};
`

export const BudgetAmountPositiveHorizontal = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  margin-left: 1rem;
  width: 8rem;
  text-align: right;

  color: ${props => props.theme.colors.secondary};
`

export const BudgetAmountNegativeHorizontal = styled(BudgetAmountPositiveHorizontal)`
  color: ${props => props.theme.colors.red};
`
export const BudgetKindHorizontal = styled(Remaining)`
  margin-top: 0.5rem;
  font-weight: normal;
  width: 8rem;
`
export const BudgetKindHorizontalNotRawMaterial = styled(BudgetKindHorizontal)`
  font-weight: normal;
  margin-top: -0.5rem;
`
export const BudgetAmountPositiveHorizontalNotRaw = styled(BudgetAmountPositiveHorizontal)`
  margin-top: -1rem;
  color: ${props => props.theme.colors.secondary};
`

export const BudgetAmountNegativeHorizontalNotRaw = styled(BudgetAmountPositiveHorizontalNotRaw)`
  color: ${props => props.theme.colors.red};
`

export const Price = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;

  margin-left: 0.5rem;
  color: ${props => props.theme.colors.black};
`

export const HorizontalProviderNotAvailableContainer = styled.div`
  margin: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  background: ${props => props.theme.colors.gray98b};
`

export const NotAvailableBudgetRow = styled(Row)`
  padding-left: 1rem;
  margin-bottom: ${props => props.marginBottom};
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  border-bottom: 1px solid rgba(51, 51, 51, 0.24);
`
export const HorizontalMessage = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  margin-left: 0.5rem;
`

export const ProviderPrice = styled.p`
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
`

export const ProviderNumProducts = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

export const AddMoreButton = styled.button`
  margin-right: 30px;
  background-color: ${props => (props.disabled ? props.theme.colors.gray3 : props.theme.colors.secondary)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  user-select: ${props => (props.disabled ? 'none' : 'auto')};
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.white};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`
