import React, { useState, useEffect, useMemo, createRef, useRef } from 'react'

import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { TextInput, MediumText, NumberFormat } from 'Components/atoms'
import { ReactComponent as PortalLogo } from 'Assets/Logos/PortalLogo.svg'
import { ReactComponent as CartIcon } from 'Assets/Icons/Cart.svg'
import SearchIcon from 'Assets/Icons/Search'
import colors from 'Assets/colors'
import Arrow from 'Assets/Icons/Arrow'
import { getProduct } from 'Services/api'
import { PurchaseTypes, PurchaseActions } from 'Redux/purchases'
import { useClickOutside, useDebounce } from 'Components/utils/customHooks'
import routes from 'Config/routes'
import { categoryTypes, KEY_CODES } from 'Config/constants'
import { CircularProgress } from '@material-ui/core'
import { needsApprovalMode } from 'Redux/approver/selectors'
import useApproverCart from 'hooks/approver/useApproverCart'
import { calculateProductAmount } from 'Components/utils/cartCalculator'
import { selectConfig } from 'Redux/auth/utils'

import {
  RelativeContainer,
  useStyles,
  Row,
  SectionSelector,
  SelectorContainer,
  ResultsContainer,
  Result,
  StyledText,
  Type,
  Container,
  TotalItems,
} from './styled'

const LIMIT_AUTOCOMPLETE_RESULTS = 25
const MIN_SEARCH_CHARS = 3
const DEBOUNCE_DELAY = 1000

const Bar = ({ setShowDrawer, showCartButton }) => {
  const searchRef = useRef()
  const inputRef = useRef()
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const isEditingCart = useSelector(({ purchase }) => purchase.isEditingCart)
  const repeatOrderLoading = useSelector(({ purchase }) => purchase.isLoadingRepeatOrder)
  const isLoadingCart = useSelector(({ purchase }) => purchase.isLoadingCart)
  const isUpdatingCart = useSelector(({ purchase }) => purchase.isUpdatingCart)
  const hidden = useSelector(state => state.auth.hiddenNavbar)
  const selectedCenter = useSelector(state => state.auth.selectedCenter)
  const results = useSelector(state => state.purchase.mainSearch)
  const cart = useSelector(state => state.purchase.cart)
  const categoriesHabitual = useSelector(state => state.purchase.categoriesHabitual)
  const needsApproval = useSelector(needsApprovalMode)
  const features = useSelector(selectConfig)

  const [query, setQuery] = useState('')
  const [hoveredResult, setHoveredResult] = useState('')
  const [displayAutocomplete, setDisplayAutocomplete] = useState('none')
  const [focused, setFocused] = useState(false)

  const debouncedQuery = useDebounce(query, DEBOUNCE_DELAY)

  const refs = useMemo(() => Array.from({ length: results.length }).map(() => createRef()), [results])

  const { showPrice } = selectedCenter

  const onClickOutsideInput = () => {
    setFocused(false)
    setDisplayAutocomplete('none')
    inputRef.current.blur()
  }

  useClickOutside(searchRef, onClickOutsideInput)

  useEffect(() => {
    setQuery('')
  }, [selectedCenter])

  useEffect(() => {
    if (debouncedQuery && debouncedQuery.length >= MIN_SEARCH_CHARS) {
      dispatch({
        type: PurchaseTypes.FETCH_AUTOCOMPLETE_CATEGORIES_AND_PROVIDERS,
        params: { query: debouncedQuery, limit: LIMIT_AUTOCOMPLETE_RESULTS },
      })
    } else {
      dispatch(PurchaseActions.setMainSearch([]))
    }
  }, [debouncedQuery, dispatch])

  useEffect(() => {
    if (hoveredResult !== '' && refs[hoveredResult]) refs[hoveredResult].current.scrollIntoView()
  }, [hoveredResult, refs])

  useEffect(() => {
    setHoveredResult('')
  }, [results])

  const handleRouting = async search => {
    if (search.type === categoryTypes.provider) {
      dispatch(PurchaseActions.setSelectedCategories({}))
      dispatch(PurchaseActions.setShowOnlyOneProducts(false))
      history.push(`${routes.addProvider(search.key)}/category/all`)
      return
    }
    if (search.type === categoryTypes.product) {
      dispatch(PurchaseActions.setShowOnlyOneProducts(true))
      const response = await getProduct(selectedCenter.id, search.key)
      dispatch(PurchaseActions.setProduct(response))
      const cat =
        response &&
        response.category &&
        response.category.children &&
        response.category.children[0] &&
        response.category.children[0].children &&
        response.category.children[0].children[0].id
          ? response.category.children[0].children[0].id
          : ''
      if (history.location.pathname !== `${routes.categories}/${cat}`) {
        dispatch(PurchaseActions.setSelectedCategories({}))
        history.push(routes.AddCategory(cat))
      }
      return
    }
    if (history.location.pathname !== `${routes.categories}/${search.key}`) {
      dispatch(PurchaseActions.setSelectedCategories({}))
    }
    dispatch(PurchaseActions.setShowOnlyOneProducts(false))
    history.push(routes.AddCategory(search.key))
  }

  const onKeyDownInput = event => {
    const char = event.which || event.keyCode
    if (_.isEmpty(results)) return
    switch (char) {
      case KEY_CODES.ESC:
        onClickOutsideInput()
        break
      case KEY_CODES.UP_ARROW:
        if (hoveredResult === '' || hoveredResult === 0) {
          setHoveredResult(results.length - 1)
          break
        }
        setHoveredResult(value => value - 1)
        break
      case KEY_CODES.DOWN_ARROW:
        if (hoveredResult === '' || hoveredResult === results.length - 1) {
          setHoveredResult(0)
          break
        }
        setHoveredResult(value => value + 1)
        break
      case KEY_CODES.ENTER:
        if (hoveredResult === '') break
        handleRouting(results[hoveredResult])
        onClickOutsideInput()
        break
      default:
        break
    }
  }

  const onFocusInput = () => {
    setFocused(true)
    setDisplayAutocomplete('block')
  }

  const renderSectionSelector = (literal, callback) => (
    <SelectorContainer>
      <SectionSelector
        selected={_.get(history, 'location.pathname', '').includes(literal)}
        onClick={callback}
        data-cy={`${literal}-section`}
      >
        <MediumText>{t(literal)}</MediumText>
      </SectionSelector>
      <div className='border-with-radius' />
    </SelectorContainer>
  )

  const onClickSearch = newSearch => {
    handleRouting(newSearch)
    onClickOutsideInput()
  }

  const goToCategories = () => {
    if (!_.get(history, 'location.pathname', '').includes('categories')) {
      dispatch(PurchaseActions.setShowOnlyOneProducts(false))
      dispatch(PurchaseActions.setSelectedCategories({}))
      history.push(routes.categories)
    }
  }

  const goToCategoriesHabitual = () => {
    dispatch(PurchaseActions.setSelectedCategoriesHabitual({}))

    const firstCategory = _.get(categoriesHabitual, '0.id', 'A')
    history.push(routes.AddCategoryHabitual(firstCategory))
  }

  const goToShoppingLists = () => {
    history.push(routes.lists)
  }

  const goToProviders = () => {
    dispatch(PurchaseActions.setSelectedCategories({}))
    history.push(routes.providers)
  }

  const hasSomethingInCart = !!_.find(cart, ({ amount = 0 }) => amount > 0)

  const fullTotal = hasSomethingInCart
    ? _.chain(cart)
        .map(({ total }) => {
          if (!total) return 0
          return total
        })
        .sum()
        .value()
    : ''

  const productQuantity = hasSomethingInCart
    ? _.chain(cart)
        .map(({ amount }) => (amount > 0 ? 1 : 0))
        .sum()
        .value()
    : ''

  const goHome = () => {
    history.push(routes.purchase)
  }

  const isDisabledCartButton = repeatOrderLoading || isLoadingCart || isUpdatingCart || isEditingCart

  return (
    <Container className={classes.root} hiddenBar={hidden}>
      <AppBar position='static' color='default' className={classes.bar}>
        <Toolbar>
          <Row>
            <PortalLogo width='55' height='55' style={{ cursor: 'pointer' }} onClick={goHome} />
            <div ref={searchRef}>
              <TextInput
                placeholder={t('searchPlaceholderCategory')}
                topIcon='1.2rem'
                width='36rem'
                respWidth='26rem'
                Icon={props => <SearchIcon fill={focused ? colors.secondary : colors.black} {...props} />}
                margin='0 2.5rem'
                value={query}
                onChange={e => setQuery(e.target.value)}
                onFocus={onFocusInput}
                onKeyDown={onKeyDownInput}
                borderRadius={focused ? '8px 8px 0 0' : '8px'}
                refProp={inputRef}
                background='#F4F1F1'
                name='search'
              />
              <RelativeContainer display={displayAutocomplete}>
                <ResultsContainer>
                  {results.map(({ content, key, type, ...rest }, index) => (
                    <Result
                      className={classNames({ selected: index === hoveredResult })}
                      key={`${key}${index}`}
                      onClick={() => onClickSearch({ content, key, type, ...rest })}
                      ref={refs[index]}
                      data-cy={`search-${index}`}
                    >
                      <StyledText padding='8px 0'>{content}</StyledText>
                      <Type>{t(type)}</Type>
                      <Arrow className='icon-arrow' />
                    </Result>
                  ))}
                </ResultsContainer>
              </RelativeContainer>
            </div>
            {renderSectionSelector('categories', goToCategories)}
            {renderSectionSelector('providers', goToProviders)}
            {features?.habituals && renderSectionSelector('habitualCats', goToCategoriesHabitual)}
            {features?.shopping_lists && renderSectionSelector('lists', goToShoppingLists)}
            {showCartButton && (
              <Button
                disabled={isDisabledCartButton}
                startIcon={isDisabledCartButton ? <CircularProgress color='inherit' size={20} /> : <CartIcon />}
                className={classes.menuButton}
                color='inherit'
                onClick={() => setShowDrawer(true)}
              >
                {hasSomethingInCart && showPrice ? <NumberFormat value={fullTotal} /> : t('cart')}
                {hasSomethingInCart ? <TotalItems>{productQuantity}</TotalItems> : null}
              </Button>
            )}
            {needsApproval && <ApprovedCartButton />}
          </Row>
        </Toolbar>
      </AppBar>
    </Container>
  )
}

const ApprovedCartButton = () => {
  const classes = useStyles()
  const history = useHistory()

  const cart = useSelector(state => state.approver.cart)

  const { fullTotal } = useApproverCart()
  const total = calculateProductAmount(cart)

  const handleClick = () => history.push(routes.approverCarts)

  return (
    <Button startIcon={<CartIcon />} className={classes.menuButton} color='inherit' onClick={handleClick}>
      <NumberFormat value={fullTotal} />
      <TotalItems>{total}</TotalItems>
    </Button>
  )
}

Bar.defaultProps = {
  setShowDrawer: () => {},
  showCartButton: true,
}

Bar.propTypes = {
  setShowDrawer: PropTypes.func,
  showCartButton: PropTypes.bool,
}

export default Bar
