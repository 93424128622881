import React from 'react'

import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import routes from 'Config/routes'

const SelectedCenterRoute = ({ children, ...rest }) => {
  const center = useSelector(({ auth }) => auth.selectedCenter)
  const user = useSelector(({ auth }) => auth.user)

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (_.isEmpty(user)) {
          return (
            <Redirect
              to={{
                pathname: routes.root,
                state: { from: location },
              }}
            />
          )
        }

        if (_.isEmpty(center)) {
          return (
            <Redirect
              to={{
                pathname: routes.home,
                state: { from: location },
              }}
            />
          )
        }

        return children
      }}
    />
  )
}

SelectedCenterRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default SelectedCenterRoute
