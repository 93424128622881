import styled from 'styled-components'
import TablePagination from '@material-ui/core/TablePagination'

export const StyledTablePagination = styled(TablePagination)`
  margin-left: auto;

  .MuiTypography-root,
  .MuiInputBase-root {
    font-size: 14px;
    font-family: Inter;
    font-weight: 500;
  }
`
