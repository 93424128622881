import { ANALYTICS_EVENT } from './constants'
import { sendAnalytics } from './utils'

const setCenter = ({ id, name, segment }) => {
  if (!id || !segment || !name) return

  sendAnalytics(ANALYTICS_EVENT.SET_CENTER, {
    center: `${name} - ${id}`,
    center_type: segment,
  })
}

export default setCenter
