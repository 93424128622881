import styled from 'styled-components'

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1.25rem;
`

export const ProductCardLoadingContainer = styled.div`
  margin: 0 0.6rem 1.5rem;
`
