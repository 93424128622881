import React from 'react'

import PropTypes from 'prop-types'
import colors from 'Assets/colors'
import { ReactComponent as ExclamationCircle } from 'Assets/Icons/ExclamationCircle.svg'
import { ReactComponent as CloseX } from 'Assets/Icons/CloseX.svg'
import { ReactComponent as Error } from 'Assets/Icons/ErrorPedido.svg'
import { ReactComponent as Loading } from 'Assets/Icons/Loading.svg'
import { ReactComponent as Info } from 'Assets/Icons/Info.svg'

import { Row, MessageContainer, MessageText, ButtonContainer, ActionButton, ButtonLiteral } from './styled'

const Notification = ({ text, anchorText, postText, color, icon, buttonText, href, onClick, onClose }) => (
  <Row buttonText={buttonText} style={{ background: color === 'error' ? colors.red : colors.secondary }}>
    <MessageContainer>
      {icon === 'error' && <Error style={{ marginRight: '1rem' }} />}
      {icon === 'warning' && <ExclamationCircle style={{ marginRight: '1rem' }} />}
      {icon === 'loading' && <Loading style={{ marginRight: '1rem' }} />}
      {icon === 'info' && <Info style={{ marginRight: '1rem' }} />}
      {icon === 'close' && <CloseX style={{ marginRight: '1rem' }} onClick={onClose} />}
      {!href && text && <MessageText>{text}</MessageText>}
      {href && text && (
        <MessageText>
          <span>{text}</span>
          <a href={href} target='_blank' rel='noopener noreferrer'>
            {anchorText}
          </a>
          <span>{postText}</span>
        </MessageText>
      )}
    </MessageContainer>
    {buttonText && (
      <ButtonContainer>
        <ActionButton onClick={onClick}>
          <ButtonLiteral style={{ color: color === 'error' ? colors.red : colors.secondary }}>
            {buttonText}
          </ButtonLiteral>
        </ActionButton>
      </ButtonContainer>
    )}
  </Row>
)

Notification.defaultProps = {
  text: '',
  anchorText: '',
  postText: '',
  color: colors.secondary,
  icon: '',
  buttonText: '',
  href: '',
  onClick: () => {},
  onClose: () => {},
}

Notification.propTypes = {
  text: PropTypes.string,
  anchorText: PropTypes.string,
  postText: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  buttonText: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
}

export default Notification
