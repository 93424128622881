import styled from 'styled-components'

export const ButtonNotAvailableContainer = styled.div`
  width: 100%;
  border: 2px solid ${props => props.theme.colors.gray40l};
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 8px;
`

export const Unavailable = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  padding: 0.5rem;
`

export const EraseButton = styled.div`
  padding: 0.5rem;
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  border-radius: 8px;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .trash {
    padding-right: 0.5rem;
  }

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`
