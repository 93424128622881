import { ROLES_KEYS } from 'Config/constants'

import { ANALYTICS_EVENT } from './constants'
import { hashText, sendAnalytics } from './utils'

const setUserProperties = async ({ email, roles }) => {
  if (!email || !roles) return

  sendAnalytics(ANALYTICS_EVENT.SET_USER_PROPERTIES, {
    user_id: await hashText(email),
    is_user: !!roles?.includes(ROLES_KEYS.ROLE_USER),
    is_approver: !!roles?.includes(ROLES_KEYS.ROLE_APPROVER),
    is_stef: !!roles?.includes(ROLES_KEYS.ROLE_STEF),
    is_sales: !!roles?.includes(ROLES_KEYS.ROLE_SALES),
    is_purchases: !!roles?.includes(ROLES_KEYS.ROLE_PURCHASES),
  })
}

export default setUserProperties
