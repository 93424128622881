import React from 'react'

import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'

const ProductInfoLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width={200}
      height={64}
      viewBox='0 0 200 64'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='0' y='46' rx='0' ry='0' width='40' height='6' />
      <rect x='0' y='12' rx='0' ry='0' width='68' height='14' />
    </ContentLoader>
  )
}

export default ProductInfoLoader
