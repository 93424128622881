import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { setErrorAdd, updateTitle } from 'Redux/lists/actions'

const useShoppingListsEditModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(s => s.lists.isFetchingUpdate)
  const error = useSelector(s => s.lists.errorAdd)
  const selectedList = useSelector(s => s.lists.data)

  const [name, setName] = useState(selectedList?.title)

  useEffect(() => {
    if (showModal && selectedList) {
      setName(selectedList.title)
    } else {
      setName('')
    }
  }, [showModal, selectedList])

  const onChangeName = e => {
    setName(e.target.value)
    dispatch(setErrorAdd(''))
  }

  const onSubmit = () => {
    dispatch(updateTitle(name, () => setShowModal(false)))
  }

  return {
    isLoading,
    error,
    name,
    onChangeName,
    onSubmit,
  }
}

export default useShoppingListsEditModal
