import React from 'react'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'

const NoRows = () => {
  const { t } = useTranslation()

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      paddingTop='56px'
      width='100%'
      height='100%'
      zIndex={998}
    >
      <Typography>{t('Sin datos')}</Typography>
    </Box>
  )
}

export default NoRows
