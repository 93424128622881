import React, { useEffect, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { setModalRepeatErrors } from 'Redux/purchases/actions'
import { Modal } from 'Components/atoms'

import { Title, Message, ActionContainer, CancelButton, ButtonLiteral, Content } from './styled'

const ModalRepeatOrder = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const errors = useSelector(state => state.purchase.repeatOrderErrors)

  const setCloseModal = useCallback(() => {
    dispatch(setModalRepeatErrors(null))
  }, [dispatch])

  useEffect(
    () => () => {
      setCloseModal()
    },
    [setCloseModal]
  )

  return (
    <Modal isOpen={errors && errors.length > 0} setIsOpen={setCloseModal} width='680px' height='300px'>
      <Content>
        <Title>{t('repeatOrderErrorsTitle')}</Title>
        <Message>
          <ul>{errors && errors.map(({ name, reference }) => <li key={reference}>{name}</li>)}</ul>
        </Message>
        <ActionContainer>
          <CancelButton onClick={setCloseModal}>
            <ButtonLiteral>{t('close')}</ButtonLiteral>
          </CancelButton>
        </ActionContainer>
      </Content>
    </Modal>
  )
}

export default ModalRepeatOrder
