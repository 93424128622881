import moment from 'moment'
import colors from 'Assets/colors'

export const getTotalPrice = products => products.map(({ total }) => total).reduce((a, b) => a + b, 0)

export const getCalendarColor = ({ isExpiredDate, deliveryDate }) => {
  if (isExpiredDate) {
    return colors.red
  }

  if (deliveryDate) {
    return colors.secondary
  }

  return colors.black
}

export const isNextMonth = checkDate => {
  const monthDeliveryDate = moment(checkDate).format('M')
  const currentMonth = moment().format('M')

  return monthDeliveryDate !== currentMonth
}

export const getDateSelectTitle = ({ t, deliveryDate, isExpiredDate }) => {
  if (isExpiredDate) {
    return t('deliveryDateExpired')
  }
  if (deliveryDate) {
    return moment(deliveryDate).format('LL')
  }
  return t('noCalendar')
}

export const areProductsNoAvailable = productList => productList.find(p => p.state === 'no_available')
