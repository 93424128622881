export const SET_PROVIDER_RECIVING_ORDERS = 'portal_compras/delivery/SET_PROVIDER_RECIVING_ORDERS'
export const SET_PROVIDER_RECIVING_ORDERS_LOADING = 'portal_compras/delivery/SET_PROVIDER_RECIVING_ORDERS_LOADING'
export const SET_PROVIDER_RECIVING_ORDERS_ERROR = 'portal_compras/delivery/SET_PROVIDER_RECIVING_ORDERS_ERROR'
export const PROVIDER_RECIVING_ORDERS = 'portal_compras/delivery/PROVIDER_RECIVING_ORDERS'
export const SET_PROVIDER_RECIVING_ORDERS_FORM = 'portal_compras/delivery/SET_PROVIDER_RECIVING_ORDERS_FORM'
export const PROVIDER_RECIVING_ORDERS_FORM = 'portal_compras/delivery/PROVIDER_RECIVING_ORDERS_FORM'
export const PROVIDER_RECIVING_ORDERS_RESET = 'portal_compras/delivery/PROVIDER_RECIVING_ORDERS_RESET'
export const SET_DELIVERY_FORM = 'portal_compras/delivery/SET_DELIVERY_FORM'
export const DELIVERY_FORM = 'portal_compras/delivery/DELIVERY_FORM'
export const RECEIVING_LINES_FORM = 'portal_compras/delivery/RECEIVING_LINES_FORM'
export const SET_RECEIVING_LINES_FORM = 'portal_compras/delivery/SET_RECEIVING_LINES_FORM'
export const SET_DELIVERY_ERRORS = 'portal_compras/delivery/SET_DELIVERY_ERRORS'
export const FETCH_DELIVERY_ERRORS = 'portal_compras/delivery/FETCH_DELIVERY_ERRORS'
