import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import {
  ShoppingListItemContainer,
  ListItemName,
  ListItemSection,
  ShowButton,
  DeleteButton,
  SeparatorTwo,
  SeparatorLine,
} from '../styled'

import AddCartButton from './addCartButton'

const Item = ({ item, onClickRemove, onClickSee }) => {
  const { t } = useTranslation()

  const { title, totalLines, created /* , expiry */ } = item

  const products = `${totalLines || 0} ${t(totalLines === 1 ? 'product' : 'products')}`
  const createdAt = t('shoppingList.list.createdAt', { date: moment(created).format('DD/MM/YYYY') })
  /* const expiresAt = t('shoppingList.list.expiresAt', { date: moment(expiry).format('DD/MM/YYYY') }) */

  const handleClickRemove = () => onClickRemove(item)
  const handleClickSee = () => onClickSee(item)

  return (
    <ShoppingListItemContainer>
      <ListItemName>{title}</ListItemName>
      <ListItemSection>{products}</ListItemSection>
      <ListItemSection>{createdAt}</ListItemSection>
      {/* <ListItemSection>{expiresAt}</ListItemSection> */}
      <SeparatorTwo />
      <DeleteButton onClick={handleClickRemove}>{t('shoppingList.list.removeList')}</DeleteButton>
      <ShowButton onClick={handleClickSee}>{t('shoppingList.list.seeList')}</ShowButton>
      <SeparatorLine />
      <AddCartButton item={item} />
    </ShoppingListItemContainer>
  )
}

Item.defaultProps = {
  item: {},
  onClickRemove: () => null,
  onClickSee: () => null,
}

Item.propTypes = {
  item: PropTypes.object,
  onClickRemove: PropTypes.func,
  onClickSee: PropTypes.func,
}

export default Item
