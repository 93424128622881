import {
  all,
  takeLatest,
  delay,
  call,
  put,
  select,
  takeLeading,
  fork,
  take,
  cancel,
  cancelled,
} from 'redux-saga/effects'
import axios from 'axios'
import i18next from 'i18next'
import get from 'lodash/get'
import {
  getCategoriesAndProviderSearch,
  getProducts,
  getCategories,
  getProviders,
  getProviderCategories,
  getProduct,
  getCart,
  deleteCart,
  getBudget,
  deleteProviderProducts,
  updateCart,
  getOriginCenters,
  getOrders,
  postPreConfirmCart,
  getProviderOrders,
  putRepeatOrder,
  getCategoriesHabitual,
  getHabitualProducts,
  getOrderProducts,
  deleteRequestFailed,
  addRequestOrderErrorId,
  repeatRequestOrder,
  addProductToOrder,
  getFavoritesHabitualProducts,
  getExistsProviderPendingOrders,
  getReloadingProducts,
} from 'Services/api'
import { getCategoryValue, getApiErrorMessage } from 'Config/utils'
import { debounceAmount } from 'Config/constants'
import { toast } from 'react-toastify'
import { SET_SELECTED_CENTER } from 'Redux/auth/types'
import { setIsSendingcart } from 'Redux/lists/actions'
import analyticsService from 'Services/analytics'
import { selectConfig } from 'Redux/auth/utils'
import * as Sentry from 'Services/sentry'
import Hotjar from '@hotjar/browser'

import {
  setErrorSearch,
  setMainSearch,
  setErrorProducts,
  setProducts,
  setProductsCount,
  setLoadingProducts,
  setErrorCategories,
  setCategories,
  setErrorProviders,
  setProviders,
  setLoadingProviders,
  setLoadingCategories,
  setProviderCategories,
  setLoadingProduct,
  setProduct,
  setCart,
  initiateCart,
  setBudget,
  setErrorCart,
  setModalErrorCart,
  setIsLoadingCart,
  setIsLoadingBudget,
  setOriginCenters,
  setDeliveries,
  setOriginCenter,
  setOrders,
  setModalRepeatErrors,
  setIsLoadingOrders,
  setProviderOrders,
  setIsLoadingProviderOrders,
  setHistoricProviders,
  setHistoricCenters,
  setLoadingRepeatOrder,
  setLoadingCategoriesHabitual,
  setCategoriesHabitual,
  setErrorCategoriesHabitual,
  setLoadingHabitualProducts,
  setHabitualProducts,
  setHabitualProductsCount,
  setErrorHabitualProducts,
  setErrorProviderOrders,
  setPreviusOrderProducts,
  setErrorPreviusOrder,
  setIsLoadingPreviusOrder,
  setHabitualProductsLastReference,
  setIsUpdatingCart,
  setIsEditingCart,
  setILoadingPreconfirm,
  setAddingProductQueue,
  setConfirmedCart,
  checkConfirmedCart,
  setNumProviderPendingOrders,
  setIsLoadingReloading,
  setIsReloading,
  setFailedReceptions,
} from './actions'
import { selectAddProductsQueue, selectSelectedCategoriesHabitual } from './selectors'
import {
  FETCH_AUTOCOMPLETE_CATEGORIES_AND_PROVIDERS,
  FETCH_AUTOCOMPLETE_PROVIDERS,
  FETCH_PRODUCTS,
  FETCH_CATEGORIES,
  FETCH_PROVIDERS,
  FETCH_CATEGORIES_PROVIDERS,
  FETCH_PRODUCT,
  FETCH_CART,
  EMPTY_CART,
  FETCH_BUDGET,
  DELETE_PROVIDER_PRODUCT,
  UPDATE_CART,
  FETCH_ORIGIN_CENTERS,
  FETCH_ORDERS,
  PRE_CONFIRM_CART,
  FETCH_PROVIDER_ORDERS,
  FETCH_HISTORIC_PROVIDERS,
  FETCH_HISTORIC_CENTERS,
  FETCH_REPEAT_ORDER,
  FETCH_PENDING_PROVIDER_ORDERS,
  FETCH_CATEGORIES_HABITUAL,
  FETCH_HABITUAL_PRODUCTS,
  FETCH_PREVIUS_ORDER_PROVIDERS,
  EDIT_CART,
  REMOVE_REQUEST_FAILED,
  ADD_REQUEST_FAILED_TO_CART,
  SET_PRODUCT,
  ADD_PRODUCT_TO_CART,
  NEXT_ADD_PRODUCT_TO_CART,
  CHECK_CONFIRMED_CART,
  FETCH_IS_RELOADING,
  FETCH_FAILED_RECEPTIONS,
} from './types'
import {
  assembleCart,
  selectCenterId,
  assemblePayload,
  selectOrders,
  selectedCategories,
  handleHabitualCategories,
  getDiffProductsFromCart,
  selectHabitualCagetories,
  selectCategories,
} from './utils'

export function* fetchSearch(action) {
  yield delay(100)
  try {
    const params = get(action, 'params', {})
    const centerId = yield select(selectCenterId)
    const searchResults = yield call(getCategoriesAndProviderSearch, params, centerId)
    searchResults.sort((a, b) => {
      const aValue = getCategoryValue(a.type)
      const bValue = getCategoryValue(b.type)

      return aValue - bValue
    })
    yield put(setMainSearch(searchResults))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(i18next.t(getApiErrorMessage(e)))
      yield put(setErrorSearch(e))
    }
  }
}

export function* fetchSearchProviders(action) {
  yield delay(100)
  try {
    const params = get(action, 'params', {})
    const centerId = yield select(selectCenterId)
    const searchResults = yield call(getProviders, centerId, params)
    yield put(setProviders(searchResults))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(i18next.t(getApiErrorMessage(e)))
      yield put(setErrorSearch(e))
    }
  }
}

export function* fetchHistoricProviders(action) {
  yield delay(100)
  try {
    const params = get(action, 'params', {})
    const centerId = yield select(selectCenterId)
    const searchResults = yield call(getProviders, centerId, params)
    yield put(setHistoricProviders(searchResults))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(i18next.t(getApiErrorMessage(e)))
      yield put(setErrorSearch(e))
    }
  }
}

export function* fetchHistoricCenters(action) {
  yield delay(100)
  try {
    const params = get(action, 'params', {})
    const centerId = yield select(selectCenterId)
    const searchResults = yield call(getOriginCenters, centerId, params)
    yield put(setHistoricCenters(searchResults))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(i18next.t(getApiErrorMessage(e)))
      yield put(setErrorSearch(e))
    }
  }
}

export function* setProductsSaga({ products, count }) {
  const categories = yield select(selectedCategories)
  yield put(setProducts(products))
  yield put(setProductsCount(count))
  analyticsService.viewItemList({ products, selectedCategories: categories })
}

export function* checkFavoritesProductSaga({ products = [], count, centerId }) {
  try {
    const productsIds = products.map(product => product.id)

    const { data = [] } = yield call(getFavoritesHabitualProducts, { products: productsIds }, centerId)
    const habitualProductList = data.map(habitualProduct => `${habitualProduct.provider}-${habitualProduct.product}`)
    const result = products.map(product => ({ ...product, isHabitual: habitualProductList.includes(product.id) }))
    yield setProductsSaga({ products: result, count })
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield setProductsSaga({ products, count })
      Sentry.captureException(e)
    }
  }
}

export function* fetchProducts(action) {
  try {
    const params = get(action, 'params', {})
    const centerId = yield select(selectCenterId)
    const features = yield select(selectConfig)
    yield put(setLoadingProducts(true))
    const { data = [], count = 0 } = yield call(getProducts, params, centerId)
    if (features?.habituals) {
      yield checkFavoritesProductSaga({ products: data, count, centerId })
    } else {
      yield setProductsSaga({ products: data, count })
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(i18next.t('purchasesErrorMessages.fetchProducts', { apiMessage: e?.response?.data?.message }))
      yield put(setErrorProducts(e))
    }
  } finally {
    yield put(setLoadingProducts(false))
  }
}

export function* fetchCategories() {
  const previousCategories = yield select(selectCategories)
  if (previousCategories?.length) {
    return
  }
  try {
    const centerId = yield select(selectCenterId)
    yield put(setLoadingCategories(true))
    const { data = [] } = yield call(getCategories, centerId)
    yield put(setCategories(data))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(
        i18next.t('purchasesErrorMessages.fetchCategories', {
          apiMessage: e?.response?.data?.message || i18next.t('petitionError'),
        })
      )
      yield put(setErrorCategories(e))
    }
  } finally {
    yield put(setLoadingCategories(false))
  }
}

export function* fetchProviders(action) {
  try {
    const params = get(action, 'params', {})
    const centerId = yield select(selectCenterId)
    yield put(setLoadingProviders(true))
    const { data = [] } = yield call(getProviders, centerId, params)
    yield put(setProviders(data))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(
        i18next.t('purchasesErrorMessages.fetchProviders', {
          apiMessage: e?.response?.data?.message || i18next.t('petitionError'),
        })
      )
      yield put(setErrorProviders(e))
    }
  } finally {
    yield put(setLoadingProviders(false))
  }
}

export function* fetchProviderCategories(action) {
  try {
    yield put(setLoadingCategories(true))
    const { providerId } = get(action, 'params', {})
    const centerId = yield select(selectCenterId)
    const { data = [] } = yield call(getProviderCategories, centerId, providerId)
    const allCategory = { id: 'all', name: 'Todas las categorías', children: [] }
    yield put(setProviderCategories([allCategory, ...data]))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      yield put(setErrorCategories(e))
    }
  } finally {
    yield put(setLoadingCategories(false))
  }
}

export function* fetchProduct(action) {
  try {
    const { productId = '' } = action
    const centerId = yield select(selectCenterId)
    yield put(setLoadingProduct(true))
    const response = yield call(getProduct, centerId, productId)
    yield put(setProduct(response))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(i18next.t(getApiErrorMessage(e)))
      yield put(setErrorProducts(e))
    }
  } finally {
    yield put(setLoadingProduct(false))
  }
}

function* checkConfirmedCartSaga(action) {
  try {
    const confirmedCart = action.payload
    const prevConfirmedCartDate = yield select(state => state.purchase.confirmedCart)

    if (new Date(confirmedCart?.updated) <= new Date(prevConfirmedCartDate?.updated)) {
      return
    }
    yield put(setConfirmedCart(confirmedCart))
  } catch (error) {
    Sentry.captureException(error)
  }
}

function* getCartSagas(endPoint) {
  try {
    yield put(setIsLoadingCart(true))
    yield put(initiateCart(false))
    const centerId = yield select(selectCenterId)
    const response = yield call(endPoint, centerId)
    yield put(checkConfirmedCart(response))
    const { products = [], deliveries = [], origin } = response
    const newCart = assembleCart(products, deliveries)
    yield put(setCart(newCart))
    yield put(setDeliveries(deliveries))
    yield put(setOriginCenter(origin))
    yield delay(debounceAmount + 100)
    yield put(initiateCart(true))
    yield put(setErrorCart(false))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(i18next.t('purchasesErrorMessages.getCartSagas', { apiMessage: getApiErrorMessage(e) }))
      yield put(setErrorCart(true))
    }
  } finally {
    yield put(setIsLoadingCart(false))
  }
}

export function* fetchCart() {
  yield getCartSagas(getCart)
}

export function* preConfirmCart() {
  yield put(setILoadingPreconfirm(true))
  yield getCartSagas(postPreConfirmCart)
  const cart = yield select(state => state.purchase.cart)
  yield put(setILoadingPreconfirm(false))

  const mappedCartForAnalytics = Object.values(cart)
  analyticsService.beingCheckout({ products: mappedCartForAnalytics, step: 1 })
}

export function* emptyCart() {
  const centerId = yield select(selectCenterId)
  yield put(initiateCart(false))
  yield call(deleteCart, centerId)
  yield fork(fetchCart)
}

export function* fetchBudget() {
  try {
    yield put(setIsLoadingBudget(true))
    const centerId = yield select(selectCenterId)
    const { currentBudget } = yield call(getBudget, centerId)
    yield put(setBudget(currentBudget))
    yield put(setIsLoadingBudget(false))
  } catch (e) {
    Sentry.captureException(e)
    toast.error(i18next.t(getApiErrorMessage(e)))
  }
}

export function* deleteProductsProvider(action) {
  const providerId = get(action, 'providerId')
  const centerId = yield select(selectCenterId)
  try {
    yield call(deleteProviderProducts, centerId, providerId)
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(i18next.t(getApiErrorMessage(e)))
      yield put(setErrorProducts(e))
    }
  } finally {
    yield fork(fetchCart)
  }
}

export function* updateCartSaga(action) {
  yield put(setIsUpdatingCart(true))
  const cart = get(action, 'debouncedCart', {})
  const products = assemblePayload(cart)
  const centerId = yield select(selectCenterId)
  try {
    yield call(updateCart, centerId, products)
  } catch (e) {
    Sentry.captureException(e)
    yield put(setModalErrorCart(true))
    yield fork(fetchCart)
  } finally {
    yield put(setIsUpdatingCart(false))
  }
}

export function* fetchOriginCenters() {
  const centerId = yield select(selectCenterId)
  try {
    const { data = [] } = yield call(getOriginCenters, centerId)
    yield put(setOriginCenters(data))
  } catch (e) {
    Sentry.captureException(e)
    toast.error(i18next.t(getApiErrorMessage(e)))
    yield put(setErrorCart(true))
  }
}

export function* fetchOrders(action) {
  const centerId = yield select(selectCenterId)
  const params = get(action, 'params', {})
  try {
    yield put(setIsLoadingOrders(true))
    const { data = [] } = yield call(getOrders, params, centerId)
    yield put(setOrders(data))
  } catch (e) {
    Sentry.captureException(e)
    toast.error(
      i18next.t('purchasesErrorMessages.fetchOrders', {
        apiMessage: e?.response?.data?.message || i18next.t('petitionError'),
      })
    )
  } finally {
    yield put(setIsLoadingOrders(false))
  }
}

export function* removeRequestFailed(action) {
  const centerId = yield select(selectCenterId)
  const requestId = get(action, 'payload.id')
  const orders = yield select(selectOrders)

  try {
    const newOrdersArray = orders.map(order => order.id !== requestId)
    yield call(deleteRequestFailed, centerId, requestId)
    yield put(setOrders(newOrdersArray))
    toast.success(i18next.t('failedRequests.removeRequestSuccess'))
  } catch (e) {
    if (!axios.isCancel(e)) {
      toast.error(e?.response?.data?.message || i18next.t('failedRequests.removeRequestFailed'))
      Sentry.captureException(e)
    }
  }
}

export function* addRequestFailedToCart(action) {
  const centerId = yield select(selectCenterId)
  const requestId = get(action, 'payload.id')
  yield put(setIsSendingcart(true))

  try {
    const response = yield call(repeatRequestOrder, centerId, requestId)
    if (response && response.errors && response.errors.length > 0) {
      yield put(setModalRepeatErrors(response.errors))
    } else {
      toast.success(i18next.t('petitionSuccess'), { hideProgressBar: true })

      const { products = [], deliveries = [], origin } = response.cart
      const newCart = assembleCart(products, deliveries)
      yield put(setCart(newCart))
      yield put(setDeliveries(deliveries))
      yield put(setOriginCenter(origin))
      yield delay(debounceAmount + 100)
      yield put(initiateCart(true))
      yield put(setErrorCart(false))

      yield call(addRequestOrderErrorId, centerId, requestId)
    }

    yield fork(fetchCart)
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(e?.response?.data?.message || i18next.t('failedRequests.addRequestToCartError'), {
        hideProgressBar: true,
      })
    }
  } finally {
    yield put(setIsSendingcart(false))
  }
}

export function* fetchProviderOrders(action) {
  const centerId = yield select(selectCenterId)
  const params = get(action, 'params', {})
  yield put(setErrorProviderOrders(false))
  yield put(setProviderOrders([]))
  try {
    yield put(setIsLoadingProviderOrders(true))
    const { data = [] } = yield call(getProviderOrders, params, centerId)
    yield put(setProviderOrders(data))
  } catch (e) {
    Sentry.captureException(e)
    toast.error(
      i18next.t('purchasesErrorMessages.fetchProviderOrders', {
        apiMessage: e?.response?.data?.message || i18next.t('petitionError'),
      })
    )
    yield put(setErrorProviderOrders(true))
  } finally {
    yield put(setIsLoadingProviderOrders(false))
  }
}

function* addRepeatOrderToCart(action) {
  const toastId = toast.info(i18next.t('repeatOrderLoading'), { hideProgressBar: true, autoClose: false })

  const orderId = get(action, 'params.orderId')
  const documentTypeCode = get(action, 'params.documentTypeCode')
  const documentCompany = get(action, 'params.documentCompany')
  let centerId = get(action, 'params.centerId')
  if (!centerId) {
    centerId = yield select(selectCenterId)
  }

  yield put(setLoadingRepeatOrder(true))
  try {
    const response = yield call(putRepeatOrder, centerId, orderId, documentTypeCode, documentCompany)
    const { products = [], deliveries = [], origin } = response.cart
    const newCart = assembleCart(products, deliveries)
    const oldCard = yield select(state => state.purchase.cart)
    const repeatProducts = getDiffProductsFromCart(products, oldCard)

    yield put(setCart(newCart))
    yield put(setDeliveries(deliveries))
    yield put(setOriginCenter(origin))
    yield delay(debounceAmount + 100)
    yield put(initiateCart(true))
    yield put(setErrorCart(false))

    const categories = yield select(state => state.purchase.selectedCategories)
    const selectedHabitualCategories = yield select(selectSelectedCategoriesHabitual)
    const habitualCategories = handleHabitualCategories(selectedHabitualCategories)

    analyticsService.addToCart({
      products: repeatProducts,
      selectedCategories: habitualCategories || categories,
      repeatCart: true,
    })

    toast.success(i18next.t('petitionSuccess'), { hideProgressBar: true })
    if (response && response.errors && response.errors.length > 0) {
      yield put(setModalRepeatErrors(response.errors))
    }
  } catch (e) {
    Sentry.captureException(e)
    toast.error(i18next.t(getApiErrorMessage(e)))
  } finally {
    toast.dismiss(toastId)
    yield put(setLoadingRepeatOrder(false))
  }
}

function* fetchPendingProviderOrders() {
  const centerId = yield select(selectCenterId)
  try {
    const data = yield call(getExistsProviderPendingOrders, centerId)
    yield put(setNumProviderPendingOrders(data.exists))
  } catch (e) {
    Sentry.captureException(e)
    toast.error(
      i18next.t('purchasesErrorMessages.fetchPendingProviderOrders', {
        apiMessage: e?.response?.data?.message || i18next.t('petitionError'),
      })
    )
  }
}

function* debouceAutocompleteCategoriesAndProviders() {
  yield takeLatest(FETCH_AUTOCOMPLETE_CATEGORIES_AND_PROVIDERS, fetchSearch)
}

export function* fetchCategoriesHabitual() {
  try {
    const previosusHabitualCategories = yield select(selectHabitualCagetories)
    const features = yield select(selectConfig)

    if (previosusHabitualCategories?.length || !features?.habituals) {
      return
    }
    yield put(setLoadingCategoriesHabitual(true))
    const centerId = yield select(selectCenterId)
    const { data = [] } = yield call(getCategoriesHabitual, centerId)
    const allCategories = {
      id: 'all',
      name: i18next.t('allCategories'),
      type: 'category',
    }

    data.unshift(allCategories)
    yield put(setCategoriesHabitual(data))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(
        i18next.t('purchasesErrorMessages.fetchCategoriesHabitual', {
          apiMessage: e?.response?.data?.message || i18next.t('petitionError'),
        })
      )
      yield put(setErrorCategoriesHabitual(e))
    }
  } finally {
    yield put(setLoadingCategoriesHabitual(false))
  }
}

export function* fetchHabitualProducts(action) {
  try {
    const features = yield select(selectConfig)
    if (!features?.habituals) return
    const params = get(action, 'params', {})
    const centerId = yield select(selectCenterId)
    yield put(setLoadingHabitualProducts(true))
    const selectedHabitualCategories = yield select(selectSelectedCategoriesHabitual)
    const { data = [], lastReference, count = 0 } = yield call(getHabitualProducts, params, centerId)
    yield put(setHabitualProductsLastReference(lastReference))
    yield put(setHabitualProducts(data))
    yield put(setHabitualProductsCount(count))

    analyticsService.viewItemList({
      products: data,
      selectedCategories: selectedHabitualCategories?.category
        ? selectedHabitualCategories
        : { category: selectedHabitualCategories },
    })
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(
        i18next.t('purchasesErrorMessages.fetchHabitualProducts', {
          apiMessage: e?.response?.data?.message || i18next.t('petitionError'),
        })
      )
      yield put(setErrorHabitualProducts(e))
    }
  } finally {
    yield put(setLoadingHabitualProducts(false))
  }
}

export function* fetchPreviusOrderProviders(action) {
  const centerId = yield select(selectCenterId)

  const params = get(action, 'params', {})
  const { order } = params
  try {
    yield put(setIsLoadingPreviusOrder(true))

    const results = yield call(getOrderProducts, {}, centerId, order.id, order.documentTypeCode, order.documentCompany)
    yield put(setPreviusOrderProducts(results))

    yield put(setErrorPreviusOrder(false))
  } catch (e) {
    Sentry.captureException(e)
    toast.error(i18next.t(getApiErrorMessage(e)))
    yield put(setErrorPreviusOrder(true))
  } finally {
    yield put(setIsLoadingPreviusOrder(false))
  }
}

export function* handleSetSelectedCenter(action) {
  yield delay(100)
  const user = yield select(state => state.auth.user)
  const config = yield select(state => state.auth.config)
  const center = get(action, 'payload', {})

  Sentry.setContext('center', center)

  Hotjar.identify(user.username, {
    name: user.name,
    email: user.email,
    center,
    version: config?.version,
  })
}

export function* handleEditCart(action) {
  yield put(setIsEditingCart(true))
  yield delay(debounceAmount + 250)
  yield put(setIsEditingCart(false))

  const categories = yield select(state => state.purchase.selectedCategories)
  const selectedHabitualCategories = yield select(selectSelectedCategoriesHabitual)
  const habitualCategories = handleHabitualCategories(selectedHabitualCategories)

  const product = action.payload

  if (product.amount === 0) {
    analyticsService.removeFromCart({
      product,
      selectedCategories: habitualCategories || categories,
    })
  } else {
    analyticsService.addToCart({
      products: [product],
      selectedCategories: habitualCategories || categories,
    })
  }
}

export function* handleSetProduct() {
  const product = yield select(state => state.purchase.product)

  const categories = yield select(state => state.purchase.selectedCategories)
  const selectedHabitualCategories = yield select(selectSelectedCategoriesHabitual)

  const habitualCategories = handleHabitualCategories(selectedHabitualCategories)

  analyticsService.selectItem({ product, selectedCategories: habitualCategories || categories })
}

export function* addProductToCart(action, endCallback) {
  try {
    yield put(setIsEditingCart(true))
    yield delay(debounceAmount)

    const centerId = yield select(selectCenterId)
    const product = action.payload
    yield call(addProductToOrder, centerId, action.id, product)

    const categories = yield select(selectedCategories)
    const selectedHabitualCategories = yield select(selectSelectedCategoriesHabitual)
    const habitualCategories = handleHabitualCategories(selectedHabitualCategories)

    if (product.amount === 0) {
      analyticsService.removeFromCart({
        product,
        selectedCategories: habitualCategories || categories,
      })
    } else {
      analyticsService.addToCart({
        products: [product],
        selectedCategories: habitualCategories || categories,
      })
    }
  } catch (e) {
    Sentry.captureException(e)
    toast.error(i18next.t(getApiErrorMessage(e)))
    yield put(setModalErrorCart(true))
    yield fork(fetchCart)
  } finally {
    if (!(yield cancelled()) && endCallback) {
      yield fork(endCallback)
    }
  }
}

export function* fetchReloadingProducts() {
  try {
    const centerId = yield select(selectCenterId)
    yield put(setIsReloading({ isReloading: false }))
    yield put(setIsLoadingReloading(true))
    const result = yield call(getReloadingProducts, centerId)
    yield put(setIsReloading(result))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      toast.error(i18next.t('purchasesErrorMessages.fetchProducts', { apiMessage: e?.response?.data?.message }))
    }
  } finally {
    yield put(setIsLoadingReloading(false))
  }
}

export function* fetchFailedReceptions(action) {
  const centerId = yield select(selectCenterId)
  const params = get(action, 'params', {})
  try {
    if (centerId) {
      const paramsToSend = { ...params, statuses: ['Error', 'Error Corregido'] }
      const { data = [] } = yield call(getOrders, paramsToSend, centerId)
      yield put(setFailedReceptions(data))
    }
  } catch (e) {
    Sentry.captureException(e)
    toast.error(
      i18next.t('purchasesErrorMessages.fetchFailedReceptions', {
        apiMessage: e?.response?.data?.message || i18next.t('petitionError'),
      })
    )
  }
}

function* debouceAutocompleteProviders() {
  yield takeLatest(FETCH_AUTOCOMPLETE_PROVIDERS, fetchSearchProviders)
}

function* watchFetchProducts() {
  yield takeLatest(FETCH_PRODUCTS, fetchProducts)
}

function* watchFetchCategories() {
  yield takeLeading(FETCH_CATEGORIES, fetchCategories)
}

function* watchFetchProviders() {
  yield takeLeading(FETCH_PROVIDERS, fetchProviders)
}

function* watchFetchProviderCategories() {
  yield takeLeading(FETCH_CATEGORIES_PROVIDERS, fetchProviderCategories)
}

function* watchFetchProduct() {
  yield takeLatest(FETCH_PRODUCT, fetchProduct)
}

function* watchFetchCart() {
  yield takeLatest(FETCH_CART, fetchCart)
}

function* watchPreConrimCart() {
  yield takeLatest(PRE_CONFIRM_CART, preConfirmCart)
}

function* watchCartCleanUp() {
  yield takeLatest(EMPTY_CART, emptyCart)
}

function* watchFetchBudget() {
  yield takeLatest(FETCH_BUDGET, fetchBudget)
}

function* watchDeleteProviderProducts() {
  yield takeLatest(DELETE_PROVIDER_PRODUCT, deleteProductsProvider)
}

function* watchUpdateCart() {
  yield takeLatest(UPDATE_CART, updateCartSaga)
}

function* watchOriginCenters() {
  yield takeLeading(FETCH_ORIGIN_CENTERS, fetchOriginCenters)
}

function* watchOrders() {
  yield takeLeading(FETCH_ORDERS, fetchOrders)
}

function* watchProviderOrders() {
  yield takeLeading(FETCH_PROVIDER_ORDERS, fetchProviderOrders)
}

function* debouceHistoricProviders() {
  yield takeLatest(FETCH_HISTORIC_PROVIDERS, fetchHistoricProviders)
}

function* debouceHistoricCenters() {
  yield takeLatest(FETCH_HISTORIC_CENTERS, fetchHistoricCenters)
}

function* watchRepeatOrder() {
  yield takeLatest(FETCH_REPEAT_ORDER, addRepeatOrderToCart)
}

function* watchFetchProviderOrdersPending() {
  yield takeLatest(FETCH_PENDING_PROVIDER_ORDERS, fetchPendingProviderOrders)
}

function* watchFetchCategoriesHabitual() {
  yield takeLeading(FETCH_CATEGORIES_HABITUAL, fetchCategoriesHabitual)
}

function* watchFetchHabitualProducts() {
  yield takeLeading(FETCH_HABITUAL_PRODUCTS, fetchHabitualProducts)
}

function* watchFetchPreviusOrderProviders() {
  yield takeLatest(FETCH_PREVIUS_ORDER_PROVIDERS, fetchPreviusOrderProviders)
}

function* watchSetSelectedCenter() {
  yield takeLatest(SET_SELECTED_CENTER, handleSetSelectedCenter)
}

function* watchEditCart() {
  yield takeLatest(EDIT_CART, handleEditCart)
}

function* watchRemoveRequestFailed() {
  yield takeLatest(REMOVE_REQUEST_FAILED, removeRequestFailed)
}

function* watchAddRequestFailedToCart() {
  yield takeLatest(ADD_REQUEST_FAILED_TO_CART, addRequestFailedToCart)
}

function* watchSetProduct() {
  yield takeLatest(SET_PRODUCT, handleSetProduct)
}

function* watchAddProductToCart() {
  let currentTask = {}

  function* nextTask() {
    currentTask = {}
    const addProductsQueue = yield select(selectAddProductsQueue)
    const queueActions = Object.values(addProductsQueue)

    if (queueActions.length) {
      const next = queueActions[0]
      yield put({ ...next, type: NEXT_ADD_PRODUCT_TO_CART })
      yield put(setAddingProductQueue(next.id, null))
    } else {
      yield put(setIsEditingCart(false))
    }
  }

  while (true) {
    const action = yield take([ADD_PRODUCT_TO_CART, NEXT_ADD_PRODUCT_TO_CART])

    if (currentTask.id === action.id) {
      yield cancel(currentTask.task)
      currentTask.task = yield fork(addProductToCart, action, nextTask)
    } else if (!currentTask.id) {
      currentTask.id = action.id
      currentTask.task = yield fork(addProductToCart, action, nextTask)
    } else {
      yield put(setAddingProductQueue(action.id, action))
    }
  }
}

function* watchCheckConfirmedCart() {
  yield takeLatest(CHECK_CONFIRMED_CART, checkConfirmedCartSaga)
}

function* watchReloadingProducts() {
  yield takeLatest(FETCH_IS_RELOADING, fetchReloadingProducts)
}

function* watchFailedReceptions() {
  yield takeLeading(FETCH_FAILED_RECEPTIONS, fetchFailedReceptions)
}

export default function* rootPurchaseSaga() {
  yield all([
    debouceAutocompleteCategoriesAndProviders(),
    watchFetchProducts(),
    watchFetchCategories(),
    watchFetchProviders(),
    watchFetchProviderCategories(),
    watchFetchProduct(),
    watchFetchCart(),
    watchPreConrimCart(),
    watchCartCleanUp(),
    watchFetchBudget(),
    watchDeleteProviderProducts(),
    watchUpdateCart(),
    watchOriginCenters(),
    debouceAutocompleteProviders(),
    watchOrders(),
    watchProviderOrders(),
    debouceHistoricProviders(),
    debouceHistoricCenters(),
    watchRepeatOrder(),
    watchFetchProviderOrdersPending(),
    watchFetchCategoriesHabitual(),
    watchFetchHabitualProducts(),
    watchFetchPreviusOrderProviders(),
    watchSetSelectedCenter(),
    watchEditCart(),
    watchRemoveRequestFailed(),
    watchAddRequestFailedToCart(),
    watchSetProduct(),
    watchAddProductToCart(),
    watchCheckConfirmedCart(),
    watchReloadingProducts(),
    watchFailedReceptions(),
  ])
}
