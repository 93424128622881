import React from 'react'

import { Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import routes from 'Config/routes'
import { isUserRoleApprover } from 'Components/utils/roles'
import { setToken } from 'Redux/auth/actions'

const DashboardRoute = ({ children, ...rest }) => {
  const dispatch = useDispatch()

  const center = useSelector(({ auth }) => auth.selectedCenter)
  const user = useSelector(({ auth }) => auth.user)
  const token = useSelector(({ auth }) => auth.token)

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (_.isEmpty(user)) {
          if (token) {
            dispatch(setToken(null))
          }

          return (
            <Redirect
              to={{
                pathname: routes.root,
                state: { from: location },
              }}
            />
          )
        }

        if (isUserRoleApprover(user) && _.isEmpty(center)) {
          return (
            <Redirect
              to={{
                pathname: routes.approverCarts,
                state: { from: location },
              }}
            />
          )
        }

        if (_.isEmpty(center)) {
          return children
        }

        return (
          <Redirect
            to={{
              pathname: routes.purchase,
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}

DashboardRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default DashboardRoute
