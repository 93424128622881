import styled from 'styled-components'
import colors from 'Assets/colors'

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 24px;
`

export const Title = styled.div`
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.black};
`

export const TitleLink = styled.a`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  color: ${colors.secondary};
  height: 40px;
  background-color: ${colors.white};
  padding: 0 12px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`
export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 24px;
`

export const CardLoadingContainer = styled.div`
  margin: 0;
`
