import colors from 'Assets/colors'
import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #e5e5e5;
  flex-direction: column;
  padding: 40px 0 40px 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  padding: 40px 127px 60px 127px;
`

export const Title = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${colors.black};
  margin-bottom: 16px;
`

export const Message = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 50px;
  color: ${colors.black};
  margin-bottom: 63px;
  white-space: pre-line;
  text-align: center;
`

export const Separator = styled.p`
  display: flex;
  flex: 1;
  width: 20px;
`
