import React from 'react'

import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

import { SingleRadioContainer, SingleRadioTitle, StyledRadioGroup } from './styled'

const RadioButtons = ({ options, title, labelPlacement, defaultValue, onChange, value, name, errors }) => (
  <SingleRadioContainer>
    <SingleRadioTitle errors={errors}>{title}</SingleRadioTitle>
    <StyledRadioGroup row defaultValue={defaultValue} onChange={onChange} name={title} value={value}>
      {options.map((object, index) => (
        <FormControlLabel
          key={index}
          value={object}
          control={<Radio color='secondary' style={{ padding: '6px 9px' }} />}
          label={object}
          labelPlacement={labelPlacement}
          name={name}
        />
      ))}
    </StyledRadioGroup>
  </SingleRadioContainer>
)

RadioButtons.defaultProps = {
  options: [],
  title: '',
  labelPlacement: '',
  defaultValue: '',
  onChange: null,
  value: '',
  name: '',
  errors: null,
}

RadioButtons.propTypes = {
  options: PropTypes.array,
  title: PropTypes.string,
  labelPlacement: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.string,
}

export default RadioButtons
