import styled from 'styled-components'
import colors from 'Assets/colors'

export const MainContainer = styled.div`
  display: flex;
  flex: 1;
`

export const ButtonCart = styled.div`
  width: 100%;
  background-color: ${props => (props.hasAmount ? (props.focused ? '#f4f1f1' : colors.white) : colors.secondary)};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-transform: none;
  color: ${props => (props.hasAmount ? colors.black : colors.white)};
  transition: all 0.2s;
  display: flex;
  justify-content: ${props => (props.hasAmount ? 'flex-end' : 'center')};
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: ${props => (props.hasAmount ? null : 'pointer')};
  border: ${props => (props.hasAmount ? `2px solid ${colors.cart}` : null)};
  border: ${props => (props.error ? `2px solid ${colors.red}` : null)};
  height: 56px;
  margin: ${props => props.margin};

  &:hover {
    opacity: ${props => (props.hasAmount ? 1 : 0.8)};
    transform: ${props => (props.hasAmount ? null : 'translateY(-2px)')};
    box-shadow: ${props => (props.hasAmount ? null : '0 3px 5px rgba(51, 51, 51, 0.2)')};
  }

  &:active {
    transform: ${props => (props.hasAmount ? null : 'translate(-1px)')};
    box-shadow: ${props => (props.hasAmount ? null : '0 1px 3px rgba(51, 51, 51, 0.4)')};
  }

  .flag {
    background: ${colors.cart};
    color: ${colors.black};
    padding: 0 8px;
    border-radius: 8px;
    margin-left: 8px;
    line-height: 24px;
  }

  &.disabled {
    background-color: ${colors.gray40l};
    pointer-events: none;
  }
`
export const ButtonCartSustitution = styled.div`
  width: 100%;
  background-color: ${props => (props.reverse === 'reverse' ? colors.secondary : colors.white)};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-transform: none;
  color: ${props => (props.reverse === 'reverse' ? colors.white : colors.black)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-radius: 0.5rem;
  border: ${props => (props.reverse === 'reverse' ? 'none' : `2px solid ${colors.gray40l}`)};
  height: 56px;
  margin: ${props => props.margin};
`

export const ReplaceButton = styled.div`
  background-color: ${props => (props.reverse === 'reverse' ? colors.white : colors.secondary)};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-transform: none;
  color: ${props => (props.reverse === 'reverse' ? colors.secondary : colors.white)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  height: 40px;
  margin: ${props => props.margin};
  &:hover {
    opacity: ${props => (props.hasAmount ? 1 : 0.8)};
    transform: ${props => (props.hasAmount ? null : 'translateY(-2px)')};
    box-shadow: ${props => (props.hasAmount ? null : '0 3px 5px rgba(51, 51, 51, 0.2)')};
  }

  &:active {
    transform: ${props => (props.hasAmount ? null : 'translate(-1px)')};
    box-shadow: ${props => (props.hasAmount ? null : '0 1px 3px rgba(51, 51, 51, 0.4)')};
  }
`

export const Selector = styled.button`
  background: ${colors.cart};
  border-radius: 0.5rem;
  cursor: pointer;
  margin-left: 0.5rem;
  padding: 1rem 1.125rem;

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const AcceptButton = styled.button`
  background: ${colors.cart};
  border-radius: 0.5rem;
  cursor: pointer;
  width: 45px;
  height: 45px;

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const Input = styled.input`
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 50px;
  text-align: left;
  width: 100%;
`
export const LabelValue = styled.span`
  width: 100%;
  text-align: left;
`

export const AmountContainer = styled.div`
  display: flex;
  margin-right: auto;
  flex: 1;
  cursor: pointer;
`

export const ButtonCartRemove = styled.div`
  width: 100%;
  background-color: ${props => (props.reverse === 'reverse' ? colors.white : colors.white)};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-transform: none;
  color: ${props => (props.reverse === 'reverse' ? colors.black : colors.black)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-radius: 0.5rem;
  border: ${props => (props.reverse === 'reverse' ? `2px solid ${colors.gray40l}` : `2px solid ${colors.gray40l}`)};
  height: 56px;
  margin: ${props => props.margin};
`

export const RemoveButton = styled.div`
  background-color: ${props => (props.reverse === 'reverse' ? colors.red : colors.red)};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-transform: none;
  color: ${props => (props.reverse === 'reverse' ? colors.white : colors.white)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  height: 40px;
  margin: ${props => props.margin};
  &:hover {
    opacity: ${props => (props.hasAmount ? 1 : 0.8)};
    transform: ${props => (props.hasAmount ? null : 'translateY(-2px)')};
    box-shadow: ${props => (props.hasAmount ? null : '0 3px 5px rgba(51, 51, 51, 0.2)')};
  }

  &:active {
    transform: ${props => (props.hasAmount ? null : 'translate(-1px)')};
    box-shadow: ${props => (props.hasAmount ? null : '0 1px 3px rgba(51, 51, 51, 0.4)')};
  }
`
export const ActionEraseButton = styled.div`
  background: ${colors.red};
  border-radius: 0.5rem;
  cursor: pointer;
  width: 48px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`
export const ButtonNotAvailable = styled.div`
  width: 100%;
  border: 2px solid ${props => props.theme.colors.gray40l};
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 8px;
`

export const Unavailable = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  padding: 0.5rem;
`

export const EraseButton = styled.div`
  padding: 0.5rem;
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  border-radius: 8px;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .trash {
    padding-right: 0.5rem;
  }

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`
