import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import colors from 'Assets/colors'

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    transition: 'all 0.4s ease-in',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#FFFFFF',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    textTransform: 'none',
  },
  username: {
    display: 'inline-block',
    marginLeft: '5px',
    marginRight: '5px',
    fontSize: '0.875rem',
  },
  role: {
    display: 'inline-block',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    marginLeft: '5px',
    marginRight: '5px',
    textTransform: 'uppercase',
    borderRadius: '10px',
    padding: '7px',
    fontSize: '0.875rem',
  },
  logout: {
    minWidth: 'auto',
    '& .MuiButton-startIcon': {
      margin: '0px',
    },
  },
}))

export const Text = styled.div`
  cursor: pointer;
`

export const Container = styled.div`
  max-height: ${props => props.maxHeight};
`

export const Topbar = styled.div`
  height: 48px;
  padding: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: ${colors.black};
  background: ${colors.grayInput};
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const RestartButton = styled.button`
  font-size: 1rem;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
`

export const ToolbarRightSide = styled.div`
  display: flex;
`
