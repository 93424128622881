import { ANALYTICS_EVENT } from './constants'
import {
  handleListCategoryType,
  valueFormatter,
  convertCategoriesToArray,
  mapListId,
  mapListName,
  sendAnalytics,
} from './utils'

const selectItem = ({ product, selectedCategories }) => {
  if (!selectedCategories || !product) return

  const categoriesToArray = convertCategoriesToArray(selectedCategories)
  const listName = mapListName(categoriesToArray)
  const listId = mapListId(categoriesToArray)

  sendAnalytics(ANALYTICS_EVENT.SELECT_ITEM, {
    item_list_name: listName,
    item_list_id: listId,
    list_category_type: handleListCategoryType(categoriesToArray),
    value: valueFormatter(product.price),
    currency: 'EUR',
    items: [
      {
        item_list_name: listName,
        item_list_id: listId,
        item_name: product.name,
        item_id: product.id,
        item_category: product?.category?.name,
        item_category2: product?.category?.children?.[0]?.name,
        item_category3: product?.category?.children?.[0]?.children?.[0]?.name,
        item_category4: product?.temperature,
        price: valueFormatter(product.price),
        currency: 'EUR',
        affiliation: `${product?.provider?.name} - ${product?.provider?.id}`,
      },
    ],
  })
}

export default selectItem
