import React from 'react'

import PropTypes from 'prop-types'
import colors from 'Assets/colors'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'

import { RequestActionContainer, RequestRepeatOrder, HelpText } from './styled'

const ActionButton = ({ onClick, buttonText, helpText, color, Icon, disabled, orderState }) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      title={t('failedRequests.requestFailedAdviceCartTooltip')}
      arrow
      disableHoverListener={!disabled}
      placement='top'
    >
      <RequestActionContainer>
        <RequestRepeatOrder color={color} disabled={orderState || disabled}>
          <Icon />
          <button type='button' onClick={onClick} disabled={orderState || disabled}>
            {buttonText}
          </button>
        </RequestRepeatOrder>
        <HelpText disabled={orderState || disabled}>{helpText}</HelpText>
      </RequestActionContainer>
    </Tooltip>
  )
}

ActionButton.propTypes = {
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  helpText: PropTypes.string,
  Icon: PropTypes.object.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  orderState: PropTypes.bool,
}

ActionButton.defaultProps = {
  onClick: () => null,
  buttonText: '',
  helpText: '',
  color: colors.black,
  disabled: false,
  orderState: false,
}

export default ActionButton
