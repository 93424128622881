import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import colors from 'Assets/colors'
import { Text } from 'Components/atoms/regularText/styled'

export const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    position: 'relative',
  },
  bar: {
    background: '#FFFFFF',
  },
  menuButton: {
    textTransform: 'none',
    marginLeft: 'auto',
    backgroundColor: colors.cart,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    padding: '1rem',
  },
  title: {
    flexGrow: 1,
    display: 'flex',
  },
}))

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

export const SectionSelector = styled.div`
  padding: 0.8rem 0;
  cursor: pointer;
  text-align: center;
  color: ${props => (props.selected ? props.theme.colors.secondary : null)};
`

export const StyledText = styled(Text)`
  transition: all 0.2s;
  padding: 0;
`

export const Type = styled.div`
  padding: 0 0.5rem;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background-color: ${props => props.theme.colors.secondary};
  margin: 0 0.75rem 0 1rem;
  color: #fff;
`

export const Container = styled.div`
  ::after {
    content: '';
    width: calc(100% - 2.5rem);
    position: absolute;
    top: 0;
    left: 1.25rem;
  }
`

export const TotalItems = styled.div`
  background: #ffffff;
  padding: 0px 8px;
  margin-left: 1rem;
`

export const TitleSection = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  margin-right: 4px;
  padding-top: 15px;
  padding-bottom: 10px;
`
export const TitleSectionNormal = styled.div`
  font-family: Inter;
  font-style: normal;
  font-size: 32px;
  line-height: 24px;
  margin-right: 4px;
  padding-top: 15px;
  padding-bottom: 10px;
`

export const SelectText = styled.div`
  margin-top: 16px;
  font-family: Inter;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  margin-left: 1rem;
  color: ${props => props.theme.colors.gray40l};
`
export const HorizontalTitleContainer = styled.div`
  display: flex;
  margin-left: 1rem;
  align-items: 'center';
`
