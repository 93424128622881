import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { ReactComponent as FrozenIcon } from 'Assets/Icons/Frozen.svg'
import { ReactComponent as RefrigeratedIcon } from 'Assets/Icons/Refrigerated.svg'
import Arrow from 'Assets/Icons/Arrow'
import Trash from 'Assets/Icons/Trash'
import Star from 'Assets/Icons/Star'
import StarOutline from 'Assets/Icons/StarOutline'
import ArrowRight from 'Assets/Icons/ArrowThinRight'
import { TEMPERATURES, MEASURE_UNIT } from 'Config/constants'
import Icons from 'Components/utils/categoryIcons'
import colors from 'Assets/colors'
import { PurchaseActions } from 'Redux/purchases'
import { getProduct } from 'Services/api'
import { handleHabitualCategories, selectCenterId } from 'Redux/purchases/utils'
import ProductButton from 'Components/atoms/productButton'
import { NumberFormat } from 'Components/atoms'
import { financial } from 'Components/utils/cartCalculator'
import { needsApprovalMode } from 'Redux/approver/selectors'
import { ApproverActions } from 'Redux/approver'
import analyticsService from 'Services/analytics'
import { selectConfig } from 'Redux/auth/utils'

import ModalAddToList from '../modalAddToList'
import { AVAILABILITY_STATES } from '../../../Config/constants'

import {
  AddListBtnContainer,
  CardContainer,
  CategoryAndIconContainer,
  IconsContainer,
  Name,
  Provider,
  Price,
  PreviousPrice,
  MinAndMulti,
  PriceKG,
  ButtonNotAvailable,
  NameAndProviderContainer,
  SimpleName,
  SimpleCardContainer,
  EraseButton,
  Unavailable,
  MoreInfoContainer,
  MoreInfo,
  TempAndHabitualContainer,
  TemperatureContainer,
  HabitualContainer,
  PricesContainer,
  AddListButton,
} from './styled'

const Card = ({
  categoryName,
  categoryId,
  temperature,
  name,
  provider,
  price,
  min,
  multiple,
  measureUnit,
  refProp,
  pricePerKg,
  id,
  isSimpleCard,
  raw_material: rawMaterial,
  notAvailable,
  reference,
  setShowModalConfirmationProduct,
  setOnConfirmProduct,
  showJustButtonCard,
  previousPrice,
  availability,
  hasMoreInfo,
  isHabitual,
  onHabitual,
  onUnHabitual,
  refButton,
  onNext,
  onPrevious,
}) => {
  const { amount = 0 } = useSelector(state => state.purchase.cart[id]) || {}
  const centerId = useSelector(selectCenterId)
  const needsApproval = useSelector(needsApprovalMode)
  const categories = useSelector(state => state.purchase.selectedCategories)
  const selectedHabitualCategories = useSelector(state => state.purchase.selectedCategoriesHabitual)
  const [openAddListModal, setOpenAddListModal] = useState(false)
  const features = useSelector(selectConfig)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const Icon = Icons[categoryId] || (() => <></>)

  const removeProductFromCart = () => {
    const editCart = needsApproval ? ApproverActions.editCart : PurchaseActions.addProductToCart

    dispatch(
      editCart(id, {
        amount: 0,
        total: 0,
        name,
        provider,
        price,
        pricePerKg,
        measureUnit,
        min,
        multiple,
        id,
        raw_material: rawMaterial,
        state: availability ? availability.status : AVAILABILITY_STATES.AVAILABLE,
        reference,
      })
    )
  }

  const onConfirmProductErase = () => {
    removeProductFromCart()
    setShowModalConfirmationProduct(false)
  }

  const setToZero = () => {
    if (isSimpleCard && !notAvailable) {
      setShowModalConfirmationProduct(true)
      setOnConfirmProduct(() => onConfirmProductErase)
      return
    }

    removeProductFromCart()
  }

  const onClickCard = async () => {
    try {
      const response = await getProduct(centerId, id)
      dispatch(
        PurchaseActions.setProduct({
          ...response,
          categoryName,
          categoryId,
          temperature,
          name,
          provider,
          price,
          min,
          multiple,
          measureUnit,
          refProp,
          pricePerKg,
          id,
          isSimpleCard,
          raw_material: rawMaterial,
          state: availability ? availability.status : AVAILABILITY_STATES.AVAILABLE,
          reference,
          availability: response ? response.availability : availability,
          previousPrice,
        })
      )
      dispatch(PurchaseActions.setModalProductOpen(true))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const handleClickHabitual = (e, itemId, itemIsHabitual) => {
    e.stopPropagation()
    if (itemIsHabitual) {
      onHabitual(itemId)
    } else {
      onUnHabitual(itemId)
    }
  }

  const sendWishlistAnalytics = () => {
    const product = { name, id, price, provider, temperature, amount: min, category: { name: categoryName } }
    const habitualCategories = handleHabitualCategories(selectedHabitualCategories)

    analyticsService.addToWishlist({
      products: [product],
      selectedCategories: habitualCategories || categories,
    })
  }

  const handleClickAddList = e => {
    e.stopPropagation()
    setOpenAddListModal(prevState => !prevState)
    sendWishlistAnalytics()
  }

  if (!amount && isSimpleCard) return null

  const differencePrice = previousPrice ? price - previousPrice : null

  const showButton = () => (
    <ProductButton
      name={name}
      provider={provider}
      price={price}
      min={min}
      multiple={multiple}
      measureUnit={measureUnit}
      pricePerKg={pricePerKg}
      id={id}
      isSimpleCard={isSimpleCard}
      rawMaterial={rawMaterial}
      notAvailable={notAvailable}
      reference={reference}
      setShowModalConfirmationProduct={setShowModalConfirmationProduct}
      setOnConfirmProduct={setOnConfirmProduct}
      showJustButtonCard={showJustButtonCard}
      availability={availability}
      innerRef={refButton}
      onPrevious={onPrevious}
      onNext={onNext}
    />
  )

  const percentage = (num, per) => (num / 100) * per

  if (isSimpleCard) {
    return (
      <SimpleCardContainer onClick={onClickCard}>
        <IconsContainer>
          <SimpleName title={name}>{name}</SimpleName>
        </IconsContainer>
        {notAvailable ? (
          <ButtonNotAvailable>
            <Unavailable>
              {availability && availability.status === AVAILABILITY_STATES.NOT_STOCK && t('noItems')}
              {availability && availability.status === AVAILABILITY_STATES.UNAVAILABLE && t('deleted')}
            </Unavailable>
            <EraseButton onClick={setToZero}>
              <Trash className='trash' width={22} fill='#fff' /> {t('delete')}
            </EraseButton>
          </ButtonNotAvailable>
        ) : (
          showButton()
        )}
      </SimpleCardContainer>
    )
  }

  if (showJustButtonCard) {
    // TODO: No tiene return, se puede quitar?
    showButton()
  }

  return (
    <>
      <CardContainer ref={refProp} onClick={onClickCard}>
        <IconsContainer>
          <CategoryAndIconContainer>
            <Icon className='icon-product-card' />
            <div className='category-product-card'> {categoryName}</div>
          </CategoryAndIconContainer>
          <TempAndHabitualContainer>
            <TemperatureContainer>
              <AdequateIcon temperature={temperature} />
              <div className='category-product-card'>{temperature || ''}</div>
            </TemperatureContainer>
            {features?.habituals && (
              <HabitualContainer onClick={e => handleClickHabitual(e, id, isHabitual)}>
                {isHabitual ? <Star /> : <StarOutline />}
              </HabitualContainer>
            )}
          </TempAndHabitualContainer>
        </IconsContainer>
        <NameAndProviderContainer>
          <Name title={name}>{name}</Name>
          <Provider>{provider.name}</Provider>
        </NameAndProviderContainer>
        {!differencePrice || Math.abs(differencePrice) < percentage(previousPrice, 0.01) ? (
          <PriceInfo measureUnit={measureUnit} price={price} pricePerKg={pricePerKg} />
        ) : (
          <PriceDifferenceInfo
            measureUnit={measureUnit}
            price={price}
            pricePerKg={pricePerKg}
            previousPrice={previousPrice}
          />
        )}
        <MoreInfoContainer>
          <MinAndMulti>{`${t('min')}: ${financial(min)} / ${t('multi')}: ${financial(multiple)}`}</MinAndMulti>
          {hasMoreInfo && (
            <MoreInfo>
              {t('moreInfoPrice')}
              <ArrowRight fill={colors.secondary} />
            </MoreInfo>
          )}
        </MoreInfoContainer>
        {showButton()}
        {!needsApproval && features?.shopping_lists && (
          <AddListBtnContainer>
            <AddListButton style={{ textDecoration: 'underline' }} onClick={handleClickAddList}>
              {t('shoppingList.addToList.btnTitle')}
            </AddListButton>
          </AddListBtnContainer>
        )}
      </CardContainer>
      {openAddListModal && (
        <ModalAddToList
          productId={id}
          showModal={openAddListModal}
          setShowModal={() => setOpenAddListModal(prevState => !prevState)}
        />
      )}
    </>
  )
}

Card.defaultProps = {
  categoryName: '',
  categoryId: '',
  temperature: '',
  name: '',
  provider: {},
  price: 0,
  min: 0,
  multiple: 0,
  measureUnit: '',
  refProp: () => null,
  pricePerKg: 0,
  id: '',
  isSimpleCard: false,
  raw_material: 'food',
  notAvailable: false,
  reference: '',
  setOnConfirmProduct: () => null,
  setShowModalConfirmationProduct: () => null,
  showJustButtonCard: false,
  previousPrice: null,
  availability: null,
  hasMoreInfo: false,
  isHabitual: false,
  onHabitual: () => null,
  onUnHabitual: () => null,
  refButton: null,
  onNext: () => null,
  onPrevious: () => null,
}

Card.propTypes = {
  categoryName: PropTypes.string,
  categoryId: PropTypes.string,
  temperature: PropTypes.string,
  name: PropTypes.string,
  provider: PropTypes.object,
  price: PropTypes.number,
  min: PropTypes.number,
  multiple: PropTypes.number,
  measureUnit: PropTypes.string,
  refProp: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  pricePerKg: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSimpleCard: PropTypes.bool,
  raw_material: PropTypes.string,
  notAvailable: PropTypes.bool,
  reference: PropTypes.string,
  setOnConfirmProduct: PropTypes.func,
  setShowModalConfirmationProduct: PropTypes.func,
  showJustButtonCard: PropTypes.bool,
  previousPrice: PropTypes.number,
  availability: PropTypes.object,
  hasMoreInfo: PropTypes.bool,
  isHabitual: PropTypes.bool,
  onHabitual: PropTypes.func,
  onUnHabitual: PropTypes.func,
  refButton: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
}

const AdequateIcon = ({ temperature }) => {
  if (temperature === TEMPERATURES.refrigerated) {
    return <RefrigeratedIcon className='icon-product-card' style={{ marginLeft: '8px' }} />
  }

  if (temperature === TEMPERATURES.frozen) {
    return <FrozenIcon className='icon-product-card' style={{ marginLeft: '8px' }} />
  }

  return ''
}

AdequateIcon.defaultProps = {
  temperature: '',
}

AdequateIcon.propTypes = {
  temperature: PropTypes.string,
}

const PriceDifferenceInfo = ({ measureUnit, price, pricePerKg, previousPrice }) => {
  const needsApproval = useSelector(needsApprovalMode)
  const center = useSelector(state => state.auth.selectedCenter)
  const { showPrice } = center

  const differencePrice = previousPrice ? price - previousPrice : null

  return (
    <PricesContainer>
      {(showPrice || needsApproval) && (
        <>
          <Price style={{ color: differencePrice > 0 ? colors.red : colors.secondary }}>
            <NumberFormat value={price} suffix={`€/${measureUnit}`} />
          </Price>
          <Arrow
            style={{
              marginRight: '0.5rem',
              transform: differencePrice > 0 ? 'rotate(270deg)' : 'rotate(90deg)',
              position: 'relative',
              bottom: '-3px',
            }}
            fill={differencePrice > 0 ? colors.red : colors.secondary}
          />
          <PreviousPrice>
            <NumberFormat value={previousPrice} suffix={`€/${measureUnit}`} />
          </PreviousPrice>
          {measureUnit !== MEASURE_UNIT.KG ? (
            <PriceKG>
              <NumberFormat value={pricePerKg} suffix='€/KG' />
            </PriceKG>
          ) : null}
        </>
      )}
    </PricesContainer>
  )
}

PriceDifferenceInfo.defaultProps = {
  price: 0,
  measureUnit: '',
  pricePerKg: 0,
  previousPrice: null,
}

PriceDifferenceInfo.propTypes = {
  price: PropTypes.number,
  measureUnit: PropTypes.string,
  pricePerKg: PropTypes.number,
  previousPrice: PropTypes.number,
}

const PriceInfo = ({ measureUnit, price, pricePerKg }) => {
  const needsApproval = useSelector(needsApprovalMode)
  const center = useSelector(state => state.auth.selectedCenter)
  const { showPrice } = center

  return (
    <PricesContainer>
      {(showPrice || needsApproval) && (
        <>
          <Price>
            <NumberFormat value={price} suffix={`€/${measureUnit}`} />
          </Price>
          {measureUnit !== MEASURE_UNIT.KG ? (
            <PriceKG>
              <NumberFormat value={pricePerKg} suffix='€/KG' />
            </PriceKG>
          ) : null}
        </>
      )}
    </PricesContainer>
  )
}

PriceInfo.defaultProps = {
  price: 0,
  measureUnit: '',
  pricePerKg: 0,
}

PriceInfo.propTypes = {
  price: PropTypes.number,
  measureUnit: PropTypes.string,
  pricePerKg: PropTypes.number,
}

export default Card
