import styled from 'styled-components'
import colors from 'Assets/colors'

export const Tabs = styled.div`
  padding-top: ${props => props.paddingTop || '10px'};
  box-shadow: 0px 2px 2px rgb(0 0 0 / 24%), 0px 0px 2px rgb(0 0 0 / 12%);
`

export const TabItem = styled.div`
  padding: 0 30px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.gray2};
  cursor: pointer;
  border-bottom: 4px solid transparent;

  @media (max-width: 1400px) {
    font-size: 22px;
    padding: 0 22px;
  }

  &.active {
    color: ${colors.black};
    border-color: ${colors.secondary};
  }
`
