import React, { useRef, useState, useEffect, useCallback } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import range from 'lodash/range'
import isNil from 'lodash/isNil'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import { AnnouncementCard, DrawerAnnouncement } from 'Components/molecules'
import { AnnouncementActions } from 'Redux/announcement'
import { WORDPRESS_ANNOUNCEMENTS } from 'Config/api'

import { TitleContainer, Title, TitleLink, CardsContainer, CardLoadingContainer } from './styled'

const LIMIT = 15

const Announcements = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [pageNumber, setPageNumber] = useState(0)
  const offset = pageNumber * LIMIT
  const [queryParams, setQueryParams] = useState({ offset, limit: LIMIT })
  const [hasMore, setHasMore] = useState('')
  const [announcements, setAnnouncements] = useState([])
  const [announcementSelected, setAnnouncementSelected] = useState(null)

  const announcementsList = useSelector(state => state.announcement.announcementsList)
  const isLoading = useSelector(state => state.announcement.isLoading)

  const observer = useRef()
  const lastProductElementRef = useCallback(
    node => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(prevPageNumber => prevPageNumber + 1)
          setQueryParams({ ...queryParams, offset: (pageNumber + 1) * LIMIT })
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, hasMore, queryParams, pageNumber]
  )

  useEffect(() => {
    if (announcementsList) {
      setAnnouncements(prev => [...prev, ...announcementsList])
      setHasMore(announcementsList.length > 0 && announcementsList.length >= LIMIT)
    }
  }, [announcementsList])

  useEffect(() => {
    setAnnouncements([])
    setPageNumber(0)
  }, [])

  useEffect(() => {
    dispatch(AnnouncementActions.fetchAnnouncements(queryParams))
    return () => dispatch(AnnouncementActions.setAnnouncements([]))
  }, [dispatch, queryParams])

  const handleClickAnnouncement = announcement => {
    setAnnouncementSelected(announcement)
  }

  return (
    <>
      <TitleContainer>
        <Title>{t('announcementsTitle')}</Title>
        <TitleLink href={WORDPRESS_ANNOUNCEMENTS}>{t('announcementsSeeAll')}</TitleLink>
      </TitleContainer>
      <CardsContainer>
        {announcements.length > 0 && (
          <AnnouncementList
            items={announcements}
            lastElementRef={lastProductElementRef}
            onClickItem={handleClickAnnouncement}
          />
        )}
        {announcements.length === 0 && !isLoading && <ResultMessage isLoading={isLoading} />}
        {isLoading && <MultipleCardLoader />}
      </CardsContainer>
      <DrawerAnnouncement
        isOpen={!isNil(announcementSelected)}
        announcement={announcementSelected}
        onClose={() => setAnnouncementSelected(null)}
      />
    </>
  )
}

Announcements.defaultProps = {}

Announcements.propTypes = {}

const ResultMessage = ({ isLoading }) => {
  const { t } = useTranslation()

  if (isLoading) return ''
  return <Title>{t('announcementsNoData')}</Title>
}

ResultMessage.defaultProps = {
  isLoading: false,
}

ResultMessage.propTypes = {
  isLoading: PropTypes.bool,
}

const AnnouncementList = ({ items, lastElementRef, onClickItem }) => (
  <>
    {items.map((announcement, index) => (
      <AnnouncementCard
        key={announcement.id}
        refProp={items.length === index + 1 ? lastElementRef : null}
        announcement={announcement}
        onClick={() => onClickItem(announcement)}
      />
    ))}
  </>
)

AnnouncementList.defaultProps = {
  items: [],
  lastElementRef: () => {},
  onClickItem: () => {},
}

AnnouncementList.propTypes = {
  items: PropTypes.array,
  lastElementRef: PropTypes.func,
  onClickItem: PropTypes.func,
}

const MultipleCardLoader = () => (
  <>
    {range(2).map(i => (
      <CardLoadingContainer key={i}>
        <AnnouncementsCardLoader />
      </CardLoadingContainer>
    ))}
  </>
)

const AnnouncementsCardLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width={680}
      height={290}
      viewBox='0 0 680 290'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='16' y='57' rx='3' ry='3' width='188' height='15' />
      <rect x='16' y='95' rx='0' ry='0' width='210' height='10' />
      <rect x='16' y='120' rx='0' ry='0' width='160' height='10' />
      <rect x='16' y='225' rx='0' ry='0' width='150' height='10' />
      <rect x='310' y='50' rx='0' ry='0' width='300' height='144' />
    </ContentLoader>
  )
}

export default Announcements
