import { all } from 'redux-saga/effects'

import { ListsReducer, listsSaga } from './lists'
import { AuthReducer, authSaga } from './auth'
import { PurchaseReducer, purchaseSaga } from './purchases'
import { DeliveryReducer, deliverySaga } from './delivery'
import { TicketReducer, ticketSaga } from './ticket'
import { AnnouncementReducer, announcementSaga } from './announcement'
import { ApproverReducer, approverSaga } from './approver'
import { CartsReducer, cartsSaga } from './carts'
import { TutorialReducer, tutorialSaga } from './tutorial'
import { MulticenterReducer, multicenterSaga } from './multicenter'

const reducers = {
  lists: ListsReducer,
  auth: AuthReducer,
  purchase: PurchaseReducer,
  delivery: DeliveryReducer,
  ticket: TicketReducer,
  announcement: AnnouncementReducer,
  approver: ApproverReducer,
  carts: CartsReducer,
  tutorial: TutorialReducer,
  multicenter: MulticenterReducer,
}

function* rootSaga() {
  yield all([
    listsSaga(),
    authSaga(),
    purchaseSaga(),
    deliverySaga(),
    ticketSaga(),
    announcementSaga(),
    approverSaga(),
    cartsSaga(),
    tutorialSaga(),
    multicenterSaga(),
  ])
}

export { reducers, rootSaga }
