/* eslint-disable no-restricted-properties */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */

import _ from 'lodash'
import moment from 'moment'
import { DELIVERY_DATE_MIN_DAYS, DELIVERY_TYPE } from 'Config/constants'
import routes from 'Config/routes'

export const assembleCart = (products, deliveries) =>
  _.chain(products)
    .map(({ product, amount, state }) => {
      const delivery = _.find(deliveries, ({ provider }) => provider.id === product.provider.id)

      const orderProduct = {
        amount,
        state,
        delivery,
        ...product,
        measureUnit: product.measure_unit,
        pricePerKg: product.price_per_kg,
        total: amount * product.price,
      }

      delete orderProduct.price_per_kg
      delete orderProduct.measure_unit
      delete orderProduct.category

      return orderProduct
    })
    .keyBy('id')
    .value()

export const selectCenterId = state => state.auth.selectedCenter.id

export const selectOrders = state => state.purchase.orders

export const selectedCategories = state => state.purchase.selectedCategories

export const assemblePayload = cart => _.map(cart, ({ id, amount }) => ({ productId: id, amount }))

export const selectCartDeliveries = state => state.purchase.deliveries

export const isExpiredDeliveryDate = ({ deliveryDate, deliveryType }) => {
  if (deliveryType === DELIVERY_TYPE.NORMAL) {
    const deliveryMoment = moment(deliveryDate)
    const deliveryMomentStart = deliveryMoment.clone().startOf('day')
    const actualDate = moment()
    const actualDateStart = actualDate.clone().startOf('day').add(DELIVERY_DATE_MIN_DAYS, 'days')

    const isExpired = deliveryMomentStart <= actualDateStart

    return deliveryDate ? isExpired : false
  }
  return false
}

export const isDeliveriesCompleted = (providerProducts, deliveries) => {
  let completed = true
  _.forEach(providerProducts, products => {
    const providerId = _.get(products, '0.provider.id', '')
    const hasDeliveryDate = _.some(deliveries, delivery => delivery.provider.id === providerId)
    if (!hasDeliveryDate) {
      completed = false
    }

    const hasDeliveryExpiredDate = _.some(deliveries, delivery => {
      const { date, type } = delivery
      const auxDate = moment(date, 'YYYY-MM-DD').toDate()
      return isExpiredDeliveryDate({ deliveryDate: auxDate, deliveryType: type })
    })

    if (hasDeliveryExpiredDate) {
      completed = false
    }
  })

  return completed
}

export const hasAvailableProductsDeliveries = (providerProducts, deliveries) => {
  let completed = true

  _.forEach(providerProducts, products => {
    const providerId = _.get(products, '0.provider.id', '')
    const notAvailable = products?.filter(element => element.state === 'no_available')?.length || 0
     if (notAvailable < products?.length) {
      const hasDeliveryDate = _.some(deliveries, delivery => delivery.provider.id === providerId)
      if (!hasDeliveryDate) {
        completed = false
      }

      const hasDeliveryExpiredDate = _.some(deliveries, delivery => {
        const { date, type } = delivery
        const auxDate = moment(date, 'YYYY-MM-DD').toDate()
        return isExpiredDeliveryDate({ deliveryDate: auxDate, deliveryType: type })
      })

      if (hasDeliveryExpiredDate) {
        completed = false
      }
    }
  })

  return completed
}

export const precision = a => {
  if (!isFinite(a)) {
    return 0
  }
  let e = 1
  let p = 0
  while (Math.round(a * e) / e !== a) {
    e *= 10
    p++
  }
  return p
}

export const floatModulus = (a, b) => {
  const maxPrecision = Math.max(precision(a), precision(b))
  return (a * Math.pow(10, maxPrecision)) % (b * Math.pow(10, maxPrecision))
}

export const selectCart = s => s.purchase.cart

export const handleHabitualCategories = selectedHabitualCategories => {
  const isHabitual = window.location.pathname.includes(routes.habitualCats)

  if (!isHabitual) return false

  return selectedHabitualCategories?.category ? selectedHabitualCategories : { category: selectedHabitualCategories }
}

export const getDiffProductsFromCart = (products, oldCard) => {
  const mappedProduct = products?.map(product => ({ amount: product.amount, ...product.product })) || []
  const mappedOldCart = Object.values(oldCard || {})
  const result = mappedProduct.filter(product => !mappedOldCart.find(item => item.id === product.id))
  return mappedProduct.length ? result : mappedProduct
}

export const selectHabitualCagetories = state => state.purchase.categoriesHabitual

export const selectCategories = state => state.purchase.categories
