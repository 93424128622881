import React from 'react'

import { useTranslation } from 'react-i18next'
import { GlobalStyle } from 'Config/styled'
import { ReactComponent as MaintenanceIcon } from 'Assets/Icons/Maintenance.svg'
import { ReactComponent as PortalLogo } from 'Assets/Logos/PortalLogo.svg'
import { ReactComponent as CompassGroup } from 'Assets/Logos/CompassGroup.svg'

import { Container, Content, Title, Message, Separator } from './styled'

const Maintenance = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <GlobalStyle />
      <PortalLogo />
      <Separator />
      <Content>
        <Title>{t('maintenance.title')}</Title>
        <Message>{t('maintenance.message')}</Message>
        <MaintenanceIcon />
      </Content>
      <Separator />
      <CompassGroup />
    </Container>
  )
}

export default Maintenance
