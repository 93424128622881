import styled, { css } from 'styled-components'

export const SectionContainer = styled.div`
  width: 21.25rem;
  height: calc(100vh - 250px);
  overflow-y: hidden;
  position: relative;
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  opacity: ${props => props.opacity};
  overflow-y: scroll;

  ${props =>
    props.$tutorial &&
    css`
      height: calc(100vh - 216px);
    `}

  ${props =>
    props.isHidden &&
    css`
      height: calc(100vh - 6.5rem);
    `}

  ${props =>
    props.needsApproval &&
    css`
      height: calc(100vh - 300px);
    `}
`

export const ProviderName = styled.div`
  padding: 1.5rem 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: ${props => props.theme.colors.black};
`
