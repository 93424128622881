import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Tooltip from '@material-ui/core/Tooltip'
import { RECEPTION_STATUS } from 'Config/constants'
import { ReactComponent as StatusDF } from 'Assets/Icons/StatusDF.svg'
import { ReactComponent as StatusEX } from 'Assets/Icons/StatusEX.svg'
import { ReactComponent as StatusFT } from 'Assets/Icons/StatusFT.svg'
import { ReactComponent as StatusOK } from 'Assets/Icons/StatusOK.svg'
import { ReactComponent as StatusST } from 'Assets/Icons/StatusST.svg'
import colors from 'Assets/colors'

import { StatusContainer, StatusShort } from './styled'

const ReceptionStatus = ({ newStatus }) => {
  const { t } = useTranslation()

  if (!newStatus) return <StatusContainer />

  const statusProps = {
    [RECEPTION_STATUS.ST]: {
      icon: <StatusST style={{ fill: colors.black }} />,
      copy: t('receptionStatusTextShort.ST'),
      title: t('receptionStatusText.ST'),
      background: colors.cart,
      color: colors.black,
    },
    [RECEPTION_STATUS.DF]: {
      icon: <StatusDF style={{ fill: colors.black }} />,
      copy: t('receptionStatusTextShort.DF'),
      title: t('receptionStatusText.DF'),
      background: colors.cart,
      color: colors.black,
    },
    [RECEPTION_STATUS.OK]: {
      icon: <StatusOK style={{ fill: colors.white }} />,
      copy: t('receptionStatusTextShort.OK'),
      title: t('receptionStatusText.OK'),
      background: colors.secondary,
      color: colors.white,
    },
    [RECEPTION_STATUS.EX]: {
      icon: <StatusEX style={{ fill: colors.black }} />,
      copy: t('receptionStatusTextShort.EX'),
      title: t('receptionStatusText.EX'),
      background: colors.cart,
      color: colors.black,
    },
    [RECEPTION_STATUS.FT]: {
      icon: <StatusFT style={{ fill: colors.white }} />,
      copy: t('receptionStatusTextShort.FT'),
      title: t('receptionStatusText.FT'),
      background: colors.red,
      color: colors.white,
    },
  }

  if (!statusProps[newStatus]) {
    return <StatusContainer />
  }

  const { icon, copy, background, color, title } = statusProps[newStatus]
  return (
    <Tooltip title={title}>
      <StatusContainer statusProp={newStatus} style={{ background }}>
        {icon}
        <StatusShort style={{ color }}>{copy}</StatusShort>
      </StatusContainer>
    </Tooltip>
  )
}

ReceptionStatus.propTypes = {
  newStatus: PropTypes.string,
}

ReceptionStatus.defaultProps = {
  newStatus: '',
}
export default ReceptionStatus
