import routes from 'Config/routes'

import { ANALYTICS_CATEGORY_TYPE, ANALYTICS_TYPE_ADD_TO_CARD, ANALYTICS_CATEGORY_ALL } from './constants'

export const handleListCategoryType = categoryArray => {
  const subcategory = categoryArray?.[1] || null
  const productType = categoryArray?.[2] || null
  const isHistoricRoute = window.location.pathname.includes(routes.historic)

  if (isHistoricRoute) return undefined
  if (productType) return ANALYTICS_CATEGORY_TYPE.product
  if (subcategory) return ANALYTICS_CATEGORY_TYPE.subCategory

  return ANALYTICS_CATEGORY_TYPE.category
}

export const handleTypeAddToCard = repeatCart => {
  const currentRoute = window.location.pathname

  if (currentRoute.includes(routes.historic) && repeatCart) return ANALYTICS_TYPE_ADD_TO_CARD.repeat
  if (![routes.historic, routes.previusOrder].includes(currentRoute)) return ANALYTICS_TYPE_ADD_TO_CARD.new
  return ANALYTICS_TYPE_ADD_TO_CARD.extend
}

export const hashText = async message => {
  const encoder = new TextEncoder()
  const data = encoder.encode(message)
  const hashBuffer = await crypto.subtle.digest('SHA-512', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

export const valueFormatter = value => +parseFloat(value).toFixed(6).slice(0, -4)

export const mapListName = categoriesToArray =>
  categoriesToArray?.map(category => category.name)?.[categoriesToArray?.length - 1] || ANALYTICS_CATEGORY_ALL

export const mapListId = categoriesToArray =>
  categoriesToArray?.map(category => category.id)?.[categoriesToArray?.length - 1] || ANALYTICS_CATEGORY_ALL

export const convertCategoriesToArray = selectedCategories =>
  Object.values(selectedCategories || {}).filter(category => category)

export const sumProductsTotalValue = products =>
  products?.reduce((acc, product) => acc + product.price * product.amount, 0)

export const quantityFormater = value => +parseFloat(value).toFixed(0)

export const sendAnalytics = (eventName, analyticsData) => {
  const analyticsEnabled = localStorage.getItem('analytics')
  if (!analyticsEnabled) {
    return
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: eventName,
    ...analyticsData,
  })
}
