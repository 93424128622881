import React, { useState } from 'react'

import ProducList from './productList'
import Form from './form'

const TicketingCreate = () => {
  const [showProductsList, setShowProductsList] = useState(false)
  const [formData, setFormData] = useState(false)

  const handleShowProductsList = payload => {
    setFormData(payload)
    setShowProductsList(true)
  }

  return <>{showProductsList ? <ProducList formData={formData} /> : <Form onNext={handleShowProductsList} />}</>
}

export default TicketingCreate
