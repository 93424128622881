import React from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { REQUEST_ORDER_STATE } from 'Config/constants'
import { NumberFormat } from 'Components/atoms'

import {
  ProviderHeaderContainer,
  ProviderHeaderContent,
  ProviderHeaderValue,
  ProviderHeaderFlag,
  ProviderHeaderText,
} from './styled'

const { APPROVED } = REQUEST_ORDER_STATE

const ProviderHeader = ({ provider }) => {
  const { t } = useTranslation()

  const showPrice = useSelector(state => state.auth.selectedCenter?.showPrice || false)
  const requestOrder = useSelector(s => s.carts.requestOrder)

  const {
    providerName,
    deliveryDate,
    totalPrice,
    totalProducts,
    approvedDeliveryDate,
    approvedTotalPrice,
    approvedTotalProducts,
  } = provider

  let price = totalPrice
  let total = totalProducts
  let date = deliveryDate

  if (requestOrder?.state === APPROVED) {
    price = approvedTotalPrice
    total = approvedTotalProducts
    date = approvedDeliveryDate
  }

  return (
    <ProviderHeaderContainer>
      <ProviderHeaderContent>
        <ProviderHeaderValue>{providerName}</ProviderHeaderValue>
        <ProviderHeaderFlag>
          {t('deliveryDateApprover', { deliveryDate: moment(date).format('DD/MM/YYYY') })}
        </ProviderHeaderFlag>
      </ProviderHeaderContent>
      <ProviderHeaderContent>
        {showPrice && (
          <ProviderHeaderValue>
            <NumberFormat value={price} />
          </ProviderHeaderValue>
        )}
        <ProviderHeaderText bold>
          <NumberFormat
            value={total}
            decimalScale={0}
            suffix={total === 1 ? t('numberOfProduct') : t('numberOfProducts')}
          />
        </ProviderHeaderText>
      </ProviderHeaderContent>
    </ProviderHeaderContainer>
  )
}

ProviderHeader.defaultProps = {
  provider: {},
}

ProviderHeader.propTypes = {
  provider: PropTypes.object,
}

export default ProviderHeader
