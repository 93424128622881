import { SHOW_REQUEST_TUTORIAL_BANNER_V2, SHOW_APPROVER_TUTORIAL_BANNER_V2 } from './types'

const initialState = {
  showRequestTutorialBannerV2: false,
  showApproverTutorialBannerV2: false,
}

const Reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SHOW_REQUEST_TUTORIAL_BANNER_V2:
      return {
        ...state,
        showRequestTutorialBannerV2: action.payload,
      }
    case SHOW_APPROVER_TUTORIAL_BANNER_V2:
      return {
        ...state,
        showApproverTutorialBannerV2: action.payload,
      }

    default:
      return state
  }
}

export default Reducer
