import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import i18next from 'i18next'
import { selectCenterId } from 'Redux/auth/utils'
import {
  getCartsPending,
  getCartsRejected,
  getCartsApproved,
  getCartsCancelled,
  getRequestOrderCart,
  cancelRequestOrderCart,
  getRequestOrderApprovalChanges,
  getRequestOrderCartWithPage,
} from 'Services/api'
import * as Sentry from 'Services/sentry'
import { REQUEST_ORDER_STATE } from 'Config/constants'
import { getApiErrorMessage } from 'Config/utils'

import {
  setApproved,
  setCancelled,
  setCart,
  setIsFetchingApproved,
  setIsFetchingCancelled,
  setIsFetchingCart,
  setIsFetchingPendingApproval,
  setIsFetchingRejected,
  setIsUpdatingCart,
  setPendingApproval,
  setRejected,
  setRequestOrder,
  setCurrentPageCart,
  setHasMore,
} from './actions'
import {
  CANCEL_REQUEST_ORDER,
  FETCH_APPROVED,
  FETCH_CANCELLED,
  FETCH_CART,
  FETCH_CART_REQUEST,
  FETCH_PENDING_APPROVAL,
  FETCH_REJECTED,
} from './types'
import { mapRequestOrderCart } from './utils'

export function* fetchPendingApproval() {
  yield put(setIsFetchingPendingApproval(true))
  try {
    const centerId = yield select(selectCenterId)
    const filterProviders = yield select(state => state.carts.filterProviders)
    const filterRequestNumber = yield select(state => state.carts.filterRequestNumber)
    const { data, count } = yield call(getCartsPending, centerId, filterProviders?.key, filterRequestNumber)
    yield put(setPendingApproval({ data, count }))
  } catch (e) {
    Sentry.captureException(e)
    toast.error(i18next.t('cartsErrorMessages.fetchPendingApproval', { apiMessage: getApiErrorMessage(e) }))
    yield put(setPendingApproval({ data: [], count: 0 }))
  } finally {
    yield put(setIsFetchingPendingApproval(false))
  }
}

export function* fetchRejected() {
  yield put(setIsFetchingRejected(true))
  try {
    const centerId = yield select(selectCenterId)
    const filterProviders = yield select(state => state.carts.filterProviders)
    const filterRequestNumber = yield select(state => state.carts.filterRequestNumber)
    const { data } = yield call(getCartsRejected, centerId, filterProviders?.key, filterRequestNumber)
    yield put(setRejected(data))
  } catch (e) {
    Sentry.captureException(e)
    toast.error(i18next.t('cartsErrorMessages.fetchRejected', { apiMessage: getApiErrorMessage(e) }))
    yield put(setRejected([]))
  } finally {
    yield put(setIsFetchingRejected(false))
  }
}

export function* fetchApproved() {
  yield put(setIsFetchingApproved(true))
  try {
    const centerId = yield select(selectCenterId)
    const filterProviders = yield select(state => state.carts.filterProviders)
    const filterRequestNumber = yield select(state => state.carts.filterRequestNumber)
    const { data } = yield call(getCartsApproved, centerId, filterProviders?.key, filterRequestNumber)
    yield put(setApproved(data))
  } catch (e) {
    Sentry.captureException(e)
    toast.error(i18next.t('cartsErrorMessages.fetchApproved', { apiMessage: getApiErrorMessage(e) }))
    yield put(setApproved([]))
  } finally {
    yield put(setIsFetchingApproved(false))
  }
}

export function* fetchCancelled() {
  yield put(setIsFetchingCancelled(true))
  try {
    const centerId = yield select(selectCenterId)
    const filterProviders = yield select(state => state.carts.filterProviders)
    const filterRequestNumber = yield select(state => state.carts.filterRequestNumber)
    const { data } = yield call(getCartsCancelled, centerId, filterProviders?.key, filterRequestNumber)
    yield put(setCancelled(data))
  } catch (e) {
    Sentry.captureException(e)
    toast.error(i18next.t('cartsErrorMessages.fetchCancelled', { apiMessage: getApiErrorMessage(e) }))
    yield put(setCancelled([]))
  } finally {
    yield put(setIsFetchingCancelled(false))
  }
}

export function* fetchCartRequest(action) {
  const { state: stateCR, requestOrderId, page = 0, pageSize = 10 } = get(action, 'payload', {})
  const currentCartList = yield select(state => state.carts.cart || {})
  const queryParams = { limit: pageSize, offset: page * pageSize }

  try {
    yield put(setIsFetchingCart(true))

    const centerId = yield select(selectCenterId)
    const requestOrder = yield select(s => s.carts.requestOrder)
    const { data: cartData = [] } = yield call(getRequestOrderCartWithPage, centerId, requestOrderId, queryParams)

    if (cartData?.length >= pageSize) {
      yield put(setHasMore(true))
    } else {
      yield put(setHasMore(false))
    }

    let approvalLines = null
    if (stateCR === REQUEST_ORDER_STATE.APPROVED) {
      const { lines } = yield call(getRequestOrderApprovalChanges, centerId, requestOrderId)
      approvalLines = lines
    }

    const cart = mapRequestOrderCart(cartData, approvalLines, requestOrder, currentCartList)
    yield put(setCart(cart))
    yield put(setCurrentPageCart(queryParams.offset))
  } catch (e) {
    Sentry.captureException(e)
    yield put(setCart(null))
    yield put(setRequestOrder(null))
    yield put(setCurrentPageCart(0))

    toast.error(i18next.t('cartsErrorMessages.fetchCart', { apiMessage: getApiErrorMessage(e) }))
  } finally {
    yield put(setIsFetchingCart(false))
  }
}

export function* fetchCart(action) {
  yield put(setIsFetchingCart(true))
  try {
    const requestOrderId = get(action, 'payload.requestOrderId')
    const state = get(action, 'payload.state')
    const centerId = yield select(selectCenterId)
    const requestOrder = yield select(s => s.carts.requestOrder)
    const { data: cartData } = yield call(getRequestOrderCart, centerId, requestOrderId)

    let approvalLines = null
    if (state === REQUEST_ORDER_STATE.APPROVED) {
      const { lines } = yield call(getRequestOrderApprovalChanges, centerId, requestOrderId)
      approvalLines = lines
    }
    const cart = mapRequestOrderCart(cartData, approvalLines, requestOrder)
    yield put(setCart(cart))
  } catch (e) {
    Sentry.captureException(e)
    yield put(setCart(null))
    yield put(setRequestOrder(null))
    toast.error(i18next.t('cartsErrorMessages.fetchCart', { apiMessage: getApiErrorMessage(e) }))
  } finally {
    yield put(setIsFetchingCart(false))
  }
}

export function* cancelRequestOrder(action) {
  try {
    yield put(setIsUpdatingCart(true))
    const requestOrderId = get(action, 'payload')
    const centerId = yield select(selectCenterId)
    const { id, username } = yield call(cancelRequestOrderCart, centerId, requestOrderId)
    toast.success(i18next.t('requestCancelledSuccessInfo', { id, username }))

    const requestOrder = yield select(state => state.carts.requestOrder)
    if (requestOrder) {
      yield put(setCart(null))
      yield put(setRequestOrder(null))
    }
    yield fetchPendingApproval()
  } catch (e) {
    Sentry.captureException(e)
    toast.error(getApiErrorMessage(e))
  } finally {
    yield put(setIsUpdatingCart(false))
  }
}

function* watchFetchPendingApproval() {
  yield takeLatest(FETCH_PENDING_APPROVAL, fetchPendingApproval)
}

function* watchFetchRejected() {
  yield takeLatest(FETCH_REJECTED, fetchRejected)
}

function* watchFetchApproved() {
  yield takeLatest(FETCH_APPROVED, fetchApproved)
}

function* watchFetchCancelled() {
  yield takeLatest(FETCH_CANCELLED, fetchCancelled)
}

function* watchFetchCart() {
  yield takeLatest(FETCH_CART, fetchCart)
}

function* watchCartRequest() {
  yield takeLatest(FETCH_CART_REQUEST, fetchCartRequest)
}

function* watchCancelRequestOrder() {
  yield takeLatest(CANCEL_REQUEST_ORDER, cancelRequestOrder)
}

export default function* rootAuthSaga() {
  yield all([
    watchFetchPendingApproval(),
    watchFetchRejected(),
    watchFetchApproved(),
    watchFetchCancelled(),
    watchFetchCart(),
    watchCancelRequestOrder(),
    watchCartRequest(),
  ])
}
