import styled from 'styled-components'
import colors from 'Assets/colors'

export const Container = styled.div`
  padding: 2.5rem 1.25rem 9rem;
  height: calc(100vh - 64px);
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2px;
`

export const Content = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 40px 80px;
  width: 920px;
  margin: 40px auto;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.black};
  margin-bottom: 16px;
  text-align: center;
`

export const SubTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  text-align: center;
`

export const Caption = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
`

export const InputContainer = styled.div`
  padding: 24px 0;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 6%;
`

export const InputLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 8px;
`

export const InputSeparator = styled.hr`
  height: 1px;
  color: rgba(51, 51, 51, 0.24);
  background-color: rgba(51, 51, 51, 0.24);
  border: none;
`

export const InputInfoTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.black};
  margin-bottom: 8px;
  margin-top: 14px;
`

export const InputInfoSubtitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 16px;
`

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

export const ActionButton = styled.div`
  width: 100%;
  background-color: ${props => (props.disabled ? colors.gray40l : colors.secondary)};
  padding: 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const ButtonLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`
export const Dropzone = styled.section`
  margin-bottom: 24px;

  .dropzone {
    width: 760px;
    height: 160px;
    left: 80px;
    top: 552px;
    background: ${props => (props.disabled ? 'rgb(243, 241, 241)' : '#FFFFFF')};
    border: ${props => (props.error ? `2px solid ${colors.red}` : '1px dashed #333333')};
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${props => (props.disabled ? 0.75 : 1)};

    .dropzone-placeholder {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: ${props => (props.error ? colors.red : '#666666')};
      pointer-events: none;
    }
  }

  .files {
    .file {
      border: 1px solid #333333;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 16px;
      margin-top: 7px;
      display: flex;
      flex: 1;
      flex-direction: row;
    }

    .file-text {
      display: flex;
      flex: 1;
      flex-direction: row;
      margin-left: 18px;
      pointer-events: none;
    }

    .file-name {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
    }

    .file-size {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #666666;
      margin-left: 8px;
    }

    .trash-icon {
      cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
      pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
    }
  }
`
