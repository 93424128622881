import React from 'react'

import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import routes from 'Config/routes'
import { ReactComponent as PedidoEspera } from 'Assets/Icons/PedidoEspera.svg'
import { ReactComponent as PedidoConfirmado } from 'Assets/Icons/PedidoConfirmado.svg'
import { ReactComponent as CompassGroup } from 'Assets/Logos/CompassGroup.svg'
import { AuthActions } from 'Redux/auth'
import { setIsRepeatingOrder } from 'Redux/multicenter/actions'
import { selectConfig } from 'Redux/auth/utils'

import { Container, Content, Title, SubTitle, SvgContainer, ButtonsContainer, useStyles } from './styled'

const CheckoutConfirmed = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const trackingId = useSelector(({ purchase }) => purchase.trackingId)
  const isMulticenterRepeatingOrder = useSelector(({ multicenter }) => multicenter.isRepeatingOrder)
  const features = useSelector(selectConfig)

  const handleMulticenterRoute = route => {
    dispatch(setIsRepeatingOrder(false))
    dispatch(AuthActions.setCenter({}))
    history.push(route)
  }

  const handleClickBack = () =>
    isMulticenterRepeatingOrder ? handleMulticenterRoute(routes.multicenterOrders) : history.push(routes.purchase)

  const handleClickHistoric = () =>
    isMulticenterRepeatingOrder ? handleMulticenterRoute(routes.multicenterOrders) : history.push(routes.historic)

  const Icon = features.order_confirm_sqs ? PedidoEspera : PedidoConfirmado

  return (
    <Container>
      <Content>
        <Title>{t(features.order_confirm_sqs ? 'confirmationTitleSQS' : 'confirmationTitle')}</Title>
        <SubTitle>
          {t(features.order_confirm_sqs ? 'confirmationSubtitleSQS' : 'confirmationSubtitle', { trackingId })}
        </SubTitle>
        <SvgContainer>
          <Icon style={{ width: '160px', height: '160px' }} />
        </SvgContainer>
        <ButtonsContainer>
          <Button onClick={handleClickBack} className={classes.back}>
            {isMulticenterRepeatingOrder ? t('backToMyCenters') : t('backHome')}
          </Button>
          <Button
            onClick={handleClickHistoric}
            className={isMulticenterRepeatingOrder ? classes.back : classes.history}
          >
            {isMulticenterRepeatingOrder ? t('backToMyOrders') : t('toHistory')}
          </Button>
        </ButtonsContainer>
      </Content>
      <CompassGroup />
    </Container>
  )
}

export default CheckoutConfirmed
