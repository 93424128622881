import React from 'react'

import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as PortalLogo } from 'Assets/Logos/PortalLogo.svg'
import { ReactComponent as ComplaintIcon } from 'Assets/Icons/Complaint.svg'
import { ReactComponent as OrdersIcon } from 'Assets/Icons/EstadoTramitado.svg'
import { ReactComponent as ApprovalIcon } from 'Assets/Icons/QaOk.svg'
import routes from 'Config/routes'

import { useStyles } from '../styled'

import { ButtonsContainer, Separator } from './styled'

const MulticenterButtons = ({ isApprover, showBackToCenter, onCenterClick }) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  return (
    <ButtonsContainer>
      {showBackToCenter && (
        <Button
          onClick={onCenterClick}
          startIcon={<PortalLogo width={24} height={24} />}
          className={classes.menuButton}
          color='inherit'
        >
          {t`backToCenter`}
        </Button>
      )}
      <Separator />
      <Link to={routes.multicenterOrders}>
        <Button
          startIcon={<OrdersIcon width={18} height={18} />}
          className={classNames(classes.menuButton, { active: pathname === routes.multicenterOrders })}
        >
          {t`orders`}
        </Button>
      </Link>
      <Link to={routes.multicenterTicketing}>
        <Button
          startIcon={<ComplaintIcon width={18} height={18} />}
          className={classNames(classes.menuButton, { active: pathname === routes.multicenterTicketing })}
        >
          {t`complaints`}
        </Button>
      </Link>
      {isApprover && (
        <Link to={routes.approverCarts}>
          <Button
            startIcon={<ApprovalIcon width={18} height={18} />}
            className={classNames(classes.menuButton, { active: pathname === routes.approverCarts })}
          >
            {t`approval`}
          </Button>
        </Link>
      )}
    </ButtonsContainer>
  )
}

MulticenterButtons.propTypes = {
  isApprover: PropTypes.bool,
  showBackToCenter: PropTypes.bool,
  onCenterClick: PropTypes.func.isRequired,
}

MulticenterButtons.defaultProps = {
  isApprover: false,
  showBackToCenter: true,
}

export default MulticenterButtons
