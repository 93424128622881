import React from 'react'

import { CenterSelector } from 'Components/molecules'
import { ReactComponent as PortalLogo } from 'Assets/Logos/PortalLogo.svg'
import { ReactComponent as CompassGroup } from 'Assets/Logos/CompassGroup.svg'

import { CenterSelectContainer, RootContainer } from './styled'

const Home = () => (
  <RootContainer>
    <PortalLogo style={{ marginBottom: '2.5rem' }} />
    <CenterSelectContainer>
      <CenterSelector title='welcomeTitle' />
    </CenterSelectContainer>
    <CompassGroup style={{ marginTop: '2.5rem' }} />
  </RootContainer>
)

export default Home
