import styled from 'styled-components'

export const Type = styled.div`
  padding: 0.5rem 1.25rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover .subcategories-text {
    color: ${props => props.theme.colors.secondary};
  }
`

export const TypeText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.2s;
  color: ${props => (props.selected ? props.theme.colors.secondary : props.theme.colors.black)};
`
