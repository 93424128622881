import { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { AVAILABILITY_STATES, TEMPERATURES } from 'Config/constants'
import { selectCenterId } from 'Redux/auth/utils'
import { getOrderProducts } from 'Services/api'

const useOrderProducts = ({ orderId, documentTypeCode, documentCompany }) => {
  const centerId = useSelector(selectCenterId)

  const [isFetching, setIsFetching] = useState([])
  const [products, setProducts] = useState([])
  const [hasUnAvailableProduct, setHasUnAvailableProduct] = useState(false)
  const [hasRefrigerated, setHasRefrigerated] = useState(false)
  const [hasFrozen, setHasFrozen] = useState(false)

  useEffect(() => {
    const checkTemperature = async () => {
      setIsFetching(true)

      try {
        const { lines } = await getOrderProducts(null, centerId, orderId, documentTypeCode, documentCompany)
        setProducts(setProducts)

        const gotRefrigeratedProducts = lines.some(
          ({ product, temperature }) =>
            product?.temperature === TEMPERATURES.refrigerated || temperature === TEMPERATURES.refrigerated
        )
        setHasRefrigerated(gotRefrigeratedProducts)

        const gotFrozenProducts = lines.some(
          ({ product, temperature }) =>
            product?.temperature === TEMPERATURES.frozen || temperature === TEMPERATURES.frozen
        )
        setHasFrozen(gotFrozenProducts)

        const hasUnAvailable = lines.some(
          ({ product }) => !product?.availability || product.availability.status === AVAILABILITY_STATES.UNAVAILABLE
        )
        setHasUnAvailableProduct(hasUnAvailable)
      } catch {
        setProducts([])
        setHasFrozen(false)
        setHasRefrigerated(false)
      }

      setIsFetching(false)
    }
    checkTemperature()
  }, [centerId, documentCompany, documentTypeCode, orderId])

  return { products, isFetching, hasUnAvailableProduct, hasFrozen, hasRefrigerated }
}

export default useOrderProducts
