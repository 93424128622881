import { ReactComponent as Carnes } from 'Assets/Icons/Carnes.svg'
import { ReactComponent as Pescado } from 'Assets/Icons/Pescado.svg'
import { ReactComponent as FiambresElaborados } from 'Assets/Icons/FiambresElaborados.svg'
import { ReactComponent as Verdura } from 'Assets/Icons/Verdura.svg'
import { ReactComponent as PanPasteleria } from 'Assets/Icons/PanPasteleria.svg'
import { ReactComponent as Fruta } from 'Assets/Icons/Fruta.svg'
import { ReactComponent as PrecocinadoTerminado } from 'Assets/Icons/PrecocinadoTerminado.svg'
import { ReactComponent as Huevos } from 'Assets/Icons/Huevos.svg'
import { ReactComponent as SecoArrocesLegumbresPasta } from 'Assets/Icons/SecoArrocesLegumbresPasta.svg'
import { ReactComponent as ConservasAceitesSalsas } from 'Assets/Icons/ConservasAceitesSalsas.svg'
import { ReactComponent as Lacteos } from 'Assets/Icons/Lacteos.svg'
import { ReactComponent as Bebidas } from 'Assets/Icons/Bebidas.svg'
import { ReactComponent as CafesInfusiones } from 'Assets/Icons/CafesInfusiones.svg'
import { ReactComponent as SnacksDulcesCereales } from 'Assets/Icons/SnacksDulcesCereales.svg'
import { ReactComponent as Consumibles } from 'Assets/Icons/Consumibles.svg'
import { ReactComponent as Limpieza } from 'Assets/Icons/Limpieza.svg'
import { ReactComponent as Uniformes } from 'Assets/Icons/Uniformes.svg'
import { ReactComponent as Genericos } from 'Assets/Icons/Genericos.svg'
import { ReactComponent as Todos } from 'Assets/Icons/Todos.svg'

export default {
  A: Carnes,
  B: Pescado,
  C: FiambresElaborados,
  D: Verdura,
  E: PanPasteleria,
  F: Fruta,
  G: PrecocinadoTerminado,
  H: Huevos,
  I: SecoArrocesLegumbresPasta,
  J: ConservasAceitesSalsas,
  L: Lacteos,
  M: Bebidas,
  O: CafesInfusiones,
  S: SnacksDulcesCereales,
  U: Consumibles,
  V: Limpieza,
  W: Uniformes,
  Z: Genericos,
  all: Todos,
}
