import React, { useState, useRef, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useClickOutside } from 'Components/utils/customHooks'
import { ModalHistoryFilter } from 'Components/molecules'
import { ReactComponent as FilterIcon } from 'Assets/Icons/Filter.svg'
import PropTypes from 'prop-types'

import { Modal } from '../../atoms'

import { FilterButton, MainText, IconContainer } from './styled'

const HistoryFilter = ({ value, onChange }) => {
  const filterRef = useRef()
  useClickOutside(filterRef)
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  const onFilterClick = () => {
    setShowModal(true)
  }

  const handleChange = filters => {
    setShowModal(false)
    onChange(filters)
  }

  const hasFilters = useMemo(() => {
    let filtersLength = value ? Object.keys(value).length : 0
    if (Object.prototype.hasOwnProperty.call(value, 'ids')) {
      filtersLength -= 1
    }
    const oneDateFilters = value.startDate && value.endtDate ? filtersLength - 1 : filtersLength
    return oneDateFilters > 0
  }, [value])

  return (
    <div ref={filterRef}>
      <FilterButton onClick={onFilterClick}>
        <MainText>{t('filterModalTrigger')}</MainText>
        <IconContainer showDot={hasFilters}>
          <FilterIcon />
        </IconContainer>
      </FilterButton>
      <Modal isOpen={showModal} setIsOpen={setShowModal} width='960px' height='660px'>
        <ModalHistoryFilter
          value={value}
          onChange={handleChange}
          setShowModal={setShowModal}
          title={t('filterModalTitle')}
          message={t('filterModalSubtitle')}
        />
      </Modal>
    </div>
  )
}

HistoryFilter.defaultProps = {
  value: {},
  onChange: () => {},
}

HistoryFilter.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
}

export default HistoryFilter
