import React from 'react'

import { BigTitle, RegularText } from 'Components/atoms'
import PropTypes from 'prop-types'
import { ReactComponent as SearchIcon } from 'Assets/Icons/search_2.svg'

import { NoResultsContainer, NoResultsBox } from './styled'

const NoSearch = ({ title, description }) => (
  <NoResultsContainer>
    <NoResultsBox>
      <BigTitle margin='0 0 16px'>{title}</BigTitle>
      <RegularText>{description}</RegularText>
      <SearchIcon />
    </NoResultsBox>
  </NoResultsContainer>
)

NoSearch.defaultProps = {
  title: '',
  description: '',
}

NoSearch.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default NoSearch
