import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import colors from 'Assets/colors'

export const useStyles = makeStyles(() => ({
  root: {
    zIndex: '900 !important',
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
  paper: { maxWidth: '50vw', overflow: 'hidden' },
}))

export const BodyContainer = styled.div`
  width: 100%;
  padding: ${props => (props.padding ? props.padding : '0 40px')};
`

export const PostInfoContainer = styled.div`
  padding: 0 40px;
`

export const CardImage = styled.img`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 32px;
  max-height: 500px;
  object-fit: cover !important;
`

export const PostTitle = styled.h1`
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.black};
`

export const PostMetasContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const PostDate = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-right: 16px;
`

export const PostCategories = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const PostCategory = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-right: 16px;
  color: ${colors.secondary};
  transition: all ease 0.2s;
  cursor: pointer;
  &:hover {
    color: ${colors.black};
  }
`

export const MainContainer = styled.span`
  overflow-y: scroll;
  height: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const CloseButtonContainer = styled.div`
  background: white;
  position: absolute;
  top: 24px;
  right: 24px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 8px;
  :hover {
    opacity: 0.7;
  }
`

export const CloseButton = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;
  position: absolute;
  top: 3px;
  right: 8px;
  transform: rotate(45deg);
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`
