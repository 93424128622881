import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import routes from 'Config/routes'
import { ORDER_STATUS, TICKET_STATUS } from 'Config/constants'
import { fetchStats } from 'Redux/multicenter/actions'
import { selectStats } from 'Redux/multicenter/selectors'

import { hasPendingToReceiveOrders } from '../../utils/order'
import { OrderPRBullet } from '../navBar/styled'

import * as S from './styled'

const MulticenterStats = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const stats = useSelector(selectStats)

  useEffect(() => {
    dispatch(fetchStats())
  }, [dispatch])

  return (
    <>
      <S.MulticenterStats>
        <div>
          <Link to={`${routes.multicenterOrders}?status=${ORDER_STATUS.PR}`}>
            <span>{t`ordersToReceive`}</span>
            {hasPendingToReceiveOrders(stats.existsOrdersToReceive) && <OrderPRBullet />}
          </Link>
        </div>
        <div>
          <Link to={`${routes.multicenterTicketing}?status=${TICKET_STATUS.pending}`}>
            <span>{t`complaints`}</span>
            <S.ChipCounter>{stats.complaints}</S.ChipCounter>
          </Link>
        </div>
      </S.MulticenterStats>
      <S.Separator />
    </>
  )
}

export default MulticenterStats
