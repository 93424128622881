import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { SelectedCenterRoute } from 'Components/system/routes/customRoutes'
import { Notification } from 'Components/atoms'
import { ModalError } from 'Components/molecules'
import { SearchBar, ProductsAndSection, CartDrawer, Announcements } from 'Components/organisms'
import { PurchaseTypes, PurchaseActions } from 'Redux/purchases'
import routes from 'Config/routes'
import { needsApprovalMode } from 'Redux/approver/selectors'
import { hasPendingToReceiveOrders } from 'Components/utils/order'

import { ORDER_STATUS } from '../../../Config/constants'

import { Container, MainContainer, AnnouncementsContainer } from './styled'

const Purchases = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const selectedCenter = useSelector(state => state.auth.selectedCenter)
  const numProviderPendingOrders = useSelector(state => state.purchase.numProviderPendingOrders)
  const showModalError = useSelector(state => state.purchase.showModalError)
  const needsApproval = useSelector(needsApprovalMode)

  const [temperature, setTemperature] = useState('')
  const [showDrawer, setShowDrawer] = useState(false)

  useEffect(() => {
    dispatch({ type: PurchaseTypes.FETCH_CATEGORIES })
    dispatch({ type: PurchaseTypes.FETCH_CART })
    dispatch({ type: PurchaseTypes.FETCH_BUDGET })
    dispatch({ type: PurchaseTypes.FETCH_CATEGORIES_HABITUAL })
  }, [dispatch, selectedCenter])

  useEffect(() => {
    setTemperature('')

    /* eslint-disable-next-line */
  }, [selectedCenter])

  const setShowModal = value => dispatch(PurchaseActions.setModalErrorCart(value))

  const onConfirmError = () => dispatch(PurchaseActions.setModalErrorCart(false))

  const isPurchaseExactRoute = useRouteMatch({
    path: [routes.purchase],
    strict: true,
  }).isExact

  const Main = isPurchaseExactRoute ? MainContainer : Container

  return (
    <>
      <SearchBar setShowDrawer={setShowDrawer} showCartButton={!needsApproval} />
      <Main $tutorial={false}>
        <SelectedCenterRoute exact path={[routes.purchase]}>
          <Container>
            {hasPendingToReceiveOrders(numProviderPendingOrders) && (
              <Notification
                onClick={() =>
                  history.push(routes.historic, { initialTab: 'showPR', filter: { status: ORDER_STATUS.PR } })
                }
                text={t('toBeReceptionedNotificationLiteral')}
                color='error'
                buttonText={t('viewPROrders')}
              />
            )}
          </Container>
        </SelectedCenterRoute>

        <SelectedCenterRoute exact path={[routes.purchase]}>
          <AnnouncementsContainer>
            <Announcements />
          </AnnouncementsContainer>
        </SelectedCenterRoute>
      </Main>
      <ProductsAndSection setTemperature={setTemperature} temperature={temperature} />
      <CartDrawer showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
      <ModalError
        showModal={showModalError}
        setShowModal={setShowModal}
        title={t('cartErrorModalTitle')}
        message={t('cartErrorModalMessage')}
        onConfirm={onConfirmError}
        color='red'
        width='680px'
        height='256px'
      />
    </>
  )
}

export default Purchases
