import styled, { css } from 'styled-components'

export const Container = styled.div`
  height: calc(100vh - 150px);
  overflow-y: scroll;
  padding: 50px 30px;

  ${props =>
    props.$tutorial &&
    css`
      height: calc(100vh - 197px);
    `}
`

export const Separator = styled.div`
  padding-bottom: 30px;
`

export const FiltersContainer = styled.div`
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  gap: 1rem;

  .separator {
    width: 16px;
  }
`

export const FilterCenter = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 20rem;
  padding: 0.5rem 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  .icon {
    margin-right: 1rem;
  }
`

export const CenterSelected = styled.div`
  display: inline-flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  border-radius: 10px;

  .icon {
    fill: ${props => props.theme.colors.white};
    margin-left: 0.5rem;
  }
`
