import React from 'react'

import PropTypes from 'prop-types'
import { ReactComponent as UpIcon } from 'Assets/Icons/Up.svg'
import { ReactComponent as DownIcon } from 'Assets/Icons/Down.svg'

import { SelectorContainer } from './styled'

const SelectorButton = ({ onClick, icon }) => (
  <SelectorContainer onClick={onClick}>{icon === 'up' ? <UpIcon /> : <DownIcon />}</SelectorContainer>
)

SelectorButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.oneOf(['up', 'down']),
}

SelectorButton.defaultProps = {
  onClick: () => null,
  icon: 'up',
}

export default SelectorButton
