import styled from 'styled-components'
import colors from 'Assets/colors'

export const Input = styled.input`
  width: 100%;
  border-radius: ${props => props.borderRadius};
  border: ${props => props.border};
  border-top: ${props => props.borderTop};
  padding: ${props => props.padding};
  transition: all 0.4s;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  height: ${props => props.height};
  color: ${props => props.color || colors.black};

  :focus {
    box-shadow: ${props =>
      props.shadow ? props.shadow : '0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)'};
  }

  &::placeholder {
    color: ${props => props.placeholderColor};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px ${colors.grayInput} inset;
  }
`

export const MinimalInput = styled.input`
  width: 100%;
  border: 10px;
  border-top: ${props => props.borderTop};
  padding: ${props => props.padding};
  transition: all 0.4s;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  height: ${props => props.height};

  &::placeholder {
    color: ${props => props.placeholderColor};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px ${colors.grayInput} inset;
  }
`

export const Label = styled.label`
  position: relative;
  width: 100%;
  background: ${props => props.background};
  border-radius: ${props => props.borderRadius};
  border-bottom: ${props => props.borderBottom};
  box-shadow: ${props =>
    props.minimalShadow ? '0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)' : ''};
  
  .icon-input {
    content: '';
    position: absolute;
    right: ${props => props.rightIcon};
    top: ${props => props.topIcon};
    bottom: 0;
  }

  .close-input-icon {
    content: '';
    position: absolute;
    right: 3rem;
    color: #cccccc;
    width: 22px;
    top: ${props => props.topIcon};
    bottom: 0;
  }

  .close-input-icon:hover {
    color: #acacac;
  }
`

export const Container = styled.div`
  position: relative;
  width: ${props => props.width};
  margin: ${props => props.margin};
  position: ${props => props.position};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  @media (max-width: 1480px) {
    width: ${props => props.respWidth};
  }
`

export const ErrorContainer = styled.div`
  color: ${colors.red};
  padding: 5px 5px 0;
  font-size: 11px;
`
