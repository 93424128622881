import styled from 'styled-components'

export const Text = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: ${props => (props.fontSize ? props.fontSize : '1rem')};
  line-height: 24px;
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`
