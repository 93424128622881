import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'Config/constants'

import { StyledTablePagination } from './styled'

const Pagination = ({ label, paginationProps }) => {
  const { t } = useTranslation()
  const { page, pageSize, rowCount, setPage, setPageSize } = paginationProps

  return (
    <StyledTablePagination
      component='div'
      count={rowCount}
      page={page}
      rowsPerPage={pageSize}
      rowsPerPageOptions={TABLE_ROWS_PER_PAGE_OPTIONS}
      onPageChange={(evt, newPage) => setPage(newPage)}
      onRowsPerPageChange={evt => {
        setPageSize(evt.target.value)
        setPage(0)
      }}
      labelRowsPerPage={t(label)}
      labelDisplayedRows={({ from, to, count }) => t('numOfNum', { num1: `${from} - ${to}`, num2: count })}
    />
  )
}

Pagination.propTypes = {
  label: PropTypes.string.isRequired,
  paginationProps: PropTypes.object.isRequired,
}

export default Pagination
