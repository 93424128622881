import React from 'react'

import { useTranslation } from 'react-i18next'
import ContentLoader from 'react-content-loader'

const SingleModalLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width='100%'
      height={380}
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='18' y='20' rx='0' ry='0' width='20%' height='16' />
      <rect x='18' y='80' rx='0' ry='0' width='4%' height='16' />
      <rect x='18' y='120' rx='0' ry='0' width='98%' height='76' />
      <rect x='18' y='210' rx='0' ry='0' width='98%' height='76' />
      <rect x='18' y='300' rx='0' ry='0' width='98%' height='76' />
    </ContentLoader>
  )
}

export default SingleModalLoader
