import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import colors from 'Assets/colors'

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

export const Input = styled(TextField)`
  width: 50%;
`

export const LoginContainer = styled.div`
  width: 42.5rem;
  background: white;
  padding: 100px 80px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  position: relative;
`

export const AppVersion = styled.span`
  position: absolute;
  bottom: 16px;
  right: 0;
  left: 0;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: ${colors.grey3};
`

export const RootContainer = styled(Container)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
