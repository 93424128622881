import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem;
`

export const SVGRow = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10rem;
  padding-bottom: 2.5rem;
`

export const ErrorTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${props => props.theme.colors.black};
  padding-bottom: 1rem;
`

export const ErrorMessage = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.black};
  padding-bottom: 2.5rem;
`

export const RetryButton = styled.button`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.cart};
  width: 100%;
  padding: 1rem;
  border-radius: 8px;

  :hover {
    opacity: 0.8;
  }
`
