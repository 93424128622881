import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as AddCartIcon } from 'Assets/Icons/AddCart.svg'
import { ReactComponent as NotAvailableIcon } from 'Assets/Icons/SinDisponibilidad.svg'
import { ReactComponent as UpIcon } from 'Assets/Icons/Up.svg'
import { ReactComponent as DownIcon } from 'Assets/Icons/Down.svg'
import { ReactComponent as CheckIcon } from 'Assets/Icons/CheckIcon.svg'
import Trash from 'Assets/Icons/Trash'
import { KEY_CODES } from 'Config/constants'
import { editCart } from 'Redux/approver/actions'

import NumberFormat from '../numberFormat'
import { AVAILABILITY_STATES } from '../../../Config/constants'
import { floatModulus } from '../../../Redux/purchases/utils'

import {
  ButtonCart,
  Selector,
  Input,
  AmountContainer,
  ButtonCartRemove,
  RemoveButton,
  ActionEraseButton,
  ButtonNotAvailable,
  Unavailable,
  EraseButton,
  AcceptButton,
  LabelValue,
  MainContainer,
} from './styled'

const ProductButtonApprover = forwardRef((props /*, ref*/) => {
  const {
    name,
    provider,
    price,
    min,
    multiple,
    measureUnit,
    pricePerKg,
    id,
    rawMaterial,
    notAvailable,
    reference,
    setShowModalConfirmationProduct,
    setOnConfirmProduct,
    availability,
    innerRef,
    onNext,
    onPrevious,
  } = props

  const { t } = useTranslation()
  const inputRef = useRef()
  const dispatch = useDispatch()

  const { amount = 0 } = useSelector(state => state.approver.cart[id]) || {}
  const inCart = useSelector(state => state.approver.cart[id]) || null

  const [inputValue, setInputValue] = useState(amount || 0)
  const [focused, setFocused] = useState(false)
  const [error, setError] = useState(false)

  const alternativeReference = availability ? availability.alternative_reference : null
  const status = availability ? availability.status : AVAILABILITY_STATES.AVAILABLE

  useImperativeHandle(innerRef, () => ({
    focus() {
      setFocused(true)
    },
    hasInput() {
      return (
        amount !== 0 &&
        status !== AVAILABILITY_STATES.UNAVAILABLE &&
        status !== AVAILABILITY_STATES.NEXT &&
        status !== AVAILABILITY_STATES.NOT_STOCK
      )
    },
    getId() {
      return id
    },
  }))

  useEffect(() => {
    if (amount !== undefined) setInputValue(amount)
  }, [amount])

  useEffect(() => {
    if (focused && inputRef && inputRef.current) {
      inputRef.current.select()
    }
  }, [focused, innerRef])

  const setFocusInput = () => setFocused(true)

  const onChangeInput = e => {
    const text = e.target.value.replace(',', '.')
    if (!text) {
      setInputValue('')
      setError(true)
      return
    }

    const newValue = parseFloat(text) || 0
    setInputValue(newValue)
    const priceModulus = floatModulus(newValue, multiple)
    // eslint-disable-next-line eqeqeq
    if (text && (newValue < min || priceModulus !== 0) && newValue != 0) {
      setError(true)
      return
    }
    setError(false)
  }

  const doEditCart = ({ newAmount, newTotal }) =>
    dispatch(
      editCart(id, {
        amount: newAmount,
        total: newTotal,
        name,
        provider,
        price,
        pricePerKg,
        measureUnit,
        min,
        multiple,
        id,
        raw_material: rawMaterial,
        state: status,
        reference,
      })
    )

  const onConfirmProductErase = () => {
    doEditCart({
      newAmount: 0,
      newTotal: 0,
    })

    setShowModalConfirmationProduct(false)
    setFocused(false)
  }

  const setToZero = () => {
    if (!notAvailable) {
      setShowModalConfirmationProduct(true)
      setOnConfirmProduct(() => onConfirmProductErase)
      return
    }
    setFocused(false)

    doEditCart({
      newAmount: 0,
      newTotal: 0,
    })
  }

  const onBlurInput = () => {
    const newValue = parseFloat(inputValue) || 0
    const priceModulus = floatModulus(newValue, multiple)

    setFocused(false)
    setError(false)

    if (newValue === 0) {
      setInputValue(amount)
      setToZero()
      return
    }

    // eslint-disable-next-line eqeqeq
    if ((newValue >= min && priceModulus === 0) || !newValue) {
      const parsedInput = Number.isNaN(newValue) ? 0 : newValue
      doEditCart({
        newAmount: parsedInput,
        newTotal: parsedInput * price,
      })
      return
    }

    setInputValue(amount)
  }

  const add = sum =>
    doEditCart({
      newAmount: +(amount + sum).toFixed(2),
      newTotal: +(amount + sum).toFixed(2) * price,
    })

  const susbtract = sub =>
    doEditCart({
      newAmount: +(amount - sub).toFixed(2),
      newTotal: +(amount - sub).toFixed(2) * price,
    })

  const onClickAdd = e => {
    e.stopPropagation()
    if (!amount) {
      doEditCart({
        newAmount: min,
        newTotal: min * price,
      })
    }
  }

  const onClickSubstract = () => {
    if (amount - multiple <= 0) {
      return setToZero()
    }

    return susbtract(multiple)
  }

  const handleKeydown = e => {
    if (e.keyCode === KEY_CODES.UP_ARROW) {
      e.preventDefault()
      add(multiple)
    } else if (e.keyCode === KEY_CODES.DOWN_ARROW) {
      e.preventDefault()
      onClickSubstract()
    } else if (e.keyCode === KEY_CODES.TAB && e.shiftKey) {
      e.preventDefault()
      inputRef.current.blur()
      setFocused(false)
      onPrevious(id)
    } else if (e.keyCode === KEY_CODES.TAB || e.keyCode === KEY_CODES.ENTER) {
      e.preventDefault()
      inputRef.current.blur()
      setFocused(false)
      onNext(id)
    }
  }

  const renderContent = (buttonCopy, flag, icon) => (
    <>
      {icon === 'unavailable' ? (
        <NotAvailableIcon style={{ marginRight: '0.5rem' }} />
      ) : (
        <AddCartIcon style={{ marginRight: '0.5rem' }} />
      )}
      <span>{buttonCopy}</span>
      {flag ? <span className='flag'>{flag}</span> : null}
    </>
  )

  const renderSelectors = () => (
    <>
      <AmountContainer onClick={setFocusInput}>
        {focused ? (
          <Input
            focused={focused}
            onBlur={onBlurInput}
            ref={inputRef}
            type='number'
            value={inputValue}
            onChange={onChangeInput}
            onKeyDown={handleKeydown}
          />
        ) : (
          <LabelValue>
            <NumberFormat value={inputValue} suffix={measureUnit} />
          </LabelValue>
        )}
      </AmountContainer>
      {focused ? (
        <AcceptButton onClick={() => setFocused(false)}>
          <CheckIcon />
        </AcceptButton>
      ) : (
        <div style={{ display: 'flex' }}>
          <Selector onClick={onClickSubstract}>
            <DownIcon />
          </Selector>
          <Selector onClick={() => add(multiple)}>
            <UpIcon />
          </Selector>
        </div>
      )}
    </>
  )

  const hasReplace = (alternativeReference && alternativeReference !== '') || false
  const willPurchase = inCart != null

  const onRemove = e => {
    e.stopPropagation()
    return setToZero()
  }

  const showUnavailableOptions = (unavailableCopy, reverse) => (
    <ButtonCartRemove reverse={reverse} onClick={onRemove}>
      <span>{unavailableCopy}</span>
      <RemoveButton reverse={reverse}>{t('delete')}</RemoveButton>
    </ButtonCartRemove>
  )

  const showSimpleCartButton = () => (
    <ButtonCart onClick={onClickAdd} hasAmount={!!amount} error={error} focused={focused}>
      {!amount ? renderContent() : renderSelectors()}
      {amount ? (
        <ActionEraseButton onClick={onRemove}>
          <Trash className='trash' width={22} fill='#fff' />
        </ActionEraseButton>
      ) : null}
    </ButtonCart>
  )

  const showButton = () => {
    if (availability) {
      if (status === AVAILABILITY_STATES.AVAILABLE) {
        return showSimpleCartButton()
      }

      if (status === AVAILABILITY_STATES.UNAVAILABLE) {
        //not available any more
        if (willPurchase || hasReplace) {
          return showUnavailableOptions(t('deleted'))
        }
        return <ButtonCart className='disabled'>{renderContent(t('deleted'), '', 'unavailable')}</ButtonCart>
      }

      if (status === AVAILABILITY_STATES.NEXT) {
        if (willPurchase || hasReplace) {
          return showUnavailableOptions(t('nextAvailability'))
        }

        return <ButtonCart className='disabled'>{renderContent(t('nextAvailability'), '', 'unavailable')}</ButtonCart>
      }

      if (status === AVAILABILITY_STATES.NOT_STOCK) {
        if (!hasReplace && willPurchase && amount > 0) {
          return (
            <ButtonNotAvailable>
              <Unavailable>
                {availability && availability.status === AVAILABILITY_STATES.NOT_STOCK && t('noItems')}
                {availability && availability.status === AVAILABILITY_STATES.UNAVAILABLE && t('deleted')}
              </Unavailable>
              <EraseButton onClick={setToZero}>
                <Trash className='trash' width={22} fill='#fff' /> {t('delete')}
              </EraseButton>
            </ButtonNotAvailable>
          )
        }
        if (willPurchase || hasReplace) {
          return showUnavailableOptions(t('noItems'))
        }

        return <ButtonCart className='disabled'>{renderContent(t('noItems'), '', 'unavailable')}</ButtonCart>
      }

      if (status === AVAILABILITY_STATES.NEW) {
        return showSimpleCartButton()
      }

      if (status === AVAILABILITY_STATES.LAST_UNITS) {
        return showSimpleCartButton()
      }
    }
    return showSimpleCartButton()
  }

  return <MainContainer>{showButton()}</MainContainer>
})

ProductButtonApprover.defaultProps = {
  name: '',
  provider: {},
  price: 0,
  min: 0,
  multiple: 0,
  measureUnit: '',
  pricePerKg: 0,
  id: '',
  rawMaterial: 'food',
  notAvailable: false,
  reference: '',
  setOnConfirmProduct: () => null,
  setShowModalConfirmationProduct: () => null,
  availability: null,
  innerRef: null,
  onNext: () => null,
  onPrevious: () => null,
}

ProductButtonApprover.propTypes = {
  name: PropTypes.string,
  provider: PropTypes.object,
  price: PropTypes.number,
  min: PropTypes.number,
  multiple: PropTypes.number,
  measureUnit: PropTypes.string,
  pricePerKg: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rawMaterial: PropTypes.string,
  notAvailable: PropTypes.bool,
  reference: PropTypes.string,
  setOnConfirmProduct: PropTypes.func,
  setShowModalConfirmationProduct: PropTypes.func,
  availability: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
}

export default ProductButtonApprover
