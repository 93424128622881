import React, { useCallback } from 'react'

import MuiButton from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import routes from 'Config/routes'
import { ReactComponent as ArrowLeft } from 'Assets/Icons/ArrowLeft.svg'
import { selectCenter } from 'Redux/multicenter/selectors'

import TicketingDetail from '../ticketingDetail'

import * as S from './styled'

const MulticenterTicketingDetail = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const center = useSelector(selectCenter)

  const handleBackClick = useCallback(() => {
    history.goBack()
  }, [history])

  if (!center) {
    return <Redirect to={routes.multicenterTicketing} />
  }

  return (
    <S.TicketingDetailModal isOpen width='100vw' height='100vh' maxHeight='unset' showClose={false}>
      <div className='modal-header'>
        <MuiButton onClick={handleBackClick} startIcon={<ArrowLeft width={16} height={16} />} color='inherit'>
          {t`back`}
        </MuiButton>
      </div>
      <div className='modal-content'>
        <TicketingDetail />
      </div>
    </S.TicketingDetailModal>
  )
}

export default MulticenterTicketingDetail
