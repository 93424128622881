import React, { useState, useEffect, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { PurchaseTypes } from 'Redux/purchases'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import get from 'lodash/get'
import find from 'lodash/find'
import moment from 'moment'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import * as Sentry from 'Services/sentry'
import { ReactComponent as Cart } from 'Assets/Icons/Cart.svg'
import { ReactComponent as EmptyCart } from 'Assets/Icons/EmptyCart.svg'
import { ReactComponent as CheckQA } from 'Assets/Icons/CheckQA.svg'
import { ReactComponent as Download } from 'Assets/Icons/Descargar.svg'
import { ReactComponent as Recepcionado } from 'Assets/Icons/EstadoRecepcionado.svg'
import { ReactComponent as ComplaintIcon } from 'Assets/Icons/Complaint.svg'
import { ReactComponent as Cancelado } from 'Assets/Icons/EstadoCancelado.svg'
import { ReactComponent as PorRecepcionar } from 'Assets/Icons/EstadoPorRecepcionar.svg'
import { ReactComponent as Tramitado } from 'Assets/Icons/EstadoTramitado.svg'
import { ReactComponent as NoDefinido } from 'Assets/Icons/EstadoNoDefinido.svg'
import { ReactComponent as Approval } from 'Assets/Icons/Approval.svg'
import ArrowDropdown from 'Assets/Icons/ArrowDropdown'
import { getDeliveryQa, getDeliveryQaExcel, getOrderProducts, getProviderOrdersExcel } from 'Services/api'
import { ORDER_STATUS, ROLES, ORDER_QUALITY_STATUS, PROVIDER_ORDERS_MODIFICATION_TYPE } from 'Config/constants'
import routes from 'Config/routes'
import colors from 'Assets/colors'
import { ModalRepeatOrder, ModalQAForm } from 'Components/molecules'
import { Modal, NumberFormat, ProductButton } from 'Components/atoms'
import useProductButton from 'Components/utils/useProductButton'
import { financial } from 'Components/utils/cartCalculator'
import ProductInfoLoader from 'Components/atoms/productInfoLoader'
import ProductNameLoader from 'Components/atoms/productNameLoader'
import ProductActionLoader from 'Components/atoms/productActionLoader'
import ActionButton from 'Components/atoms/actionButton'
import ModalDownloadEmail from 'Components/molecules/modalDownloadEmail'

import {
  ProductsContainer,
  TableHeaderRow,
  TableHeaderItem,
  SingleProductRow,
  SingleProductRowCL,
  SingleProductNameHelper,
  SingleProductName,
  SingleProductPriceContainer,
  SingleProductQtyContainer,
  SingleProductTotalPrice,
  SingleProductTotalPriceSplit,
  SingleProductTotalQty,
  SingleProductButtonContainer,
  Price,
  PriceKG,
  MinAndMulti,
  RequestProviderInfoContainer,
  RequestProviderInfo,
  RequestProviderName,
  RequestProviderDeliveryDate,
  RequestProviderOrderNumber,
  ProviderDetail,
  TotalPrice,
  TotalProductLines,
  RequestOrderContainer,
  RequestRepeatOrderContainer,
  RequestActionContainer,
  RequestRepeatOrder,
  RequestActionOrderContainer,
  RequestActionOrder,
  HelpText,
  NotAvailable,
  NotAvailableLiteral,
  ShowProductsContainer,
  ShowMoreCopy,
  ShowLessCopy,
  useStyles,
  RequestActionButtonsContainer,
} from './styled'

const HistoryOrderProduct = ({ centerId, order }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { onNext, onPreviuos, setRef } = useProductButton()

  const [products, setProducts] = useState(null)
  const [totalPrice, setTotalPrice] = useState(null)
  const [totalProducts, setTotalProducts] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [productsLoading, setProductsLoading] = useState(false)
  const [openProducts, setOpenProducts] = useState(false)
  const [showQAModal, setShowQAModal] = useState(false)
  const [qaData, setQaData] = useState({})
  const [showDownloadQaExcelModal, setShowDownloadQaExcelModal] = useState(false)
  const [showDownloadProviderOrdersExcelModal, setShowDownloadProviderOrdersExcelModal] = useState(false)

  const user = useSelector(state => state.auth.user)
  const center = useSelector(state => state.auth.selectedCenter)
  const role = find(user.roles, elem => elem !== 'ROLE_USER')

  const classes = useStyles()

  const { showPrice } = center

  useEffect(() => {
    if (openProducts) {
      setProductsLoading(true)
      getOrderProducts({}, centerId, order.id, order.documentTypeCode, order.documentCompany)
        .then(response => {
          setProducts(response)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('ERROR', error)
        })
        .finally(() => setProductsLoading(false))
    } else {
      setProducts(null)
    }
  }, [openProducts, centerId, order])

  useEffect(() => {
    if (products) {
      let total = 0
      get(products, 'lines', []).map(product => {
        const { price } = product
        total += price
        return null
      })
      setTotalPrice(total)
      setTotalProducts(get(products, 'lines', []).length)
    }
  }, [products])

  const onShowQA = useCallback(() => {
    getDeliveryQa(order.id, order.documentTypeCode, order.documentCompany, centerId)
      .then(response => {
        setQaData(response)
        setShowQAModal(true)
      })
      .catch(() => toast.error(t('petitionError')))
  }, [centerId, order.documentCompany, order.documentTypeCode, order.id, t])

  const onDownloadQA = useCallback(() => {
    getDeliveryQaExcel(order.id, order.documentTypeCode, order.documentCompany, centerId)
      .then(() => setShowDownloadQaExcelModal(true))
      .catch(e => {
        toast.error(t('petitionError'))
        Sentry.captureException(e)
      })
  }, [centerId, order.documentCompany, order.documentTypeCode, order.id, t])

  const OrderState = ({ state }) => {
    const stateProps = {
      [ORDER_STATUS.PR]: {
        icon: <PorRecepcionar style={{ fill: colors.red }} />,
        copy: t('orderStatusPR'),
        copyStef: t('orderStatusPRStef'),
        link: `/delivery/${order.id}/${order.documentTypeCode}/${order.documentCompany}/qa`,
      },
      [ORDER_STATUS.RC]: {
        icon: <Recepcionado style={{ fill: colors.secondary }} />,
        copy: t('orderStatusRC'),
      },
      [ORDER_STATUS.PE]: {
        icon: <Tramitado />,
        copy: t('orderStatusPE'),
      },
      [ORDER_STATUS.CN]: {
        icon: <Cancelado style={{ fill: colors.secondary }} />,
        copy: t('orderStatusCN'),
      },
      [ORDER_STATUS.OT]: {
        icon: <NoDefinido style={{ fill: colors.gray40l }} />,
      },
    }
    const { icon, copy, copyStef, link } = stateProps[state] || { icon: null, copy: '', copyStef: '', link: null }

    const ActionOrder = () => (
      <RequestActionOrderContainer stateProp={state} role={ROLES[role]} openProducts={openProducts[order.id]}>
        <RequestActionOrder stateProp={state}>
          {icon}
          {t(`orderStatus.${state}`)}
        </RequestActionOrder>
        {state !== ORDER_STATUS.RC || order.receptionFeedbackStatus === ORDER_QUALITY_STATUS.SC ? (
          <HelpText>{ROLES[role] ? copyStef : copy}</HelpText>
        ) : (
          <RequestActionButtonsContainer>
            <Button endIcon={<CheckQA />} className={classes.seeQA} onClick={onShowQA}>
              {t('seeQA')}
            </Button>
            <Button endIcon={<Download />} className={classes.seeQA} onClick={onDownloadQA}>
              {t('downloadQA')}
            </Button>
          </RequestActionButtonsContainer>
        )}
      </RequestActionOrderContainer>
    )
    if (link && !ROLES[role]) {
      return (
        <Link to={link} className='link'>
          <ActionOrder />
        </Link>
      )
    }
    return <ActionOrder />
  }

  const handleClickExcel = () => {
    getProviderOrdersExcel({}, centerId, order.id, order.documentTypeCode, order.documentCompany)
      .then(() => setShowDownloadProviderOrdersExcelModal(true))
      .catch(e => {
        toast.error(t('petitionError'))
        Sentry.captureException(e)
      })
  }

  const handleClickOpenCarts = () => {
    const requestOrderId = order.parentRequest
    history.push(routes.addCartsId(requestOrderId))
  }

  const handleClickRepeatOrder = () => {
    dispatch({
      type: PurchaseTypes.FETCH_REPEAT_ORDER,
      params: {
        orderId: order.id,
        documentTypeCode: order.documentTypeCode,
        documentCompany: order.documentCompany,
      },
    })
    setShowModal(false)
  }

  const handleClickOpenNoConformities = () => {
    const providerId = order.provider.id
    history.push(routes.addTicketing(providerId, order.id, order.documentTypeCode, order.documentCompany))
  }

  const onFilterClick = () => {
    setShowModal(true)
  }

  const handleNewOrder = () => {
    dispatch({ type: PurchaseTypes.FETCH_PREVIUS_ORDER_PROVIDERS, params: { order } })
    history.push(routes.previusOrder)
  }

  const handleChange = () => {
    setShowModal(false)
  }

  const ProductLinesPrice = () => {
    const textProduct =
      totalProducts === 1
        ? `${Math.abs(totalProducts)} ${t('product').toLowerCase()}`
        : `${Math.abs(totalProducts)} ${t('products').toLowerCase()}`
    return <TotalProductLines>{totalProducts ? textProduct : ' '}</TotalProductLines>
  }

  const handleClickSeeProducts = id => setOpenProducts({ ...openProducts, [id]: !openProducts[id] })

  const totalToShow = !totalPrice && order ? order.total : totalPrice

  return (
    <>
      <RequestProviderInfoContainer>
        <RequestProviderInfo>
          <RequestProviderName>{get(order, 'provider.name')}</RequestProviderName>
          <RequestProviderDeliveryDate>
            {t('historyDeliveryDate')} {moment(order.delivery).format('DD/MM/YYYY')}
          </RequestProviderDeliveryDate>
          <RequestProviderOrderNumber>
            {t('order')} {order.id}
          </RequestProviderOrderNumber>
        </RequestProviderInfo>
        <ProviderDetail>
          {totalToShow && showPrice ? (
            <TotalPrice>
              <NumberFormat value={totalToShow} />
            </TotalPrice>
          ) : null}
          <ProductLinesPrice />
          <ShowProductsContainer onClick={() => handleClickSeeProducts(order.id)}>
            <ArrowDropdown
              fill={openProducts[order.id] ? colors.secondary : colors.black}
              style={{ margin: '0 0.5rem', transform: openProducts[order.id] ? 'rotate(180deg)' : 'none' }}
            />
            {openProducts[order.id] ? (
              <ShowLessCopy>{t('contractProducts')}</ShowLessCopy>
            ) : (
              <ShowMoreCopy>{t('seeProducts')}</ShowMoreCopy>
            )}
          </ShowProductsContainer>
        </ProviderDetail>
      </RequestProviderInfoContainer>
      <RequestOrderContainer openProducts={openProducts[order.id]}>
        <RequestRepeatOrderContainer>
          <ActionButton
            onClick={onFilterClick}
            buttonText={t('repeatOrder')}
            helpText={t('repeatOrderHelpText')}
            Icon={Cart}
          />
          <ActionButton
            onClick={handleNewOrder}
            buttonText={t('newOrder')}
            helpText={t('newOrderHelp')}
            Icon={EmptyCart}
          />
          <ActionButton
            onClick={handleClickExcel}
            buttonText={t('downloadOrder')}
            helpText={t('downloadOrderHelpText')}
            Icon={Download}
          />
          {ROLES.ROLE_STEF !== ROLES[role] && order.state === ORDER_STATUS.RC && (
            <ActionButton
              onClick={handleClickOpenNoConformities}
              buttonText={t('openNoConformities')}
              helpText={t('openNoConformitiesHelpText')}
              Icon={ComplaintIcon}
            />
          )}
          {order.parentRequest && (
            <SeeApprovedRequestAction onClick={handleClickOpenCarts} type={order.modificationType} />
          )}
        </RequestRepeatOrderContainer>
        <OrderState state={order.state} />
      </RequestOrderContainer>

      {openProducts[order.id] && (
        <ProductsContainer>
          {products && (
            <TableHeaderRow>
              <TableHeaderItem>{t('products')}</TableHeaderItem>
              {showPrice && <TableHeaderItem>{t('sum')}</TableHeaderItem>}
              <TableHeaderItem>{t('quantity')}</TableHeaderItem>
            </TableHeaderRow>
          )}
          {productsLoading && !products && (
            <SingleProductRowCL>
              <SingleProductNameHelper>
                <ProductNameLoader />
              </SingleProductNameHelper>
              <SingleProductPriceContainer>
                <ProductInfoLoader />
              </SingleProductPriceContainer>
              <SingleProductQtyContainer>
                <ProductInfoLoader />
              </SingleProductQtyContainer>
              <SingleProductButtonContainer>
                <ProductActionLoader />
              </SingleProductButtonContainer>
            </SingleProductRowCL>
          )}
          {products &&
            get(products, 'lines', []).map((product, index) => {
              const { name, price, measureUnit, amount, priceUnit, product: productData } = product
              return (
                <SingleProductRow withPrice={showPrice}>
                  <SingleProductNameHelper>
                    <SingleProductName>{name}</SingleProductName>
                  </SingleProductNameHelper>
                  {showPrice && (
                    <SingleProductPriceContainer>
                      <SingleProductTotalPrice>
                        <NumberFormat value={price} />
                      </SingleProductTotalPrice>
                      <SingleProductTotalPriceSplit>
                        <Price>
                          <NumberFormat value={priceUnit} suffix={`€/${measureUnit}`} />
                        </Price>
                        <PriceKG />
                      </SingleProductTotalPriceSplit>
                    </SingleProductPriceContainer>
                  )}
                  <SingleProductQtyContainer>
                    <SingleProductTotalQty>
                      {amount} {measureUnit}
                    </SingleProductTotalQty>
                    <div style={{ display: 'flex' }}>
                      {productData && (
                        <MinAndMulti>
                          {`${t('min')}: ${financial(productData.min)} / ${t('multi')}: ${financial(
                            productData.multiple
                          )}`}
                        </MinAndMulti>
                      )}
                    </div>
                  </SingleProductQtyContainer>
                  <SingleProductButtonContainer>
                    {productData ? (
                      <ProductButton
                        {...productData}
                        innerRef={element => setRef(element, index)}
                        onPrevious={id => onPreviuos(id, index)}
                        onNext={id => onNext(id, index)}
                      />
                    ) : (
                      <NotAvailable>
                        <NotAvailableLiteral>{t('notAvailable')}</NotAvailableLiteral>
                      </NotAvailable>
                    )}
                  </SingleProductButtonContainer>
                </SingleProductRow>
              )
            })}
        </ProductsContainer>
      )}
      <Modal isOpen={showModal} setIsOpen={setShowModal} width='680px' height='256px'>
        <ModalRepeatOrder
          color={colors.secondary}
          onChange={handleChange}
          setShowModal={setShowModal}
          repeatOrder={handleClickRepeatOrder}
          title={t('repeatOrder')}
          message={t('reapeatOrderModalText')}
        />
      </Modal>
      <ModalQAForm showModal={showQAModal} setShowModal={setShowQAModal} data={{ qaData }} orderNumber={order.id} />
      <ModalDownloadEmail
        setShowModal={() => setShowDownloadProviderOrdersExcelModal(false)}
        showModal={showDownloadProviderOrdersExcelModal}
        title={t('downloadOrdersExcelTitle')}
        description={t('downloadOrdersExcelDescription')}
      />
      <ModalDownloadEmail
        setShowModal={() => setShowDownloadQaExcelModal(false)}
        showModal={showDownloadQaExcelModal}
        title={t('downloadQaExcelTitle')}
        description={t('downloadQaExcelDescription')}
      />
    </>
  )
}

HistoryOrderProduct.propTypes = {
  centerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  order: PropTypes.object,
  isOpen: PropTypes.bool,
  state: PropTypes.object,
  price: PropTypes.number,
}

HistoryOrderProduct.defaultProps = {
  centerId: null,
  order: null,
  isOpen: false,
  state: null,
  price: null,
}

const SeeApprovedRequestAction = ({ onClick, type }) => {
  const { t } = useTranslation()

  const { MODIFIED, AUT } = PROVIDER_ORDERS_MODIFICATION_TYPE
  const isModified = type === MODIFIED
  const isAutomaticallyApproved = type === AUT

  let helperText = t('noModified')
  if (isModified) {
    helperText = t('modified')
  } else if (isAutomaticallyApproved) {
    helperText = t('isAutomaticallyApproved')
  }

  return (
    <RequestActionContainer>
      <RequestRepeatOrder>
        <Approval />
        <button type='button' onClick={onClick}>
          {t('requestOrderStatus.Aprobada')}
        </button>
      </RequestRepeatOrder>
      <HelpText bold={isModified}>{helperText}</HelpText>
    </RequestActionContainer>
  )
}

SeeApprovedRequestAction.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
}

SeeApprovedRequestAction.defaultProps = {
  onClick: () => null,
  type: '',
}

export default HistoryOrderProduct
