import styled from 'styled-components'

export const Cell = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;

  .hidden {
    visibility: hidden;
  }

  svg {
    width: 18px;
  }

  button {
    white-space: nowrap;
    font-size: 12px;
  }
`
