import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSelectedProvider, setModalProviderOpen } from 'Redux/purchases/actions'
import moment from 'moment'
import 'moment/locale/es'
import { HAS_CALENDAR } from 'Config/constants'
import Button from '@material-ui/core/Button'
import { ReactComponent as ProviderIcon } from 'Assets/Icons/Proveedor.svg'
import routes from 'Config/routes'

import { CardContainer, TextsContainer, Name, NextDeliveryDate, FormattedDate, useStyles } from './styled'

const Card = ({ name, id, refProp, nextDeliveryDate, calendar }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const onClickButton = e => {
    e.stopPropagation()
    history.push(`${routes.addProvider(id)}/category/all`)
  }

  const onClickProvider = () => {
    dispatch(setSelectedProvider({ name, id, nextDeliveryDate, calendar }))
    dispatch(setModalProviderOpen(true))
  }

  const formattedDate = moment(nextDeliveryDate).format('dddd, LL')

  return (
    <CardContainer ref={refProp} onClick={onClickProvider}>
      <TextsContainer>
        <Name>{name}</Name>
        {calendar === HAS_CALENDAR.YES && !nextDeliveryDate ? (
          <NextDeliveryDate>{t('noCalendar')}</NextDeliveryDate>
        ) : null}
        {nextDeliveryDate ? (
          <NextDeliveryDate>
            {t('nextDeliveryDate')}
            <FormattedDate>{formattedDate}</FormattedDate>
          </NextDeliveryDate>
        ) : null}
      </TextsContainer>
      <Button endIcon={<ProviderIcon />} className={classes.addCartButton} onClick={onClickButton}>
        {t('seeProducts')}
      </Button>
    </CardContainer>
  )
}

Card.defaultProps = {
  name: '',
  id: '',
  refProp: () => {},
  nextDeliveryDate: '',
  calendar: '',
}

Card.propTypes = {
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refProp: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  nextDeliveryDate: PropTypes.string,
  calendar: PropTypes.string,
}
export default Card
