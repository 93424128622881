import React from 'react'

import PropTypes from 'prop-types'
import { ReactComponent as ExclamationCircle } from 'Assets/Icons/ExclamationCircle.svg'

import { Row, Message } from './styled'

const CostMessage = ({ message }) => (
  <Row>
    <ExclamationCircle style={{ marginRight: '0.5rem' }} />
    <Message>{message}</Message>
  </Row>
)

CostMessage.defaultProps = {
  message: '',
}

CostMessage.propTypes = {
  message: PropTypes.string,
}

export default CostMessage
