export const FETCH_LISTS = 'portal_compras/lists/FETCH_LISTS'
export const SET_IS_FETCHING_LISTS = 'portal_compras/lists/SET_IS_FETCHING_LISTS'
export const SET_LISTS = 'portal_compras/lists/SET_LISTS'
export const ADD_LIST = 'portal_compras/lists/ADD_LIST'
export const SET_IS_FETCHING_ADD = 'portal_compras/lists/SET_IS_FETCHING_ADD'
export const SET_ERROR_ADD = 'portal_compras/lists/SET_ERROR_ADD'
export const REMOVE_LIST = 'portal_compras/lists/REMOVE_LIST'
export const FETCH_LIST_PRODUCTS = 'portal_compras/lists/FETCH_LIST_PRODUCTS'
export const SET_IS_FETCHING_LIST_PRODUCTS = 'portal_compras/lists/SET_IS_FETCHING_LIST_PRODUCTS'
export const SET_LIST_PRODUCTS = 'portal_compras/lists/SET_LIST_PRODUCTS'
export const SET_LIST_DATA = 'portal_compras/lists/SET_LIST_DATA'
export const REMOVE_PRODUCT = 'portal_compras/lists/REMOVE_PRODUCT'
export const UPDATE_TITLE = 'portal_compras/lists/UPDATE_TITLE'
export const SET_IS_FETCHING_UPDATE = 'portal_compras/lists/SET_IS_FETCHING_UPDATE'
export const SEND_CART = 'portal_compras/lists/SEND_CART'
export const SET_IS_SENDING_CART = 'portal_compras/lists/SET_IS_SENDING_CART'
export const UPDATE_PRODUCT = 'portal_compras/lists/UPDATE_PRODUCT'
export const ADD_PRODUCT = 'portal_compras/lists/ADD_PRODUCT'
