import React, { useEffect, useMemo, useState } from 'react'

import omitBy from 'lodash/omitBy'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import colors from 'Assets/colors'
import SearchIcon from 'Assets/Icons/Search'
import { TextInput, SelectInput } from 'Components/atoms'
import { AutocompleteInput, CalendarInput } from 'Components/molecules'
import { LIMIT_REQUEST_CENTERS, LIMIT_REQUEST_PROVIDERS, ORDER_STATUS, MIN_CHARS_FOR_SEARCH } from 'Config/constants'
import {
  fetchFilterCenters,
  fetchFilterOriginCenters,
  fetchFilterProviders,
  setFilterOriginCenters,
  setFilterCenters,
} from 'Redux/multicenter/actions'

import {
  Title,
  Message,
  FiltersContainer,
  FullWidthFilterItems,
  SplitWidthFilterItems,
  ItemContainer,
  InputLabel,
  CleanButton,
  ActionButton,
  ButtonLiteral,
} from './styled'

const ModalMulticenterOrderFilter = ({ initialFilters, message, title, onChange, disabledStatus }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [filters, setFilter] = useState(initialFilters)
  const [visible, setVisible] = useState({})

  const centersList = useSelector(({ multicenter }) => multicenter.filterCenters)
  const providers = useSelector(({ multicenter }) => multicenter.filterProviders)
  const originCenters = useSelector(({ multicenter }) => multicenter.filterOriginCenters)

  useEffect(() => {
    dispatch(fetchFilterOriginCenters({ limit: LIMIT_REQUEST_CENTERS }))
  }, [dispatch])

  const centers = useMemo(
    () =>
      centersList.map(center => ({
        ...center,
        name: `${center.name} - ${center.id}`,
      })),
    [centersList]
  )

  const handleFocus = id => {
    setVisible({ ...visible, [id]: true })
  }
  const handleBlur = id => {
    setVisible({ ...visible, [id]: false })
  }

  const handleChange = newfilters => {
    setFilter(omitBy(newfilters, e => !e))
  }

  const numFilters = myFilters => {
    const filtersLength = myFilters ? Object.keys(myFilters).length : 0
    const oneDateFilters = myFilters.startDate && myFilters.endtDate ? filtersLength - 1 : filtersLength
    return oneDateFilters
  }

  return (
    <>
      <Title>{title}</Title>
      <Message>{message}</Message>
      <FiltersContainer>
        <ItemContainer className='full-width'>
          <InputLabel>{t('center')}</InputLabel>
          <AutocompleteInput
            value={filters.targetCenter}
            onChange={valueInput => {
              handleChange({ ...filters, targetCenter: valueInput ? valueInput.id : null })
            }}
            onSearch={valueInput => {
              if (valueInput?.length >= MIN_CHARS_FOR_SEARCH || valueInput == null || valueInput?.length === 0) {
                dispatch(fetchFilterCenters({ search: valueInput, limit: LIMIT_REQUEST_CENTERS }))
              } else {
                dispatch(setFilterCenters([]))
              }
            }}
            choices={centers}
            placeholder={t('filterPlaceholderCenter')}
            topIcon='1.2rem'
            Icon={props => <SearchIcon fill={visible.center ? colors.secondary : colors.black} {...props} />}
            onFocus={() => handleFocus('center')}
            onBlur={() => handleBlur('center')}
            borderRadius={visible.center ? '8px 8px 0 0' : '8px'}
            background={visible.center ? colors.white : '#F4F1F1'}
          />
        </ItemContainer>
        <SplitWidthFilterItems>
          <ItemContainer>
            <InputLabel>{t('filterLabelDate')}</InputLabel>
            <CalendarInput
              label={t('filterPlaceholderDate')}
              onRemove={() => {
                const { startDate, endDate, ...restFilters } = filters
                handleChange(restFilters)
              }}
              value={filters.startDate && filters.endDate ? [filters.startDate, filters.endDate] : null}
              onChange={valueInput => handleChange({ ...filters, ...valueInput })}
              selectRange
            />
          </ItemContainer>

          <ItemContainer>
            <InputLabel>{t('filterLabelState')}</InputLabel>
            <SelectInput
              placeholder={t('filterPlaceholderState')}
              id='status'
              value={filters.status}
              onFocus={() => handleFocus('status')}
              onBlur={() => handleBlur('status')}
              onChange={e => handleChange({ ...filters, status: e.target.value })}
              background={visible.status ? colors.white : '#F4F1F1'}
              borderRadius={visible.status ? '8px 8px 0 0' : '8px'}
              boxShadow={visible.status ? '0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)' : 'none'}
              opacity={visible.status ? '0' : '1'}
              choices={
                disabledStatus
                  ? [{ id: ORDER_STATUS.RC, name: t(`orderStatus.${ORDER_STATUS.RC}`) }]
                  : [
                      { id: null, name: null },
                      { id: ORDER_STATUS.PR, name: t(`orderStatus.${ORDER_STATUS.PR}`) },
                      { id: ORDER_STATUS.RC, name: t(`orderStatus.${ORDER_STATUS.RC}`) },
                      { id: ORDER_STATUS.PE, name: t(`orderStatus.${ORDER_STATUS.PE}`) },
                      { id: ORDER_STATUS.CN, name: t(`orderStatus.${ORDER_STATUS.CN}`) },
                    ]
              }
            />
          </ItemContainer>
        </SplitWidthFilterItems>
        <SplitWidthFilterItems>
          <ItemContainer>
            <InputLabel>{t('filterLabelProvider')}</InputLabel>
            <AutocompleteInput
              value={filters.provider}
              onChange={valueInput => {
                handleChange({ ...filters, provider: valueInput ? valueInput.id : null })
              }}
              onSearch={valueInput =>
                dispatch(fetchFilterProviders({ search: valueInput, limit: LIMIT_REQUEST_PROVIDERS }))
              }
              choices={providers}
              placeholder={t('filterPlaceholderProvider')}
              topIcon='1.2rem'
              Icon={props => <SearchIcon fill={visible.provider ? colors.secondary : colors.black} {...props} />}
              onFocus={() => handleFocus('provider')}
              onBlur={() => handleBlur('provider')}
              borderRadius={visible.provider ? '8px 8px 0 0' : '8px'}
              background={visible.provider ? colors.white : '#F4F1F1'}
            />
          </ItemContainer>
          <ItemContainer>
            <InputLabel>{t('filterLabelOrigin')}</InputLabel>
            <AutocompleteInput
              value={filters.origin}
              onChange={valueInput => {
                handleChange({ ...filters, origin: valueInput ? valueInput.id : null })
              }}
              onSearch={valueInput => {
                if (valueInput?.length >= MIN_CHARS_FOR_SEARCH || valueInput == null || valueInput?.length === 0) {
                  dispatch(fetchFilterOriginCenters({ search: valueInput, limit: LIMIT_REQUEST_CENTERS }))
                } else {
                  dispatch(setFilterOriginCenters([]))
                }
              }}
              choices={originCenters}
              placeholder={t('filterPlaceholderSelectOrigin')}
              topIcon='1.2rem'
              Icon={props => <SearchIcon fill={visible.origin ? colors.secondary : colors.black} {...props} />}
              onFocus={() => handleFocus('origin')}
              onBlur={() => handleBlur('origin')}
              borderRadius={visible.origin ? '8px 8px 0 0' : '8px'}
              background={visible.origin ? colors.white : '#F4F1F1'}
            />
          </ItemContainer>
        </SplitWidthFilterItems>
        <SplitWidthFilterItems>
          <ItemContainer>
            <InputLabel>
              {t('filterLabelOrderNumber')} {t('filterLabelOrderNumberRes')}
            </InputLabel>
            <TextInput
              value={filters.id}
              onChange={e => {
                const { value: inputValue } = e.target
                const numeric = inputValue.replace(/\D/g, '').slice(0, 8)
                handleChange({ ...filters, id: numeric })
              }}
              onFocus={() => handleFocus('id')}
              onBlur={() => handleBlur('id')}
              placeholder={t('filterPlaceholderOrderNumber')}
              background={visible.id ? colors.white : '#F4F1F1'}
              borderRadius='8px'
            />
          </ItemContainer>
          <ItemContainer>
            <InputLabel>{t('filterLabelRequestNumber')}</InputLabel>
            <TextInput
              value={filters.request}
              onChange={e => handleChange({ ...filters, request: e.target.value })}
              onFocus={() => handleFocus('request')}
              onBlur={() => handleBlur('request')}
              placeholder={t('filterPlaceholderRequestNumber')}
              background={visible.request ? colors.white : '#F4F1F1'}
              borderRadius='8px'
            />
          </ItemContainer>
        </SplitWidthFilterItems>
        {!disabledStatus && (
          <FullWidthFilterItems onClick={() => setFilter({})}>
            <CleanButton disabled={numFilters(filters) <= 0}>
              <ButtonLiteral>{t('filterButtonClean')}</ButtonLiteral>
            </CleanButton>
          </FullWidthFilterItems>
        )}
        <FullWidthFilterItems>
          <ActionButton onClick={() => onChange(filters)}>
            <ButtonLiteral>{!disabledStatus ? t('filterButtonSearch') : t('download')}</ButtonLiteral>
          </ActionButton>
        </FullWidthFilterItems>
      </FiltersContainer>
    </>
  )
}

ModalMulticenterOrderFilter.propTypes = {
  initialFilters: PropTypes.object,
  message: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  disabledStatus: PropTypes.bool,
}

ModalMulticenterOrderFilter.defaultProps = {
  initialFilters: {},
  message: '',
  title: '',
  onChange: () => {},
  disabledStatus: false,
}

export default ModalMulticenterOrderFilter
