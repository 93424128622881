import { SET_ANNOUNCEMENTS_ERROR, SET_ANNOUNCEMENTS_LIST, SET_ANNOUNCEMENTS_IS_LOADING } from './types'

const initialState = {
  announcementsList: [],
  announcementsListCount: 0,
  isLoading: false,
  error: null,
}

const Reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ANNOUNCEMENTS_ERROR:
      return { ...state, error: action.payload }
    case SET_ANNOUNCEMENTS_IS_LOADING:
      return { ...state, isLoading: action.payload }
    case SET_ANNOUNCEMENTS_LIST:
      return { ...state, announcementsList: action.payload.list, announcementsListCount: action.payload.count }
    default:
      return state
  }
}

export default Reducer
