import React from 'react'

import { useTranslation } from 'react-i18next'
import ContentLoader from 'react-content-loader'

const RequestOrderLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width='100%'
      height={210}
      viewBox='0 0 100% 210'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='30' y='30' rx='10' ry='10' width='97%' height='30' />
      <rect x='30' y='40' rx='0' ry='1' width='101' height='10' />
      <rect x='3%' y='90' rx='0' ry='0' width='20%' height='20' />
      <rect x='33%' y='90' rx='6' ry='6' width='10%' height='20' />
      <rect x='66%' y='90' rx='0' ry='0' width='20%' height='20' />
      <rect x='3%' y='140' rx='0' ry='0' width='12%' height='20' />
      <rect x='33%' y='140' rx='6' ry='6' width='17%' height='20' />
      <rect x='66%' y='140' rx='0' ry='0' width='6%' height='20' />
    </ContentLoader>
  )
}

export default RequestOrderLoader
