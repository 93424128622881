/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */

import axios from 'axios'
import debounce from 'debounce-promise'
import jwt_decode from 'jwt-decode'
import { BASE_URL } from 'Config/api'
import routes from 'Config/routes'
import { TUTORIAL_MODE } from 'Config/constants'
import { setRefreshToken, setToken } from 'Redux/auth/actions'

import { LOGIN_URL, LOGIN_V2_URL, refreshAccessToken } from './auth'

const TIME_UNTIL_REFRESH = 30000 // 30s in millis
const REFRESH_EXPIRATION = 3540000 // 59min in millis (after token expiration)

let requestsToRefresh = []
let isRefreshRequesting = false

axios.defaults.baseURL = BASE_URL

// TUTORIAL
axios.interceptors.request.use(
  config => {
    const mode = sessionStorage.getItem('mode')
    if (mode === 'demo') {
      config.headers['x-demo-mode'] = TUTORIAL_MODE
    }
    return config
  },
  error => error
)

export const configureToken = (token = false, refreshToken = undefined, user = localStorage.getItem('username')) => {
  if (token) {
    localStorage.setItem('token', token)
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken)
  }
  if (user) {
    localStorage.setItem('username', user)
  }
}

export const resetApp = async () => {
  localStorage.clear()

  requestsToRefresh.forEach(cb => cb(null, true))

  return import('Config/redux')
    .then(({ persistor }) => persistor.purge())
    .finally(() => window.location.replace(routes.root))
}

const debouncedReset = debounce(resetApp, 100)

const cancelRequest = config => {
  const controller = new AbortController()
  controller.abort()

  return {
    ...config,
    signal: controller.signal,
  }
}

const queueRefreshRequest = (config, responseError) =>
  new Promise((resolve, reject) => {
    requestsToRefresh.push((token, shouldCancel = false) => {
      if (shouldCancel) {
        resolve(cancelRequest(config))
      } else if (token) {
        config.headers.Authorization = `Bearer ${token}`
        resolve(responseError ? axios(config) : config)
      } else {
        reject(responseError)
      }
    })
  })

// Refresh Token logic (Multiple requests)
const refreshTokenFlow = async (config, responseError) => {
  const refreshToken = localStorage.getItem('refreshToken')
  if (!refreshToken) {
    debouncedReset()
    return cancelRequest(config)
  }

  if (!isRefreshRequesting) {
    isRefreshRequesting = true

    refreshAccessToken()
      .then(newToken => {
        if (!newToken?.token) {
          return debouncedReset()
        }

        // Update redux store
        import('Config/redux').then(({ store }) => {
          store.dispatch(setToken(newToken.token))
          store.dispatch(setRefreshToken(newToken.refresh_token))
        })

        // Update localStorage
        configureToken(newToken.token, newToken.refresh_token)

        // Update and make queued requests
        requestsToRefresh.forEach(cb => cb(newToken.token))

        return Promise.resolve()
      })
      .catch(debouncedReset)
      .finally(() => {
        // Reset refesh vars
        requestsToRefresh = []
        isRefreshRequesting = false
      })
  }

  // Queue other concurrent requests to execute later
  return queueRefreshRequest(config, responseError)
}

// Set header token in all requests
axios.interceptors.request.use(
  config => {
    if (isRefreshRequesting) {
      return refreshTokenFlow(config)
    }

    const token = localStorage.getItem('token')
    if (token) {
      const { exp } = jwt_decode(token)
      const timeUntilExp = exp * 1000 - Date.now()
      if (timeUntilExp < TIME_UNTIL_REFRESH) {
        if (Math.abs(timeUntilExp) > REFRESH_EXPIRATION) {
          debouncedReset()
          return cancelRequest(config)
        }

        return refreshTokenFlow(config)
      }

      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  error => Promise.reject(error)
)

axios.interceptors.response.use(
  response => (response?.data ? response.data : null),
  error => {
    // Check if error is in login process
    const url = error?.response?.config?.url
    if (url === LOGIN_V2_URL || url === LOGIN_URL) {
      return Promise.reject(error)
    }

    // Check UnAuthorized error
    const status = error?.response?.status
    if (status !== 401 && status !== 403) {
      return Promise.reject(error)
    }

    // Check session refresh token
    return refreshTokenFlow(error?.response?.config, error)
  }
)

export * from './shoppingLists'
export * from './approver'
export * from './requestOrders'
export * from './habituals'
export * from './providerOrders'
export * from './announcement'
export * from './nonConformities'
export * from './providerReceptionOrders'
export * from './order'
export * from './providers'
export * from './centers'
export * from './products'
export * from './search'
export * from './categories'
export * from './auth'
export * from './multicenter'
