import { useCallback, useMemo, useRef, useState } from 'react'

const useInfiniteScroll = (currentPageCount, pageSize) => {
  const [page, setPage] = useState(0)
  const [intersectElementNode, setIntersectElementNode] = useState()
  const observer = useRef()

  const hasMore = useMemo(() => currentPageCount >= pageSize, [currentPageCount, pageSize])

  const intersectElementRef = useCallback(
    node => {
      setIntersectElementNode(node)
      if (!currentPageCount) {
        return
      }

      if (observer.current) {
        observer.current.disconnect()
      }

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(prev => prev + 1)
        }
      })

      if (node) {
        observer.current.observe(node)
      }
    },
    [currentPageCount, hasMore, setIntersectElementNode]
  )

  const resetPagination = useCallback(() => {
    if (intersectElementNode?.parentElement) {
      intersectElementNode.parentElement.scrollTo(0, 0)
    }
    setPage(0)
  }, [intersectElementNode])

  return { page, intersectElementRef, resetPagination }
}

export default useInfiniteScroll
