import React, { useEffect, useCallback, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { ApproverActions } from 'Redux/approver'
import ModalCenter from 'Components/molecules/modalCenter'

import useSummaryList from './use-summary-list'
import SummaryListView from './view'
import NotCenterView from './not-center-view'

const SummaryList = () => {
  const dispatch = useDispatch()

  const summaryCenter = useSelector(state => state.approver.summaryCenter)
  const isFetching = useSelector(state => state.approver.isFetchingSummary)

  const [weekFirstDate, setWeekFirstDate] = useState(moment().startOf('isoweek').toDate())
  const [showModal, setShowModal] = useState(false)

  const { summaryGroupedList, summarySpend } = useSummaryList()

  const handleSelectCenter = useCallback(
    center => {
      dispatch(ApproverActions.setSummaryCenter(center))
    },
    [dispatch]
  )

  useEffect(() => {
    if (summaryCenter.id) {
      setShowModal(false)
    }
  }, [summaryCenter])

  const handleShowModal = useCallback(value => {
    setShowModal(value)
  }, [])

  React.useEffect(() => {
    if (summaryCenter?.id) {
      dispatch(
        ApproverActions.fetchWeekSummary(
          summaryCenter?.id,
          moment(weekFirstDate).format('yyyy/MM/DD'),
          moment(weekFirstDate).add(6, 'd').format('yyyy/MM/DD')
        )
      )
    }
  }, [dispatch, summaryCenter.id, weekFirstDate])

  return (
    <>
      {summaryCenter?.id ? (
        <SummaryListView
          summaryList={summaryGroupedList}
          onSelectCenter={handleSelectCenter}
          summaryCenter={summaryCenter}
          summarySpend={summarySpend}
          weekFirstDate={weekFirstDate}
          onChangeWeek={setWeekFirstDate}
          isFetching={isFetching}
        />
      ) : (
        <NotCenterView onSelect={() => handleShowModal(true)} />
      )}
      {showModal && (
        <ModalCenter
          showModal={showModal}
          onSelectCenter={handleSelectCenter}
          showSubtitle='summary.search'
          title='summary.selectCenter'
          onClose={() => setShowModal(false)}
          summaryCenter={summaryCenter}
        />
      )}
    </>
  )
}

export default SummaryList
