import { ANALYTICS_EVENT } from './constants'
import { sendAnalytics, valueFormatter } from './utils'

const removeFromCart = ({ product, selectedCategories }) => {
  if (!selectedCategories || !product) return

  sendAnalytics(ANALYTICS_EVENT.REMOVE_FROM_CART, {
    type_remove_from_cart: 'eliminar',
    value: valueFormatter(product.price),
    currency: 'EUR',
    items: [
      {
        item_list_name: undefined,
        item_list_id: undefined,
        item_name: product.name,
        item_id: product.id,
        item_category: product?.category?.name,
        item_category2: product?.category?.children?.[0]?.name,
        item_category3: product?.category?.children?.[0]?.children?.[0]?.name,
        item_category4: product?.temperature,
        price: valueFormatter(product.price),
        currency: 'EUR',
        affiliation: `${product?.provider?.name} - ${product?.provider?.id}`,
        quantity: product.amount,
      },
    ],
  })
}

export default removeFromCart
