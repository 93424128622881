import styled from 'styled-components'

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
  cursor: pointer;
  padding: 8px 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  margin: 0;

  &.squared {
    border-radius: 8px;
  }

  &:disabled {
    pointer-events: none;
    cursor: initial;

    * {
      color: initial;
      opacity: 0.6;
    }
  }
`

export default StyledButton
