import styled, { css } from 'styled-components'
import colors from 'Assets/colors'

export const Container = styled.div`
  padding: 2.5rem 1.25rem 9rem;
  height: calc(100vh - 64px);
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Content = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 40px 80px;
  width: 920px;
  margin: 40px auto;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.black};
  margin-bottom: 16px;
  text-align: center;
`

export const SubTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  text-align: center;
`

export const RadioContainer = styled.div`
  padding: 24px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${props =>
    props.showBottomBorder &&
    css`
      border-bottom: showBottomBorder 1px solid rgba(51, 51, 51, 0.24);
    `}
`

export const SingleRadioContainer = styled.div`
  padding: 12px 0;
`

export const InputContainer = styled.div`
  padding: 24px 0;
  display: grid;
  grid-template-columns: 30% 63%;
  grid-column-gap: 6%;
`

export const InputLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 8px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled.div`
  width: 100%;
  background-color: ${colors.secondary};
  padding: 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const ButtonLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`

export const ShowFrozenTemperatureButton = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.black};
  cursor: pointer;
  align-items: center;
  text-decoration: underline;
  margin-bottom: 10px;
`
