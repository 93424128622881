import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SearchIcon from 'Assets/Icons/Search'
import colors from 'Assets/colors'
import { TextInput } from 'Components/atoms'
import { useDebounce } from 'Components/utils/customHooks'
import { debounceAmount } from 'Config/constants'

const RequestSearchInput = ({ onChange }) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, debounceAmount)

  useEffect(() => {
    onChange(debouncedSearch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  const handleOnChange = event => {
    if (!event) onChange('')
    setSearch(event?.target?.value ?? '')
  }

  return (
    <TextInput
      minimal
      value={search}
      borderRadius='8px'
      minimalShadow
      onChange={handleOnChange}
      Icon={props => <SearchIcon fill={colors.secondary} {...props} />}
      topIcon='1.2rem'
      height='56px'
      padding='1rem 1rem 1rem 24px'
      placeholder={t('searchRequest')}
      closeIcon
    />
  )
}

RequestSearchInput.propTypes = {
  onChange: PropTypes.func,
}

RequestSearchInput.defaultProps = {
  onChange: () => null,
}

export default RequestSearchInput
