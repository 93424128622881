import styled from 'styled-components'
import RadioGroup from '@material-ui/core/RadioGroup'
import colors from 'Assets/colors'

export const SingleRadioContainer = styled.div`
  padding: 12px 0;
`

export const SingleRadioTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ errors }) => (errors ? colors.red : colors.black)};
  margin-bottom: 18px;
`

export const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .MuiFormControlLabel-root {
    margin-right: 42px;
    .MuiIconButton-root {
      .MuiIconButton-label {
        .MuiSvgIcon-root {
          fill: ${colors.black};
        }
      }
    }
    .MuiRadio-colorSecondary.Mui-checked {
      .MuiIconButton-label {
        .MuiSvgIcon-root {
          fill: ${colors.secondary};
        }
      }
    }
    .MuiRadio-colorSecondary.Mui-checked + .MuiTypography-body1 {
      color: ${colors.secondary};
    }
    .MuiTypography-body1 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: unset;
      color: ${colors.black};
    }
  }
  .MuiFormHelperText-root {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.gray40l};
    padding-left: 32px;
    letter-spacing: 0;
    margin-top: 0;
  }
`
