import React from 'react'

import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'

const ProductActionLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width={300}
      height={64}
      viewBox='0 0 300 64'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='0' y='0' rx='0' ry='0' width='250' height='56' />
    </ContentLoader>
  )
}

export default ProductActionLoader
