import styled, { css } from 'styled-components'
import colors from 'Assets/colors'

export const RequestActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
`

export const RequestRepeatOrder = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 8px;

  ${p =>
    !p.disabled &&
    css`
      &:hover {
        svg {
          path {
            fill: ${colors.secondary};
          }
        }

        button {
          color: ${colors.secondary};
        }
      }
    `}

  svg {
    margin-right: 18px;
    width: 24px;
    path {
      transition: all 0.2s;
      fill: ${p => (p.disabled ? colors.grey4 : p.color)};
    }
  }
  button {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${p => (p.disabled ? colors.grey4 : colors.black)};
    margin-left: -5px;
    cursor: ${p => (p.disabled ? 'auto' : 'pointer')};
    transition: all 0.2s;
  }
`

export const HelpText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${p => (p.disabled ? colors.grey4 : colors.gray40l)};
  margin-left: 38px;

  ${props =>
    props.bold &&
    css`
      color: ${colors.blue};
      font-weight: 700;
    `}
`
