import React from 'react'

import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ReactComponent as ArrowRight } from 'Assets/Icons/ArrowRight.svg'
import { Modal } from 'Components/atoms'

import {
  Title,
  Description,
  ButtonContainer,
  ButtonLiteral,
  ActionButton,
  DownloadSection,
  TitleSection,
} from './styled'

const ModalMulticenterDownloadList = ({
  canDownloadCatalog,
  canDownloadOrder,
  canDownloadQA,
  isSingleOrder,
  showModal,
  setShowModal,
  onDownloadCatalog,
  onDownloadOrder,
  onDownloadQa,
  isLoadingOrders,
  isLoadingQA,
  isLoadingCatalog,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      margin='3rem'
      isOpen={showModal}
      setIsOpen={setShowModal}
      height='20rem'
      width='30rem'
      alignItems='center'
      justifyContent='center'
      padding='2rem 2rem 2rem 2rem'
    >
      <Box alignSelf='center' justifyContent='center' alignItems='center'>
        <TitleSection>
          <Title>{t('downloadAdmin')}</Title>
          {!isSingleOrder && <Description>{t('selectDownload')}</Description>}
        </TitleSection>
        <DownloadSection>
          {canDownloadOrder && (
            <ButtonContainer>
              <ActionButton onClick={onDownloadOrder}>
                <ButtonLiteral>{t(isSingleOrder ? 'downloadOrder' : 'downloadOrderList')}</ButtonLiteral>
                {isLoadingOrders ? <CircularProgress size='1rem' color='secondary' /> : <ArrowRight />}
              </ActionButton>
            </ButtonContainer>
          )}
          {canDownloadQA && (
            <ButtonContainer>
              <ActionButton onClick={onDownloadQa}>
                <ButtonLiteral>{t('downloadQAList')}</ButtonLiteral>
                {isLoadingQA ? <CircularProgress size='1rem' color='secondary' /> : <ArrowRight />}
              </ActionButton>
            </ButtonContainer>
          )}
          {canDownloadCatalog && (
            <ButtonContainer>
              <ActionButton onClick={onDownloadCatalog}>
                <ButtonLiteral>{t('downloadCatList')}</ButtonLiteral>
                {isLoadingCatalog ? <CircularProgress size='1rem' color='secondary' /> : <ArrowRight />}
              </ActionButton>
            </ButtonContainer>
          )}
        </DownloadSection>
      </Box>
    </Modal>
  )
}

ModalMulticenterDownloadList.propTypes = {
  canDownloadCatalog: PropTypes.bool,
  canDownloadOrder: PropTypes.bool,
  canDownloadQA: PropTypes.bool,
  isSingleOrder: PropTypes.bool,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onDownloadOrder: PropTypes.func,
  onDownloadQa: PropTypes.func,
  onDownloadCatalog: PropTypes.func,
  isLoadingOrders: PropTypes.bool,
  isLoadingQA: PropTypes.bool,
  isLoadingCatalog: PropTypes.bool,
}

ModalMulticenterDownloadList.defaultProps = {
  canDownloadCatalog: false,
  canDownloadOrder: true,
  canDownloadQA: false,
  isSingleOrder: false,
  showModal: false,
  setShowModal: () => {},
  onDownloadOrder: () => {},
  onDownloadQa: () => {},
  onDownloadCatalog: () => {},
  isLoadingOrders: false,
  isLoadingQA: false,
  isLoadingCatalog: false,
}

export default ModalMulticenterDownloadList
