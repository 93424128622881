import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Modal, TextInput } from 'Components/atoms'
import colors from 'Assets/colors'
import useShoppingListsEditModal from 'hooks/shoppingLists/useShoppingListsEditModal'
import { SHOPPING_LIST_TITLE_MAX_LENGTH } from 'Config/constants'

import {
  Title,
  InputLabel,
  InputContainer,
  ActionButton,
  ButtonLiteral,
  CircularProgressStyled,
  Message,
} from '../modalCreateShoppingList/styled'

const ModalEditShoppingList = ({ showModal, setShowModal }) => {
  const { t } = useTranslation()
  const { isLoading, error, name, onChangeName, onSubmit } = useShoppingListsEditModal({ showModal, setShowModal })

  return (
    <Modal showClose isOpen={showModal} setIsOpen={setShowModal} height='390px'>
      <Title>{t('shoppingList.edit.title')}</Title>
      <InputContainer>
        <InputLabel>{t('shoppingList.edit.label')}</InputLabel>
        <TextInput
          value={name}
          placeholder={t('shoppingList.edit.placeholder')}
          borderRadius='8px'
          background='#F4F1F1'
          margin='0 0 24px'
          height='56px'
          onChange={onChangeName}
          name='shopping-list-name'
          type='text'
          color={error ? colors.red : colors.black}
          maxLength={SHOPPING_LIST_TITLE_MAX_LENGTH}
        />
      </InputContainer>
      <Message>{error || t('shoppingList.edit.info')}</Message>
      <ActionButton onClick={onSubmit} submitLoading={isLoading} disabled={!name || error}>
        {isLoading && <CircularProgressStyled size={16} />}
        <ButtonLiteral>{t('shoppingList.edit.submit')}</ButtonLiteral>
      </ActionButton>
    </Modal>
  )
}

ModalEditShoppingList.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
}

ModalEditShoppingList.defaultProps = {
  showModal: false,
  setShowModal: () => {},
}

export default ModalEditShoppingList
