import axios from 'axios'
import qs from 'qs'

export const getProviderOrdinaryDates = (providerId, centerId) =>
  axios.get(`/api/providers/${providerId}/ordinary-calendar`, { headers: { 'X-Center-Id': centerId } })

export const getProviderExceptionalDates = (providerId, centerId) =>
  axios.get(`/api/providers/${providerId}/exceptional-calendar`, { headers: { 'X-Center-Id': centerId } })

export const getProviderCategories = (centerId, providerId) =>
  axios.get(`/api/providers/${providerId}/categories`, { headers: { 'X-Center-Id': centerId } })

export const getProviders = (centerId, params) =>
  axios.get(`/api/providers?${qs.stringify(params, { skipNulls: true })}`, { headers: { 'X-Center-Id': centerId } })
