import { makeStyles, alpha } from '@material-ui/core/styles'
import styled from 'styled-components'
import colors from 'Assets/colors'
import { Text } from 'Components/atoms/regularText/styled'

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    zIndex: 100,
  },
  bar: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.24)',
    borderRadius: '0px 0px 4px 4px',
  },
  menuButton: {
    textTransform: 'none',
    marginLeft: 'auto',
    backgroundColor: colors.cart,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    padding: '1rem',
  },
  title: {
    flexGrow: 1,
    display: 'flex',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '24ch',
      '&:focus': {
        width: '40ch',
      },
    },
  },
}))

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 1.5rem 0;

  .border-with-radius {
    width: 5.25rem;
    height: 0px;
    transition: all 0.2s;
  }
`

export const SectionSelector = styled.div`
  padding: 0.8rem 0;
  cursor: pointer;
  text-align: center;
  color: ${props => (props.selected ? props.theme.colors.secondary : null)};

  + .border-with-radius {
    border-bottom: ${props => (props.selected ? `4px solid ${props.theme.colors.secondary}` : null)};
  }

  :hover + .border-with-radius {
    border-bottom: 4px solid ${props => props.theme.colors.secondary};
  }
`

export const SelectorContainer = styled.div`
  margin-right: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ResultsContainer = styled.div`
  text-align: left;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 14rem;
  overflow-y: scroll;
  background-color: #fff;

  :hover {
    .selected {
      background-color: #fff;
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(51, 51, 51, 0.24);
    border-radius: 4px;
    max-height: 2.5rem;
  }
  ::-webkit-scrollbar-thumb:hover {
  }

  .icon-arrow {
    opacity: 0;
    transition: all 0.2s;
    margin: 3px 10px;
    display: inline-block;
  }
`

export const Result = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  cursor: pointer;
  align-items: center;

  &.selected {
    background-color: #eaeaea;
    &:hover {
      background-color: #eaeaea;
    }

    .icon-arrow {
      opacity: 1;
    }
  }

  :hover {
    background-color: #eaeaea;

    .icon-arrow {
      opacity: 1;
    }
  }
`

export const StyledText = styled(Text)`
  transition: all 0.2s;
  padding: 0;
`

export const RelativeContainer = styled.div`
  height: 0;
  border: 1px solid rgba(51, 51, 51, 0.24);
  position: relative;
  margin: 0 2.5rem;
  display: ${props => props.display};
`

export const Type = styled.div`
  padding: 0 0.5rem;
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background-color: ${props => props.theme.colors.secondary};
  margin: 0 0.75rem 0 1rem;
  color: #fff;
`

export const Container = styled.div``

export const TotalItems = styled.div`
  background: #ffffff;
  border-radius: 4px;
  padding: 0px 8px;
  margin-left: 1rem;
`
