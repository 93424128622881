import React from 'react'

import PropTypes from 'prop-types'
import Icons from 'Components/utils/categoryIcons'

import { ButtonContainer, Text } from './styled'

const CategoryHabitualButton = ({ name, id, navigate, selected, onClick }) => {
  const Icon = Icons[id] || (() => <></>)

  const selectCategory = () => {
    onClick()
    navigate(id)
  }

  return (
    <ButtonContainer onClick={selectCategory}>
      <Icon />
      <Text className='categories-text' selected={selected} data-cy='categoryHabitualButton'>
        {name}
      </Text>
    </ButtonContainer>
  )
}

CategoryHabitualButton.defaultProps = {
  name: '',
  id: '',
  selected: false,
  onClick: () => {},

  navigate: () => {},
}

CategoryHabitualButton.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,

  navigate: PropTypes.func,
}

export default CategoryHabitualButton
