import styled from 'styled-components'
import colors from 'Assets/colors'

export const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${colors.black};
  margin-bottom: 15px;
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.black};
  margin-bottom: 3rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`

export const ActionButton = styled.div`
  width: 100%;
  background-color: transparent;
  padding: 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  border: 1px solid ${colors.black};
  column-gap: 12px;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const ButtonLiteral = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`
