import styled from 'styled-components'

export const Text = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px;
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  text-align: ${props => props.textAlign || null};
`
