import React from 'react'

import { useTranslation } from 'react-i18next'
import { ReactComponent as CarroError } from 'Assets/Icons/CarroError.svg'
import { ReactComponent as PortalLogo } from 'Assets/Logos/PortalLogo.svg'
import { ReactComponent as CompassGroup } from 'Assets/Logos/CompassGroup.svg'
import { BigTitle, RegularText } from 'Components/atoms'

import { MainContainer, IEContainer } from './styled'

const IEBrowserAlert = () => {
  const { t } = useTranslation()

  return (
    <MainContainer>
      <PortalLogo />
      <IEContainer>
        <BigTitle textAlign='center'>{t('ieBrowserHeader')}</BigTitle>
        <RegularText textAlign='center'>{t('ieBrowserSubHeader')}</RegularText>
        <CarroError style={{ width: '6rem', height: '6rem' }} />
      </IEContainer>
      <CompassGroup />
    </MainContainer>
  )
}

export default IEBrowserAlert
