import React, { useState, useCallback } from 'react'

import MuiButton from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import * as Sentry from 'Services/sentry'
import { ReactComponent as CloseX } from 'Assets/Icons/CloseX.svg'
import ModalMulticenterProducts from 'Components/molecules/modalMulticenterProducts'
import ModalMulticenterDownload from 'Components/molecules/modalMulticenterDownload'
import ModalQAForm from 'Components/molecules/modalQAForm'
import ModalRepeatOrder from 'Components/molecules/modalRepeatOrder'
import ModalDownloadEmail from 'Components/molecules/modalDownloadEmail'
import CenterAccordion from 'Components/organisms/centerAccordion'
import { ORDER_STATUS } from 'Config/constants'

import * as S from './styled'
import useOrderActions from './useOrderActions'

const MulticenterOrder = ({ center }) => {
  const { t } = useTranslation()

  const [showOrderProducts, setShowOrderProducts] = useState(false)
  const [showRepeatOrder, setShowRepeatOrder] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [showQAModal, setShowQAModal] = useState(false)
  const [orderIsRC, setOrderIsRC] = useState(false)
  const [showDownloadQaExcelModal, setShowDownloadQaExcelModal] = useState(false)
  const [showDownloadProviderOrdersExcelModal, setShowDownloadProviderOrdersExcelModal] = useState(false)
  const [showDownloadProviderOrdersExcelMultipleModal, setShowDownloadProviderOrdersExcelMultipleModal] = useState(
    false
  )
  const [isLoadingQA, setIsLoadingQA] = useState(false)

  const [isLoadingOrders, setIsLoadingOrders] = useState(false)

  const {
    isLoadingProducts,
    isLoadingRepeatOrder,
    orderProducts,
    qaData,
    handleViewProducts,
    handleRepeatOrder,
    handleRepeatOrderConfirm,
    handleDownload,
    handleViewQA,
    handleNewOrder,
    handleDownloadAll,
    handleViewNoCompliance,
    downloadSingleOrder,
    downloadQA,
  } = useOrderActions(center, setShowOrderProducts, setShowRepeatOrder, setShowDownloadModal, setShowQAModal)

  const onDownload = useCallback(
    order => {
      setOrderIsRC(order.state === ORDER_STATUS.RC)
      handleDownload(order)
    },
    [handleDownload]
  )

  const handleDownloadSingleOrder = useCallback(async () => {
    try {
      setIsLoadingOrders(true)
      await downloadSingleOrder()
      setShowDownloadProviderOrdersExcelModal(true)
    } catch (e) {
      toast.error(t('petitionError'))
      Sentry.captureException(e)
    } finally {
      setIsLoadingOrders(false)
      setShowDownloadModal(false)
    }
  }, [downloadSingleOrder, t])

  const handleDownloadMultipleOrders = useCallback(async () => {
    try {
      setIsLoadingOrders(true)
      await handleDownloadAll()
      setShowDownloadProviderOrdersExcelMultipleModal(true)
    } catch (e) {
      toast.error(t('petitionError'))
      Sentry.captureException(e)
    } finally {
      setIsLoadingOrders(false)
      setShowDownloadModal(false)
    }
  }, [handleDownloadAll, t])

  const handleDownloadQA = useCallback(async () => {
    try {
      setIsLoadingQA(true)
      await downloadQA()
      setShowDownloadQaExcelModal(true)
    } catch (e) {
      toast.error(t('petitionError'))
      Sentry.captureException(e)
    } finally {
      setIsLoadingQA(false)
      setShowDownloadModal(false)
    }
  }, [downloadQA, t])

  return (
    <>
      <CenterAccordion
        center={center}
        onDownloadClick={handleDownloadMultipleOrders}
        onNewOrderClick={handleNewOrder}
        onViewProductsClick={handleViewProducts}
        onRepeatOrderClick={handleRepeatOrder}
        onDownloadOrderClick={onDownload}
        onViewQAClick={handleViewQA}
        onNoComplianceClick={handleViewNoCompliance}
      />
      <S.OrderProductsModal
        isOpen={showOrderProducts}
        setIsOpen={setShowOrderProducts}
        width='100vw'
        height='100vh'
        maxHeight='unset'
        showClose={false}
      >
        <div className='modal-header'>
          <MuiButton
            onClick={() => setShowOrderProducts(false)}
            startIcon={<CloseX width={16} height={16} />}
            color='inherit'
          >
            {t`close`}
          </MuiButton>
        </div>
        <div className='modal-content'>
          <ModalMulticenterProducts center={center} isLoading={isLoadingProducts} order={orderProducts} />
        </div>
      </S.OrderProductsModal>
      <S.RepeatOrderModal isOpen={showRepeatOrder} setIsOpen={setShowRepeatOrder} width='680px' height='256px'>
        <ModalRepeatOrder
          title={t('repeatOrder')}
          message={t('reapeatOrderModalText')}
          isLoading={isLoadingRepeatOrder}
          repeatOrder={handleRepeatOrderConfirm}
          setShowModal={setShowRepeatOrder}
        />
      </S.RepeatOrderModal>
      <ModalMulticenterDownload
        isSingleOrder
        canDownloadQA={orderIsRC}
        showModal={showDownloadModal}
        setShowModal={setShowDownloadModal}
        onDownloadOrder={handleDownloadSingleOrder}
        onDownloadQa={handleDownloadQA}
        isLoadingQA={isLoadingQA}
        isLoadingOrders={isLoadingOrders}
      />
      <ModalQAForm
        showModal={showQAModal}
        setShowModal={setShowQAModal}
        data={{ qaData }}
        orderNumber={qaData?.receptionOrder?.id}
      />
      <ModalDownloadEmail
        setShowModal={() => setShowDownloadProviderOrdersExcelMultipleModal(false)}
        showModal={showDownloadProviderOrdersExcelMultipleModal}
        title={t('downloadOrdersExcelMultipleTitle')}
        description={t('downloadOrdersExcelMultipleDescription')}
      />
      <ModalDownloadEmail
        setShowModal={() => setShowDownloadProviderOrdersExcelModal(false)}
        showModal={showDownloadProviderOrdersExcelModal}
        title={t('downloadOrdersExcelTitle')}
        description={t('downloadOrdersExcelDescription')}
      />
      <ModalDownloadEmail
        setShowModal={() => setShowDownloadQaExcelModal(false)}
        showModal={showDownloadQaExcelModal}
        title={t('downloadQaExcelTitle')}
        description={t('downloadQaExcelDescription')}
      />
    </>
  )
}

MulticenterOrder.propTypes = {
  center: PropTypes.object.isRequired,
}

export default MulticenterOrder
