import styled, { css } from 'styled-components'
import colors from 'Assets/colors'

export const Container = styled.div``

export const MainContainer = styled.div`
  background-color: ${colors.gray98b};
  padding: 0 24px 24px;
  height: calc(100vh - 168px);
  overflow: auto;

  ${props =>
    props.$tutorial &&
    css`
      height: calc(100vh - 216px);
    `}
`

export const AnnouncementsContainer = styled.div``
