import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import colors from 'Assets/colors'
import Arrow from 'Assets/Icons/ArrowRight'
import ArrowDropdown from 'Assets/Icons/ArrowDropdown'
import TypeButton from 'Components/atoms/typeButton'

import { SubCategory, SubCatText, TypeContainer, ShowMore, ShowMoreText } from './styled'

const TYPES_TO_SHOW = 5

const SubCategoryButton = ({ id, name, types, navigate }) => {
  const [hovered, setHovered] = useState(false)
  const [open, setOpen] = useState(false)
  const { subCategory = {} } = useSelector(state => state.purchase.selectedCategories)
  const [selected, setSelected] = useState(subCategory.id === id)
  const [dropdownDisplay, setDropdownDisplay] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setTimeout(() => setDropdownDisplay(selected), 10)
    if (!selected) setOpen(false)
  }, [selected])

  useEffect(() => {
    if (selected && subCategory.id !== id) setSelected(false)
    if (!selected && subCategory.id === id) setSelected(true)
    /* eslint-disable-next-line */
  }, [subCategory.id, id])

  const selectCategory = () => {
    setSelected(value => !value)
    navigate(id)
  }

  const filteredTypes = open ? types : types.slice(0, TYPES_TO_SHOW)

  return (
    <>
      <SubCategory
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={selectCategory}
      >
        <SubCatText className='subcategories-text' selected={selected}>
          {name}
        </SubCatText>
        <Arrow fill={selected || hovered ? colors.secondary : colors.black} />
      </SubCategory>
      {selected && (
        <TypeContainer selected={dropdownDisplay} height={types.length * 40 + 40}>
          {filteredTypes.map(({ id: typeId, name: typeName }) => (
            <TypeButton key={typeId} id={typeId} name={typeName} navigate={navigate} />
          ))}
          {types.length > TYPES_TO_SHOW ? (
            <ShowMore onClick={() => setOpen(value => !value)}>
              <ArrowDropdown style={{ margin: '0 0.5rem', transform: open ? 'rotate(180deg)' : 'none' }} />
              <ShowMoreText>{open ? t('contractTypes') : t('deployTypes')}</ShowMoreText>
            </ShowMore>
          ) : null}
        </TypeContainer>
      )}
    </>
  )
}

SubCategoryButton.defaultProps = {
  name: '',
  id: '',
  types: [],
  navigate: () => {},
}

SubCategoryButton.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  types: PropTypes.array,
  navigate: PropTypes.func,
}

export default SubCategoryButton
