import styled from 'styled-components'
import Modal from 'Components/atoms/modal'

export const OrderProductsModal = styled(Modal)`
  padding: 0;

  > div:last-child {
    padding: 0;
  }

  .modal-header {
    height: 56px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    border-radius: 4px;

    .MuiButton-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-transform: none;
    }
  }

  .modal-content {
    margin-top: 56px;
    padding: 32px 24px;
  }
`

export const RepeatOrderModal = styled(Modal)`
  > div:last-child {
    overflow: hidden;
  }
`
