import React, { useState, useCallback } from 'react'

import PropTypes from 'prop-types'
import { Calendar } from 'Components/atoms'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ReactComponent as CalendarIcon } from 'Assets/Icons/Calendario.svg'
import moment from 'moment'

import { useClickOutside } from '../../utils/customHooks'

import { Container, ModalContainer, ModalItem } from './styled'

const CalendarDateRange = ({
  label,
  error,
  value,
  onChange,
  selectRange,
  onRemove,
  height,
  showIcon,
  left,
  right,
  range,
  ...rest
}) => {
  const [visible, setVisible] = useState('')

  const myRef = React.createRef()
  useClickOutside(myRef, () => setVisible(false))

  const handleChange = useCallback(
    valueInput => {
      const endDate = moment(valueInput[1]).startOf('day').toDate()
      const startDate = moment(valueInput[0]).startOf('day').toDate()
      const timeDifference = endDate.getTime() - startDate.getTime()
      const diffDates = Math.round(timeDifference / (1000 * 3600 * 24))

      if (diffDates <= 31) {
        setVisible(false)
        onChange({ endDate, startDate })
      } else {
        setVisible(false)
      }
    },
    [onChange]
  )

  return (
    <Container height={height} showIcon={showIcon}>
      <FormControl error={error} fullWidth>
        <TextField
          value={
            value
              ? [moment(value[0]).format('DD/MM/YYYY'), moment(value[1]).format('DD/MM/YYYY')]
                  .toString()
                  .replace(',', ' - ')
              : ''
          }
          placeholder={label}
          onChange={e => {
            if (!e.target.value) onRemove()
          }}
          variant='filled'
          onFocus={() => setVisible(true)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <CalendarIcon />
              </InputAdornment>
            ),
          }}
        />
        {visible && (
          <ModalContainer ref={myRef} left={left} right={right} range={range}>
            <ModalItem>
              <Calendar
                {...rest}
                onChange={handleChange}
                value={value}
                selectRange={selectRange}
                className='searchCalendar'
              />
            </ModalItem>
          </ModalContainer>
        )}
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Container>
  )
}

CalendarDateRange.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  error: PropTypes.string || PropTypes.bool,
  label: PropTypes.string,
  selectRange: PropTypes.bool,
  onRemove: PropTypes.func,
  height: PropTypes.string,
  showIcon: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  range: PropTypes.bool,
}

CalendarDateRange.defaultProps = {
  placeholder: '',
  value: '',
  onChange: () => {},
  onRemove: () => {},
  error: '',
  label: '',
  selectRange: '',
  height: '',
  showIcon: '',
  left: '',
  right: '',
  range: '',
}

export default CalendarDateRange
