import Box from '@material-ui/core/Box'
import styled from 'styled-components'

export const BoxContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 48px;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`

export const ImageNotList = styled.img`
  width: 80px;
  height: 80px;
`
