import _ from 'lodash'
import { isAvailableProduct } from 'Components/utils/products'

export const calculateProductAmount = cart =>
  _.chain(cart)
    .map(({ amount }) => (amount > 0 ? 1 : 0))
    .sum()
    .value()

export const calculateTotalProductPrice = cart =>
  _.chain(cart)
    .map(({ total }) => total)
    .sum()
    .value()

export const hasSomethingInCart = cart => !!_.find(cart, ({ amount = 0 }) => amount > 0)

export const financial = x =>
  Number.parseFloat(x)
    .toLocaleString('es-ES', { maximumFractionDigits: 2 })
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')

export const calculateAvailableProductTotalPrice = cart => {
  const products = _.values(cart).filter(element => isAvailableProduct(element))
  return _.chain(products)
    .map(({ total }) => total)
    .sum()
    .value()
}

export const calculateAvailableProductAmount = cart => {
  const products = _.values(cart).filter(element => isAvailableProduct(element))
  return _.chain(products)
    .map(({ amount }) => (amount > 0 ? 1 : 0))
    .sum()
    .value()
}

export const hasSomethingAvailableInCart = cart => {
  const products = _.values(cart).filter(element => isAvailableProduct(element))
  return !!_.find(products, ({ amount = 0 }) => amount > 0)
}
