import styled from 'styled-components'
import { Text } from 'Components/atoms/regularText/styled'

export const CentersContainer = styled.div`
  text-align: left;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 14rem;
  overflow-y: scroll;
  background: #fff;

  :hover {
    .selected {
      background-color: #fff;
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(51, 51, 51, 0.24);
    border-radius: 4px;
    max-height: 2.5rem;
  }
  ::-webkit-scrollbar-thumb:hover {
  }

  .icon-arrow {
    opacity: 0;
    transition: all 0.2s;
    margin: 3px 10px;
    display: inline-block;
  }
`

export const StyledText = styled(Text)`
  transition: all 0.2s;
  padding: 0;
`

export const Center = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  cursor: pointer;
  align-items: center;

  &.selected {
    background-color: #eaeaea;
    &:hover {
      background-color: #eaeaea;
    }

    .icon-arrow {
      opacity: 1;
    }
  }

  :hover {
    background-color: #eaeaea;

    .icon-arrow {
      opacity: 1;
    }
  }
`
