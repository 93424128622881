import set from 'lodash/set'

const validate = (lines, category, t) => {
  const errors = {}

  if (lines && category.requiredLot) {
    lines.forEach(({ isChecked, lot, expirationDate, reference }) => {
      if (isChecked && !lot) set(errors, `[${reference}].lot`, t('mandatoryField'))
      if (isChecked && !expirationDate) set(errors, `[${reference}].expirationDate`, t('mandatoryField'))
    })
  } else {
    lines.forEach(({ isChecked, lot, expirationDate, reference }) => {
      if (isChecked && lot && !expirationDate) set(errors, `[${reference}].expirationDate`, t('mandatoryField'))
      if (isChecked && !lot && expirationDate) set(errors, `[${reference}].lot`, t('mandatoryField'))
    })
  }

  return errors
}

export default validate
