import styled, { css } from 'styled-components'

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
  margin-top: 0;
`

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 100px;
  overflow-y: scroll;

  ${props => {
    const category = '62px'
    const tutorial = props.$tutorial ? '48px' : '0px'
    const needsApproval = props.$needsApproval ? '132px' : '0px'
    return css`
      height: calc(100vh - 274px - ${category} - ${tutorial} - ${needsApproval});
    `
  }}
`

export const TopbarContainer = styled.div`
  padding: 2rem 1.25rem;
`

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
