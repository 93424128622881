import React from 'react'

import PropTypes from 'prop-types'
import { Modal, RegularText, SelectInput } from 'Components/atoms'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'
import useShoppingListsAddProduct from 'hooks/shoppingLists/useShoppingListAddProduct'
import Title from 'Components/atoms/bigTitle'

import Image from '../../../Assets/Images/search_placeholder.png'

import { BoxContainer, ImageNotList } from './styled'

const ModalAddToList = ({ showModal, setShowModal, productId }) => {
  const { t } = useTranslation()
  const { isFetching, inputValue, lists, error, isLoading, handleChange, onClose } = useShoppingListsAddProduct({
    setShowModal,
    productId,
  })

  return (
    <Modal isOpen={showModal} height='416px' width='680px' setIsOpen={setShowModal} onClose={onClose}>
      <BoxContainer>
        <Title textAlign='center' padding='10px'>
          {t('shoppingList.addToList.modalTitle')}
        </Title>
        {isFetching || !lists ? (
          <CircularProgress />
        ) : lists.length <= 0 ? (
          <>
            <ImageNotList src={Image} alt='No Lists Found' />
            <RegularText textAlign='center'>{t('shoppingList.addToList.noResults')}</RegularText>
          </>
        ) : (
          <SelectInput
            value={inputValue}
            label={t('shoppingList.addToList.select')}
            placeholder={t('shoppingList.addToList.select')}
            choices={lists}
            onChange={handleChange}
            boxShadow='0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)'
            error={error}
          />
        )}
        {(isLoading || isFetching) && <CircularProgress />}
      </BoxContainer>
    </Modal>
  )
}

ModalAddToList.defaultProps = {
  showModal: false,
  setShowModal: () => null,
  productId: '',
}

ModalAddToList.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  productId: PropTypes.string,
}

export default ModalAddToList
