import React from 'react'

import { BigTitle, RegularText } from 'Components/atoms'
import { ReactComponent as EmptyBasket } from 'Assets/Icons/Vacio.svg'
import PropTypes from 'prop-types'

import { NoResultsContainer, NoResultsBox } from './styled'

const NoResults = ({ title, description }) => (
  <NoResultsContainer>
    <NoResultsBox>
      <BigTitle margin='0 0 16px'>{title}</BigTitle>
      <RegularText>{description}</RegularText>
      <EmptyBasket style={{ width: '150px', height: '150px', marginTop: '40px', marginBottom: '10px' }} />
    </NoResultsBox>
  </NoResultsContainer>
)

NoResults.defaultProps = {
  title: '',
  description: '',
}

NoResults.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default NoResults
