import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { ReactComponent as CloseX } from 'Assets/Icons/CloseX.svg'
import { TutorialActions } from 'Redux/tutorial'
import { TUTORIAL_NAMES } from 'Config/constants'

import { Topbar, RestartButton, Close } from './styled'

const ApproverTutorialBanner = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const showApproverTutorialBannerV2 = useSelector(state => state.tutorial.showApproverTutorialBannerV2)

  const handleClick = () => {
    window.open(process.env.REACT_APP_APPROVER_TUTORIAL_URL, '_blank')
  }

  const handleClose = useCallback(() => {
    dispatch(TutorialActions.finishTutorialV2({ tutorial: TUTORIAL_NAMES.approverRoleV2 }))
  }, [dispatch])

  if (!showApproverTutorialBannerV2) return null

  return (
    <Topbar>
      <p>
        {t('newFunctionality')} <strong>{t('monitorRequest')}</strong>
        {t('nowApprovalApproverRequests')}
      </p>
      <RestartButton type='button' onClick={handleClick}>
        <strong>{t('howItWorks')}</strong>
      </RestartButton>
      <Close onClick={handleClose}>
        <CloseX style={{ marginRight: '1rem' }} />
      </Close>
    </Topbar>
  )
}

export default ApproverTutorialBanner
