import React from 'react'

import PropTypes from 'prop-types'

const Arrow = ({ fill, className }) => (
  <svg className={className} width='6' height='11' viewBox='0 0 6 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0.5 10.5L5.5 5.5L0.5 0.5L0.5 10.5Z' fill={fill} />
  </svg>
)

Arrow.defaultProps = {
  fill: '#333333',
  className: '',
}

Arrow.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
}

export default Arrow
