import axios from 'axios'
import qs from 'qs'

export const getProduct = (centerId, productId) =>
  axios.get(`/api/products/${productId}`, { headers: { 'X-Center-Id': centerId } })

export const getProducts = (params, centerId) =>
  axios.get(`/api/products?${qs.stringify(params, { skipNulls: true })}`, { headers: { 'X-Center-Id': centerId } })

export const downloadProductCatalog = centerId =>
  axios.get('/api/products/process-product-catalog', { headers: { 'X-Center-Id': centerId } })

export const downloadMulticenterProductCatalog = strParams =>
  axios.get(`api/products/multicenter/process-product-catalog?${strParams}`)

export const getReloadingProducts = centerId =>
  axios.get('/api/products/is-reloading', { headers: { 'X-Center-Id': centerId } })
