import React from 'react'

import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import routes from 'Config/routes'
import { isUserRoleApprover } from 'Components/utils/roles'

const ApproverRoute = ({ children, ...rest }) => {
  const user = useSelector(({ auth }) => auth.user)
  const isApprover = isUserRoleApprover(user)

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isEmpty(user)) {
          return (
            <Redirect
              to={{
                pathname: routes.root,
                state: { from: location },
              }}
            />
          )
        }

        if (!isApprover) {
          return (
            <Redirect
              to={{
                pathname: routes.home,
                state: { from: location },
              }}
            />
          )
        }

        return children
      }}
    />
  )
}

ApproverRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default ApproverRoute
