import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as UpIcon } from 'Assets/Icons/Up.svg'
import { ReactComponent as DownIcon } from 'Assets/Icons/Down.svg'
import { TextInput } from 'Components/atoms'
import colors from 'Assets/colors'
import { KEY_CODES } from 'Config/constants'
import { isNumber } from 'Config/utils'
import { financial } from 'Components/utils/cartCalculator'

import { useClickOutside } from '../../utils/customHooks'

import { AmountContainer, Selector, Input, SingleFieldContainer, ButtonCart } from './styled'

const CountInput = forwardRef(props => {
  const { onChange, value, amountOrdered, unit, min, format, paddingLeft, error, innerRef, onNext, onPrevious } = props

  const { t } = useTranslation()
  const [focused, setFocused] = useState(false)
  const [text, setText] = useState()
  const containerRef = useRef()
  const inputRef = useRef()

  useImperativeHandle(innerRef, () => ({
    focus() {
      setFocused(true)
    },
  }))

  useEffect(() => {
    if (focused && inputRef) {
      inputRef.current.select()
    }
  }, [focused])

  const formatNumber = num => {
    const removeCommaNum = num ? num.toString().replace(',', '.') : '0'
    const number = format === 'float' ? +parseFloat(removeCommaNum).toFixed(2) : parseInt(removeCommaNum, 10)
    // eslint-disable-next-line no-restricted-globals
    return !number || !isNumber(number) ? 0 : number
  }

  useEffect(() => {
    setText(value)
  }, [value])

  const handleChange = num => {
    onChange(formatNumber(num))
  }

  useClickOutside(containerRef, () => {
    if (focused) {
      setFocused(false)
      handleChange(text)
    }
  })

  const handleText = num => {
    const formatedNum = formatNumber(num)

    if (min !== null) {
      const number = formatedNum >= min ? formatedNum : formatNumber(min)
      setText(number)
    } else {
      setText(formatedNum)
    }
  }

  const handleSubstract = () => {
    if (min === null) {
      handleText(formatNumber(text) - 1)
    } else if (text > 0) {
      handleText(formatNumber(text) - 1)
    }
  }

  const handleAdd = () => handleText(formatNumber(text) + 1)

  const handleKeydown = e => {
    if (e.keyCode === KEY_CODES.UP_ARROW) {
      e.preventDefault()
      handleAdd()
    } else if (e.keyCode === KEY_CODES.DOWN_ARROW) {
      e.preventDefault()
      handleSubstract()
    } else if (e.keyCode === KEY_CODES.TAB && e.shiftKey) {
      e.preventDefault()
      handleChange(text)
      setFocused(false)
      setTimeout(onPrevious, 250)
    } else if (e.keyCode === KEY_CODES.TAB || e.keyCode === KEY_CODES.ENTER) {
      e.preventDefault()
      handleChange(text)
      setFocused(false)
      setTimeout(onNext, 250)
    }
  }

  const handleChangeAmount = e => {
    let formatText = e.target.value

    if (formatText && isNumber(formatText)) {
      formatText = parseInt(formatText, 10)

      if ((amountOrdered > 0 && formatText < 0) || (amountOrdered < 0 && formatText > 0)) {
        formatText = 0
      }
    }
    setText(formatText)
  }

  return (
    <div ref={innerRef}>
      <SingleFieldContainer onClick={() => setFocused(true)} ref={containerRef} paddingLeft={paddingLeft}>
        {focused ? (
          <ButtonCart error={error}>
            <AmountContainer>
              <Input
                ref={inputRef}
                focused={focused}
                value={text}
                onChange={handleChangeAmount}
                onKeyDown={handleKeydown}
              />
            </AmountContainer>
            <div style={{ display: 'flex' }}>
              <Selector onClick={handleSubstract} disabled={min !== null ? text <= 0 : false}>
                <DownIcon />
              </Selector>
              <Selector onClick={handleAdd} disabled={amountOrdered < 0 && text >= 0}>
                <UpIcon />
              </Selector>
            </div>
          </ButtonCart>
        ) : (
          <TextInput
            value={`${financial(text)} ${unit}`}
            placeholder={t('recievedQty')}
            background={colors.white}
            border={error ? `1px solid ${colors.red}` : `1px solid ${colors.black}`}
            height='38px'
            error={error}
            onKeyDown={handleKeydown}
          />
        )}
      </SingleFieldContainer>
    </div>
  )
})

CountInput.defaultProps = {
  onChange: () => {},
  value: null,
  amountOrdered: null,
  unit: null,
  min: null,
  format: 'int',
  paddingLeft: '',
  error: '',
  innerRef: null,
  onNext: () => {},
  onPrevious: () => {},
}

CountInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  amountOrdered: PropTypes.number,
  unit: PropTypes.string,
  min: PropTypes.number,
  format: PropTypes.string,
  paddingLeft: PropTypes.string,
  error: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
}

export default CountInput
