import {
  SET_ERROR_ADD,
  SET_IS_FETCHING_ADD,
  SET_IS_FETCHING_LISTS,
  SET_IS_FETCHING_LIST_PRODUCTS,
  SET_IS_FETCHING_UPDATE,
  SET_IS_SENDING_CART,
  SET_LISTS,
  SET_LIST_DATA,
  SET_LIST_PRODUCTS,
} from './types'

const initialState = {
  isFetchingLists: false,
  lists: null,
  isFetchingAdd: false,
  errorAdd: '',
  products: null,
  data: null,
  isFetchingProducts: false,
  isFetchingUpdate: false,
  isSendingCart: false,
}

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_LISTS:
      return { ...state, isFetchingLists: action.payload }
    case SET_LISTS:
      return { ...state, lists: action.payload }
    case SET_IS_FETCHING_ADD:
      return { ...state, isFetchingAdd: action.payload }
    case SET_ERROR_ADD:
      return { ...state, errorAdd: action.payload }
    case SET_LIST_PRODUCTS:
      return { ...state, products: action.payload }
    case SET_IS_FETCHING_LIST_PRODUCTS:
      return { ...state, isFetchingProducts: action.payload }
    case SET_LIST_DATA:
      return { ...state, data: action.payload }
    case SET_IS_FETCHING_UPDATE:
      return { ...state, isFetchingUpdate: action.payload }
    case SET_IS_SENDING_CART:
      return { ...state, isSendingCart: action.payload }

    default:
      return state
  }
}

export default Reducer
