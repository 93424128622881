import React from 'react'

import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import routes from 'Config/routes'
import { ReactComponent as PedidoConfirmado } from 'Assets/Icons/PedidoPorAprobarConfirmado.svg'
import { ReactComponent as CompassGroup } from 'Assets/Logos/CompassGroup.svg'
import { AuthActions } from 'Redux/auth'
import { setIsRepeatingOrder } from 'Redux/multicenter/actions'
import { ListItem } from '@material-ui/core'

import { Container, Content, Title, SubTitle, SvgContainer, ButtonsContainer, useStyles, ListContainer } from './styled'

const CheckoutApprovedConfirmed = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const trackingIds = useSelector(({ purchase }) => purchase.trackingId || [])
  const isMulticenterRepeatingOrder = useSelector(({ multicenter }) => multicenter.isRepeatingOrder)

  const handleMulticenterRoute = route => {
    dispatch(setIsRepeatingOrder(false))
    dispatch(AuthActions.setCenter({}))
    history.push(route)
  }

  const handleClickBack = () =>
    isMulticenterRepeatingOrder ? handleMulticenterRoute(routes.multicenterOrders) : history.push(routes.purchase)

  const handleClickCarts = () =>
    isMulticenterRepeatingOrder ? handleMulticenterRoute(routes.multicenterOrders) : history.push(routes.carts)

  return (
    <Container>
      <Content>
        <Title>{t('confirmationMulReqApprovedTitle')}</Title>
        <SvgContainer>
          <PedidoConfirmado style={{ width: '160px', height: '160px' }} />
        </SvgContainer>
        <SubTitle>{t('confirmationMulReqApprovedSubtitle')}</SubTitle>
        <ListContainer>
          {trackingIds?.map(element => (
            <ListItem style={{ display: 'list-item', alignItems: 'center' }}>{element}</ListItem>
          ))}
        </ListContainer>
        <ButtonsContainer>
          <Button onClick={handleClickBack} className={classes.back}>
            {isMulticenterRepeatingOrder ? t('backToMyCenters') : t('backHome')}
          </Button>
          <Button onClick={handleClickCarts} className={isMulticenterRepeatingOrder ? classes.back : classes.history}>
            {isMulticenterRepeatingOrder ? t('backToMyOrders') : t('toRequests')}
          </Button>
        </ButtonsContainer>
      </Content>
      <CompassGroup />
    </Container>
  )
}

export default CheckoutApprovedConfirmed
