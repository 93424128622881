import styled from 'styled-components'
import colors from 'Assets/colors'

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
`

export const OrderProvider = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  padding-bottom: 5px;
`
export const BorderBox = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  margin-bottom: 30px;
  width: 350px;
  padding: 5px;
  margin-right: 50px;
  align-items: center;
`

export const HeaderRow = styled.div`
  display: flex;
  margin-top: 16px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
`

export const ShowProductsContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
`

export const ShowMoreCopy = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  width: 11rem;
  margin-right: 40px;
`

export const ShowLessCopy = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  width: 11rem;
  margin-right: 40px;
`
export const Text = styled.p`
  font-size: 14;
  color: ${colors.black};
  margin-left: 20px;
  margin-right: 10px;
`

export const ColorRow = styled.p`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.yellow};
  border-radius: 8px;
  padding-right: 10px;
  padding-bottom: 8px;
  padding-top: 8px;
  min-width: 380px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.gray40l};
`

export const SpaceBetweenRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 50px;
  flex: 1;
`

export const SpaceBetweenDetailRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 20px;
  flex: 1;
`

export const ItemContainer = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  border-radius: 8px;
  border: 1px solid rgba(51, 51, 51, 0.24);
  background-color: ${props => props.theme.colors.gray98b};
  align-items: 'center';
  font-size: 14px;
`

export const RequestNumber = styled.div`
  padding: 20px;
  display: flex;
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  font-size: 16px;
  width: 250px;
  align-items: center;
`

export const ButtonSection = styled.div`
  padding: 20px;
  width: 300px;
  height: 100px;
  display: flex;
`
export const NumberP = styled.div`
  padding-left: 20px;
  font-weight: bold;
  color: ${props => props.theme.colors.black};
  flex: 1;
`

export const StateRow = styled.div`
  display: flex;
  align-items: center;
  margin-right: 50px;
  font-size: 14px;
  min-width: 250px;
`

export const SelectedDeliveryDate = styled.div`
  padding: 6px;
  background-color: ${props => props.theme.colors.gray2};
  color: ${props => props.theme.colors.white};
  border-radius: 8px;
`

export const DetailRow = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.gray40l};
  width: 220px;
`
export const InfoRow = styled.div`
  display: flex;
  align-items: center;
`

export const Total = styled.div`
  display: flex;
  font-size: 16px;

  color: ${props => props.theme.colors.gray40l};
  height: 50px;
  margin-right: 10px;
  margin-left: 50px;
`

export const Value = styled.div`
  display: flex;
  font-size: 16px;
  color: ${props => props.theme.colors.black};
  height: 50px;
  margin-right: 50px;
  font-weight: bold;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled.div`
  width: 100%;
  background-color: ${colors.secondary};
  padding: 1rem;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  pointer-events: ${props => (props.submitLoading ? 'none' : 'all')};
  padding: 15px 75px;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const ButtonLiteral = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`

export const DateSelectContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  svg {
    position: relative;
    top: 0px;
  }
`

export const DateSelect = styled.div`
  margin-left: 18px;
  justify-content: center;
  align-items: center;
`

export const DateSelectTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;
  cursor: pointer;
  padding-top: 5px;
`

export const CalendarContainer = styled.div`
  position: absolute;
  right: 0;
  background: white;
  top: 48px;
  z-index: 9;
`

export const ImageNotCenter = styled.img`
  width: 100px;
  height: 100px;
  align-items: 'center';
`

export const SearchContainer = styled.div`
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  max-width: 660px;
  margin: 0 auto;
`
