import React, { useEffect, useRef, useState, useMemo, createRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AuthTypes, AuthActions } from 'Redux/auth'
import classNames from 'classnames'
import _ from 'lodash'
import { getProducts, getProviders } from 'Services/api'
import { KEY_CODES, LIMIT_REQUEST_CENTERS } from 'Config/constants'
import { getApiErrorMessage } from 'Config/utils'
import { BigTitle, TextInput, RegularText } from 'Components/atoms'
import ArrowDropdown from 'Assets/Icons/ArrowDropdown'
import Arrow from 'Assets/Icons/Arrow'
import { setIsInitialFecthCenters } from 'Redux/auth/actions'

import { CentersContainer, StyledText, Center, ModalSpinnerContainer, ProvidersErrorMessage } from './styled'

const RenderSpinner = () => (
  <ModalSpinnerContainer>
    <CircularProgress />
  </ModalSpinnerContainer>
)

const SelectCenter = ({ isLoadingCenter, centers, hoveredCenter, onSelectCenter, refs }) => (
  <div style={{ height: 0, border: '1px solid rgba(51, 51, 51, 0.24)', position: 'relative' }}>
    {isLoadingCenter ? (
      <CentersContainer isLoadingCenter={isLoadingCenter}>
        <CircularProgress />
      </CentersContainer>
    ) : (
      <CentersContainer>
        {centers?.map((center, index) => {
          const { name, id, ...rest } = center
          return (
            <Center
              className={classNames({ selected: index === hoveredCenter })}
              key={id}
              onClick={() => onSelectCenter({ name, center, id, ...rest })}
              ref={refs[index]}
              data-cy={`center-${id}`}
            >
              <StyledText padding='8px 0'>{`${name} - ${id}`}</StyledText>
              <Arrow className='icon-arrow' />
            </Center>
          )
        })}
      </CentersContainer>
    )}
  </div>
)

SelectCenter.propTypes = {
  isLoadingCenter: PropTypes.bool.isRequired,
  centers: PropTypes.array.isRequired,
  hoveredCenter: PropTypes.string.isRequired,
  onSelectCenter: PropTypes.func.isRequired,
  refs: PropTypes.array.isRequired,
}

const CenterSelector = ({ title, onSelectCenter, customSelectCallback, showSubtitle }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const InputRef = useRef()

  const isLoadingCenter = useSelector(({ auth }) => auth.isLoadingCenter)
  const centers = useSelector(({ auth }) => auth.centersList)
  const isInitialFechtCenters = useSelector(({ auth }) => auth.isInitialFechtCenters)

  const refs = useMemo(() => Array.from({ length: centers.length }).map(() => createRef()), [centers])

  const [inputValue, setInputValue] = useState('')
  const [hoveredCenter, setHoveredCenter] = useState('')
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [noProviders, setNoProviders] = useState('')

  useEffect(() => {
    setTimeout(() => (InputRef.current ? InputRef.current.focus() : null), 100)
  }, [])

  useEffect(() => {
    if (isInitialFechtCenters) {
      dispatch(setIsInitialFecthCenters(false))
      return
    }
    dispatch({
      type: AuthTypes.FETCH_AUTOCOMPLETE_CENTERS,
      params: { search: inputValue, limit: LIMIT_REQUEST_CENTERS },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, dispatch])

  useEffect(() => {
    if (hoveredCenter !== '' && refs[hoveredCenter] && refs[hoveredCenter].current) {
      refs[hoveredCenter].current.scrollIntoView()
    }
  }, [hoveredCenter, refs])

  useEffect(() => {
    setHoveredCenter('')
  }, [centers])

  const onChangeCenter = value => setInputValue(value)

  const onKeyDownInput = event => {
    const char = event.which || event.keyCode
    if (_.isEmpty(centers)) return
    switch (char) {
      case KEY_CODES.UP_ARROW:
        if (hoveredCenter === '' || hoveredCenter === 0) {
          setHoveredCenter(centers.length - 1)
          break
        }
        setHoveredCenter(value => value - 1)
        break
      case KEY_CODES.DOWN_ARROW:
        if (hoveredCenter === '' || hoveredCenter === centers.length - 1) {
          setHoveredCenter(0)
          break
        }
        setHoveredCenter(value => value + 1)
        break
      case KEY_CODES.ENTER:
        if (hoveredCenter === '') break
        dispatch(AuthActions.setCenter(centers[hoveredCenter]))
        break
      default:
        break
    }
  }

  const haveProviders = async ({ centerId }) => {
    try {
      const { count } = await getProviders(centerId, {})
      if (count <= 0) {
        throw new Error('User has no providers')
      }
      return true
    } catch (error) {
      return false
    }
  }

  const handleSelectCenter = async data => {
    const { name, center, id, configured, ...rest } = data

    setNoProviders('')
    setShowLoadingModal(true)

    if (customSelectCallback && onSelectCenter) {
      setShowLoadingModal(false)
      onSelectCenter(data)
      return
    }

    if (configured === false) {
      setShowLoadingModal(false)
      setNoProviders(t('centerModal.centerWithoutProviders'))
      return
    }

    if (!configured) {
      const areThereProviders = await haveProviders({ centerId: id })
      if (!areThereProviders) {
        setShowLoadingModal(false)
        setNoProviders(t('centerModal.centerWithoutProviders'))
        return
      }
    }

    try {
      await getProducts({ mode: 'init' }, center.id)
    } catch (error) {
      toast.error(getApiErrorMessage(error))
    }

    dispatch(AuthActions.setCenter({ name, id, ...rest }))
    onSelectCenter(center)
    setShowLoadingModal(false)
  }

  return (
    <>
      <BigTitle margin='0 0 1rem'>{t(title)}</BigTitle>
      {showSubtitle && <RegularText margin='0 0 1.5rem'>{t('accessCenterMessage')}</RegularText>}
      <ProvidersErrorMessage>{noProviders}</ProvidersErrorMessage>
      <TextInput
        value={inputValue}
        onChange={e => onChangeCenter(e.target.value)}
        refProp={InputRef}
        placeholder={t('selectACenter')}
        Icon={props => <ArrowDropdown {...props} />}
        borderRadius='8px 8px 0 0'
        onKeyDown={onKeyDownInput}
        name='center'
      />
      {showLoadingModal ? (
        <RenderSpinner />
      ) : (
        <SelectCenter
          isLoadingCenter={isLoadingCenter}
          centers={centers}
          hoveredCenter={hoveredCenter}
          onSelectCenter={handleSelectCenter}
          refs={refs}
        />
      )}
    </>
  )
}

CenterSelector.defaultProps = {
  title: 'changeCenter',
  onSelectCenter: () => null,
  customSelectCallback: false,
  showSubtitle: true,
}

CenterSelector.propTypes = {
  title: PropTypes.string,
  onSelectCenter: PropTypes.func,
  customSelectCallback: PropTypes.bool,
  showSubtitle: PropTypes.bool,
}

export default CenterSelector
