import React, { useState, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import colors from 'Assets/colors'
import PropTypes from 'prop-types'
import { PurchaseTypes } from 'Redux/purchases'
import { AutocompleteInput, CalendarInput } from 'Components/molecules'
import { TextInput, SelectInput } from 'Components/atoms'
import SearchIcon from 'Assets/Icons/Search'
import get from 'lodash/get'
import omitBy from 'lodash/omitBy'
import { ORDER_STATUS } from 'Config/constants'

import {
  Title,
  Message,
  FiltersContainer,
  FullWidthFilterItems,
  SplitWidthFilterItems,
  ItemContainer,
  InputLabel,
  CleanButton,
  ActionButton,
  ButtonLiteral,
  useStyles,
} from './styled'

const ModalHistoryFilter = ({ onChange, message, color, title, value }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [filters, setFilter] = useState(value)

  const [visible, setVisible] = useState({})
  const classes = useStyles()

  const providers = useSelector(({ purchase }) => purchase.historicProviders)
  const centers = useSelector(({ purchase }) => purchase.historicCenters)

  const handleFocus = id => {
    setVisible({ ...visible, [id]: true })
  }
  const handleBlur = id => {
    setVisible({ ...visible, [id]: false })
  }

  const handleChange = newfilters => {
    setFilter(omitBy(newfilters, e => !e))
  }

  const numFilters = myFilters => {
    let filtersLength = myFilters ? Object.keys(myFilters).length : 0
    if (Object.prototype.hasOwnProperty.call(filters, 'ids')) {
      filtersLength -= 1
    }
    const oneDateFilters = myFilters.startDate && myFilters.endtDate ? filtersLength - 1 : filtersLength
    return oneDateFilters
  }

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(filters, 'ids')) {
      setFilter(prev => {
        const auxFilter = { ...prev }
        delete auxFilter.ids
        return auxFilter
      })
    }
  }, [filters, value])

  return (
    <>
      <Title color={color}>{title}</Title>
      <Message>{message}</Message>
      <FiltersContainer>
        <SplitWidthFilterItems>
          <ItemContainer>
            <InputLabel>{t('filterLabelDate')}</InputLabel>
            <CalendarInput
              label={t('filterPlaceholderDate')}
              onRemove={() => {
                const { startDate, endDate, ...restFilters } = filters
                handleChange(restFilters)
              }}
              value={filters.startDate && filters.endDate ? [filters.startDate, filters.endDate] : null}
              onChange={valueInput => handleChange({ ...filters, ...valueInput })}
              selectRange
            />
          </ItemContainer>
          <ItemContainer>
            <InputLabel>{t('filterLabelState')}</InputLabel>
            <SelectInput
              className={classes.selectInput}
              placeholder={t('filterPlaceholderState')}
              id='status'
              value={filters.status}
              onFocus={() => handleFocus('status')}
              onBlur={() => handleBlur('status')}
              onChange={e => handleChange({ ...filters, status: e.target.value })}
              background={visible.status ? colors.white : '#F4F1F1'}
              borderRadius={visible.status ? '8px 8px 0 0' : '8px'}
              boxShadow={visible.status ? '0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)' : 'none'}
              opacity={visible.status ? '0' : '1'}
              choices={[
                { id: null, name: null },
                { id: ORDER_STATUS.PR, name: t(`orderStatus.${ORDER_STATUS.PR}`) },
                { id: ORDER_STATUS.RC, name: t(`orderStatus.${ORDER_STATUS.RC}`) },
                { id: ORDER_STATUS.PE, name: t(`orderStatus.${ORDER_STATUS.PE}`) },
                { id: ORDER_STATUS.CN, name: t(`orderStatus.${ORDER_STATUS.CN}`) },
              ]}
            />
          </ItemContainer>
        </SplitWidthFilterItems>
        <SplitWidthFilterItems>
          <ItemContainer>
            <InputLabel>{t('filterLabelProvider')}</InputLabel>
            <AutocompleteInput
              value={filters.provider}
              onChange={valueInput => {
                handleChange({ ...filters, provider: valueInput ? valueInput.id : null })
              }}
              onSearch={valueInput =>
                dispatch({
                  type: PurchaseTypes.FETCH_HISTORIC_PROVIDERS,
                  params: { search: valueInput, limit: 50 },
                })
              }
              choices={get(providers, 'data')}
              placeholder={t('filterPlaceholderProvider')}
              topIcon='1.2rem'
              Icon={props => <SearchIcon fill={visible.provider ? colors.secondary : colors.black} {...props} />}
              onFocus={() => handleFocus('provider')}
              onBlur={() => handleBlur('provider')}
              borderRadius={visible.provider ? '8px 8px 0 0' : '8px'}
              background={visible.provider ? colors.white : '#F4F1F1'}
            />
          </ItemContainer>
          <ItemContainer>
            <InputLabel>{t('filterLabelOrigin')}</InputLabel>
            <AutocompleteInput
              value={filters.origin}
              onChange={valueInput => handleChange({ ...filters, origin: valueInput ? valueInput.id : null })}
              onSearch={valueInput => {
                dispatch({
                  type: PurchaseTypes.FETCH_HISTORIC_CENTERS,
                  params: { search: valueInput, limit: 50 },
                })
              }}
              choices={get(centers, 'data')}
              placeholder={t('filterPlaceholderOrigin')}
              topIcon='1.2rem'
              Icon={props => <SearchIcon fill={visible.origin ? colors.secondary : colors.black} {...props} />}
              onFocus={() => handleFocus('origin')}
              onBlur={() => handleBlur('origin')}
              borderRadius={visible.origin ? '8px 8px 0 0' : '8px'}
              background={visible.origin ? colors.white : '#F4F1F1'}
            />
          </ItemContainer>
        </SplitWidthFilterItems>
        <SplitWidthFilterItems>
          <ItemContainer>
            <InputLabel>
              {t('filterLabelOrderNumber')} {t('filterLabelOrderNumberRes')}
            </InputLabel>
            <TextInput
              value={filters.id}
              onChange={e => {
                const { value: inputValue } = e.target
                const numeric = inputValue.replace(/\D/g, '').slice(0, 8)
                handleChange({ ...filters, id: numeric })
              }}
              onFocus={() => handleFocus('id')}
              onBlur={() => handleBlur('id')}
              placeholder={t('filterPlaceholderOrderNumber')}
              background={visible.id ? colors.white : '#F4F1F1'}
              borderRadius='8px'
            />
          </ItemContainer>
          <ItemContainer>
            <InputLabel>{t('filterLabelRequestNumber')}</InputLabel>
            <TextInput
              value={filters.request}
              onChange={e => handleChange({ ...filters, request: e.target.value })}
              onFocus={() => handleFocus('request')}
              onBlur={() => handleBlur('request')}
              placeholder={t('filterPlaceholderRequestNumber')}
              background={visible.request ? colors.white : '#F4F1F1'}
              borderRadius='8px'
            />
          </ItemContainer>
        </SplitWidthFilterItems>
        <FullWidthFilterItems onClick={() => setFilter({})}>
          <CleanButton disabled={numFilters(filters) <= 0}>
            <ButtonLiteral>{t('filterButtonClean')}</ButtonLiteral>
          </CleanButton>
        </FullWidthFilterItems>
        <FullWidthFilterItems>
          <ActionButton onClick={() => onChange(filters)}>
            <ButtonLiteral>{t('filterButtonSearch')}</ButtonLiteral>
          </ActionButton>
        </FullWidthFilterItems>
      </FiltersContainer>
    </>
  )
}

ModalHistoryFilter.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onConfirm: PropTypes.func,
  message: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
}

ModalHistoryFilter.defaultProps = {
  showModal: false,
  setShowModal: () => {},
  onConfirm: () => {},
  message: '',
  color: '',
  title: '',
  width: '',
  height: '',
  value: {},
  onChange: () => {},
}

export default ModalHistoryFilter
