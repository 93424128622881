import React from 'react'

import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

const NumberFormatCustom = ({
  value,
  displayType,
  suffix,
  thousandSeparator,
  decimalSeparator,
  decimalScale,
  fixedDecimalScale,
  placeholder,
}) => (
  <NumberFormat
    placeholder={placeholder}
    value={value}
    displayType={displayType}
    suffix={` ${suffix}`}
    thousandSeparator={thousandSeparator}
    decimalSeparator={decimalSeparator}
    decimalScale={decimalScale}
    fixedDecimalScale={fixedDecimalScale}
  />
)

NumberFormatCustom.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayType: PropTypes.string,
  placeholder: PropTypes.string,
  suffix: PropTypes.string,
  thousandSeparator: PropTypes.string,
  decimalSeparator: PropTypes.string,
  decimalScale: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fixedDecimalScale: PropTypes.bool,
}

NumberFormatCustom.defaultProps = {
  value: '',
  displayType: 'text',
  suffix: '€',
  thousandSeparator: '.',
  decimalSeparator: ',',
  placeholder: '',
  decimalScale: '2',
  fixedDecimalScale: true,
}

export default NumberFormatCustom
