import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { fetchCancelled } from 'Redux/carts/actions'

const useCartsCancelled = () => {
  const dispatch = useDispatch()

  const data = useSelector(state => state.carts.cancelled)
  const isFetching = useSelector(state => state.approver.isFetchingCancelled)
  const filterProviders = useSelector(state => state.carts.filterProviders)
  const filterRequestNumber = useSelector(state => state.carts.filterRequestNumber)

  const isFilterActive = !!filterProviders?.length || !!filterRequestNumber

  useEffect(() => {
    dispatch(fetchCancelled())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProviders, filterRequestNumber])

  return { data, isFetching, isFilterActive }
}

export default useCartsCancelled
