import React, { useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NumberFormat } from 'Components/atoms'
import ProductInfoLoader from 'Components/atoms/productInfoLoader'
import ProductNameLoader from 'Components/atoms/productNameLoader'
import { getRequestOrderApprovalChanges } from 'Services/api'

import * as S from './styled'
import SingleRowProduct from './productRow'
import {
  SingleProductName,
  SingleProductPriceContainer,
  SingleProductQtyContainer,
  SingleProductApprovedQty,
} from './productRow/styled'

const ModalMulticenterProducts = ({ center, isLoading, order }) => {
  const { t } = useTranslation()

  const { requiresApproval, showPrice } = center
  const { id, provider } = order

  const [totalPrice, setTotalPrice] = useState(0)
  const [totalProducts, setTotalProducts] = useState(0)
  const [approvalChanges, setApprovalChanges] = useState({})

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (order?.lines?.length) {
      const total = order.lines.reduce((prev, product) => prev + product.price, 0)

      setTotalPrice(total)
      setTotalProducts(order.lines.length)
    }

    if (center.requiresApproval) {
      getRequestOrderApprovalChanges(center.id, order.parentRequest).then(setApprovalChanges)
    }
  }, [center, isLoading, order])

  const orderProducts = useMemo(() => {
    if (!order?.lines) {
      return []
    }

    if (!approvalChanges?.lines?.length) {
      return order.lines
    }

    return order.lines.map(orderProduct => ({
      ...orderProduct,
      approvalChanges: approvalChanges?.lines.find(({ productId }) => productId === orderProduct.reference),
    }))
  }, [order, approvalChanges])

  return (
    <>
      {id && (
        <S.Title>
          {t`orderProducts`} {id}
        </S.Title>
      )}

      <S.ProductsContainer>
        <S.ProductsHeader>
          <h3>{provider?.name}</h3>
          <div>
            {showPrice && <NumberFormat value={totalPrice} />}
            <S.TotalProducts>
              {totalProducts === 1
                ? `${Math.abs(totalProducts)} ${t('product').toLowerCase()}`
                : `${Math.abs(totalProducts)} ${t('products').toLowerCase()}`}
            </S.TotalProducts>
          </div>
        </S.ProductsHeader>

        <S.ProductsList>
          <S.TableHeaderRow withPrice={showPrice}>
            <S.TableHeaderItem>{t('products')}</S.TableHeaderItem>
            {showPrice && <S.TableHeaderItem>{t('sum')}</S.TableHeaderItem>}
            <S.TableHeaderItem>{t('requestedAmount')}</S.TableHeaderItem>
            {requiresApproval && <S.TableHeaderItem>{t('approvedAmount')}</S.TableHeaderItem>}
          </S.TableHeaderRow>

          {isLoading && (
            <S.SingleProductRowCL>
              <SingleProductName>
                <ProductNameLoader />
              </SingleProductName>
              <SingleProductPriceContainer>
                <ProductInfoLoader />
              </SingleProductPriceContainer>
              <SingleProductQtyContainer>
                <ProductInfoLoader />
              </SingleProductQtyContainer>
              {requiresApproval && (
                <SingleProductApprovedQty>
                  <ProductInfoLoader />
                </SingleProductApprovedQty>
              )}
            </S.SingleProductRowCL>
          )}

          {orderProducts.map(product => (
            <SingleRowProduct
              key={`${product.documentLineNumber}_${product.itemId}`}
              product={product}
              showPrice={showPrice}
              requiresApproval={requiresApproval}
            />
          ))}
        </S.ProductsList>
      </S.ProductsContainer>
    </>
  )
}

ModalMulticenterProducts.propTypes = {
  center: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  order: PropTypes.object,
}

ModalMulticenterProducts.defaultProps = {
  isLoading: false,
  order: {},
}

export default ModalMulticenterProducts
