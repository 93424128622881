import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Title, Message, Row, Cancel } from './styled'

const ErrorDisplay = ({ title, message, color, onConfirm }) => {
  const { t } = useTranslation()

  return (
    <>
      <Title color={color}>{title}</Title>
      <Message>{message}</Message>
      <Row>
        <Cancel color={color} onClick={onConfirm}>
          {t('close')}
        </Cancel>
      </Row>
    </>
  )
}

ErrorDisplay.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string,
  onConfirm: PropTypes.func,
}

ErrorDisplay.defaultProps = {
  title: '',
  message: '',
  color: 'red',
  onConfirm: () => {},
}

export default ErrorDisplay
