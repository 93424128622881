import {
  SET_TICKET_CATEGORIES,
  SET_TICKET_IS_LOADING,
  SET_TICKET_ERROR,
  FETCH_CATEGORIES,
  SET_TICKETS_LIST,
  SET_TICKET,
  FETCH_TICKETS,
  FETCH_TICKET,
  SET_FILTER_STATUS,
  SET_TICKET_COMMENT_IS_LOADING,
  ADD_TICKET_COMMENT,
  SET_PENDING_TICKETS_LIST,
  FETCH_PENDING_TICKETS,
} from './types'

export const setTicketCategories = payload => ({
  type: SET_TICKET_CATEGORIES,
  payload,
})

export const setTicketError = payload => ({
  type: SET_TICKET_ERROR,
  payload,
})

export const setTicketsIsLoading = payload => ({
  type: SET_TICKET_IS_LOADING,
  payload,
})

export const setTickets = payload => ({
  type: SET_TICKETS_LIST,
  payload,
})

export const setPendingTickets = payload => ({
  type: SET_PENDING_TICKETS_LIST,
  payload,
})

export const fetchPendingTickets = params => ({
  type: FETCH_PENDING_TICKETS,
  params,
})

export const setTicket = payload => ({
  type: SET_TICKET,
  payload,
})

export const setFilterStatus = payload => ({
  type: SET_FILTER_STATUS,
  payload,
})

export const setTicketCommentIsLoading = payload => ({
  type: SET_TICKET_COMMENT_IS_LOADING,
  payload,
})

export const fetchTicket = id => ({
  type: FETCH_TICKET,
  params: { id },
})

export const fetchCategories = () => ({
  type: FETCH_CATEGORIES,
})

export const fetchTickets = params => ({
  type: FETCH_TICKETS,
  params,
})

export const addTicketComment = params => ({
  type: ADD_TICKET_COMMENT,
  params,
})
