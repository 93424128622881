export const ANALYTICS_CATEGORY_TYPE = {
  product: 'tipo-producto',
  subCategory: 'subcategoria',
  category: 'categoria',
}

export const ANALYTICS_PURCHASE_TYPE = {
  buy: 'compra',
  approved: 'aprobacion',
}

export const ANALYTICS_TYPE_ADD_TO_CARD = {
  new: 'nuevo',
  repeat: 'repetir',
  extend: 'extender',
}

export const ANALYTICS_CATEGORY_ALL = 'Todas las categorías'

export const ANALYTICS_EVENT = {
  ADD_TO_CART: 'add_to_cart',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  BEING_CHECKOUT: 'being_checkout',
  PURCHASE: 'purchase',
  REMOVE_FROM_CART: 'remove_from_cart',
  SELECT_ITEM: 'select_item',
  SET_CENTER: 'set_center',
  SET_USER_PROPERTIES: 'set_user_properties',
  VIEW_ITEM_LIST: 'view_item_list',
}
