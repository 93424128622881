import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { fetchLists, setListData, setListProducts } from 'Redux/lists/actions'

const useShoppingLists = () => {
  const dispatch = useDispatch()

  const isFetching = useSelector(s => s.lists.isFetchingLists)
  const lists = useSelector(s => s.lists.lists)
  const selectedList = useSelector(s => s.lists.data)
  const isSendingCart = useSelector(s => s.lists.isSendingCart)

  useEffect(() => {
    if (!lists?.length) {
      dispatch(fetchLists())
    }

    return () => {
      dispatch(setListData(null))
      dispatch(setListProducts(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const onSeeProducts = item => dispatch(setListData(item))

  return {
    isFetching,
    selectedList,
    isSendingCart,
    onSeeProducts,
  }
}

export default useShoppingLists
