import React from 'react'

import PropTypes from 'prop-types'

import { Input, Label, ErrorContainer, Container } from './styled'

const TextArea = ({
  placeholder,
  placeholderColor,
  topIcon,
  rightIcon,
  value,
  onChange,
  Icon,
  width,
  height,
  refProp,
  borderRadius,
  border,
  onKeyDown,
  margin,
  onFocus,
  onBlur,
  padding,
  background,
  name,
  error,
}) => (
  <Container margin={margin} width={width}>
    <Label background={background} borderRadius={borderRadius} topIcon={topIcon} rightIcon={rightIcon}>
      <Input
        onKeyDown={onKeyDown}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        ref={refProp}
        borderRadius={borderRadius}
        border={border}
        onFocus={onFocus}
        onBlur={onBlur}
        padding={padding}
        height={height}
        name={name}
        error={error}
      />
      <Icon className='icon-input' />
    </Label>
    {error && <ErrorContainer>{error}</ErrorContainer>}
  </Container>
)

TextArea.propTypes = {
  placeholder: PropTypes.string,
  placeholderColor: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  width: PropTypes.string,
  refProp: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  borderRadius: PropTypes.string,
  border: PropTypes.string,
  onKeyDown: PropTypes.func,
  topIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  margin: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  padding: PropTypes.string,
  background: PropTypes.string,
  height: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
}

TextArea.defaultProps = {
  placeholder: '',
  placeholderColor: '',
  value: '',
  onChange: () => {},
  Icon: () => <></>,
  width: '100%',
  refProp: () => {},
  borderRadius: '8px',
  border: '',
  onKeyDown: () => {},
  rightIcon: '1rem',
  topIcon: '1.5rem',
  margin: '',
  onFocus: () => {},
  onBlur: () => {},
  padding: '1rem',
  background: '',
  height: '',
  name: '',
  error: '',
}

export default TextArea
