import axios from 'axios'
import { REQUEST_ORDER_STATE } from 'Config/constants'
import qs from 'qs'

const getApproverRequestOrders = ({ closeToAutoApproved, centerId, status, providers, sort, order, requestId }) => {
  const params = qs.stringify(
    {
      closeToAutoApproved,
      centerId,
      status,
      providers,
      sort,
      order,
      limit: 100,
      ids: requestId ? JSON.stringify([requestId]) : null,
    },
    { skipNulls: true }
  )
  return axios.get(`api/approval/request-orders?${params}`)
}

export const getApproverCloseToAutoApproved = (center, providers, requestId) =>
  getApproverRequestOrders({
    closeToAutoApproved: true,
    centerId: center?.id,
    providers: providers?.map(o => o.key),
    sort: 'deliveryDate',
    order: 'desc',
    requestId,
  })

export const getApproverPendingApproval = (center, providers, requestId) =>
  getApproverRequestOrders({
    closeToAutoApproved: false,
    centerId: center?.id,
    providers: providers?.map(o => o.key),
    sort: 'deliveryDate',
    order: 'desc',
    requestId,
  })

export const getApproverRejected = (center, providers, requestId) =>
  getApproverRequestOrders({
    status: REQUEST_ORDER_STATE.REJECTED,
    centerId: center?.id,
    providers: providers?.map(o => o.key),
    sort: 'requestOrderId',
    order: 'desc',
    requestId,
  })

export const getApproverApproved = (center, providers, requestId) =>
  getApproverRequestOrders({
    status: REQUEST_ORDER_STATE.APPROVED,
    centerId: center?.id,
    providers: providers?.map(o => o.key),
    sort: 'requestOrderId',
    order: 'desc',
    requestId,
  })

export const getApproverCart = requestOrderId => axios.get(`/api/approval/cart/${requestOrderId}`)

export const confirmApproverCart = (requestOrderId, params) =>
  axios.post(`/api/v2/approval/cart/${requestOrderId}/confirm`, params)

export const confirmApproverCartSQS = (requestOrderId, params) =>
  axios.post(`/api/v3/approval/cart/${requestOrderId}/confirm`, params)

export const rejectApproverCart = requestOrderId => axios.post(`/api/approval/request-orders/${requestOrderId}/reject`)

export const updateApproverCart = (requestOrderId, products) =>
  axios.put(`/api/approval/cart/${requestOrderId}/product`, { products })

export const deleteApproverProviderProducts = (requestOrderId, providerId) =>
  axios.delete(`/api/approval/cart/${requestOrderId}/provider-products/${providerId}`)

export const getApproverRequestOrderCount = () => axios.get('/api/approval/request-orders/count')

export const putApproverDeliveryDate = (requestOrderId, payload) =>
  axios.put(`/api/approval/cart/${requestOrderId}/delivery`, payload)

export const putApproverOriginCenter = (requestOrderId, centerId) =>
  axios.put(`/api/approval/cart/${requestOrderId}/origin-center`, { center: centerId })

export const getApproverOriginCenters = centerId =>
  axios.get('/api/centers/origins', { headers: { 'X-Center-Id': centerId } })

export const getWeekSummary = (centerId, params) =>
  axios.get(`/api/approval/request-orders/weekly-report?startDate=${params.startDate}&endDate=${params.endDate}`, {
    headers: { 'X-Center-Id': centerId },
  })
