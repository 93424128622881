import styled from 'styled-components'
import colors from 'Assets/colors'
import CircularProgress from '@material-ui/core/CircularProgress'

export const InputLabel = styled.label`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
  color: ${colors.black};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled.div`
  width: 100%;
  background-color: ${colors.secondary};
  padding: 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  pointer-events: ${props => (props.submitLoading ? 'none' : 'all')};

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const ButtonLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`

export const MicrosoftLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  background: ${colors.white};
  border: 1px solid ${colors.secondary};
  border-radius: 8px;
  height: 56px;
  cursor: pointer;
  transition: all 0.2s;
  pointer-events: ${props => (props.submitLoading ? 'none' : 'all')};

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }

  span {
    margin-left: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.black};
  }
`

export const MsalButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  background: ${colors.secondary};
  border-radius: 8px;
  height: 56px;
  cursor: pointer;
  transition: all 0.2s;
  pointer-events: ${props => (props.submitLoading ? 'none' : 'all')};

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }

  span {
    margin-left: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.white};
  }
`

export const CircularProgressStyled = styled(CircularProgress)`
  margin-right: 10px;
  margin-left: 10px;
  &.MuiCircularProgress-colorPrimary {
    color: ${colors.white};
  }
`

export const MSLogoContainer = styled.div`
  text-align: center;
  padding-bottom: 8px;
`
