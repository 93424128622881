import React from 'react'

import { useSelector } from 'react-redux'

import OrderFromOtherView from './view'

const OrderFromOther = () => {
  const isLoadingPreviusOrder = useSelector(state => state.purchase.isLoadingPreviusOrder)
  const errorPreviusOrder = useSelector(state => state.purchase.errorPreviusOrder)
  const previusOrderProducts = useSelector(state => state.purchase.previusOrderProducts)

  return (
    <OrderFromOtherView
      totalByProvider={previusOrderProducts.total}
      productsToShow={previusOrderProducts.lines}
      provider={previusOrderProducts.provider ? previusOrderProducts.provider.name : ''}
      isLoadingPreviusOrder={isLoadingPreviusOrder}
      errorPreviusOrder={errorPreviusOrder}
      requestId={previusOrderProducts.id}
    />
  )
}

export default OrderFromOther
