import React, { useCallback, useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CartDrawer } from 'Components/organisms'
import routes from 'Config/routes'
import { AuthActions } from 'Redux/auth'
import { setIsRepeatingOrder } from 'Redux/multicenter/actions'

const MulticenterRepeatOrder = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(setIsRepeatingOrder(true))
  }, [dispatch])

  const handleCloseCart = useCallback(() => {
    if (history.location.pathname !== routes.multicenterRepeatOrder) {
      return
    }

    dispatch(AuthActions.setCenter({}))
    dispatch(setIsRepeatingOrder(false))
    history.replace(routes.multicenterOrders)
  }, [history, dispatch])

  return <CartDrawer setShowDrawer={handleCloseCart} initialExpanded showDrawer />
}

export default MulticenterRepeatOrder
