import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Title, Message, ActionContainer, CancelButton, RepeatOrderButton, ButtonLiteral } from './styled'

const ModalRepeatOrder = ({ message, color, title, isLoading, setShowModal, repeatOrder }) => {
  const { t } = useTranslation()
  return (
    <>
      <Title color={color}>{title}</Title>
      <Message>{message}</Message>
      <ActionContainer>
        <CancelButton onClick={() => setShowModal(false)} disabled={isLoading}>
          <ButtonLiteral>{t('cancelButton')}</ButtonLiteral>
        </CancelButton>
        <RepeatOrderButton onClick={repeatOrder} disabled={isLoading}>
          <ButtonLiteral>{t('repeatOrder')}</ButtonLiteral>
          {isLoading && <CircularProgress size={16} />}
        </RepeatOrderButton>
      </ActionContainer>
    </>
  )
}

ModalRepeatOrder.propTypes = {
  message: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  setShowModal: PropTypes.func,
  repeatOrder: PropTypes.func,
}

ModalRepeatOrder.defaultProps = {
  message: '',
  color: '',
  title: '',
  isLoading: false,
  setShowModal: () => {},
  repeatOrder: () => {},
}

export default ModalRepeatOrder
