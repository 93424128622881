import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: #ffffff;
  transition: all 0.4s;
`
export const Row = styled.div`
  display: flex;
  width: 20rem;
  margin-bottom: 32px;
`
export const ItemsRow = styled.div`
  display: flex;
  width: 40rem;
  margin-left: 32px;
`

export const TextItem = styled.div`
  margin-left: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: colors.black;
`

export const Title = styled.div`
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: colors.black;
  margin-bottom: 64px;
`

export const DetailSection = styled.div`
  display: flex;
  margin-top: 20px;
  width: 40rem;
`
export const DetailSectionBorder = styled.div`
  border-top: 1px solid rgba(51, 51, 51, 0.24);
  width: 40;
`

export const DetailTitle = styled.div`
  margin-top: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: colors.black;
  width: 17rem;
`
export const DetailTitleComments = styled(DetailTitle)`
  width: 20rem;
`
export const DetailText = styled.div`
  margin-top: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: colors.black;
  width: 17rem;
`
export const DetailTextComments = styled(DetailText)`
  width: 20rem;
`

export const DetailSectionText = styled.div`
  display: flex;

  width: 40rem;
`
