import styled from 'styled-components'
import colors from 'Assets/colors'
import { Button } from '@material-ui/core'

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
`

export const IconContainer = styled.div`
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.gray40l};
  margin-right: 1.75rem;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.black};
`

export const Provider = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.gray};
  margin-bottom: 3rem;
`

export const Price = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.black};
`

export const PriceKG = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.gray};
  margin-left: 12px;
  margin-top: 4px;
`

export const MinAndMulti = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.gray40l};
`

export const Border = styled.div`
  border-bottom: 1px solid rgba(51, 51, 51, 0.24);
  max-height: 0;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
`

export const AllergensTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 0.5rem;
  width: 37rem;
`

export const Allergens = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 1.5rem;
`

export const CommentsContainer = styled.div`
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  margin-top: 20px;
`

export const Comment = styled.div`
  padding: 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
`

export const DifferencePrice = styled.div`
  display: flex;
  align-items: center;
`

export const PreviousPrice = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  text-decoration: line-through;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
`

export const DifferencePriceKG = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.gray};
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
`

export const MoreInfoPrice = styled.p`
  margin: 0px;
  padding: 0px;
  margin-bottom: 24px;
  color: ${props => props.theme.colors.gray};
`

export const BoldText = styled.span`
  font-weight: bold;
  color: ${props => props.theme.colors.black};
  font-size: 12px;
`

export const RowFullScreen = styled.div`
  display: flex;
  width: 50rem;
`
export const IconSection = styled.div`
  margin-top: 3rem;
  justify-content: space-between;
`
export const IconContainerFullScreen = styled.div`
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.gray40l};
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  margin-right: 1.75rem;
`

export const RowFullScreenIcon = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50rem;
`
export const BtnSection = styled.div`
  display: flex;
  gap: 48px;
  width: 100%;
`

export const ReturnText = styled.div`
  font-family: Inter;
  font-style: normal;
  display: flex;
  font-size: 14px;
  flex-direction: row;

  color: ${props => props.theme.colors.black};
  padding: 0.5rem 1rem;
  cursor: pointer;
  background: #ffffff;

  .icon {
    margin-right: 0.5rem;
  }
`
export const ReturnSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
`

export const AddListButton = styled(Button)`
  text-decoration: underline !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 600 !important;
  text-transform: none !important;
  width: 280px !important;
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`

export const PriceRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-family: Inter;
    font-style: normal;
    font-size: 14px;
  }
`
