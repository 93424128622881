import { all, delay, call, put, select, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import get from 'lodash/get'
import { getAnnouncements } from 'Services/api'
import * as Sentry from 'Services/sentry'

import { FETCH_ANNOUNCEMENTS } from './types'
import { selectCenterId } from './selectors'
import { setAnnouncements, setAnnouncementsError, setAnnouncementsIsLoading } from './actions'

export function* fetchAnouncements(action) {
  yield delay(100)
  yield put(setAnnouncementsIsLoading(true))

  try {
    const centerId = yield select(selectCenterId)
    const params = get(action, 'params', {})
    const response = yield call(getAnnouncements, centerId, { ...params })
    yield put(setAnnouncements({ list: response, count: response.length }))
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
      yield put(setAnnouncementsError(e))
    }
  }

  yield put(setAnnouncementsIsLoading(false))
}

function* watchFetchAnouncements() {
  yield takeLatest(FETCH_ANNOUNCEMENTS, fetchAnouncements)
}

export default function* rootAnnouncementsSaga() {
  yield all([watchFetchAnouncements()])
}
