import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import TypeL from 'Assets/Icons/TypeL'
import colors from 'Assets/colors'

import { Type, TypeText } from './styled'

const Button = ({ id, name, navigate }) => {
  const [hovered, setHovered] = useState(false)
  const { keymap3 = {} } = useSelector(state => state.purchase.selectedCategories)
  const [selected, setSelected] = useState(keymap3.id === id)

  useEffect(() => {
    if (selected && keymap3.id !== id) setSelected(false)
    if (!selected && keymap3.id === id) setSelected(true)
    /* eslint-disable-next-line */
  }, [keymap3.id, id])

  const selectCategory = () => {
    setSelected(value => !value)
    navigate(id)
  }

  return (
    <Type onClick={selectCategory} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <TypeL fill={hovered || selected ? colors.secondary : colors.black} />
      <TypeText className='subcategories-text' selected={selected}>
        {name}
      </TypeText>
    </Type>
  )
}

Button.defaultProps = {
  name: '',
  id: '',
  navigate: () => {},
}

Button.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  navigate: PropTypes.func,
}

export default Button
