import React, { useCallback, useEffect, useMemo, useState } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ORDER_STATUS, TABLE_ROWS_PER_PAGE_OPTIONS } from 'Config/constants'
import BasicTable from 'Components/molecules/basicTable'
import routes from 'Config/routes'
import { AuthActions } from 'Redux/auth'
import { fetchCenterOrders, fetchRequestOrders, setIsCreateQa } from 'Redux/multicenter/actions'
import { selectCenterOrders, selectIsLoadingCenter, selectRequestOrders } from 'Redux/multicenter/selectors'

import ActionsCell from './actionsCell'
import RequestOrderCell from './requestOrderCell'
import { StateCell } from './styled'

const MulticenterOrdersTable = ({
  center,
  requiresApproval,
  onViewProductsClick,
  onRepeatOrderClick,
  onDownloadOrderClick,
  onViewQAClick,
  onNoComplianceClick,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const centerId = center.id

  const isLoading = useSelector(selectIsLoadingCenter(centerId))
  const { orders, count } = useSelector(selectCenterOrders(centerId)) || { orders: [], count: 0 }
  const requestOrders = useSelector(selectRequestOrders(centerId)) || []
  const filters = useSelector(({ multicenter }) => multicenter.ordersFilters)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(TABLE_ROWS_PER_PAGE_OPTIONS[0])

  const ordersWithRequest = useMemo(
    () => orders.map(order => ({ ...order, requestOrder: requestOrders.find(({ id }) => order.parentRequest === id) })),
    [orders, requestOrders]
  )

  useEffect(() => {
    dispatch(fetchCenterOrders(centerId, { page, pageSize, filters }))
  }, [dispatch, centerId, filters, page, pageSize])

  useEffect(() => {
    if (!orders?.length || !requiresApproval) {
      return
    }

    dispatch(fetchRequestOrders(centerId, orders))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders])

  const handlePageEvent = useCallback(pageEvent => {
    setPage(pageEvent.page)
    setPageSize(pageEvent.pageSize)
  }, [])

  const handleOnStateClick = useCallback(
    order => {
      if (order.state === ORDER_STATUS.PR) {
        dispatch(AuthActions.setCenter(center))
        dispatch(setIsCreateQa(true))
        history.push(
          routes.deliveryQa
            .replace(':orderId', order.id)
            .replace(':documentTypeCode', order.documentTypeCode)
            .replace(':documentCompany', order.documentCompany)
        )
      }
    },
    [center, history, dispatch]
  )

  const columns = [
    {
      field: 'id',
      headerName: `${t('order')}`,
      width: 138,
      valueFormatter: ({ value }) => `Nº ${value}`,
    },
    {
      field: 'provider',
      headerName: `${t('provider')}`,
      width: 270,
      valueFormatter: ({ value }) => (value ? value.name : '-'),
    },
    {
      field: 'delivery',
      headerName: `${t('deliveryDate')}`,
      width: 130,
      valueFormatter: ({ value }) => (value ? moment(value).format('DD/MM/YYYY') : '-'),
    },
    ...(requiresApproval
      ? [
          {
            field: 'requestOrder',
            headerName: `${t('requestOrder')}`,
            width: 200,
            renderCell: ({ data }) => <RequestOrderCell row={data} />,
          },
        ]
      : []),
    {
      field: 'state',
      headerName: `${t('status')}`,
      width: 126,
      renderCell: ({ data }) => (
        <StateCell state={data.state} onClick={() => handleOnStateClick(data)}>
          {t(`orderStatus.${data.state}`)}
        </StateCell>
      ),
    },
    {
      field: 'actions',
      headerName: `${t('actions')}`,
      width: 350,
      renderCell: ({ data }) => (
        <ActionsCell
          row={data}
          onViewProductsClick={onViewProductsClick}
          onRepeatOrderClick={onRepeatOrderClick}
          onDownloadOrderClick={onDownloadOrderClick}
          onViewQAClick={onViewQAClick}
          onNoComplianceClick={onNoComplianceClick}
        />
      ),
    },
  ].map(column => ({ ...column, resizable: false, sortable: false }))

  return (
    <BasicTable
      autoHeight
      count={count}
      isLoading={!!isLoading}
      page={page}
      pageSize={pageSize}
      paginationLabel='ordersPerPage'
      rows={ordersWithRequest}
      columns={columns}
      onPageChange={handlePageEvent}
      onPageSizeChange={handlePageEvent}
    />
  )
}

MulticenterOrdersTable.propTypes = {
  center: PropTypes.object.isRequired,
  requiresApproval: PropTypes.bool.isRequired,
  onViewProductsClick: PropTypes.func.isRequired,
  onRepeatOrderClick: PropTypes.func.isRequired,
  onDownloadOrderClick: PropTypes.func.isRequired,
  onViewQAClick: PropTypes.func.isRequired,
  onNoComplianceClick: PropTypes.func.isRequired,
}

export default MulticenterOrdersTable
