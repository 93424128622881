import React from 'react'

import PropTypes from 'prop-types'
import { ReactComponent as AddIcon } from 'Assets/Icons/add_cart_mini.svg'

import { AddCartButtonContanier } from './styled'

const AddCartButton = ({ onClick }) => (
  <AddCartButtonContanier onClick={onClick}>
    <AddIcon />
  </AddCartButtonContanier>
)

AddCartButton.propTypes = {
  onClick: PropTypes.func,
}

AddCartButton.defaultProps = {
  onClick: () => null,
}

export default AddCartButton
