import styled from 'styled-components'
import colors from 'Assets/colors'

export const CardContainer = styled.div`
  background: ${colors.white};
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 16px;
  padding: 16px;
  position: relative;
`

export const CardContent = styled.div`
  height: 100%;
`

export const CardBody = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.image ? '1fr 1fr' : '1fr')};
  grid-column-gap: ${props => (props.image ? '20px' : '0')};
  margin-bottom: 48px;
  height: 144px;
`

export const CardTitle = styled.p`
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.black};
  margin-bottom: 16px;
`

export const CardImage = styled.img`
  border-radius: 6px;
  max-height: 144px;
  object-fit: cover;
  width: 100%;
`

export const CardDate = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-right: 16px;
`

export const CardInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: 16px;
`

export const CardCategories = styled.span`
  display: flex;
  flex-wrap: wrap;
`

export const CardCategory = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-right: 16px;
  color: ${colors.secondary};
  transition: all ease 0.2s;
  &:hover {
    color: ${colors.black};
  }
`

export const CardDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
`
