import React from 'react'

import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { useHistory, useLocation } from 'react-router-dom'
import routes from 'Config/routes'
import { ReactComponent as CartUpdated } from 'Assets/Icons/CartUpdated.svg'
import { ReactComponent as CartError } from 'Assets/Icons/CartError.svg'
import { REQUEST_ORDER_STATE } from 'Config/constants'

import { Container, Content, Title, SvgContainer, ButtonsContainer, useStyles } from './styled'

const ApproverSuccessUpdateOrder = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const {
    state: { status },
  } = useLocation()

  const handleClickBack = () => history.push(routes.approverCarts)

  const getTitle = () => {
    switch (status) {
      case REQUEST_ORDER_STATE.APPROVED:
        return t('updateRequestSuccess')
      case REQUEST_ORDER_STATE.REJECTED:
        return t('rejectRequestSuccess')
      default:
        return t('updateRequestSuccess')
    }
  }

  const getIcon = () => {
    switch (status) {
      case REQUEST_ORDER_STATE.APPROVED:
        return CartUpdated
      case REQUEST_ORDER_STATE.REJECTED:
        return CartError
      default:
        return CartUpdated
    }
  }

  const Icon = getIcon()
  const title = getTitle()

  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <SvgContainer>
          <Icon style={{ width: '160px', height: '160px' }} />
        </SvgContainer>
        <ButtonsContainer>
          <Button fullWidth onClick={handleClickBack} className={classes.back}>
            {t('backToRequests')}
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  )
}

export default ApproverSuccessUpdateOrder
