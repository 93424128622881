import { useDispatch, useSelector } from 'react-redux'
import { setFilterRequestNumber } from 'Redux/carts/actions'

const useCartsRequestNumberFilter = () => {
  const dispatch = useDispatch()

  const value = useSelector(state => state.carts.filterRequestNumber)

  const onChange = (requestId) => dispatch(setFilterRequestNumber(requestId))

  return { value, onChange }
}

export default useCartsRequestNumberFilter
