import styled from 'styled-components'
import colors from 'Assets/colors'

export const Row = styled.div`
  background: ${colors.secondary};
  padding: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    path {
      fill: ${colors.white};
    }
  }
`

export const Message = styled.div`
  color: ${colors.white};
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
`
