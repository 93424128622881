import React, { useState, useEffect, useMemo } from 'react'

import PropTypes from 'prop-types'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import Drawer from '@material-ui/core/Drawer'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import { scroller } from 'react-scroll'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ReactComponent as ExclamationCircle } from 'Assets/Icons/ExclamationCircle.svg'
import { ReactComponent as CloseX } from 'Assets/Icons/CloseX.svg'
import { ReactComponent as CleanUp } from 'Assets/Icons/CleanUp.svg'
import { EMPTY_CART, DELETE_PROVIDER_PRODUCT } from 'Redux/purchases/types'
import { fetchCart } from 'Redux/purchases/actions'
import { productState, debounceAmount } from 'Config/constants'
import routes from 'Config/routes'
import { NumberFormat, ProductsNotAvailable } from 'Components/atoms'
import {
  ProductCard,
  ModalConfirmation,
  ProductHorizontalCard,
  CartSearchBar,
  ModalCreateShoppingList,
} from 'Components/molecules'
import { useDebounce } from 'Components/utils/customHooks'
import { hasSomethingInCart, calculateProductAmount } from 'Components/utils/cartCalculator'
import useProductButton from 'Components/utils/useProductButton'
import { setIsRepeatingOrder } from 'Redux/multicenter/actions'
import colors from 'Assets/colors'
import { selectConfig } from 'Redux/auth/utils'

import CartTemplate from '../cartTemplate'

import { getTotalOfValidProducts, providerPrice } from './utils'
import {
  MainRow,
  Row,
  Cart,
  Empty,
  ProductContainer,
  Provider,
  HorizontalProvider,
  useStyles,
  Budget,
  Remaining,
  BudgetRow,
  BudgetKind,
  BudgetAmountPositive,
  FinishButton,
  ButtonLiteral,
  TotalItems,
  BudgetAmountNegative,
  Container,
  NotAvailableTitle,
  NotAvailablesContainer,
  Message,
  ProductCardLoaderContainer,
  SeeButton,
  ButtonCartLiteral,
  HorizontalClose,
  HorizontalMainRow,
  SearchRow,
  HorizontalRedSquare,
  HorizontalButtonNoProducts,
  ExpandedBudget,
  HorizontalProductContainer,
  HorizontalProviderContainer,
  ProviderRow,
  HorizontalTitleSection,
  HorizontalTitleProduct,
  HorizontalTitleTotal,
  HorizontalTitleValue,
  FinishButtonSection,
  Remainings,
  RemainingHorizontal,
  RemainingsSectionHorizontal,
  BudgetRowHorizontal,
  BudgetAmountPositiveHorizontal,
  BudgetAmountNegativeHorizontal,
  BudgetKindHorizontal,
  BudgetKindHorizontalNotRawMaterial,
  BudgetAmountPositiveHorizontalNotRaw,
  BudgetAmountNegativeHorizontalNotRaw,
  EmptyMargin,
  HorizontalText,
  HorizontalProviderNotAvailableContainer,
  NotAvailableBudgetRow,
  HorizontalMessage,
  ProviderNumProducts,
  ProviderPrice,
  CreateListButton,
  AddMoreButton,
} from './styled'

const CartDrawer = ({ showDrawer, setShowDrawer, initialExpanded }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const { onNext, onPreviuos, setRef } = useProductButton()

  const isLoadingCart = useSelector(state => state.purchase.isLoadingCart)
  const errorCart = useSelector(state => state.purchase.errorCart)
  const center = useSelector(state => state.auth.selectedCenter)
  const cart = useSelector(state => state.purchase.cart)
  const { food = 0, nofood = 0 } = useSelector(state => state.purchase.budget) || {}

  const [onConfirmProduct, setOnConfirmProduct] = useState(() => {})
  const [showModalConfirmationAll, setShowModalConfirmationAll] = useState(false)
  const [showModalConfirmationProvider, setShowModalConfirmationProvider] = useState(false)
  const [showModalConfirmationProduct, setShowModalConfirmationProduct] = useState(false)
  const [showUnavailableProducts, setShowUnavailableProducts] = React.useState(false)
  const [providerToEraseId, setProviderToEraseId] = useState('')
  const [expandMode, setExpandMode] = useState(initialExpanded)
  const [queryString, setQueryString] = useState('')
  const [showModalCreateShoppingList, setShowModalCreateShoppingList] = useState(false)
  const isEditingCart = useSelector(({ purchase }) => purchase.isEditingCart)
  const repeatOrderLoading = useSelector(({ purchase }) => purchase.isLoadingRepeatOrder)
  const isUpdatingCart = useSelector(({ purchase }) => purchase.isUpdatingCart)
  const isMulticenterRepeatOrder = useSelector(({ multicenter }) => multicenter.isRepeatingOrder)

  const features = useSelector(selectConfig)
  const { showPrice } = center

  const debounceDrawer = useDebounce(showDrawer, debounceAmount)

  const isDisabledCartButton = repeatOrderLoading || isLoadingCart || isUpdatingCart || isEditingCart

  useEffect(() => {
    if (debounceDrawer === true) dispatch(fetchCart())
  }, [debounceDrawer, dispatch])

  const [okProducts, notAvailable] = _.map(cart)?.reduce(
    ([ok, notOk], elem) => (elem.state === productState.noAvailable ? [ok, [...notOk, elem]] : [[...ok, elem], notOk]),
    [[], []]
  )

  const notAvailableToShow = useMemo(
    () => notAvailable.filter(element => element.name.toUpperCase().includes(queryString.toUpperCase())),
    [notAvailable, queryString]
  )

  const filteredProducts = useMemo(
    () => okProducts.filter(element => element.name.toUpperCase().includes(queryString.toUpperCase())),
    [okProducts, queryString]
  )
  const productsToShow = _.groupBy(filteredProducts, 'provider.name')

  const totalByProvider = useMemo(
    () =>
      _(filteredProducts)
        .groupBy('provider.name')
        .map((item, key) => ({
          index: key,
          total: _.sumBy(item, 'total'),
        }))
        .value(),
    [filteredProducts]
  )

  const hasAtLeastOneProduct = key => productsToShow[key].find(({ amount }) => amount > 0)

  let emptyCart = true
  Object.keys(productsToShow).forEach(key => {
    if ((!_.isEmpty(productsToShow[key]) && hasAtLeastOneProduct(key)) || queryString !== '') {
      emptyCart = false
    }
  })
  if ((notAvailable && notAvailable.length > 0) || queryString !== '') emptyCart = false

  let totalFood = food
  let totalNoFood = nofood

  const fullTotal = hasSomethingInCart(cart)
    ? _.chain(cart)
        .map(({ total, raw_material: rawMaterial }) => {
          if (rawMaterial === 'food') totalFood -= total
          else totalNoFood -= total
          return total
        })
        .sum()
        .value()
    : ''

  const productQuantity = hasSomethingInCart(cart) ? calculateProductAmount(cart) : ''

  const onCloseCart = () => {
    if (expandMode && !isMulticenterRepeatOrder) {
      setExpandMode(false)
    }
    setShowDrawer(false)
  }

  const onEmptyCart = () => dispatch({ type: EMPTY_CART })

  const onAcceptProviderErase = () => {
    dispatch({ type: DELETE_PROVIDER_PRODUCT, providerId: providerToEraseId })
    setShowModalConfirmationProvider(false)
  }

  const onAcceptCartErase = () => {
    setShowModalConfirmationAll(false)
    onEmptyCart()

    if (isMulticenterRepeatOrder) {
      dispatch(setIsRepeatingOrder(false))
    }
  }

  const onFinishPurchase = () => {
    history.push(routes.checkout)
    setShowDrawer(false)
  }

  const onExpandCart = () => {
    setExpandMode(true)
  }

  const handleCreateShoppingList = () => setShowModalCreateShoppingList(true)

  const onCancelCartErase = () => setShowModalConfirmationAll(false)

  const onCancelProviderErase = () => setShowModalConfirmationProvider(false)

  const scrollToNoOkProducts = () => {
    if (showUnavailableProducts) {
      scroller.scrollTo('myScrollToElement', {
        smooth: true,
        containerId: 'containerElement',
      })
    } else {
      setShowUnavailableProducts(true)
    }
  }

  useEffect(() => {
    scroller.scrollTo('myScrollToElement', {
      smooth: true,
      containerId: 'containerElement',
    })
  }, [showUnavailableProducts])

  const deleteProducts = key => {
    const providerId = productsToShow[key][0].provider.id
    setProviderToEraseId(providerId)
    setShowModalConfirmationProvider(true)
  }

  const onCancelProductDelete = () => {
    setShowModalConfirmationProduct(false)
    setOnConfirmProduct(() => {})
  }

  const onSeek = qr => {
    setQueryString(qr)
  }

  const handleAddMoreProducts = () => {
    history.push(routes.categories)
    setShowDrawer(false)
  }

  const renderProducts = () => (
    <>
      <MainRow>
        <Row>
          <CloseX style={{ marginRight: '1rem' }} className='hoverable' onClick={onCloseCart} />
          <Cart>{t('cart')}</Cart>
          {isLoadingCart && <CircularProgress color='inherit' size={20} style={{ marginLeft: 8 }} />}
        </Row>
        {errorCart ? null : (
          <Row className='hoverable' onClick={() => setShowModalConfirmationAll(true)}>
            <CleanUp style={{ marginRight: '0.5rem' }} />
            <Empty>{t('emptyCart')}</Empty>
          </Row>
        )}
      </MainRow>

      {emptyCart ? (
        <CartTemplate variant='empty' />
      ) : (
        <>
          {isLoadingCart && emptyCart ? (
            <ProductCardLoaderContainer>
              <ProductCardLoader />
            </ProductCardLoaderContainer>
          ) : (
            <ProductContainer name='containerElement' id='containerElement'>
              {errorCart ? (
                <CartTemplate variant='error' />
              ) : (
                <>
                  {notAvailableToShow.length > 0 ? (
                    <ProductsNotAvailable onClick={scrollToNoOkProducts} cursor='pointer' />
                  ) : null}
                  {Object.keys(productsToShow).map(key => {
                    if (!hasAtLeastOneProduct(key)) return null

                    const totalOfProducts = getTotalOfValidProducts(productsToShow[key])

                    return (
                      <Container key={key}>
                        <Row justifyContent='space-between' margin='0 0 0.5rem'>
                          <Provider>{key}</Provider>
                          <CleanUp
                            style={{ margin: '0.5rem', cursor: 'pointer' }}
                            onClick={() => deleteProducts(key)}
                          />
                        </Row>
                        <Row margin='0 0 1.5rem' justifyContent='space-between'>
                          <ProviderPrice>
                            {showPrice && <NumberFormat value={providerPrice(productsToShow[key])} />}
                          </ProviderPrice>
                          <ProviderNumProducts>
                            {`${totalOfProducts} ${totalOfProducts === 1 ? t('product') : t('products')}`}
                          </ProviderNumProducts>
                        </Row>
                        {productsToShow[key].map(
                          ({
                            id,
                            measureUnit,
                            min,
                            multiple,
                            name,
                            price,
                            pricePerKg,
                            providerName,
                            state,
                            raw_material: productFood,
                            provider,
                            reference,
                            previous_price: previousPrice,
                            availability,
                          }) => (
                            <ProductCard
                              key={id}
                              id={id}
                              isSimpleCard
                              measureUnit={measureUnit}
                              min={min}
                              multiple={multiple}
                              name={name}
                              price={price}
                              pricePerKg={pricePerKg}
                              providerName={providerName}
                              raw_material={productFood}
                              state={state}
                              provider={provider}
                              reference={reference}
                              setOnConfirmProduct={setOnConfirmProduct}
                              setShowModalConfirmationProduct={setShowModalConfirmationProduct}
                              previousPrice={previousPrice}
                              availability={availability}
                              refButton={element => setRef(element, id)}
                              onPrevious={onPreviuos}
                              onNext={onNext}
                            />
                          )
                        )}
                      </Container>
                    )
                  })}
                  {notAvailableToShow.length > 0 && (
                    <NotAvailablesContainer name='myScrollToElement'>
                      <BudgetRow marginBottom='0.5rem'>
                        <NotAvailableTitle>{t('notAvailableProducts')}</NotAvailableTitle>
                      </BudgetRow>
                      <Message>{t('notAvailableProductsMessage')}</Message>
                      {notAvailableToShow.map(
                        ({
                          id,
                          measureUnit,
                          min,
                          multiple,
                          name,
                          price,
                          pricePerKg,
                          providerName,
                          state,
                          raw_material: productFood,
                          provider,
                          reference,
                          previous_price: previousPrice,
                          availability,
                        }) => (
                          <ProductCard
                            key={id}
                            id={id}
                            isSimpleCard
                            measureUnit={measureUnit}
                            min={min}
                            multiple={multiple}
                            name={name}
                            price={price}
                            pricePerKg={pricePerKg}
                            providerName={providerName}
                            raw_material={productFood}
                            state={state}
                            notAvailable
                            provider={provider}
                            reference={reference}
                            previousPrice={previousPrice}
                            availability={availability}
                          />
                        )
                      )}
                    </NotAvailablesContainer>
                  )}
                </>
              )}
            </ProductContainer>
          )}
        </>
      )}
    </>
  )

  const renderHorizontalProducts = () => (
    <>
      <HorizontalMainRow>
        <Row>
          <CloseX style={{ marginRight: '1rem' }} className='hoverable' onClick={onCloseCart} />
          <HorizontalClose>{t('close')}</HorizontalClose>
        </Row>
        {errorCart ? null : (
          <Row className='hoverable' onClick={() => setShowModalConfirmationAll(true)}>
            <CleanUp style={{ marginRight: '0.5rem' }} />
            <Empty>{t('emptyCart')}</Empty>
          </Row>
        )}
      </HorizontalMainRow>
      {emptyCart ? (
        <CartTemplate variant='empty' />
      ) : (
        <>
          <SearchRow>
            <Cart style={{ fontSize: 30 }}>{t('cart')}</Cart>
            <CartSearchBar expanded onSeek={onSeek} />
          </SearchRow>
          {isLoadingCart && emptyCart ? (
            <CircularProgress />
          ) : (
            <HorizontalProductContainer name='containerElement' id='containerElement'>
              {errorCart ? (
                <CartTemplate variant='error' />
              ) : (
                <>
                  {notAvailableToShow.length > 0 ? (
                    <Container>
                      <HorizontalRedSquare>
                        <Row justifyContent='space-between' alignItems='center'>
                          {t('someProductsNotAvailable')}
                          <HorizontalButtonNoProducts onClick={scrollToNoOkProducts}>
                            <ExclamationCircle style={{ marginRight: '0.5rem' }} />
                            {t('seeProducts')}
                          </HorizontalButtonNoProducts>
                        </Row>
                      </HorizontalRedSquare>
                    </Container>
                  ) : null}
                  {Object.keys(productsToShow).map(key => {
                    if (!hasAtLeastOneProduct(key)) return null

                    const totalOfProducts = getTotalOfValidProducts(productsToShow[key])

                    return (
                      <HorizontalProviderContainer key={key}>
                        <ProviderRow>
                          <HorizontalProvider>{key}</HorizontalProvider>
                          <Row className='hoverable' onClick={() => deleteProducts(key)} justifyContent='flex-end'>
                            <CleanUp style={{ margin: '0.5rem', cursor: 'pointer' }} />
                            <EmptyMargin>{t('emptyCart')}</EmptyMargin>
                            <HorizontalText>
                              {showPrice && (
                                <NumberFormat value={totalByProvider.find(element => element.index === key).total} />
                              )}
                            </HorizontalText>
                            <HorizontalText>
                              {`${totalOfProducts} ${totalOfProducts === 1 ? t('product') : t('products')}`}
                            </HorizontalText>
                          </Row>
                        </ProviderRow>

                        <HorizontalTitleSection>
                          <HorizontalTitleProduct>{t('product')}</HorizontalTitleProduct>
                          <HorizontalTitleValue>{t('price')}</HorizontalTitleValue>
                          <HorizontalTitleTotal>{t('totalLine')}</HorizontalTitleTotal>
                        </HorizontalTitleSection>
                        {productsToShow[key].map(
                          ({
                            id,
                            measureUnit,
                            min,
                            multiple,
                            name,
                            price,
                            pricePerKg,
                            providerName,
                            state,
                            raw_material: productFood,
                            provider,
                            reference,
                            previous_price: previousPrice,
                            availability,
                          }) => (
                            <ProductHorizontalCard
                              key={id}
                              id={id}
                              isSimpleCard
                              measureUnit={measureUnit}
                              min={min}
                              multiple={multiple}
                              name={name}
                              price={price}
                              pricePerKg={pricePerKg}
                              providerName={providerName}
                              raw_material={productFood}
                              state={state}
                              provider={provider}
                              reference={reference}
                              setOnConfirmProduct={setOnConfirmProduct}
                              setShowModalConfirmationProduct={setShowModalConfirmationProduct}
                              previousPrice={previousPrice}
                              availability={availability}
                              refButton={element => setRef(element, id)}
                              onPrevious={onPreviuos}
                              onNext={onNext}
                            />
                          )
                        )}
                      </HorizontalProviderContainer>
                    )
                  })}
                  {notAvailableToShow.length > 0 && showUnavailableProducts ? (
                    <HorizontalProviderNotAvailableContainer name='myScrollToElement'>
                      <NotAvailableBudgetRow marginBottom='0.5rem'>
                        <NotAvailableTitle>{t('notAvailableProducts')}</NotAvailableTitle>
                        <HorizontalMessage>{t('notAvailableProductsMessage')}</HorizontalMessage>
                      </NotAvailableBudgetRow>
                      <HorizontalTitleSection>
                        <HorizontalTitleProduct>{t('product')}</HorizontalTitleProduct>
                        <HorizontalTitleValue>{t('total')}</HorizontalTitleValue>
                        <HorizontalTitleTotal>{t('totalLine')}</HorizontalTitleTotal>
                      </HorizontalTitleSection>
                      {notAvailableToShow.map(
                        ({
                          id,
                          measureUnit,
                          min,
                          multiple,
                          name,
                          price,
                          pricePerKg,
                          providerName,
                          state,
                          raw_material: productFood,
                          provider,
                          reference,
                          previous_price: previousPrice,
                          availability,
                        }) => (
                          <ProductHorizontalCard
                            key={id}
                            id={id}
                            isSimpleCard
                            measureUnit={measureUnit}
                            min={min}
                            multiple={multiple}
                            name={name}
                            price={price}
                            pricePerKg={pricePerKg}
                            providerName={providerName}
                            raw_material={productFood}
                            state={state}
                            notAvailable
                            provider={provider}
                            reference={reference}
                            previousPrice={previousPrice}
                            availability={availability}
                          />
                        )
                      )}
                    </HorizontalProviderNotAvailableContainer>
                  ) : null}
                </>
              )}
              <ExpandedBudget>
                {showPrice && (
                  <>
                    <RemainingsSectionHorizontal>
                      <RemainingHorizontal>{t('remainingBudget')}</RemainingHorizontal>
                      <Remainings>
                        <BudgetRowHorizontal>
                          <BudgetKindHorizontal>{t('rawMaterial')}</BudgetKindHorizontal>
                          {totalFood > 0 ? (
                            <BudgetAmountPositiveHorizontal>
                              <NumberFormat value={totalFood} />
                            </BudgetAmountPositiveHorizontal>
                          ) : (
                            <BudgetAmountNegativeHorizontal>
                              <NumberFormat value={totalFood} />
                            </BudgetAmountNegativeHorizontal>
                          )}
                        </BudgetRowHorizontal>
                        <BudgetRowHorizontal>
                          <BudgetKindHorizontalNotRawMaterial>{t('notRawMaterial')}</BudgetKindHorizontalNotRawMaterial>
                          {totalNoFood > 0 ? (
                            <BudgetAmountPositiveHorizontalNotRaw>
                              <NumberFormat value={totalNoFood} />
                            </BudgetAmountPositiveHorizontalNotRaw>
                          ) : (
                            <BudgetAmountNegativeHorizontalNotRaw>
                              <NumberFormat value={totalNoFood} />
                            </BudgetAmountNegativeHorizontalNotRaw>
                          )}
                        </BudgetRowHorizontal>
                      </Remainings>
                    </RemainingsSectionHorizontal>
                  </>
                )}
                {!emptyCart && !expandMode && (
                  <SeeButton onClick={onExpandCart}>
                    <ButtonCartLiteral>{t('seeCart')}</ButtonCartLiteral>
                  </SeeButton>
                )}
                {!emptyCart && expandMode && (
                  <FinishButtonSection>
                    {features?.shopping_lists && (
                      <CreateListButton onClick={handleCreateShoppingList}>
                        {t('shoppingList.createShoppingList')}
                      </CreateListButton>
                    )}
                    {isMulticenterRepeatOrder && (
                      <AddMoreButton onClick={handleAddMoreProducts}>{t('addMoreProducts')}</AddMoreButton>
                    )}
                    <FinishButton onClick={onFinishPurchase} disabled={isDisabledCartButton}>
                      <ButtonLiteral>
                        {t('finishPurchase')}
                        {isDisabledCartButton ? (
                          <CircularProgress style={{ marginLeft: 8 }} color={colors.black} size={16} />
                        ) : null}
                      </ButtonLiteral>
                      {showPrice ? hasSomethingInCart(cart) ? <NumberFormat value={fullTotal} /> : '0€' : null}
                      {hasSomethingInCart(cart) ? <TotalItems>{productQuantity}</TotalItems> : null}
                    </FinishButton>
                  </FinishButtonSection>
                )}
              </ExpandedBudget>
            </HorizontalProductContainer>
          )}
        </>
      )}
    </>
  )

  return (
    <>
      <Drawer
        disableEnforceFocus
        anchor='right'
        open={showDrawer}
        onClose={onCloseCart}
        type='temporary'
        classes={{
          paper: expandMode ? classes.expand : classes.paper,
        }}
      >
        {expandMode ? renderHorizontalProducts() : renderProducts()}

        {!expandMode && (
          <Budget>
            {showPrice && (
              <>
                <Remaining>{t('remainingBudget')}</Remaining>
                <BudgetRow>
                  <BudgetKind>{t('rawMaterial')}</BudgetKind>
                  {totalFood > 0 ? (
                    <BudgetAmountPositive>
                      <NumberFormat value={totalFood} />
                    </BudgetAmountPositive>
                  ) : (
                    <BudgetAmountNegative>
                      <NumberFormat value={totalFood} />
                    </BudgetAmountNegative>
                  )}
                </BudgetRow>
                <BudgetRow>
                  <BudgetKind>{t('notRawMaterial')}</BudgetKind>
                  {totalNoFood > 0 ? (
                    <BudgetAmountPositive>
                      <NumberFormat value={totalNoFood} />
                    </BudgetAmountPositive>
                  ) : (
                    <BudgetAmountNegative>
                      <NumberFormat value={totalNoFood} />
                    </BudgetAmountNegative>
                  )}
                </BudgetRow>
              </>
            )}
            {!emptyCart && (
              <SeeButton onClick={onExpandCart}>
                <ButtonCartLiteral>{t('seeCart')}</ButtonCartLiteral>
              </SeeButton>
            )}
            {features?.shopping_lists && !emptyCart && (
              <CreateListButton onClick={handleCreateShoppingList}>
                {t('shoppingList.createShoppingList')}
              </CreateListButton>
            )}
          </Budget>
        )}
      </Drawer>
      <ModalConfirmation
        onCancel={onCancelCartErase}
        onAccept={onAcceptCartErase}
        showModal={showModalConfirmationAll}
        setShowModal={setShowModalConfirmationAll}
        message={t('warningCart')}
        title={t('titleCart')}
        action={t('actionEmptyCard')}
        color='red'
        width='680px'
        height='256px'
      />
      <ModalConfirmation
        onCancel={onCancelProviderErase}
        onAccept={onAcceptProviderErase}
        showModal={showModalConfirmationProvider}
        setShowModal={setShowModalConfirmationProvider}
        message={t('emptyProviderMessage')}
        title={t('emptyProvider')}
        action={t('actionEmptyProvider')}
        color='red'
        width='680px'
        height='256px'
      />
      <ModalConfirmation
        onCancel={onCancelProductDelete}
        onAccept={onConfirmProduct}
        showModal={showModalConfirmationProduct}
        setShowModal={setShowModalConfirmationProduct}
        message={t('deleteProductMessage')}
        title={t('deleteProductTitle')}
        action={t('deleteProductAction')}
        color='red'
        width='680px'
        height='256px'
      />

      <ModalCreateShoppingList showModal={showModalCreateShoppingList} setShowModal={setShowModalCreateShoppingList} />
    </>
  )
}

CartDrawer.defaultProps = {
  showDrawer: false,
  initialExpanded: false,
  setShowDrawer: () => {},
}

CartDrawer.propTypes = {
  showDrawer: PropTypes.bool,
  initialExpanded: PropTypes.bool,
  setShowDrawer: PropTypes.func,
}

const ProductCardLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width={320}
      height={210}
      viewBox='0 0 320 210'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='14' y='16' rx='3' ry='3' width='256' height='15' />
      <rect x='16' y='99' rx='0' ry='0' width='150' height='10' />
      <rect x='16' y='164' rx='0' ry='0' width='288' height='38' />
      <rect x='16' y='133' rx='0' ry='0' width='150' height='10' />
      <circle cx='296' cy='24' r='10' />
    </ContentLoader>
  )
}

export default CartDrawer
