import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

// eslint-disable-next-line import/order
import React from 'react'

import ReactDOM from 'react-dom'
import { App } from 'Components/system'

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root')
)
