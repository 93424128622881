import { useEffect, useMemo, useState } from 'react'

import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { scroller } from 'react-scroll'
import { deleteProviderProducts, fetchCart, impersonate, rejectCart } from 'Redux/approver/actions'
import { productState, REQUEST_ORDER_STATE } from 'Config/constants'
import { hasSomethingInCart } from 'Components/utils/cartCalculator'
import { useHistory } from 'react-router-dom'
import routes from 'Config/routes'

const useApproverCart = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const cart = useSelector(state => state.approver.cart)
  const requestOrder = useSelector(state => state.approver.requestOrder)
  const isUpdatingCart = useSelector(state => state.approver.isUpdatingCart)
  const isFetchingCart = useSelector(state => state.approver.isFetchingCart)

  const [queryString, setQueryString] = useState('')
  const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false)
  const [showModalConfirmationProduct, setShowModalConfirmationProduct] = useState(false)
  const [onConfirmProduct, setOnConfirmProduct] = useState(() => null)
  const [providerToEraseId, setProviderToEraseId] = useState('')
  const [showModalConfirmationProvider, setShowModalConfirmationProvider] = useState(false)

  useEffect(() => {
    if (requestOrder?.id) {
      dispatch(fetchCart(requestOrder?.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestOrder?.id])

  const food = 0
  const nofood = 0

  const carMap = _.map(cart) || []
  const [okProducts, notAvailable] = carMap?.reduce(
    ([ok, notOk], elem) => (elem.state === productState.noAvailable ? [ok, [...notOk, elem]] : [[...ok, elem], notOk]),
    [[], []]
  )

  const notAvailableToShow = useMemo(
    () => notAvailable.filter(element => element.name.toUpperCase().includes(queryString.toUpperCase())),
    [notAvailable, queryString]
  )

  const filteredProducts = useMemo(
    () => okProducts.filter(element => element.name.toUpperCase().includes(queryString.toUpperCase())),
    [okProducts, queryString]
  )
  const productsToShow = _.groupBy(filteredProducts, 'provider.name')

  const totalByProvider = useMemo(
    () =>
      _(filteredProducts)
        .groupBy('provider.name')
        .map((item, key) => ({
          index: key,
          total: _.sumBy(item, 'total'),
        }))
        .value(),
    [filteredProducts]
  )

  const hasAtLeastOneProduct = key => productsToShow[key].find(({ amount }) => amount > 0)

  let emptyCart = true
  Object.keys(productsToShow).forEach(key => {
    if ((!_.isEmpty(productsToShow[key]) && hasAtLeastOneProduct(key)) || queryString !== '') {
      emptyCart = false
    }
  })
  if ((notAvailable && notAvailable.length > 0) || queryString !== '') emptyCart = false

  let totalFood = food
  let totalNoFood = nofood

  const fullTotal = hasSomethingInCart(cart)
    ? _.chain(cart)
        .map(({ total, raw_material: rawMaterial }) => {
          if (rawMaterial === 'food') totalFood -= total
          else totalNoFood -= total
          return total
        })
        .sum()
        .value()
    : ''

  const hasRequestOrderPendingState = requestOrder?.state === REQUEST_ORDER_STATE.PENDING

  const onSeek = qr => setQueryString(qr)

  const scrollToNoOkProducts = () => {
    scroller.scrollTo('myScrollToElement', {
      smooth: true,
      containerId: 'containerElement',
    })
  }

  const deleteProducts = key => {
    const providerId = productsToShow[key][0].provider.id
    setProviderToEraseId(providerId)
    setShowModalConfirmationProvider(true)
  }

  const gotoRejectSuccess = () =>
    history.push(routes.approverSuccessUpdatedOrder, { status: REQUEST_ORDER_STATE.REJECTED })

  const doRejectCart = () => {
    setShowConfirmRejectModal(false)
    dispatch(rejectCart(requestOrder?.id, gotoRejectSuccess))
  }

  const onClickReject = () => setShowConfirmRejectModal(true)

  const onClickAddProducts = () => {
    const successCallback = () => history.push(routes.categories)
    dispatch(impersonate(successCallback))
  }

  const onCancelProductDelete = () => {
    setShowModalConfirmationProduct(false)
    setOnConfirmProduct(() => null)
  }

  const onCancelProviderErase = () => setShowModalConfirmationProvider(false)

  const onAcceptProviderErase = () => {
    dispatch(deleteProviderProducts(providerToEraseId))
    setShowModalConfirmationProvider(false)
  }

  return {
    productsToShow,
    showConfirmRejectModal,
    showModalConfirmationProduct,
    onConfirmProduct,
    showModalConfirmationProvider,
    isFetchingCart,
    isUpdatingCart,
    notAvailableToShow,
    totalByProvider,
    emptyCart,
    totalFood,
    totalNoFood,
    fullTotal,
    hasRequestOrderPendingState,
    onSeek,
    scrollToNoOkProducts,
    onAcceptProviderErase,
    onCancelProviderErase,
    onCancelProductDelete,
    onClickAddProducts,
    onClickReject,
    doRejectCart,
    deleteProducts,
    setOnConfirmProduct,
    setShowModalConfirmationProduct,
    hasAtLeastOneProduct,
    setShowConfirmRejectModal,
    setShowModalConfirmationProvider,
  }
}

export default useApproverCart
