import { APPROVAL_STATE } from 'Config/constants'
import styled, { css } from 'styled-components'

export const Root = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 20px 5px 20px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const CartData = styled.div``

export const ProviderContainer = styled.div`
  margin-bottom: 12px;
`

export const ProviderBody = styled.div`
  padding: 16px;
  background-color: ${props => props.theme.colors.gray98b};
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

export const ProviderHeaderFlag = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 0 8px;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.cart};
  border-radius: 8px;
  margin-left: 16px;

  ${props =>
    props.bold &&
    css`
      font-weight: 600;
      font-size: 16px;
    `}
`

export const ProviderBodyContent = styled.div`
  display: flex;
  margin-bottom: 16px;
`

export const ProviderBodyLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`

export const ProviderProduct = styled.div`
  display: flex;
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  background-color: ${props => props.theme.colors.white};
  height: 50px;
  align-items: center;
  margin-bottom: 4px;
`
export const ProviderProductColumn = styled.div`
  display: flex;
  padding-left: 16px;
  padding-right: 16px;

  ${props =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
`

export const ProviderProductColumnSeparator = styled.div`
  width: 0.5px;
  background-color: rgba(51, 51, 51, 0.24);
  align-self: stretch;
`

export const ProductDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  color: ${props => props.theme.colors.black};
        
  .status {
    ${props =>
      props.status === APPROVAL_STATE.MODIFIED &&
      css`
        color: ${props.theme.colors.blue};
      `}

      ${props =>
        props.status === APPROVAL_STATE.DELETED &&
        css`
          color: ${props.theme.colors.red};
        `}

      ${props =>
        props.status === APPROVAL_STATE.ADDED &&
        css`
          color: ${props.theme.colors.secondary};
        `}
  }
`

export const ProductValue = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const ProductValueUnits = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.gray};
  margin-left: 8px;
`
