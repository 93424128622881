import React, { useState } from 'react'

import PropTypes from 'prop-types'
import moment from 'moment'
import map from 'lodash/map'
import get from 'lodash/get'
import { WORDPRESS_ANNOUNCEMENTS } from 'Config/api'

import {
  CardContainer,
  CardContent,
  CardTitle,
  CardImage,
  CardInfo,
  CardDescription,
  CardBody,
  CardDate,
  CardCategories,
  CardCategory,
} from './styled'

const Card = ({ onClick, refProp, announcement }) => {
  const [showImage, setShowImage] = useState(true)

  const categoriesText = map(announcement.categories, 'name')
  const categoriesLinks = map(announcement.categories, 'url')

  const hadleClickCategory = (e, index) => {
    e.stopPropagation()
    window.open(get(categoriesLinks, index, WORDPRESS_ANNOUNCEMENTS))
  }

  return (
    <CardContainer ref={refProp} onClick={onClick}>
      <CardContent>
        <CardTitle>{announcement.title}</CardTitle>
        <CardBody image={announcement.image}>
          <CardDescription dangerouslySetInnerHTML={{ __html: announcement.description }} />
          {showImage && announcement.image && (
            <CardImage src={announcement.image} alt={announcement.slug} onError={() => setShowImage(false)} />
          )}
        </CardBody>
        <CardInfo>
          <CardDate>{moment(announcement.published).format('LL')}</CardDate>
          <CardCategories>
            {map(categoriesText, (text, index) => (
              <CardCategory key={`${index}${text}`} onClick={e => hadleClickCategory(e, index)}>
                {text}
              </CardCategory>
            ))}
          </CardCategories>
        </CardInfo>
      </CardContent>
    </CardContainer>
  )
}

Card.defaultProps = {
  refProp: () => {},
  onClick: () => {},
  announcement: {},
}

Card.propTypes = {
  refProp: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onClick: PropTypes.func,
  announcement: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    description: PropTypes.string,
    published: PropTypes.string,
    image: PropTypes.string,
    slug: PropTypes.string,
    categories: PropTypes.array,
  }),
}

export default Card
