import styled from 'styled-components'
import colors from 'Assets/colors'
import { makeStyles } from '@material-ui/core/styles'

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.black};
  padding-bottom: 1rem;
`

export const Message = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  text-align: center;
  padding-bottom: 1.5rem;
  max-width: 520px;
  margin: 0 auto;
`

export const FiltersContainer = styled.div``

export const FullWidthFilterItems = styled.div`
  display: grid;
  grid-template-columns: 100%;
  margin-bottom: 16px;
`

export const SplitWidthFilterItems = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  grid-column-gap: 4%;
  margin-bottom: 24px;
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

export const InputLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 8px;
`

export const ActionButton = styled.div`
  width: 100%;
  background-color: ${colors.secondary};
  padding: 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const ButtonLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-right: auto;
`

export const CleanButton = styled.div`
  width: 100%;
  padding: 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: ${props => (props.disabled ? colors.gray40l : colors.white)};
  border: 2px solid ${props => (props.disabled ? 'transparent' : colors.red)};
  color: ${props => (props.disabled ? colors.white : colors.red)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const useStyles = makeStyles(() => ({}))
