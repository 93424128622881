import React from 'react'

import PropTypes from 'prop-types'
import { Modal, ErrorDisplay } from 'Components/atoms'

const ModalCenter = ({ showModal, setShowModal, message, color, title, width, height, onConfirm }) => (
  <Modal isOpen={showModal} setIsOpen={setShowModal} width={width} height={height}>
    <ErrorDisplay title={title} message={message} color={color} onConfirm={onConfirm} />
  </Modal>
)

ModalCenter.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onConfirm: PropTypes.func,
  message: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}

ModalCenter.defaultProps = {
  showModal: false,
  setShowModal: () => {},
  onConfirm: () => {},
  message: '',
  color: '',
  title: '',
  width: '',
  height: '',
}

export default ModalCenter
