import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ContentLoader from 'react-content-loader'
import { ReactComponent as ExclamationCircle } from 'Assets/Icons/ExclamationCircle.svg'
import { ProviderCheckout } from 'Components/organisms'
import { NumberFormat } from 'Components/atoms'
import { ModalOriginCenter, ModalLoading, ModalConfirmation, ProductHorizontalCard } from 'Components/molecules'
import {
  hasSomethingAvailableInCart,
  calculateAvailableProductAmount,
  calculateAvailableProductTotalPrice,
} from 'Components/utils/cartCalculator'
import {
  confirmOrder,
  deleteRequestFailed,
  getCart,
  putOriginCenter,
  approveOrder,
  confirmOrderSQS,
} from 'Services/api'
import { selectCenterId, selectCartDeliveries, isDeliveriesCompleted } from 'Redux/purchases/utils'
import { getApiErrorMessage } from 'Config/utils'
import {
  setCartConfirmed,
  fetchCart,
  setDatesUpdated,
  preConfirmCart,
  setPreviusOrderProducts,
  checkConfirmedCart,
} from 'Redux/purchases/actions'
import routes from 'Config/routes'
import { EMPTY_CART } from 'Redux/purchases/types'
import { needsApprovalCart } from 'Components/utils/cart'
import { CART_CHANGES_EXCEPTION, productState } from 'Config/constants'
import analyticsService from 'Services/analytics'
import { ANALYTICS_PURCHASE_TYPE } from 'Services/analytics/constants'
import * as Sentry from 'Services/sentry'
import { selectConfig } from 'Redux/auth/utils'

import {
  Container,
  Title,
  LowerMenu,
  Row,
  Center,
  CenterHelper,
  CenterInfo,
  CenterAction,
  CenterLabel,
  CenterPlace,
  Selector,
  FinishButton,
  ButtonLiteral,
  TotalItems,
  LoadingWrapper,
  HorizontalProviderNotAvailableContainer,
  HorizontalRedSquare,
  RowRed,
  HorizontalTitleSection,
  HorizontalTitleProduct,
  HorizontalTitleTotal,
  HorizontalTitleValue,
} from './styled'

const Checkout = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const errorCart = useSelector(state => state.purchase.errorCart)
  const isLoadingCart = useSelector(state => state.purchase.isLoadingCart)
  const originCenters = useSelector(state => state.purchase.originCenters)
  const selectedOrigin = useSelector(state => state.purchase.origin)
  const cart = useSelector(state => state.purchase.cart)
  const selectedCenter = useSelector(state => state.auth.selectedCenter)
  const centerId = useSelector(selectCenterId)
  const deliveries = useSelector(selectCartDeliveries)
  const user = useSelector(state => state.auth.user)
  const confirmedCart = useSelector(state => state.purchase.confirmedCart)
  const features = useSelector(selectConfig)

  const [showModal, setShowModal] = useState(false)
  const [showRequestFailedModal, setShowRequestFailedModal] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [errors, setErrors] = useState({})
  const [errorRelatedId, setErrorRelatedId] = useState(null)
  const [isLoadinfErrorRelatedId, setIsLoadinfErrorRelatedId] = useState(false)

  const orderedByProvider = _.groupBy(cart, 'provider.name')

  const needsApproval = needsApprovalCart({ center: selectedCenter, user })

  useEffect(() => {
    if (confirmedCart?.updated && !confirmedCart?.products.length && !confirmedCart?.deliveries.length) {
      history.push(routes.home)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedCart, history])

  useEffect(() => {
    dispatch(preConfirmCart())
    dispatch(setDatesUpdated(false))
  }, [dispatch])

  useEffect(() => {
    setIsLoadinfErrorRelatedId(true)
    getCart(centerId)
      .then(res => {
        dispatch(checkConfirmedCart(res))
        if (res.errorRelatedId) {
          setErrorRelatedId(res.errorRelatedId)
        }
      })
      .catch(e => Sentry.captureException(e))
      .finally(() => setIsLoadinfErrorRelatedId(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerId])

  const sendSelectedOriginCenter = async center => {
    try {
      await putOriginCenter(centerId, { center: center.id })
      dispatch(fetchCart())
    } catch (error) {
      Sentry.captureException(error)
      toast.error(getApiErrorMessage(error))
    }
  }

  const handleConfirmCartError = error => {
    if (error?.response?.data?.error === CART_CHANGES_EXCEPTION && error?.response?.data?.statusCode === 400) {
      toast.info(t('updatedCart'))
      dispatch(preConfirmCart())
      dispatch(setDatesUpdated(false))
    } else {
      toast.error(getApiErrorMessage(error))
    }
  }

  const onConfirm = async (errorId = null) => {
    setIsSubmiting(true)
    const params = { updated: confirmedCart.updated }
    try {
      const confirmOrderEp = features.order_confirm_sqs ? confirmOrderSQS : confirmOrder
      const result = !needsApproval ? await confirmOrderEp(centerId, params) : await approveOrder(centerId, params)
      const route = !needsApproval ? routes.checkoutConfirmed : routes.checkoutApprovedConfirmed
      const trackingId = !needsApproval ? result?.trackingId : result?.carts?.map(el => el.parentId) || []
      const mappedProductsForAnalytics = result?.products?.map(product => ({
        amount: product.amount,
        ...product.product,
      }))

      if (!needsApproval) {
        analyticsService.purchase({
          products: mappedProductsForAnalytics,
          transactionId: trackingId,
          purchaseType: ANALYTICS_PURCHASE_TYPE.buy,
        })
      } else {
        analyticsService.beingCheckout({
          products: mappedProductsForAnalytics,
          step: 2,
        })
      }

      dispatch(setCartConfirmed({ trackingId }))
      dispatch(setPreviusOrderProducts([]))
      dispatch({ type: EMPTY_CART })
      if (errorId) {
        await deleteRequestFailed(centerId, errorRelatedId)
      }
      history.push(route)
    } catch (e) {
      Sentry.captureException(e)
      handleConfirmCartError(e)
    } finally {
      setIsSubmiting(false)
    }
  }

  const onHandleModal = () => {
    if (errorRelatedId) {
      setShowRequestFailedModal(true)
    } else {
      onConfirm()
    }
  }

  const onHandleErrorRelated = (errorId = null) => {
    setShowRequestFailedModal(false)
    if (errorId) {
      onConfirm(errorId)
    } else {
      onConfirm()
    }
  }

  const onCenterSelected = center => {
    setShowModal(false)
    if (center) {
      sendSelectedOriginCenter(center)
    }
  }

  const onClickChangeCenter = () => setShowModal(true)

  if (isLoadingCart || (deliveries?.length === 0 && !errorCart)) {
    return <ProviderCardLoading />
  }

  return (
    <>
      <Container $tutorial={false}>
        <Title>{t('finishPurchase')}</Title>
        <Providers providers={orderedByProvider} errors={errors} setErrors={setErrors} />
      </Container>
      <LowerMenu>
        <Row>
          <OriginCenter
            center={selectedOrigin}
            onCenterSelected={onCenterSelected}
            showModalOriginCenter={showModal}
            onClickChangeCenter={onClickChangeCenter}
            originCenters={originCenters}
          />
          <DestinationCenter center={selectedCenter} />
          <SubmitButton
            onConfirm={onHandleModal}
            cart={cart}
            orderedByProvider={orderedByProvider}
            deliveries={deliveries}
            selectedOrigin={selectedOrigin}
            errors={errors}
            needsApproval={needsApproval}
            isLoadinfErrorRelatedId={isLoadinfErrorRelatedId}
          />
        </Row>
      </LowerMenu>
      <ModalLoading showModal={isSubmiting} />
      <ModalConfirmation
        showModal={showRequestFailedModal}
        setShowModal={() => null}
        onCancel={() => onHandleErrorRelated()}
        onAccept={() => onHandleErrorRelated(errorRelatedId)}
        showClose={false}
        color='red'
        titleColor='black'
        title={t('failedRequests.removeRequestCheckoutModalTitle')}
        message={t('failedRequests.removeRequestCheckoutModalMessage')}
        action={t('failedRequests.removeRequestCheckoutModalConfirmBtn')}
        cancelAction={t('failedRequests.removeRequestCheckoutModalNotNow')}
        height='240px'
        width='680px'
      />
    </>
  )
}

const Providers = ({ providers, setErrors, errors }) => {
  const { t } = useTranslation()
  return (
    <>
      {Object.keys(providers).map(key => {
        const notAvailableToShow = providers[key]?.filter(element => element.state === 'no_available') || []

        return (
          <>
            <ProviderCheckout
              key={key}
              providerName={key}
              products={providers[key]}
              setErrors={error => {
                const newErrors = { ..._.remove({ ...errors }, key) }
                if (error) {
                  setErrors({ ...errors, [key]: error })
                } else {
                  setErrors(newErrors)
                }
              }}
            >
              {notAvailableToShow.length > 0 ? (
                <HorizontalProviderNotAvailableContainer>
                  {/* <NotAvailableTitle>{t('notAvailableProducts')}</NotAvailableTitle> */}
                  <HorizontalRedSquare>
                    <RowRed justifyContent='space-between' alignItems='center'>
                      <ExclamationCircle style={{ marginRight: '0.5rem' }} />
                      {t('notAvailableDate')}
                    </RowRed>
                  </HorizontalRedSquare>

                  <HorizontalTitleSection>
                    <HorizontalTitleProduct>{t('product')}</HorizontalTitleProduct>
                    <HorizontalTitleValue>{t('total')}</HorizontalTitleValue>
                    <HorizontalTitleTotal>{t('totalLine')}</HorizontalTitleTotal>
                  </HorizontalTitleSection>
                  {notAvailableToShow.map(product => (
                    <ProductHorizontalCard
                      key={product.id}
                      id={product.id}
                      isSimpleCard
                      measureUnit={product.measure_unit}
                      min={product.min}
                      multiple={product.multiple}
                      name={product.name}
                      price={product.price}
                      pricePerKg={product.price_per_kg}
                      providerName={product.provider?.name}
                      raw_material={product.raw_material}
                      state={product.state}
                      notAvailable={product.state === 'no_available'}
                      provider={product.provider}
                      reference={product.reference}
                      previousPrice={product.previous_price}
                      availability={product.availability}
                      showNextDate
                    />
                  ))}
                </HorizontalProviderNotAvailableContainer>
              ) : null}
            </ProviderCheckout>
          </>
        )
      })}
    </>
  )
}

Providers.propTypes = {
  providers: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
}

const DestinationCenter = ({ center }) => {
  const { t } = useTranslation()

  if (!center) return null

  return (
    <Center>
      <CenterInfo>
        <CenterLabel>{t('destinationCenter')}</CenterLabel>
        <CenterPlace>{center.name}</CenterPlace>
      </CenterInfo>
    </Center>
  )
}

DestinationCenter.propTypes = {
  center: PropTypes.object,
}

DestinationCenter.defaultProps = {
  center: null,
}

const OriginCenter = ({ center, showModalOriginCenter, onCenterSelected, onClickChangeCenter, originCenters }) => {
  const { t } = useTranslation()

  return (
    <CenterHelper>
      <Center>
        <CenterInfo>
          <CenterLabel>{t('originCenter')}</CenterLabel>
          <CenterPlace>{center ? center.name : t('noOriginCenterSelected')}</CenterPlace>
        </CenterInfo>
        {originCenters.length === 0 && <p>{t('invalidERPOriginCenters')}</p>}
        {originCenters.length > 1 && (
          <CenterAction>
            <Selector type='button' onClick={onClickChangeCenter}>
              {t('change')}
            </Selector>
          </CenterAction>
        )}

        <ModalOriginCenter
          showModal={showModalOriginCenter}
          onCenterSelected={onCenterSelected}
          centers={originCenters}
        />
      </Center>
    </CenterHelper>
  )
}

OriginCenter.propTypes = {
  center: PropTypes.object,
  showModalOriginCenter: PropTypes.bool.isRequired,
  originCenters: PropTypes.array.isRequired,
  onCenterSelected: PropTypes.func.isRequired,
  onClickChangeCenter: PropTypes.func.isRequired,
}

OriginCenter.defaultProps = {
  center: null,
}

const SubmitButton = ({
  onConfirm,
  cart,
  orderedByProvider,
  deliveries,
  selectedOrigin,
  errors,
  needsApproval,
  isLoadinfErrorRelatedId,
}) => {
  const { t } = useTranslation()

  const selectedCenter = useSelector(state => state.auth.selectedCenter)
  const isLoadingPreConfirm = useSelector(state => state.purchase.isLoadingPreConfirm)

  const productQuantity = hasSomethingAvailableInCart(cart) ? calculateAvailableProductAmount(cart) : ''
  const fullTotal = hasSomethingAvailableInCart(cart) ? calculateAvailableProductTotalPrice(cart) : ''
  const isAllDeliveriesCompleted = isDeliveriesCompleted(orderedByProvider, deliveries)

  const hasAvailable =
    cart &&
    Object.keys(cart).find(
      item => cart[item].state === productState.ok || cart[item].state === productState.changePrice
    )

  const disabled =
    !isAllDeliveriesCompleted ||
    !selectedOrigin ||
    Object.keys(errors).length > 0 ||
    !hasAvailable ||
    isLoadingPreConfirm ||
    isLoadinfErrorRelatedId

  const { showPrice } = selectedCenter

  const getSubmitLabel = () => {
    if (!isAllDeliveriesCompleted) return t('completeDeliveryDates')
    if (!selectedOrigin) return t('selectOriginCenter')
    if (needsApproval) return t('requestApproval')
    return t('confirmOrder')
  }

  const price = () => {
    if (showPrice) {
      return hasSomethingAvailableInCart(cart) ? <NumberFormat value={fullTotal} /> : '0€'
    }
    return null
  }

  return (
    <Center>
      <FinishButton onClick={onConfirm} disabled={disabled}>
        <ButtonLiteral>{getSubmitLabel()}</ButtonLiteral>
        {price()}
        <TotalItems>{productQuantity}</TotalItems>
      </FinishButton>
    </Center>
  )
}

SubmitButton.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  orderedByProvider: PropTypes.object.isRequired,
  deliveries: PropTypes.array.isRequired,
  selectedOrigin: PropTypes.object,
  errors: PropTypes.object.isRequired,
  needsApproval: PropTypes.bool,
  isLoadinfErrorRelatedId: PropTypes.bool,
}

SubmitButton.defaultProps = {
  selectedOrigin: null,
  needsApproval: false,
  isLoadinfErrorRelatedId: false,
}

const ProviderCardLoading = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <LoadingWrapper>
        <ContentLoader
          speed={2}
          width={2000}
          height={400}
          viewBox='0 0 2000 400'
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
          title={t('loading')}
        >
          <rect x='0' y='10' rx='0' ry='0' width='2000' height='38' />
          <rect x='0' y='70' rx='0' ry='0' width='2000' height='6' />
          <rect x='0' y='90' rx='0' ry='0' width='150' height='6' />
          <rect x='0' y='130' rx='0' ry='0' width='2000' height='38' />
          <rect x='0' y='190' rx='0' ry='0' width='2000' height='6' />
          <rect x='0' y='210' rx='0' ry='0' width='150' height='6' />
        </ContentLoader>
      </LoadingWrapper>
    </Container>
  )
}

export default Checkout
