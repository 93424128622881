import {
  SET_TOKEN,
  SET_ERROR_TOKEN,
  SET_CENTERS_LIST,
  SET_ERROR_CENTERS_LIST,
  SET_CENTER_COUNT,
  SET_SELECTED_CENTER,
  SET_USER,
  LOGOUT,
  LOGOUT_USER,
  SET_HAS_ONLY_ONE_CENTER,
  HIDE_NAVBAR,
  SET_REFRESH_TOKEN,
  SET_ERROR_LOGIN,
  IS_LOADING_CENTER,
  SET_IS_READY,
  SET_REFRESHING_CATEGORY,
  IS_LOADING_LOGIN,
  SET_IS_LOADING_MSAL,
  FETCH_FEATURE_FLAG_CONFIG,
  SET_FEATURE_FLAG_CONFIG,
  IS_INITIAL_FECTH_CENTERS,
} from './types'

export const setToken = token => ({
  type: SET_TOKEN,
  payload: token,
})

export const setErrorToken = e => ({
  type: SET_ERROR_TOKEN,
  payload: e,
})

export const setCenterList = centers => ({
  type: SET_CENTERS_LIST,
  payload: centers,
})

export const setErrorCenterList = e => ({
  type: SET_ERROR_CENTERS_LIST,
  payload: e,
})

export const setCenterCount = count => ({
  type: SET_CENTER_COUNT,
  payload: count,
})

export const setCenter = center => ({
  type: SET_SELECTED_CENTER,
  payload: center,
})

export const setIsLoadingCenter = center => ({
  type: IS_LOADING_CENTER,
  payload: center,
})

export const setIsLoadingLogin = center => ({
  type: IS_LOADING_LOGIN,
  payload: center,
})

export const setUser = center => ({
  type: SET_USER,
  payload: center,
})

export const Logout = () => ({
  type: LOGOUT_USER,
})

export const hasOnlyOneCenter = value => ({
  type: SET_HAS_ONLY_ONE_CENTER,
  payload: value,
})

export const setHideNavbar = payload => ({
  type: HIDE_NAVBAR,
  payload,
})

export const setRefreshToken = payload => ({
  type: SET_REFRESH_TOKEN,
  payload,
})

export const setErrorLogin = e => ({
  type: SET_ERROR_LOGIN,
  payload: e,
})

export const doLogout = () => ({
  type: LOGOUT,
})

export const setIsReady = e => ({
  type: SET_IS_READY,
  payload: e,
})

export const setRefreshingCategory = payload => ({
  type: SET_REFRESHING_CATEGORY,
  payload,
})

export const setIsLoadingMsal = payload => ({
  type: SET_IS_LOADING_MSAL,
  payload,
})

export const fetchFeatureFlagConfig = payload => ({
  type: FETCH_FEATURE_FLAG_CONFIG,
  payload,
})

export const setFeatureFlagConfig = payload => ({
  type: SET_FEATURE_FLAG_CONFIG,
  payload,
})

export const setIsInitialFecthCenters = payload => ({
  type: IS_INITIAL_FECTH_CENTERS,
  payload,
})
