import React, { useEffect, useRef, useState, useMemo, createRef, useCallback } from 'react'

import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
import map from 'lodash/map'
import findLast from 'lodash/findLast'
import get from 'lodash/get'
import { KEY_CODES } from 'Config/constants'
import { TextInput } from 'Components/atoms'
import Arrow from 'Assets/Icons/Arrow'
import { useDebounce } from 'Components/utils/customHooks'

import { useClickOutside } from '../../utils/customHooks'

import { ChoicesContainer, StyledText, Choice, Container, DisabledText } from './styled'

const OriginCenterSelector = ({
  onChange,
  onSearch,
  choices,
  value,
  placeholder,
  onFocus,
  icon,
  onBlur,
  disabledItems,
  notIcon,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('')
  const [hoveredChoice, setHoveredChoice] = useState('')
  const [visible, setVisible] = useState('')
  const refs = useMemo(() => Array.from({ length: choices.length }).map(() => createRef()), [choices])
  const dispatch = useDispatch()

  const debouncedSearch = useDebounce(inputValue, 600)

  const ContainerRef = useRef()
  useClickOutside(ContainerRef, () => setVisible(false))

  useEffect(() => {
    onSearch(debouncedSearch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, dispatch])

  useEffect(() => {
    if (value) {
      setInputValue(get(findLast(choices, { id: value }), 'name'))
    } else {
      setInputValue('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (hoveredChoice !== '' && refs[hoveredChoice]) refs[hoveredChoice].current.scrollIntoView()
  }, [hoveredChoice, refs])

  useEffect(() => {
    setHoveredChoice('')
  }, [choices])

  const onChangeInputValue = e => setInputValue(e.target.value)

  const onKeyDownInput = event => {
    const char = event.which || event.keyCode
    if (_.isEmpty(choices)) return
    switch (char) {
      case KEY_CODES.UP_ARROW:
        setVisible(true)
        if (hoveredChoice === '' || hoveredChoice === 0) {
          setHoveredChoice(choices.length - 1)
          break
        }
        setHoveredChoice(hoveredChoice - 1)
        break
      case KEY_CODES.DOWN_ARROW:
        setVisible(true)
        if (hoveredChoice === '' || hoveredChoice === choices.length - 1) {
          setHoveredChoice(0)
          break
        }
        setHoveredChoice(hoveredChoice + 1)
        break
      case KEY_CODES.ENTER:
        if (hoveredChoice === '') break
        onChange(choices[hoveredChoice])
        break
      default:
        break
    }
  }

  const handleFocus = () => {
    setVisible(true)
    onFocus()
  }

  const handleOnBlur = e => {
    if (!inputValue) onChange(null)
    onBlur(e)
  }

  const handleChange = useCallback(
    ({ name, id }) => {
      setVisible(false)
      if (onChange) {
        onChange({ name, id })
      }
    },
    [onChange]
  )

  return (
    <Container ref={ContainerRef}>
      <TextInput
        value={inputValue}
        onChange={onChangeInputValue}
        placeholder={placeholder}
        onFocus={handleFocus}
        onKeyDown={onKeyDownInput}
        icon={icon}
        onBlur={handleOnBlur}
        {...rest}
      />
      <ChoicesContainer open={visible}>
        {map(choices, ({ name, id }, index) => (
          <Choice
            className={classNames({ selected: index === hoveredChoice })}
            key={id}
            onClick={() => {
              handleChange({ name, id })
            }}
            ref={refs[index]}
          >
            {disabledItems ? (
              <DisabledText padding='8px 0'>{name}</DisabledText>
            ) : (
              <StyledText padding='8px 0'>{name}</StyledText>
            )}
            {!notIcon ? <Arrow className='icon-arrow' /> : null}
          </Choice>
        ))}
      </ChoicesContainer>
    </Container>
  )
}

OriginCenterSelector.defaultProps = {
  title: 'changeOriginCenter',
  onChange: () => {},
  value: null,
  resource: null,
  onSearch: () => {},
  choices: [],
  placeholder: '',
  onFocus: () => {},
  icon: {},
  onBlur: () => {},
  disabledItems: false,
  notIcon: false,
}

OriginCenterSelector.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number,
  resource: PropTypes.string,
  onSearch: PropTypes.func,
  choices: PropTypes.array,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  icon: PropTypes.object,
  onBlur: PropTypes.func,
  disabledItems: PropTypes.bool,
  notIcon: PropTypes.bool,
}

export default OriginCenterSelector
