export const FETCH_PENDING_APPROVAL = 'portal_compras/approver/FETCH_PENDING_APPROVAL'
export const SET_PENDING_APPROVAL = 'portal_compras/approver/SET_PENDING_APPROVAL'
export const SET_IS_FETCHING_PENDING_APPROVAL = 'portal_compras/approver/SET_IS_FETCHING_PENDING_APPROVAL'
export const FETCH_REJECTED = 'portal_compras/approver/FETCH_REJECTED'
export const SET_REJECTED = 'portal_compras/approver/SET_REJECTED'
export const SET_IS_FETCHING_REJECTED = 'portal_compras/approver/SET_IS_FETCHING_REJECTED'
export const FETCH_APPROVED = 'portal_compras/approver/FETCH_APPROVED'
export const SET_APPROVED = 'portal_compras/approver/SET_APPROVED'
export const SET_IS_FETCHING_APPROVED = 'portal_compras/approver/SET_IS_FETCHING_APPROVED'
export const FETCH_CART = 'portal_compras/approver/FETCH_CART'
export const SET_CART = 'portal_compras/approver/SET_CART'
export const SET_IS_FETCHING_CART = 'portal_compras/approver/SET_IS_FETCHING_CART'
export const SET_REQUEST_ORDER = 'portal_compras/approver/SET_REQUEST_ORDER'
export const CONFIRM_CART = 'portal_compras/approver/CONFIRM_CART'
export const REJECT_CART = 'portal_compras/approver/REJECT_CART'
export const SET_IS_UPDATING_CART = 'portal_compras/approver/SET_IS_UPDATING_CART'
export const EDIT_CART = 'portal_compras/approver/EDIT_CART'
export const SYNC_CART = 'portal_compras/approver/SYNC_CART'
export const SET_ERROR_CART = 'portal_compras/approver/SET_ERROR_CART'
export const DELETE_PROVIDER_PRODUCT = 'portal_compras/approver/DELETE_PROVIDER_PRODUCT'
export const IMPERSONATE = 'portal_compras/approver/IMPERSONATE'
export const SET_FILTER_CENTER = 'portal_compras/approver/SET_FILTER_CENTER'
export const SET_FILTER_PROVIDER = 'portal_compras/approver/SET_FILTER_PROVIDER'
export const SET_FILTER_REQUEST_NUMBER = 'portal_compras/approver/SET_FILTER_REQUEST_NUMBER'
export const SET_CHECKOUT_DATES_UPDATED = 'portal_compras/approver/SET_CHECKOUT_DATES_UPDATED'
export const SET_CART_DELIVERIES = 'portal_compras/approver/SET_CART_DELIVERIES'
export const FETCH_ORIGIN_CENTERS = 'portal_compras/approver/FETCH_ORIGIN_CENTERS'
export const SET_ORIGIN_CENTERS = 'portal_compras/approver/SET_ORIGIN_CENTERS'
export const CHECK_APPROVER_CONFIRMED_CART = 'portal_compras/approver/CHECK_CONFIRMED_CART'
export const SET_APPROVER_CONFIRMED_CART = 'portal_compras/approver/SET_CONFIRMED_CART'
export const FETCH_WEEK_SUMMARY = 'portal_compras/approver/FETCH_WEEK_SUMMARY'
export const SET_WEEK_SUMMARY = 'portal_compras/approver/SET_WEEK_SUMMARY'
export const SET_IS_LOADING_WEEK_SUMMARY = 'portal_compras/approver/SET_IS_LOADING_WEEK_SUMMARY'
export const SET_SUMMARY_CENTER = 'portal_compras/approver/SET_SUMMARY_CENTER'
export const SET_IS_FETCHING_SUMMARY_CENTER = 'portal_compras/approver/SET_IS_FETCHING_SUMMARY_CENTER'
