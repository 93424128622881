export const getSelectedCategories = (key, categories) => {
  let category
  let subCategory
  let keymap3

  categories.every(({ id, children: subCategories = [], name }) => {
    if (key === id) {
      category = { id, name }
      return false
    }

    subCategories.every(({ id: subCatId, children: types = [], name: subCatName }) => {
      if (key === subCatId) {
        category = { id, name }
        subCategory = { id: subCatId, name: subCatName }
        return false
      }

      types.every(({ id: typeId, name: typeName }) => {
        if (key === typeId) {
          category = { id, name }
          subCategory = { id: subCatId, name: subCatName }
          keymap3 = { id: typeId, name: typeName }
          return false
        }
        return true
      })

      if (keymap3 !== undefined) return false

      return true
    })

    if (subCategory !== undefined) return false

    return true
  })

  return { category, subCategory, keymap3 }
}

export const getSearchParams = (category, subCategory, keymap3) => {
  switch (true) {
    case !!keymap3:
      return { keymap3: keymap3.id }
    case !!subCategory:
      return { subcategory: subCategory.id }
    case !!category:
      return { category: category.id }
    default:
      return {}
  }
}

export const getTitle = ({ category, subCategory, keymap3 }) => {
  switch (true) {
    case !!keymap3:
      return keymap3.name
    case !!subCategory:
      return subCategory.name
    case !!category:
      return category.name
    default:
      return ''
  }
}
