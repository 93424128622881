import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import moment from 'moment'
import { toast } from 'react-toastify'
import CircularProgress from '@material-ui/core/CircularProgress'
import colors from 'Assets/colors'
import { ReactComponent as Checked } from 'Assets/Icons/Checked.svg'
import { ReactComponent as Arrow } from 'Assets/Icons/ArrowRightButton.svg'
import { TextInput, NumberFormat } from 'Components/atoms'
import { DateInput, ModalLoading } from 'Components/molecules'
import { selectCategories, selectCenterId } from 'Redux/ticket/selectors'
import { getOrderProducts, confirmNoConformities } from 'Services/api'
import routes from 'Config/routes'

import validate from './validate'
import {
  ProductsContainer,
  TableHeaderRow,
  TableHeaderItem,
  SingleProductRow,
  SingleProductNameHelper,
  SingleProductName,
  SingleProductPriceContainer,
  SingleProductQtyContainer,
  SingleProductTotalPrice,
  SingleProductTotalPriceSplit,
  SingleProductTotalQty,
  Price,
  PriceKG,
  Container,
  RequestedContainer,
  RequestHeader,
  RequestHeaderInfo,
  RequestProviderInfoContainer,
  RequestProviderInfo,
  RequestProviderName,
  RequestProviderDeliveryDate,
  RequestProviderOrderNumber,
  ProviderDetail,
  Title,
  SubTitle,
  CheckboxContainer,
  StyledCheckbox,
  TextInputContainer,
  ActionBoxContainer,
  ActionBoxInfoContainer,
  ActionBoxInfoTitle,
  ActionBoxInfo,
  ActionBoxActionContainer,
  ActionButton,
  ButtonLiteral,
} from './styled'
import { findProduct } from './utils'

const ProductList = ({ formData }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { orderId, documentTypeCode, documentCompany } = useParams()

  const [isSubmited, setSubmited] = useState(false)
  const [errors, setErrors] = useState({})
  const [products, setProducts] = useState(null)
  const [lines, setLines] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [productsLoading, setProductsLoading] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)

  const centerId = useSelector(selectCenterId)
  const categories = useSelector(selectCategories)
  const category = categories.find(({ id }) => id === formData.category)

  const hasOneLineChecked = lines.some(({ isChecked }) => isChecked)
  const submitDisabled = !hasOneLineChecked

  useEffect(() => {
    let setChecked = true
    lines.map(line => {
      if (!line.isChecked) setChecked = false
      return false
    })

    setAllChecked(setChecked)
  }, [lines, setAllChecked])

  const handleCheckProduct = (e, product) => {
    const newLines = [...lines]
    const currentLine = findProduct(newLines, product)
    currentLine.isChecked = !currentLine.isChecked
    setLines(newLines)
  }

  const handleCheckAllProducts = _lines => {
    const linesFormated = [..._lines]

    // TODO: Refactorizar
    if (allChecked) {
      linesFormated.map(line => {
        // eslint-disable-next-line no-param-reassign
        line.isChecked = false
        return line
      })
    } else {
      linesFormated.map(line => {
        // eslint-disable-next-line no-param-reassign
        line.isChecked = true
        return line
      })
    }

    setAllChecked(!allChecked)
    setLines(linesFormated)
  }

  const handleChangeLot = (e, product) => {
    const newLines = [...lines]
    const currentLine = findProduct(newLines, product)
    currentLine.lot = e.target.value
    if (e.target.value) {
      currentLine.isChecked = true
    }
    setLines(newLines)
  }

  const handleChangeExpirationDate = (date, product) => {
    const newLines = [...lines]
    const currentLine = findProduct(newLines, product)
    currentLine.expirationDate = date
    currentLine.isChecked = true
    setLines(newLines)
  }

  const handleChangeAffectedAmount = (e, product) => {
    const newLines = [...lines]
    const currentLine = findProduct(newLines, product)
    const affectedAmount = e.target.value
    const affectedAmountInt = parseInt(affectedAmount, 10)

    if (!isNumber(affectedAmountInt)) {
      currentLine.affectedAmount = ''
    } else if (affectedAmountInt < 0) {
      currentLine.affectedAmount = '0'
    } else if (affectedAmountInt > product.amount) {
      currentLine.affectedAmount = `${product.amount}`
    } else {
      currentLine.affectedAmount = affectedAmount
    }

    if (affectedAmount) {
      currentLine.isChecked = true
    }

    setLines(newLines)
  }

  const handleFocus = (e, product) => {
    const newLines = [...lines]
    const currentLine = findProduct(newLines, product)
    currentLine.isVisible = true
    setLines(newLines)
  }

  const handleBlur = (e, product) => {
    const newLines = [...lines]
    const currentLine = findProduct(newLines, product)
    currentLine.isVisible = false
    setLines(newLines)
  }

  useEffect(() => {
    setProductsLoading(true)
    getOrderProducts({}, centerId, orderId, documentTypeCode, documentCompany)
      .then(response => setProducts(response))
      .finally(() => setProductsLoading(false))
  }, [centerId, orderId, documentTypeCode, documentCompany])

  useEffect(() => {
    if (products) {
      const productLines = get(products, 'lines', []).map(line => ({
        ...line,
        isChecked: false,
        lot: '',
        expirationDate: '',
        isVisible: false,
        affectedAmount: '',
      }))
      setLines(productLines)
    }
  }, [products])

  useEffect(() => {
    setErrors(validate(lines, category, t))
  }, [lines, category, t])

  const handleSubmit = async () => {
    setSubmited(true)

    if (!errors || Object.keys(errors).length <= 0) {
      const ticketLines = []
      lines.forEach(({ lot, expirationDate, reference, isChecked, documentLineNumber, affectedAmount }) => {
        if (isChecked) {
          const line = { id: reference, line: documentLineNumber }
          if (lot) {
            line.lot = lot
          }
          if (expirationDate) {
            line.expirationDate = moment(expirationDate).format()
          }
          if (affectedAmount) {
            line.affectedAmount = parseInt(affectedAmount, 10)
          }
          ticketLines.push(line)
        }
      })

      const payload = { ...formData, ticketLines, documentTypeCode, documentCompany }

      try {
        setIsSubmiting(true)
        await confirmNoConformities(centerId, payload)
        history.push(routes.addTicketingConfirmed(orderId, documentTypeCode, documentCompany))
      } catch {
        toast.error(t('ticketingSubmitError'), { hideProgressBar: true })
      } finally {
        setIsSubmiting(false)
      }
    }
  }

  return (
    <>
      <Container>
        <Title>{t('ticketingCompleteTitle')}</Title>
        <SubTitle>{t('ticketingCompleteSubtitle')}</SubTitle>
        <RequestedContainer>
          {productsLoading && (!lines || lines.length <= 0) && <RequestsLoader />}
          {products && (
            <>
              <ProductsHeader products={products} lines={lines} />
              <ProductsContainer>
                <TableHeader allChecked={allChecked} lines={lines} onCheckAllProducts={handleCheckAllProducts} />
                {lines.map(product => (
                  <ProductLine
                    key={product.reference}
                    product={product}
                    onCheckProduct={handleCheckProduct}
                    onChangeLot={handleChangeLot}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChangeExpirationDate={handleChangeExpirationDate}
                    isSubmited={isSubmited}
                    errors={errors}
                    onChangeAffectedAmount={handleChangeAffectedAmount}
                  />
                ))}
              </ProductsContainer>
            </>
          )}
        </RequestedContainer>
      </Container>
      <ActionBoxContainer>
        <ActionBoxInfoContainer>
          <ActionBoxInfoTitle>{t('ticketingActionInfoTitle')}</ActionBoxInfoTitle>
          <ActionBoxInfo>{category.name}</ActionBoxInfo>
        </ActionBoxInfoContainer>
        <ActionBoxActionContainer>
          <SubmitButton
            handleSubmit={handleSubmit}
            submitDisabled={isSubmiting || submitDisabled}
            isSubmiting={isSubmiting}
          />
        </ActionBoxActionContainer>
      </ActionBoxContainer>
      <ModalLoading showModal={isSubmiting} />
    </>
  )
}

ProductList.propTypes = {
  formData: PropTypes.object,
}

ProductList.defaultProps = {
  formData: {},
}

const RequestsLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width={1400}
      height={232}
      viewBox='0 0 1400 232'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='18' y='60' rx='0' ry='0' width='700' height='10' />
      <rect x='18' y='18' rx='0' ry='0' width='150' height='10' />
      <rect x='18' y='110' rx='0' ry='0' width='100' height='10' />
      <rect x='400' y='110' rx='0' ry='0' width='100' height='10' />
      <rect x='18' y='142' rx='0' ry='0' width='700' height='10' />
    </ContentLoader>
  )
}

const ProductLine = ({
  product,
  onCheckProduct,
  onChangeLot,
  onFocus,
  onBlur,
  onChangeExpirationDate,
  isSubmited,
  errors,
  onChangeAffectedAmount,
}) => {
  const { t } = useTranslation()
  const center = useSelector(state => state.auth.selectedCenter)
  const { showPrice } = center

  let inputLotBorder = ''
  if (product.isVisible) {
    inputLotBorder = `1px solid ${colors.gray40l}`
  } else if (isSubmited && product.isChecked && get(errors, `[${product.reference}.lot]`)) {
    inputLotBorder = `2px solid ${colors.red}`
  }

  let inputExpirationDateBorder = ''
  if (product.isVisible) {
    inputExpirationDateBorder = `1px solid ${colors.gray40l}`
  } else if (isSubmited && product.isChecked && get(errors, `[${product.reference}.expirationDate]`)) {
    inputExpirationDateBorder = `2px solid ${colors.red}`
  }

  return (
    <SingleProductRow>
      <CheckboxContainer>
        <StyledCheckbox
          icon={<Checked />}
          checkedIcon={<Checked />}
          checked={product.isChecked}
          onClick={e => onCheckProduct(e, product)}
        />
      </CheckboxContainer>
      <SingleProductNameHelper>
        <SingleProductName>{product.name}</SingleProductName>
      </SingleProductNameHelper>
      <SingleProductQtyContainer>
        <SingleProductTotalQty>
          {product.amount} {product.measureUnit}
        </SingleProductTotalQty>
      </SingleProductQtyContainer>
      {showPrice && (
        <SingleProductPriceContainer>
          <SingleProductTotalPrice>
            <NumberFormat value={product.price} />
          </SingleProductTotalPrice>
          <SingleProductTotalPriceSplit>
            <Price>
              <NumberFormat value={product.priceUnit} suffix={`€/${product.measureUnit}`} />
            </Price>
            <PriceKG />
          </SingleProductTotalPriceSplit>
        </SingleProductPriceContainer>
      )}
      <TextInputContainer>
        <TextInput
          placeholder={t('ticketingTableHeaderLot')}
          placeholderColor='rgb(117,117,117)'
          background={product.isVisible ? colors.white : colors.grayInput}
          border={inputLotBorder}
          name='lote'
          value={product.lot}
          onChange={e => onChangeLot(e, product)}
          onFocus={e => onFocus(e, product)}
          onBlur={e => onBlur(e, product)}
          height='48px'
        />
      </TextInputContainer>
      <TextInputContainer>
        <DateInput
          label={t('ticketingTableHeaderExpDateAbrev')}
          value={product.expirationDate}
          onChange={e => onChangeExpirationDate(e, product)}
          height='48px'
          showIcon='none'
          left='unset'
          right='0%'
          border={inputExpirationDateBorder}
        />
      </TextInputContainer>
      <TextInputContainer>
        <TextInput
          placeholder={t('affectedAmountLabel')}
          placeholderColor='rgb(117,117,117)'
          background={product.isVisible ? colors.white : colors.grayInput}
          border={inputLotBorder}
          name='affectedAmount'
          value={product.affectedAmount}
          onChange={e => onChangeAffectedAmount(e, product)}
          onFocus={e => onFocus(e, product)}
          onBlur={e => onBlur(e, product)}
          height='48px'
          type='number'
        />
      </TextInputContainer>
    </SingleProductRow>
  )
}

ProductLine.propTypes = {
  product: PropTypes.object,
  onCheckProduct: PropTypes.func,
  onChangeLot: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChangeExpirationDate: PropTypes.func,
  isSubmited: PropTypes.bool,
  errors: PropTypes.object,
  onChangeAffectedAmount: PropTypes.func,
}

ProductLine.defaultProps = {
  product: {},
  onCheckProduct: () => {},
  onChangeLot: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onChangeExpirationDate: () => {},
  isSubmited: false,
  errors: {},
  onChangeAffectedAmount: () => {},
}

const TableHeader = ({ allChecked, lines, onCheckAllProducts }) => {
  const { t } = useTranslation()
  const center = useSelector(state => state.auth.selectedCenter)

  const { showPrice } = center

  return (
    <TableHeaderRow>
      <TableHeaderItem>
        <StyledCheckbox
          icon={<Checked />}
          checkedIcon={<Checked />}
          checked={allChecked}
          onClick={() => onCheckAllProducts(lines)}
        />
      </TableHeaderItem>
      <TableHeaderItem>{t('products')}</TableHeaderItem>
      <TableHeaderItem>{t('quantity')}</TableHeaderItem>
      {showPrice && <TableHeaderItem>{t('sum')}</TableHeaderItem>}
      <TableHeaderItem>{t('ticketingTableHeaderLot')}</TableHeaderItem>
      <TableHeaderItem>{t('ticketingTableHeaderExpDate')}</TableHeaderItem>
      <TableHeaderItem>{t('affectedAmountLabel')}</TableHeaderItem>
    </TableHeaderRow>
  )
}

TableHeader.propTypes = {
  allChecked: PropTypes.bool,
  lines: PropTypes.array,
  onCheckAllProducts: PropTypes.func,
}

TableHeader.defaultProps = {
  allChecked: false,
  lines: [],
  onCheckAllProducts: () => {},
}

const ProductsHeader = ({ products, lines }) => {
  const { t } = useTranslation()
  const center = useSelector(state => state.auth.selectedCenter)

  const { showPrice } = center

  const getProviderDetailText = () => {
    const totalPrice = lines?.reduce((accumulator, { price }) => accumulator + price, 0)
    const numProducts = t(lines.length === 1 ? 'ticketingNumberOfProduct' : 'ticketingNumberOfProducts', {
      numberOfProducts: lines.length,
    })

    if (!showPrice) {
      return numProducts
    }
    return <NumberFormat value={totalPrice} suffix={`€\u00A0\u00A0\u00A0${numProducts}`} />
  }

  return (
    <>
      <RequestHeader>
        <RequestHeaderInfo>{t('request', { request: products.request })}</RequestHeaderInfo>
        <RequestHeaderInfo>{moment(products.created).format('DD/MM/YYYY')}</RequestHeaderInfo>
      </RequestHeader>
      <RequestProviderInfoContainer>
        <RequestProviderInfo>
          <RequestProviderName>{get(products, 'provider.name')}</RequestProviderName>
          <RequestProviderDeliveryDate>
            {t('historyDeliveryDate')} {moment(products.delivery).format('DD/MM/YYYY')}
          </RequestProviderDeliveryDate>
          <RequestProviderOrderNumber>{products.id}</RequestProviderOrderNumber>
        </RequestProviderInfo>
        <ProviderDetail>{getProviderDetailText()}</ProviderDetail>
      </RequestProviderInfoContainer>
    </>
  )
}

ProductsHeader.propTypes = {
  products: PropTypes.object,
  lines: PropTypes.array,
}

ProductsHeader.defaultProps = {
  products: {},
  lines: [],
}

const SubmitButton = ({ handleSubmit, submitDisabled, isSubmiting }) => {
  const { t } = useTranslation()
  return (
    <ActionButton type='button' onClick={handleSubmit} disabled={submitDisabled || isSubmiting}>
      <ButtonLiteral>{submitDisabled ? t('ticketingActionDisabled') : t('ticketingActionSend')}</ButtonLiteral>
      {isSubmiting ? (
        <CircularProgress style={{ position: 'absolute', top: '12px', right: '16px', color: colors.white }} size={30} />
      ) : (
        <Arrow style={{ fill: colors.white }} />
      )}
    </ActionButton>
  )
}

SubmitButton.propTypes = {
  handleSubmit: PropTypes.func,
  submitDisabled: PropTypes.bool,
  isSubmiting: PropTypes.bool,
}

SubmitButton.defaultProps = {
  handleSubmit: () => {},
  submitDisabled: false,
  isSubmiting: false,
}

export default ProductList
