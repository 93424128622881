import set from 'lodash/set'

import i18n from '../../../Assets/i18n'

const validate = value => {
  const { receptionlines } = value
  const errors = {}
  if (receptionlines) {
    receptionlines.forEach((line, index) => {
      if (!line.priceUnit && line.amount > 0) {
        set(errors, `receptionlines[${index}].priceUnit`, i18n.t('priceUnit0error'))
      }
    })
  }
  return errors
}

export default validate
