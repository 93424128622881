import axios from 'axios'
import { BASE_URL } from 'Config/api'

export const LOGIN_URL = '/api/auth/login'
export const LOGIN_V2_URL = '/api/v2/auth/login'
export const REFRESH_TOKEN_URL = '/api/auth/refresh-token'
const CHECK_COOKIE = '/api/session/check-cookie'

export const hardCodeLogin = params => axios.post('/api/auth/login', params)

export const getMe = params => axios.get('/api/auth/me', params)

export const checkCookie = () =>
  fetch(new URL(CHECK_COOKIE, BASE_URL), {
    method: 'GET',
    credentials: 'include',
    mode: 'no-cors',
  })

export const loginv2 = accessToken =>
  axios.post(
    LOGIN_V2_URL,
    {},
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )

export const login = params => axios.post(LOGIN_URL, params)

export const refreshAccessToken = () =>
  fetch(new URL(REFRESH_TOKEN_URL, BASE_URL), {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'refresh-token': localStorage.getItem('refreshToken'),
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    body: JSON.stringify({
      username: localStorage.getItem('username'),
    }),
  }).then(response => (response?.ok ? response.json() : null))

export const getFeatureFlagConfig = () => axios.get('/api/config/features')
