import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Calendar } from 'Components/atoms'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ReactComponent as CalendarIcon } from 'Assets/Icons/Calendario.svg'
import moment from 'moment'

import { useClickOutside } from '../../utils/customHooks'

import { Container, ModalContainer, ModalItem, SubLabel } from './styled'

const DateInput = ({
  label,
  error,
  value,
  onChange,
  selectRange,
  onRemove,
  height,
  showIcon,
  left,
  right,
  range,
  border,
  ...rest
}) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState('')
  const myRef = React.createRef()
  useClickOutside(myRef, () => setVisible(false))

  const handleChange = date => {
    setVisible(false)
    onChange(date)
  }

  return (
    <Container height={height} showIcon={showIcon} border={border}>
      <FormControl error={error} fullWidth>
        <TextField
          value={value ? moment(value).format('DD/MM/YYYY') : ''}
          placeholder={label}
          onChange={e => {
            if (!e.target.value) onRemove()
          }}
          variant='filled'
          onFocus={() => setVisible(true)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <CalendarIcon />
              </InputAdornment>
            ),
          }}
        />
        {visible && (
          <ModalContainer ref={myRef} left={left} right={right} range={range}>
            <ModalItem>
              <SubLabel range={range}>{t('filterPlaceholderDate')}</SubLabel>
              <Calendar {...rest} onChange={handleChange} value={value} className='searchCalendar' />
            </ModalItem>
          </ModalContainer>
        )}
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Container>
  )
}

DateInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  error: PropTypes.string || PropTypes.bool,
  label: PropTypes.string,
  selectRange: PropTypes.bool,
  onRemove: PropTypes.func,
  height: PropTypes.string,
  showIcon: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  range: PropTypes.bool,
  border: '',
}

DateInput.defaultProps = {
  placeholder: '',
  value: '',
  onChange: () => {},
  onRemove: () => {},
  error: '',
  label: '',
  selectRange: false,
  height: '',
  showIcon: '',
  left: '',
  right: '',
  range: 'false',
  border: PropTypes.string,
}

export default DateInput
