import React from 'react'

import PropTypes from 'prop-types'
import { Modal, Confirmation } from 'Components/atoms'

const ModalCenter = ({
  icon,
  showModal,
  setShowModal,
  onAccept,
  onCancel,
  message,
  color,
  titleColor,
  title,
  width,
  height,
  action,
  cancel,
  showClose,
  cancelAction,
}) => (
  <Modal
    isOpen={showModal}
    setIsOpen={setShowModal}
    width={width}
    height={height}
    showClose={showClose}
    overflowInside='hidden'
  >
    <Confirmation
      icon={icon}
      title={title}
      onAccept={onAccept}
      onCancel={onCancel}
      message={message}
      color={color}
      titleColor={titleColor}
      action={action}
      cancelAction={cancelAction}
      cancel={cancel}
    />
  </Modal>
)

ModalCenter.propTypes = {
  icon: PropTypes.any,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  message: PropTypes.string,
  color: PropTypes.string,
  titleColor: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  action: PropTypes.string,
  cancelAction: PropTypes.string,
  cancel: PropTypes.string,
  showClose: PropTypes.bool,
}

ModalCenter.defaultProps = {
  icon: null,
  showModal: false,
  setShowModal: () => {},
  onAccept: () => {},
  onCancel: () => {},
  message: '',
  color: '',
  titleColor: '',
  title: '',
  width: '',
  height: '',
  action: '',
  cancelAction: '',
  cancel: '',
  showClose: true,
}

export default ModalCenter
