import styled, { css } from 'styled-components'

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
`

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 100px;
  overflow-y: scroll;

  ${props => {
    const tutorial = props.$tutorial ? '3rem' : '0px'
    const needsApproval = props.$needsApproval ? '138px' : '0px'
    return css`
      height: calc(100vh - 21rem - ${tutorial} - ${needsApproval});
    `
  }}
`

export const TopbarContainer = styled.div`
  padding: 2rem 1.25rem;
`

export const ProviderCardLoadingContainer = styled.div`
  margin: 0 1.25rem 1.5rem;
`
