import { makeStyles } from '@material-ui/core'
import colors from 'Assets/colors'
import styled, { css } from 'styled-components'

export const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    textTransform: 'none',
  },
}))

export const Container = styled.div`
  height: ${process.env.REACT_APP_ENVIRONMENT_NAME === 'dev'
    ? 'calc(100vh - 168px - 24px)'
    : 'calc(100vh - 168px)'}; // 24px for development bar
  overflow-y: scroll;

  ${props =>
    props.$tutorial &&
    (process.env.REACT_APP_ENVIRONMENT_NAME === 'dev'
      ? css`
          height: calc(100vh - 216px - 24px);
        `
      : css`
          height: calc(100vh - 216px);
        `)}
`

export const TitleContainer = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`

export const TopbarContainer = styled.div`
  padding: 2rem 1.25rem;
`

export const ShoppingListContainer = styled.div`
  padding: 0 20px;
`

export const ShoppingListItemContainer = styled.div`
  margin-bottom: 20px;
  padding: 0px 16px;
  background-color: ${props => props.theme.colors.gray98b};
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
`

export const SeparatorTwo = styled.div`
  flex: 5;
`

export const SeparatorLine = styled.div`
  border-right: 0.1px solid rgba(51, 51, 51, 0.24);
  align-self: stretch;
  margin-right: 16px;
`

export const ListItemName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  margin-right: 36px;
  flex: 3;
`

export const ListItemSection = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.gray40l};
  margin-right: 24px;
`

export const ShowButton = styled.div`
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  margin-right: 38px;
  cursor: pointer;
  margin-right: 48px;

  &:hover {
    text-decoration: underline;
  }
`

export const DeleteButton = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.gray40l};
  margin-right: 24px;

  cursor: pointer;
  margin-right: 56px;

  &:hover {
    text-decoration: underline;
  }
`

export const DivButton = styled.div``

export const SingleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.white};

  .header {
    background: ${colors.white};
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 16px 20px;
    margin-bottom: 2px;
  }

  .content {
    padding: 20px;

    height: calc(100vh - 300px);
    overflow-y: scroll;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title-actions {
        display: flex;

        .delete {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: ${colors.red};
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-left: 53px;

          &:hover {
            text-decoration: underline;
          }

          span {
            margin-left: 8px;
          }
        }

        .change {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: ${colors.black};
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    background-color: ${colors.gray98b};
    border-top: 1px solid rgba(51, 51, 51, 0.24);
    height: 65px;
    align-items: center;
    padding-right: 8px;
  }
`

export const AddCartButtonContainer = styled.div`
  width: 288px;
  height: 56px;
  background-color: ${colors.secondary};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-transform: none;
  color: ${colors.white};
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }

  .flag {
    background: ${colors.cart};
    color: ${colors.black};
    padding: 0 8px;
    border-radius: 8px;
    margin-left: 8px;
    line-height: 24px;
  }

  &.disabled {
    background-color: ${colors.gray40l};
    pointer-events: none;
  }
`

export const ProviderProductsCardContainer = styled.div`
  background: ${colors.gray98b};
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  margin-bottom: 24px;

  .provider-header {
    display: flex;
    padding: 16px;

    .provider-name {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: ${colors.black};
      flex: 1;
      display: flex;
    }

    .provider-total {
      font-family: Inter;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: ${colors.black};
      margin-right: 24px;
    }

    .provider-amount {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${colors.black};
      background: ${colors.white};
      border-radius: 4px;
      padding: 0 8px;
    }
  }

  .provider-content {
    border-top: 1px solid rgba(51, 51, 51, 0.24);
  }

  .provider-product-header {
    display: flex;
    padding: 16px;

    span {
      display: flex;
      flex: 1;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${colors.black};
      padding-left: 16px;
    }
  }
`

export const ProductCardContainer = styled.div`
  .product-content {
    margin: 0 16px 16px 16px;
    display: flex;
    align-items: center;
    height: 80px;
    background: ${colors.white};
    border: 1px solid rgba(51, 51, 51, 0.24);
    border-radius: 8px;

    .product-left {
      display: flex;
      flex: 1;
      padding: 16px;
    }

    .product-separator {
      border-right: 1px solid rgba(51, 51, 51, 0.24);
      align-self: stretch;
    }

    .product-right {
      display: flex;
      flex: 1;
      padding: 16px;
    }

    .product-name {
      display: flex;
      flex: 1;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${colors.black};
      padding-left: 16px;
    }

    .product-total {
      display: flex;
      flex: 1;
      align-self: center;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${colors.black};
    }

    .product-amount {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${colors.black};
      flex: 1;
      display: flex;
      align-self: center;
    }
  }
`
export const ProductButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

export const ButtonCart = styled.div`
  width: 100%;
  background-color: ${props => (props.hasAmount ? (props.focused ? '#f4f1f1' : colors.white) : colors.secondary)};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-transform: none;
  color: ${props => (props.hasAmount ? colors.black : colors.white)};
  transition: all 0.2s;
  display: flex;
  justify-content: ${props => (props.hasAmount ? 'flex-end' : 'center')};
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: ${props => (props.hasAmount ? null : 'pointer')};
  border: ${props => (props.hasAmount ? `2px solid ${colors.cart}` : null)};
  border: ${props => (props.error ? `2px solid ${colors.red}` : null)};
  height: 56px;
  margin: ${props => props.margin};

  &:hover {
    opacity: ${props => (props.hasAmount ? 1 : 0.8)};
    transform: ${props => (props.hasAmount ? null : 'translateY(-2px)')};
    box-shadow: ${props => (props.hasAmount ? null : '0 3px 5px rgba(51, 51, 51, 0.2)')};
  }

  &:active {
    transform: ${props => (props.hasAmount ? null : 'translate(-1px)')};
    box-shadow: ${props => (props.hasAmount ? null : '0 1px 3px rgba(51, 51, 51, 0.4)')};
  }

  .flag {
    background: ${colors.cart};
    color: ${colors.black};
    padding: 0 8px;
    border-radius: 8px;
    margin-left: 8px;
    line-height: 24px;
  }

  &.disabled {
    background-color: ${colors.gray40l};
    pointer-events: none;
  }
`

export const NoResultsBox = styled.div`
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  max-width: 660px;
  margin: 0 auto;
`
